import styled from 'styled-components'
import { Link } from '@naturacosmeticos/natds-web'

export const CountdownLinkContainer = styled.div`
  display: flex;
`

export const LinkWithoutDecoration = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`
