import { Popover } from '@naturacosmeticos/natds-web'
import React, { useState } from 'react'
import { Icon } from '../icon'

type IconPopoverProps = {
  iconName: string,
  popoverText: string,
  iconSize?: string,
  className?: string;
  iconColor?: string;
}

export const IconPopover: React.FC<IconPopoverProps> = ({
  iconName,
  popoverText,
  iconSize,
  className,
  iconColor,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Icon
        data-testid="iconPopover"
        name={iconName as any}
        size={iconSize as any}
        color={iconColor as any}
        className={className}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        style={{ zIndex: 9 }}
        anchorEl={anchorEl}
        open={open}
        id="popover"
        maxWidth={0}
        placement="bottom"
        popperOptions={{}}
      >
        <span dangerouslySetInnerHTML={{__html: popoverText}} />
      </Popover>
    </>
  )
}
