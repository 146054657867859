export enum LevelName {
  City = 'city',
  Cologne = 'cologne',
  Neighborhood = 'neighborhood',
  Complement = 'complement',
  ComplementaryData = 'complementaryData',
  Country = 'country',
  Number = 'number',
  References = 'references',
  State = 'state',
  Street = 'street',
  ZipCode = 'zipCode',
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3',
  Level4 = 'level4',
  DeliveryZipCode = 'deliveryZipCode',
  DeliveryComplement = 'deliveryComplement',
  DeliveryNumber = 'deliveryNumber',
  DeliveryStreet = 'deliveryStreet',
  DeliveryCity = 'deliveryCity',
  DeliveryCologne = 'deliveryCologne',
  DeliveryLevel1 = 'deliveryLevel1',
  DeliveryLevel2 = 'deliveryLevel2',
  DeliveryLevel3 = 'deliveryLevel3',
  DeliveryLevel4 = 'deliveryLevel4',
  DeliveryReference = 'deliveryReference',
  addressDeliveryType = 'addressDeliveryType',
  Urbanizacion = 'urbanizacion',
}
