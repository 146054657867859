import React from 'react'
import styled from 'styled-components'
import {
 Dialog, DialogContent, DialogTitle, DialogContentText, IconButton, Icon, Avatar, Link,
} from '@naturacosmeticos/natds-web'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import NatAvatar from '@/main/assets/pngs/nat-avatar.png'
import { DigitalStoreStatusEmailStepMessages } from '@/domain/models/messages/messages'

const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    .MuiTypography-root {
      display: flex;
      justify-content: space-between;
    }
  }
  .MuiDialogContent-root {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 42px;
    
    .MuiAvatar-root {
      margin: 16px 0 8px 0%;
    }
    .MuiLink-root {
      margin-bottom: 24px;
      color: #227BBD;
    }
  }
`

type NatDialogProps = {
  modalOpen: boolean,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  messages: DigitalStoreStatusEmailStepMessages
}

export const NatDialog = ({ modalOpen, setModalOpen, messages }: NatDialogProps) => (
  <StyledDialog
    PaperProps={{}}
    fullWidth
    maxWidth="xs"
    open={modalOpen}
    onBackdropClick={() => setModalOpen(false)}
    onClose={() => setModalOpen(false)}
    scroll="paper"
  >
    <DialogTitle>
      {messages.title}
      <IconButton
        aria-label="Cerca"
        color="inherit"
        onClick={() => setModalOpen(false)}
      >
        <Icon
          name="outlined-navigation-close"
          size="tiny"
        />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText align="center">
        {messages.helpBot.text}
      </DialogContentText>
      <Avatar size="huge" src={NatAvatar} />
      <Link href={messages.helpBot.link} variant="body1" color="default" target="_blank">
        <WhatsAppIcon />
        {' '}
        {messages.helpBot.buttonLabel}
      </Link>
      <DialogContentText>
        {messages.hint}
      </DialogContentText>
    </DialogContent>
  </StyledDialog>
)
