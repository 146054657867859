import React from 'react'
import { FieldErrors } from 'react-hook-form'
import {
  FormHelperText, InputLabel,
} from '@naturacosmeticos/natds-web'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import { Button } from '@/main/components'
import {
  Input,
  InputContainer,
} from '@/main/components/text-field/text-field-with-button.styles'
import { getLabel } from '@/main/components/text-field/helper/get-label'
import { InputStateHelpTextProvider } from '@naturacosmeticos/natds-web/dist/Components/InputStateHelpTextProvider'
import {
  CustomChangeEvent,
  handleOnChange,
} from '@/main/components/text-field/helper/handle-onchange'
import { getEloToggleByCountry } from '@/utils/getEloToggle'
import { getFinalErrorMessage, StringOrObjectOfStrings } from './helper/error-message-getter'

export type ButtonTextFieldProps = {
  buttonLabel: React.ReactNode
  buttonOnClick: () => void
  buttonOnChange?: () => void
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  shouldButtonBeDisabled?: boolean
}

export type InputTextFieldProps = {
  disabled?: boolean,
  mask?: Mask
  onBlur?: () => void
  onChange?: (event: CustomChangeEvent) => void
  ref?: React.MutableRefObject<any>
  value?: number | string
}

export type TextFieldWithButtonProps = {
  id?: string;
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  errorMessage?: StringOrObjectOfStrings;
  successMessage?: StringOrObjectOfStrings;
  validChars?: RegExp
  infoText?: React.ReactNode;
  errors?: FieldErrors;
  showAsterisk?: boolean;
  optionalText?: string;
  invalid?: boolean
  name?: string
  isSuccess?: boolean
  buttonProps:ButtonTextFieldProps
  inputProps: InputTextFieldProps
}

export const TextFieldWithButton: React.FC<TextFieldWithButtonProps> = ({
id,
label,
placeholder,
required,
errorMessage,
successMessage,
validChars,
infoText,
errors,
showAsterisk = true,
optionalText,
invalid,
name,
inputProps,
buttonProps,
isSuccess = false,
}) => {
  const {
    variant = 'contained',
    color = 'primary',
  } = buttonProps
  const finalErrorMessage = getFinalErrorMessage(errors, errorMessage, id)
  const labelFormatted = getLabel({
    labelText: label, showAsterisk, required, optionalText,
  })
  const getState = () => {
    if (invalid) return 'error'
    if (isSuccess) return 'success'
    return null
  }

  return (
    <>
      <InputLabel state={getState()} htmlFor={id}>
        {labelFormatted}
      </InputLabel>
      <InputContainer>
        <Input
          ref={inputProps.ref}
          onBlur={inputProps.onBlur}
          value={inputProps.value}
          name={name}
          id={id}
          data-testid={id}
          placeholder={placeholder}
          disabled={inputProps.disabled}
          state={getState()}
          mask={inputProps.mask}
          onChange={(event: CustomChangeEvent) => handleOnChange({
            event,
            onChange: inputProps.onChange,
            validChars,
          })}
        />
        <Button
          color={color}
          variant={getEloToggleByCountry() ? 'outlined' :variant}
          size="large"
          disabled={buttonProps.shouldButtonBeDisabled}
          onClick={buttonProps.buttonOnClick}
          onChange={buttonProps.buttonOnChange}
        >
          {buttonProps.buttonLabel}
        </Button>
      </InputContainer>
      <InputStateHelpTextProvider state={getState()} />
      <FormHelperText state={getState()}>
        {/*
         // @ts-ignore */}
        {invalid ? invalid.message || finalErrorMessage : undefined}
        {isSuccess ? successMessage : undefined}
      </FormHelperText>
      <FormHelperText>{infoText}</FormHelperText>
    </>
  )
}
