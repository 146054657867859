import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { PreRegisterComplete } from '@/domain/use-cases/pre-register'
import { RemotePreRegisterComplete } from '@/data/use-cases/pre-register'

export const makeRemotePreRegister = (): PreRegisterComplete => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpPostClient = new AxiosHttpClient(axiosBaseUrl)
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY

  const remoteGetPersonData = new RemotePreRegisterComplete(
    httpPostClient,
    xApiKey,
  )

  return remoteGetPersonData
}
