import { RemoteCompletePersonalData } from '@/data/use-cases/personal-data/remote-complete-personal-data'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteCompletePersonalData = (): RemoteCompletePersonalData => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpPostClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteCompletePersonalData(httpPostClient, xApiKey)
}
