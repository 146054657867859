import { GeographicalLevelsMessages } from '@/domain/models/geographical-levels/geographical-levels-translation'
import { RequiredErrorMessage } from '@/domain/models/messages/messages'

export const getGeoCommercialConfig = (messages: GeographicalLevelsMessages & RequiredErrorMessage, length: number, isDeliveryAddress = false) => {
  const createLevelConfig = (index: number) => {
    const key = `level${index}`
    return {
      id: isDeliveryAddress ? `deliveryLevel${index}` : key,
      label: messages[key],
      placeholder: messages.placeholders[key],
    }
  }
  return ({
    level1: createLevelConfig(1),
    level2: createLevelConfig(2),
    level3: length >= 3 ? createLevelConfig(3) : null,
    level4: length === 4 ? createLevelConfig(4) : null,
    requiredErrorMessage: messages.requiredErrorMessage,
  })
}
