import { HttpStatusCode, HttpPostClient } from '@/data/protocols/http'
import {
  SaveDigitalStore,
  SaveDigitalStoreParams,
} from '@/domain/use-cases/digital-store/save-digital-store'
import { UnexpectedError } from '@/domain/errors'

import { CountryId } from '@/domain/models/country'
import { BadRequestError, ConflictError } from '@/data/protocols/errors'
import { CompanyId } from '@/domain/models/company/company-id'

export type SaveDigitalStoreHttpRequestHeaders = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
  'x-api-key': string
}

export type SaveDigitalStoreHttpRequestBody = {
  agreement: {
    id: string
    accepted: boolean
  }
  extraEntries: {}
}

export type SaveDigitalStoreHttpRequestResponseBody = {
  saved: boolean
}

export class RemoteSaveDigitalStore implements SaveDigitalStore {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient<
      SaveDigitalStoreHttpRequestHeaders,
      SaveDigitalStoreHttpRequestBody,
      SaveDigitalStoreHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  save = async (params: SaveDigitalStoreParams): Promise<boolean> => {
    const headers: SaveDigitalStoreHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      countryId: params.countryId,
      personId: params.personId,
      companyId: params.companyId,
    }

    const body: SaveDigitalStoreHttpRequestBody = {
      agreement: {
        id: params.agreementId,
        accepted: params.accepted,
      },
      extraEntries: params.extraEntries,
    }

    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(/:digitalStoreName/i, params.digitalStoreName),
      headers,
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body.saved
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.conflict:
        throw new ConflictError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
