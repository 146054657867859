import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ControlledTextField } from '../../../../components/text-field'
import { validateFullName } from './validate-full-name'

export type FullNameMessages = {
  label: string,
  placeholder: string,
  errorMessage: string,
  infoText: string,
}

export type FullNameCustomProps = {
  shouldInfoTextBeVisible: boolean;
  showAsteriskInRequiredLabel?: boolean
  allowedCharsRegex?: RegExp
}

export type FullNameTextFieldProps = {
  id?: string;
  messages: FullNameMessages;
  customProps?: FullNameCustomProps;
}

export const FullNameTextField: React.FC<FullNameTextFieldProps> = ({ messages, id = 'fullName', customProps = { shouldInfoTextBeVisible: false, allowedCharsRegex: new RegExp(/^[a-zA-Z ]+$/) } }) => {
  const { control } = useFormContext()
  const allowedCharsListRegex = new RegExp(customProps.allowedCharsRegex.source, 'i')

  return (
    <ControlledTextField
      id={id}
      label={messages.label}
      control={control}
      placeholder={messages.placeholder}
      errorMessage={messages.errorMessage}
      rules={{ validate: validateFullName }}
      validChars={allowedCharsListRegex}
      showAsterisk={customProps.showAsteriskInRequiredLabel}
      infoText={customProps.shouldInfoTextBeVisible ? messages.infoText : undefined}
      required
    />
  )
}
