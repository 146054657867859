import styled from 'styled-components'
import Card, { CardProps } from '@material-ui/core/Card'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export type StyledCardProps = CardProps
export const StyledCard = styled(Card)`
  margin: 0 0 74px;
  padding: 16px;
  box-shadow: none;
  min-height: unset;

  &.status_screen {
    height: calc(100vh - 7.1rem);
  }

  @media ${minDeviceSizeForMediaQueries.mobileS} {
    &.status_screen {
      height: calc(100vh - 8.7rem);
    }

    &.gray_card {
      background-color: #fafafa;
    }
  }

  @media ${minDeviceSizeForMediaQueries.mobileXL} {
    padding: 40px 64px;
    margin: 0 auto;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 1px 1px #00000029;
    border-radius: 6px;
    max-width: 608px;
    margin-top: 56px;
    margin-bottom: 0;
  }
`
