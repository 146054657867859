import React from 'react'
import MaterialModal from '@material-ui/core/Modal'
import { Button } from '@/main/components'
import {
  StyledCardContent,
  StyledCardHeader,
  CenteredCard, LoadingContainer, StyledCardActions,
} from '@/main/components/modal/modal.styles'
import { ProgressIndicator } from '@/main/components/progress-indicator'

type modal = {
  modalOpened: boolean,
  title: string | JSX.Element,
  content: string | JSX.Element | JSX.Element[],
  closeModal: (boolean) => void,
  closeLabel?: string,
  onAccept?: () => void,
  acceptLabel?: string,
  dataTestId?: string
  disableAcceptButton?: boolean,
  bordered?: boolean,
  buttons?: JSX.Element | JSX.Element[],
  isLoading?: boolean,
  width?: string,
}

const Loader = () => (
  <LoadingContainer>
    <ProgressIndicator data-testid="modal-loading-container" />
  </LoadingContainer>
)

export const Modal: React.FC<modal> = ({
  modalOpened,
  closeModal,
  onAccept,
  title,
  content,
  closeLabel = 'Close',
  acceptLabel = 'Accept',
  dataTestId = 'modal-component',
  disableAcceptButton = false,
  buttons,
  isLoading = false,
  width,
  bordered = true,
}) => (
  <MaterialModal open={modalOpened} data-testid={dataTestId}>
    <CenteredCard width={width}>
      {isLoading ? (<Loader />) : (
        <>
          <StyledCardHeader title={title} />
          <StyledCardContent bordered={bordered}>{content}</StyledCardContent>
          <StyledCardActions>
            {buttons ? <>{buttons}</> : (
              <>
                <Button
                  aria-label="close"
                  onClick={() => closeModal(false)}
                >
                  {closeLabel}
                </Button>
                {
                  onAccept
                  && (
                    <Button variant="contained" color="primary" aria-label="submit" onClick={onAccept} disabled={disableAcceptButton}>
                      {acceptLabel}
                    </Button>
                  )
                }
              </>
            )}
          </StyledCardActions>
        </>
        )}
    </CenteredCard>
  </MaterialModal>
)
