import { RemoteUpdatePersonSubstatus } from '@/data/use-cases/person'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteUpdatePersonSubstatus = () => {
  const apiUrl = '/people/:personId/substatus/:substatusId'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteUpdatePersonSubstatus = new RemoteUpdatePersonSubstatus(
    apiUrl,
    httpPatchClient,
    xApiKey,
  )
  return remoteUpdatePersonSubstatus
}
