import {
  Typography, Link, Card,
} from '@naturacosmeticos/natds-web'
import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const DescriptionTexts = styled(Typography)`
  text-align: center;
  white-space: pre-line;

  &.bold {
    font-weight: bolder;
  }

  &.description {
    margin: 0 28px 0 29px
  }
`

export const Content = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 0;
  border-radius: 6px;
  padding: 36px 24px;
  max-width: 480px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    padding: 36px 64px;
    margin-top: 5rem;
  }
`

export const Anchor = styled(Link)`
  &.anchor_link {
    margin-top: ${({ theme }) => `${theme.sizes.semi}px`};
  }
`
