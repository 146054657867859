import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const SearchContainer = styled.div`
  width: 100%;
  @media ${minDeviceSizeForMediaQueries.tablet} {
    max-width: 70%;
  }
`

export const IndicationInfoContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.sizes.small}px`};
`

export const ProgressContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 50vh;
`
