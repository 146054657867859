import { HttpStatusCode } from '@/data/protocols/http'

export class BadRequestError extends Error {
  statusCode: HttpStatusCode

  data: any

  constructor(data) {
    super()
    this.name = 'BadRequestError'
    this.statusCode = 400
    this.data = data
  }
}
