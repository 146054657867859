/* eslint-disable jsx-a11y/anchor-is-valid */
import { CountryId } from '@/domain/models/country'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { ProfileConfigs } from '@/domain/models/tenant/tenant-configs'
import { validateStructureCodeSegmentation } from '@/domain/models/tenant/tenant-configs/helpers/validate-structure-code-segmentation'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { PersonDataResponse } from '@/domain/use-cases/person/get-person-data'
import { Icon } from '@/main/components'
import MFEGrowthPlan from '@/main/components/growth-plan/mfe-growth-plan'
import { IdentityInfoContext } from '@/main/contexts'
import { useMiGroupRedPage } from '@/main/hooks/useMiGroupRedPage'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { setObjectInStorage } from '@/main/hooks/useStorage'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { DigitalSpace } from '@/main/pages/my-profile/digital-space/digital-space'
import { useMyProfileStyles } from '@/main/pages/my-profile/my-profile.styles'
import { Container, GridContainer, GridItem, Typography } from '@naturacosmeticos/natds-web'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { Address } from './address/address'
import { MyProfileDataApi } from './api/my-profile-person-data-api'
import { CnCredit } from './cn-credit/cn-credit'
import { ConsultantData } from './consultant-data/consultant-data'
import { ProfileContact } from './contact/contact'
import { MGMBanner } from './mgm-banner'
import { ChangeGroupSectorLeader, MyLeader } from './my-leader/my-leader'
import { MyProfileLoader } from './my-profile-loader'

export type MyProfileDataPageProps = {
  api: MyProfileDataApi
}

export const MyProfile: React.FC<MyProfileDataPageProps> = ({ api }) => {
  const [isLoading, setIsLoading] = useState(true)
  const {
    countryId,
    companyId,
    personId,
    accessTokenNaturaWeb,
    loginId,
    externalComponent,
    isCN,
    country,
    cycle,
    setProfileRoles,
    isCNCeased,
  } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const {
    showMyLeader,
    showCnCredit,
    digitalSpace: { showDigitalSpace },
    showGrowthPlan,
    showEditAddress = true,
    showEditContact = true,
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const messages = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const [person, setPerson] = useState<PersonDataResponse>(null)
  const [digitalStoreData, setDigitalStoreData] = useState<any>(null)
  const [address, setAddress] = useState<any>(null)
  const classComponent = useMyProfileStyles()
  const [showEditButton, setShowEditButton] = useState(false)
  const [showModalEditAddress, setShowModalEditAddress] = useState(false)
  const [groupSectorLeader, setGroupSectorLeader] = useState<ChangeGroupSectorLeader>()
  const location = useLocation()
  const { isMiGroupRedPage } = useMiGroupRedPage()

  const isCountryPeruChileOrColombia = [
    CountryId.Peru,
    CountryId.Chile,
    CountryId.Colombia,
    CountryId.Ecuador,
  ].includes(countryId)
  const iconSectionTitle = isCountryPeruChileOrColombia
    ? `${classComponent.iconSectionTitle} ${classComponent.title}`
    : classComponent.iconSectionTitle

  // function getRenderDigitalSpaceByCountry(countryCode: string) {
  //   if (process.env.ENABLE_DIGITAL_STORE_MY_PROFILE_COUNTRY && !process.env.ENABLE_DIGITAL_STORE_MY_PROFILE_COUNTRY.includes(countryCode.toLocaleUpperCase())) {
  //     // if have a list and country is not in the list, disable
  //     return false
  //   }
  //   return true // if not have a list, enable (default)
  // }

  const shouldAllowEdit = (value: boolean): boolean => {
    if (isMiGroupRedPage) {
      return false
    }
    return value
  }

  async function fetchPersonData() {
    const addressesPromise = api.getMultipleAddresses({
      personId,
      countryId,
      companyId,
      requesterPersonId: personId,
    })
    const statusPromise = api.getStatusAddressCache({ personId })
    const person = await api.getMyProfile({ countryid: countryId, companyid: companyId, personId })
    const personData = person as PersonDataResponse
    if (!personData.person.naturaCode) {
      personData.person.naturaCode = loginId
    }
    setPerson(personData)
    setProfileRoles(personData.person.roles)
    setObjectInStorage('recommendedSalesHierarchies', personData.person.recommendedSalesHierarchies)
    setShowEditButton(validateStructureCodeSegmentation(personData.person.recommendedSalesHierarchies))

    const isEcuador = countryId === CountryId.Ecuador

    try {
      if (showDigitalSpace && !isCNCeased) {
        const digitalStoreDataPromise = api.getDigitalSpaceData({
          companyId,
          countryId,
          personCode: personData?.person.naturaCode,
          accessToken: accessTokenNaturaWeb,
          personId,
        })

        const digitalStoreData = await digitalStoreDataPromise
        setDigitalStoreData(digitalStoreData)
      }
    } catch (error) {
      console.error(error)
    }

    const addresses = await addressesPromise
    setAddress(addresses.deliveryAddress)

    if (!isEcuador) {
      const status = await statusPromise
      setShowModalEditAddress(status.exist)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchPersonData()
  }, [api, location])

  return (
    <MyProfileLoader loading={isLoading}>
      <Container className={classComponent.container}>
        <Helmet>
          <title>{messages.container.myData}</title>
        </Helmet>
        <GridContainer className="natds2" spacing={2}>
          {!isMiGroupRedPage && (
            <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
              <ConsultantData personData={person} groupSectorLeader={groupSectorLeader} />
            </GridItem>
          )}

          {showGrowthPlan && (
            <GridItem
              className="natds2"
              style={isMiGroupRedPage ? { marginTop: '-48px' } : {}}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <MFEGrowthPlan
                country={country}
                personData={person}
                token={accessTokenNaturaWeb}
                cycle={cycle}
              />
            </GridItem>
          )}

          {externalComponent && (
            <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
              {externalComponent}
            </GridItem>
          )}
          <MGMBanner />
          {showCnCredit && (
            <GridItem
              className={`natds2 ${!isMiGroupRedPage && classComponent.forcePadding}`}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div className={classComponent.sectionTitle}>
                <Icon name="outlined-content-indicator" size="small" className={iconSectionTitle} />
                <Typography variant="h6" className={isCountryPeruChileOrColombia ? classComponent.title : ''}>
                  {messages.credit.credit}
                </Typography>
              </div>
              <GridContainer className="natds1" spacing={2}>
                <CnCredit personData={person} accessTokenNaturaWeb={accessTokenNaturaWeb} />
              </GridContainer>
            </GridItem>
          )}
          <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
            <div className={classComponent.sectionTitle}>
              <Icon name="outlined-social-person" size="small" className={iconSectionTitle} />
              <Typography variant="h6" className={isCountryPeruChileOrColombia ? classComponent.title : ''}>
                {messages.container.myData}
              </Typography>
            </div>
            <GridContainer className="natds1" spacing={2}>
              <GridItem className="natds2" lg={6} md={12} sm={12} xs={12}>
                <ProfileContact
                  showEditButton={shouldAllowEdit(!isCNCeased && showEditContact)}
                  personData={person}
                />
              </GridItem>
              <GridItem className="natds2" lg={6} md={12} sm={12} xs={12}>
                <Address
                  addressData={address}
                  showEditButton={shouldAllowEdit(!isCNCeased && showEditAddress && showEditButton && isCN)}
                  showModalEditAddress={showModalEditAddress}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          {showMyLeader && <MyLeader personData={person} onChangeGroupSectorLeader={setGroupSectorLeader} />}

          {showDigitalSpace && !isCNCeased && (
            <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
              <div className={classComponent.sectionTitle}>
                <Icon name="outlined-media-monitor" size="small" className={iconSectionTitle} />
                <Typography variant="h6" className={isCountryPeruChileOrColombia ? classComponent.title : ''}>
                  {messages.container.digitalSpace}
                </Typography>
              </div>
              <DigitalSpace digitalStore={digitalStoreData} personData={person} />
            </GridItem>
          )}
        </GridContainer>
      </Container>
    </MyProfileLoader>
  )
}
