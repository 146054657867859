export const defaultMessages = {
  availableName: 'This space is available :)',
  digitalStoreDigitalSpace: 'E-Store',
  digitalStoreDigitalSpaceAddress: 'E-Store URL',
  digitalStoreUrl: 'www.natura.com.br/consultoria/',
  digitalStoreDigitalSpaceName: 'E-Store Name',
  errorSaving: 'An error has ocurred while saving E-Store. Try again.',
  genericError: 'An error ocurred. Please try again later.',
  invalidNameSpace: 'Your E-Store must contain only lowercase characters and numbers',
  digitalStoreNext: 'Next',
  digitalStorePrevious: 'Back',
  digitalStoreStatus: 'Status',
  unavailableName: 'This space is unavailable :(',
  digitalStoreVerify: 'Verify',
  digitalStoreDescription:
    'Do you want to have your own <b>E-store</b> on Natura? Thus, you can sell Natura products online without leaving your home.',
  digitalStoreInfo:
    'Choose a simple, easy-to-remember name for your customers to reach you. Make sure it is available and remember that you need to type lowercase letters without spaces.',
  digitalStoreTermsAcceptance: 'I have read and agree to the Natura E-Store Terms and Conditions.',
  digitalStoreTermsAcceptanceLink: 'E-Store Terms and Conditions',
  digitalStoreTermsAcceptanceModalClose: 'Close',
  digitalStoreTermsAcceptanceModalAccept: 'Accept',
}

export const FACELIFT_WEB_SOURCESYSTEM_ID = '102'
export const MAIN_EMAIL_TYPE_ID = 1
