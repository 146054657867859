import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

type useI18nResponse = {
  translate: TFunction
}

export const useI18n = (): useI18nResponse => {
  const { t: translate } = useTranslation()
  return { translate }
}
