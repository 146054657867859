import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { UnlinkAgreement } from '@/domain/use-cases/agreement'
import { RemoteUnlinkAgreement } from '@/data/use-cases/agreement/remote-unlink-agreement'

export const makeRemoteUnlinkAgreement = (): UnlinkAgreement => {
  const apiUrl = '/people/:personId/agreement/unlink'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteUnlinkAgreement(
    apiUrl,
    httpPatchClient,
    xApiKey,
  )
}
