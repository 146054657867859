import React from 'react'

const PlayStoreSVG = ({ text = 'DISPONIBLE EN' }: { text?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={135} height={40}>
      <defs>
        <linearGradient id="linearGradient1" x1={0.915} y1={6.614} x2={-0.383} y2={5.945} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#00a0ff" />
          <stop offset={0.007} stopColor="#00a1ff" />
          <stop offset={0.26} stopColor="#00beff" />
          <stop offset={0.512} stopColor="#00d2ff" />
          <stop offset={0.76} stopColor="#00dfff" />
          <stop offset={1} stopColor="#00e3ff" />
        </linearGradient>
        <linearGradient
          id="linearGradient2"
          x1={1.076}
          y1={17.098}
          x2={-1.305}
          y2={17.098}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ffe000" />
          <stop offset={0.409} stopColor="#ffbd00" />
          <stop offset={0.775} stopColor="orange" />
          <stop offset={1} stopColor="#ff9c00" />
        </linearGradient>
        <linearGradient
          id="linearGradient3"
          x1={0.862}
          y1={10.863}
          x2={-0.501}
          y2={9.093}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#ff3a44" />
          <stop offset={1} stopColor="#c31162" />
        </linearGradient>
        <linearGradient
          id="linearGradient4"
          x1={-0.188}
          y1={13.583}
          x2={0.421}
          y2={12.793}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#32a071" />
          <stop offset={0.068} stopColor="#2da771" />
          <stop offset={0.476} stopColor="#15cf74" />
          <stop offset={0.801} stopColor="#06e775" />
          <stop offset={1} stopColor="#00f076" />
        </linearGradient>
        <style>{'.h{isolation:isolate;opacity:.12}'}</style>
      </defs>
      <rect width={135} height={40} rx={5} />
      <path
        d="M140 10.8a4.2 4.2 0 0 1 4.2 4.2v30a4.2 4.2 0 0 1-4.2 4.2H15a4.2 4.2 0 0 1-4.2-4.2V15a4.2 4.2 0 0 1 4.2-4.2h125m0-.8H15a5.015 5.015 0 0 0-5 5v30a5.015 5.015 0 0 0 5 5h125a5.015 5.015 0 0 0 5-5V15a5.015 5.015 0 0 0-5-5Z"
        transform="translate(-10 -10)"
        style={{
          fill: '#a6a6a6',
        }}
      />
      <path
        d="M78.136 31.752a4.253 4.253 0 1 0 4.264 4.253 4.192 4.192 0 0 0-4.264-4.253Zm0 6.83a2.584 2.584 0 1 1 2.4-2.577 2.46 2.46 0 0 1-2.4 2.577Zm-9.313-6.83a4.253 4.253 0 1 0 4.269 4.253 4.192 4.192 0 0 0-4.269-4.253Zm0 6.83a2.584 2.584 0 1 1 2.4-2.577 2.46 2.46 0 0 1-2.4 2.577Zm-11.079-5.525v1.8h4.317a3.765 3.765 0 0 1-.982 2.271 4.42 4.42 0 0 1-3.335 1.321 4.8 4.8 0 0 1 0-9.6A4.6 4.6 0 0 1 61 30.141l1.273-1.273a6.3 6.3 0 0 0-4.527-1.821 6.606 6.606 0 1 0 0 13.211 6.036 6.036 0 0 0 4.605-1.858 5.962 5.962 0 0 0 1.563-4.221 5.829 5.829 0 0 0-.1-1.127Zm45.308 1.4a3.953 3.953 0 0 0-3.641-2.706 4.041 4.041 0 0 0-4.011 4.254 4.162 4.162 0 0 0 4.221 4.253 4.23 4.23 0 0 0 3.544-1.885l-1.45-.967a2.43 2.43 0 0 1-2.094 1.176 2.165 2.165 0 0 1-2.063-1.288l5.687-2.353Zm-5.8 1.418a2.333 2.333 0 0 1 2.223-2.475 1.645 1.645 0 0 1 1.579.9ZM92.629 40H94.5V27.5h-1.871Zm-3.062-7.3H89.5a2.948 2.948 0 0 0-2.239-.95 4.257 4.257 0 0 0 0 8.506 2.9 2.9 0 0 0 2.239-.967h.064v.611c0 1.627-.869 2.5-2.271 2.5a2.354 2.354 0 0 1-2.143-1.515l-1.627.677a4.053 4.053 0 0 0 3.77 2.513c2.191 0 4.044-1.289 4.044-4.43v-7.636h-1.77Zm-2.142 5.88a2.587 2.587 0 0 1 0-5.155 2.4 2.4 0 0 1 2.275 2.596 2.376 2.376 0 0 1-2.275 2.561Zm24.381-11.08h-4.471V40h1.865v-4.736h2.606a3.888 3.888 0 1 0 0-7.765Zm.048 6.025H109.2v-4.286h2.654a2.143 2.143 0 1 1 0 4.285Zm11.532-1.795a3.505 3.505 0 0 0-3.33 1.913l1.657.692a1.768 1.768 0 0 1 1.705-.917 1.8 1.8 0 0 1 1.962 1.609v.128a4.127 4.127 0 0 0-1.946-.482c-1.785 0-3.6.981-3.6 2.814a2.889 2.889 0 0 0 3.1 2.751 2.633 2.633 0 0 0 2.381-1.223h.064v.965h1.8v-4.794c.002-2.219-1.654-3.457-3.793-3.457Zm-.225 6.851c-.612 0-1.464-.305-1.464-1.061 0-.965 1.061-1.335 1.978-1.335a3.329 3.329 0 0 1 1.7.418 2.262 2.262 0 0 1-2.214 1.977ZM133.743 32l-2.139 5.42h-.064L129.32 32h-2.01l3.329 7.575-1.9 4.214h1.946L135.818 32Zm-16.807 8h1.864V27.5h-1.866Z"
        transform="translate(-10 -10)"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M20.435 17.538a2.007 2.007 0 0 0-.462 1.405v22.116a2.005 2.005 0 0 0 .462 1.405l.074.072L32.9 30.147v-.292l-12.391-12.39Z"
        transform="translate(-10 -9.999)"
        style={{
          fill: 'url(#linearGradient1)',
        }}
      />
      <path
        d="M37.028 34.279 32.9 30.147v-.292l4.131-4.131.093.054 4.893 2.78c1.4.794 1.4 2.093 0 2.887l-4.893 2.78Z"
        transform="translate(-10 -9.999)"
        style={{
          fill: 'url(#linearGradient2)',
        }}
      />
      <path
        d="M37.122 34.225 32.9 30 20.435 42.464a1.627 1.627 0 0 0 2.079.061l14.608-8.3"
        transform="translate(-10 -9.999)"
        style={{
          fill: 'url(#linearGradient3)',
        }}
      />
      <path
        d="m37.122 25.777-14.608-8.3a1.627 1.627 0 0 0-2.079.061L32.9 30Z"
        transform="translate(-10 -9.999)"
        style={{
          fill: 'url(#linearGradient4)',
        }}
      />
      <path
        d="m37.029 34.132-14.515 8.247a1.665 1.665 0 0 1-2 .011l-.075.074.074.073a1.665 1.665 0 0 0 2-.011l14.608-8.3Z"
        transform="translate(-10 -9.999)"
        style={{
          isolation: 'isolate',
          opacity: 0.2,
        }}
      />
      <path
        className="h"
        d="M10.435 32.319a2.005 2.005 0 0 1-.462-1.405v.147a2 2 0 0 0 .462 1.4l.075-.074ZM32.015 21.301l-4.986 2.833.093.093 4.893-2.78a1.755 1.755 0 0 0 1.048-1.446 1.861 1.861 0 0 1-1.048 1.3Z"
      />
      <path
        d="m22.514 17.623 19.5 11.08A1.861 1.861 0 0 1 43.063 30a1.753 1.753 0 0 0-1.048-1.444l-19.5-11.08c-1.4-.794-2.541-.134-2.541 1.466v.147c-.001-1.6 1.142-2.26 2.54-1.466Z"
        transform="translate(-10 -9.999)"
        style={{
          opacity: 0.25,
          isolation: 'isolate',
          fill: '#fff',
        }}
      />
      <text
        transform="translate(70 13)"
        style={{
          fontSize: 8,
          fontFamily: 'Roboto-Medium,Roboto',
          fontWeight: 500,
          letterSpacing: '.008em',
          fill: '#fff',
        }}
      >
        <tspan x={-28.874} y={0}>
          {text}
        </tspan>
      </text>
    </svg>
  )
}

export default PlayStoreSVG
