/* eslint-disable jsx-a11y/anchor-is-valid */
import { SendCodeValidationOrigin } from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'
import { AuthorizationCodeMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { AuthorizationCodeConfigs } from '@/domain/models/tenant/tenant-configs'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { CountdownLink } from '@/main/components/countdown-link/countdown-link'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { Container } from '@/main/components/pages-structures'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { IdentityInfoContext, NavigationContext, NavigationContextProps } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Spacing, TextField, Typography } from '@naturacosmeticos/natds-web'
import React, { useContext, useEffect, useState } from 'react'
import { AuthorizationCodePageApi } from './api/make-authorization-code-api'
import { useGetPersonData } from './hooks/use-get-person-data'

type AuthorizationCodePageProps = {
  api: AuthorizationCodePageApi
}

export const AuthorizationCodePage: React.FC<AuthorizationCodePageProps> = ({ api }) => {
  const { sendAuthorizationCode, acceptAuthorizationCode, sendCodeValidation, checkValidationCode } = api
  const [showDialogAlertEvent, setShowDialogAlertEvent] = useState(false)
  const [authorizationCode, setAuthorizationCode] = useState('')
  const [helperText, setHelperText] = useState('')
  const [authorizationCodeFieldState, setAuthorizationCodeFieldState] = useState(undefined)
  const [isSendingAuthorizationCode, setIsSendingAuthorizationCode] = useState(false)
  const {
    countryId,
    userId,
    personId: personIdFromContext,
    role,
    businessModel,
    sourceSystem,
    functionId,
    companyId,
    authorization,
    tenantId,
    authorizationCodeMessageAvonMX,
  } = useContext(IdentityInfoContext)
  const isNaturaMEX = tenantId === TenantId.NaturaMEX
  const isNaturaCOL = tenantId === TenantId.NaturaCOL
  const isAvonMX = tenantId === TenantId.AvonMEX
  const { goToNextPage, goToPreviousPage, bypassStep } = useContext<NavigationContextProps>(NavigationContext)
  const [personId, setpersonId] = useState(() => {
    const personIdInURL = window.location.pathname.split('/').pop()
    return personIdFromContext || personIdInURL
  })

  const [uiInformation, setUiInformation] = useState({ phoneNumber: null, email: null })
  const authorizationCodeSuccess = authorizationCodeFieldState === 'success'
  const authorizationCodeLoading = authorizationCodeFieldState === 'loading'
  const authorizationCodeDisabled = authorizationCodeSuccess || authorizationCodeLoading
  const { phoneNumberHyphenIndex } = useTenantConfigs(
    tenantId,
    Page.AuthorizationCode
  ) as AuthorizationCodeConfigs
  const messages = usePageMessages(Page.AuthorizationCode, {
    phone: formatPhoneNumber(uiInformation.phoneNumber, phoneNumberHyphenIndex),
    email: uiInformation.email,
  }).messages as AuthorizationCodeMessages

  const { personData, personDataLoading } = useGetPersonData({
    relations: ['telephones'],
    personId,
    countryId,
    companyId,
  })
  const PHONE_NUMBER_TYPE = 1
  const phoneExists = personData?.person?.phones
  const filterPhoneNumber = phoneExists?.find((phone) => phone.type === PHONE_NUMBER_TYPE)

  const sendAuthorizationCodeHandler = async () => {
    try {
      if (isAvonMX && !authorization) {
        setUiInformation({
          phoneNumber: `${personData.person?.phones?.[0].areaCode}${personData.person?.phones?.[0].phoneNumber}`,
          email: null,
        })

        await sendCodeValidation({
          countryId,
          companyId,
          personId,
          channel: 'SMS',
          origin: SendCodeValidationOrigin.CREATE_CN,
        })

        return
      }

      const response = await sendAuthorizationCode({
        userId,
        personId,
        countryId,
        authorization,
        companyId,
        businessModel,
        role,
        functionId,
        sourceSystem,
      })
      const { shouldDisplayPage, phoneNumber, email } = response

      if (!shouldDisplayPage && !isNaturaMEX && !isNaturaCOL && !isAvonMX) {
        bypassStep()
      }

      setUiInformation({ phoneNumber, email })
    } catch {
      setShowDialogAlertEvent(true)
    } finally {
      setIsSendingAuthorizationCode(false)
    }
  }

  useEffect(() => {
    const isAuthCountry = isNaturaMEX || isNaturaCOL || isAvonMX
    if (!authorization) {
      setIsSendingAuthorizationCode(true)
    }

    if (isAuthCountry && !personDataLoading && filterPhoneNumber && !authorization) {
      setUiInformation({ phoneNumber: filterPhoneNumber.phoneNumber, email: null })
      sendAuthorizationCodeHandler()
    }

    if (!isSendingAuthorizationCode && authorization) {
      sendAuthorizationCodeHandler()
      setIsSendingAuthorizationCode(true)
    }
  }, [personDataLoading])

  const validateAuthorizationCode = async (event) => {
    const sanitizedAuthorizationCode = event.target.value.replace(/[^0-9]/, '').substr(0, 6)
    setAuthorizationCode(sanitizedAuthorizationCode)

    const validAuthorizationCodeLength = 6
    if (sanitizedAuthorizationCode.length === validAuthorizationCodeLength) {
      setAuthorizationCodeFieldState('loading')

      if (isAvonMX && !authorization) {
        const response = await checkValidationCode({
          personId,
          countryId,
          companyId,
          verificationCode: sanitizedAuthorizationCode,
          checkType: SendCodeValidationOrigin.CREATE_CN,
          channel: 'SMS',
          sequence: personData?.person?.phones?.[0]?.sequence,
        })

        if (response.code === 'VERIFIED_TOKEN') {
          setAuthorizationCodeFieldState('success')
          setHelperText(messages.authorizationCodeMessages.success)
          return
        }

        if (response.code === 'INVALID_TOKEN') {
          setAuthorizationCodeFieldState('error')
          setHelperText(messages.authorizationCodeMessages.errorAutoRegistration)
          return
        }

        return
      }

      const validAuthorizationCode = await acceptAuthorizationCode({
        countryId,
        personId,
        confirmationCode: sanitizedAuthorizationCode,
        businessModel,
        role,
        functionId,
        sourceSystem,
        companyId: Number(companyId),
        userId,
      })

      if (validAuthorizationCode) {
        setAuthorizationCodeFieldState('success')
        setHelperText(messages.authorizationCodeMessages.success)
      } else {
        setAuthorizationCodeFieldState('error')
        setHelperText(messages.authorizationCodeMessages.error)
      }
    } else {
      setAuthorizationCodeFieldState(undefined)
      setHelperText('')
    }
  }

  const getAuthorizationCodeInstructions = () => {
    if (uiInformation.email && uiInformation.phoneNumber) {
      return messages.authorizationInstructions.emailAndPhoneNumber
    }
    if (uiInformation.phoneNumber) {
      return messages.authorizationInstructions.phoneNumber
    }
    return messages.authorizationInstructions.email
  }

  useEffect(() => {
    if (!personIdFromContext || !personId) {
      const personIdInURL = window.location.pathname.split('/').pop()

      setpersonId(personIdInURL)
    }
  }, [personId, personIdFromContext])

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={goToNextPage}
      disableNextButton={!authorizationCode || !authorizationCodeSuccess}
      isLoading={isSendingAuthorizationCode}
    >
      <Spacing padding="standard">
        <Typography variant="h5">{messages.title}</Typography>
        <Spacing className="natds2" display="flex" marginY="standard">
          <Typography variant="body1" color="textSecondary">
            <ParseStringToReact stringToParse={getAuthorizationCodeInstructions()} />
          </Typography>
        </Spacing>
        <TextField
          label={messages.authorizationCode.label}
          helpText={helperText}
          value={authorizationCode}
          onChange={validateAuthorizationCode}
          placeholder={messages.authorizationCode.placeholder}
          state={authorizationCodeFieldState}
          inputProps={{ disabled: authorizationCodeDisabled }}
          id="authorization-code"
        />
        {authorizationCodeFieldState !== 'success' && (
          <Spacing paddingTop="semi">
            <CountdownLink
              onClick={() => sendAuthorizationCodeHandler()}
              timerMessageSeconds={messages.resendCodeMessage}
              linkText={messages.resendCodeButton}
            />
          </Spacing>
        )}
        {authorizationCodeMessageAvonMX && (
          <DialogAlert
            show={showDialogAlertEvent}
            messages={{
              infoText: messages.dialog.infoText.unexpectedError,
              closeButtonText: messages.dialog.closeButtonText,
            }}
            closeDialogCallback={() => setShowDialogAlertEvent(false)}
          />
        )}
      </Spacing>
    </Container>
  )
}

const formatPhoneNumber = (phoneNumber: string, hyphenIndex: number | undefined) => {
  const phone = phoneNumber || '**********'

  if (hyphenIndex) {
    return `${phone.slice(0, hyphenIndex)}-${phone.slice(hyphenIndex)}`
  }
  return phone
}
