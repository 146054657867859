import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: 0px 0px 0px -20px;
`

export const AgreementsPanelContainer = styled.section`
  margin: ${({ theme }) => theme.spacing * 2}px 0;
  & > hr:first-child {
    margin-bottom: ${({ theme }) => theme.spacing * 2}px;
  }
  button {
    margin: ${({ theme }) => theme.spacing * 2}px 0;
    width: 160px;
  }
`
