import * as React from 'react'
import { SectionTitle, TextParagraph } from '@/main/components'
import { Container } from './digital-store-status-warning-panel.styles'

export const DigitalStoreStatusWarningPanel: React.FunctionComponent<{
    title?: string,
    content?: string,
}> = ({ title, content }) => (
  <Container>
    <SectionTitle title={title} />
    <TextParagraph content={content} />
  </Container>
    )
