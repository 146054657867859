import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteGetCNContacts } from '@/data/use-cases/register-maintenance-security-validation/remote-get-cn-contacts'

export const makeRemoteGetCNContacts = (): RemoteGetCNContacts => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteGetCNContacts = new RemoteGetCNContacts(
    httpGetClient,
    xApiKey,
  )

  return remoteGetCNContacts
}
