export enum Page {
  Documentation = 'documentation',
  DigitalStore = 'digitalStore',
  PersonalData = 'personalData',
  Attachments = 'attachments',
  DigitalSignaturePage = 'digitalSignaturePage',
  DigitalStoreBrasil = 'digitalStoreBrasil',
  PaymentAccountStatus = 'paymentAccountStatus',
  PaymentAccountStatusSuccess = 'paymentAccountStatusSuccess',
  Address = 'address',
  AuthorizationCode = 'authorizationCode',
  Completed = 'completed',
  PreRegisterConfirmation = 'preRegisterConfirmation',
  Indication = 'indication',
  DigitalStoreStatus = 'digitalStoreStatus',
  PreRegister = 'preRegister',
  PendingSignature = 'pendingSignature',
  DigitalStoreStatusNotFound = 'digitalStoreStatusNotFound',
  DigitalStoreStatusPayment = 'digitalStoreStatusPayment',
  DigitalStoreStatusDefaultContent = 'digitalStoreStatusDefaultContent',
  DigitalStoreStatusPendingAgreements = 'digitalStoreStatusPendingAgreements',
  DigitalStoreStatusIntroductionStep = 'digitalStoreStatusIntroductionStep',
  DigitalStoreStatusEmailStep = 'digitalStoreStatusEmailStep',
  DigitalStoreStatusAccountStep = 'digitalStoreStatusAccountStep',
  DigitalStoreStatusAccountResetStep = 'digitalStoreStatusAccountResetStep',
  DigitalStoreStatusAccountLinkStep = 'digitalStoreStatusAccountLinkStep',
  MaintenanceRegister = 'maintenanceRegisterPage',
  MaintenancePersonalData = 'maintenancePersonalDataPage',
  MaintenanceAddress = 'maintenanceAddressPage',
  MaintenanceSecurityValidation = 'maintenanceSecurityValidation',
  MyProfile = 'profile',
  MaintenanceValidationCode = 'maintenanceValidationCode',
  MaintenanceValidationCodePhoneEmail = 'maintenanceValidationCodePhoneEmail',
  MaintenanceAddressApproved = 'maintenanceAddressApproved',
  MaintenanceAddressRejected = 'maintenanceAddressRejected',
  MaintenancePhoneEmailApproved = 'maintenancePhoneEmailApproved',
  MaintenancePhoneEmailRejected = 'maintenancePhoneEmailRejected'
}
