import { BadRequestError } from '@/data/protocols/errors'
import { HttpGetClient, HttpResponse, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import {
  ValidIfEmailAlreadyExistInfoRequestHeaders,
  ValidIfEmailAlreadyExistParams,
  ValidIfEmailAlreadyExistResponse,
} from '@/domain/use-cases/register-maintenance/save-consultant-info'
import { ValidIfEmailAlreadyExisInfo } from '@/domain/use-cases/register-maintenance/valid-if-email-already-exist'
import { FACELIFT_WEB_SOURCESYSTEM_ID } from '@/utils/constants'

export class RemoteValidIfEmailAlreadyExistInfo implements ValidIfEmailAlreadyExisInfo {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
      ValidIfEmailAlreadyExistInfoRequestHeaders,
      ValidIfEmailAlreadyExistParams
    >,
    private readonly xApiKey: string
  ) {}

  valid = async (params: ValidIfEmailAlreadyExistParams): Promise<ValidIfEmailAlreadyExistResponse> => {
    const { 
      personId,
      email,
      companyId,
      countryId,
      requesterPersonId
    } = params

    const response = await this.httpGetClient.get({
      url: `${this.url}/${personId}?email=${email}`,
      headers: {
        'x-api-key': this.xApiKey,
        companyid: companyId,
        countryid: countryId,
        sourcesystem: FACELIFT_WEB_SOURCESYSTEM_ID,
        requesterPersonId,
      },
    })

    return this.handleResponse(response)
  }

  private handleResponse(response: HttpResponse) {
    const { statusCode, body } = response
    switch (statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(body.error)
      default:
        throw new UnexpectedError()
    }
  }
}
