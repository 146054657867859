import {
  GetDigitalStoreInfo,
  GetDigitalStoreInfoParams,
  GetDigitalStoreInfoResponse,
} from '@/domain/use-cases/digital-store/get-digital-store-info'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { NotFoundError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { CompanyId } from '@/domain/models/company/company-id'

export type GetDigitalStoreInfoHttpRequestHeaders = {
  'x-api-key': string
  companyId: CompanyId
  countryId: string,
}

export type GetDigitalStoreInfoHttpRequestResponseBody = GetDigitalStoreInfoResponse

export class RemoteGetDigitalStoreInfo implements GetDigitalStoreInfo {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
      GetDigitalStoreInfoHttpRequestHeaders,
      GetDigitalStoreInfoHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  get = async (params: GetDigitalStoreInfoParams): Promise<GetDigitalStoreInfoHttpRequestResponseBody> => {
    const headers: GetDigitalStoreInfoHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      companyId: params.companyId,
      countryId: params.countryId,
    }
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(/:personId/i, params.personId),
      headers,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
