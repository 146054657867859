import { MaintenanceAddressRejectedMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenanceAddressRejectedMessages(translate: TFunction): MaintenanceAddressRejectedMessages {
  return {
    rejectededIcon: translate('maintenanceAddressRejected.rejectededIcon'),
    title: translate('maintenanceAddressRejected.title'),
    description: translate('maintenanceAddressRejected.description'),
    myProfileButton: translate('maintenanceAddressRejected.myProfileButton'),
  }
}