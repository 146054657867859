import { BadRequestError } from '@/data/protocols/errors'
import { HttpGetClient, HttpResponse, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { AddressResponse, GetAddress, GetAddressParams } from '@/domain/use-cases/address'

type GetAddressHttpRequestHeaders = {
  'x-api-key': string
}

export class RemoteGetAddress implements GetAddress {
  constructor(
    private readonly httpGetClient: HttpGetClient<GetAddressHttpRequestHeaders>,
    private readonly xApiKey: string,
  ) {}

  get = async (params: GetAddressParams): Promise<AddressResponse> => {
    const url = `/address/places/${params.placeId}`
    const headers = {
      tenantid: params.tenantId,
      sessiontoken: params.sessionToken,
      'x-api-key': this.xApiKey,
    }

    const response = await this.httpGetClient.get({ url, headers })

    return this.handleResponse(response)
  }

  private handleResponse(response: HttpResponse) {
    const { statusCode, body } = response
    switch (statusCode) {
      case HttpStatusCode.ok:
        return body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(body.error)
      default:
        throw new UnexpectedError()
    }
  }
}
