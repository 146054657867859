import { CountryId, Country } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { BUSINESS_MODELS } from '@/domain/models/person'
import { DocumentType } from '@/domain/models/documents/document'
import { AddressesType, EmailsType, PhonesType } from '@/main/contexts'

export type GetPersonRelationsOptions =
  | 'additionalInformation'
  | 'addresses'
  | 'approvedCandidates'
  | 'attachments'
  | 'call'
  | 'contactReferences'
  | 'documents'
  | 'emails'
  | 'paymentPlan'
  | 'personPoliciesAccepts'
  | 'personRoles'
  | 'personRolesFunctions'
  | 'products'
  | 'recommendedSalesHierachies'
  | 'socialMedias'
  | 'telephones'
  | 'verifications'

export type GetPersonDataParams = {
  personId: string
  countryId: CountryId
  companyId: CompanyId
  relations: GetPersonRelationsOptions[]
}

export type GetMyLeaderDataParams = {
  personId: string
  companyId: CompanyId
  countryId: CountryId
  businessModelId: BUSINESS_MODELS
  userId: string
  userName: string
  relations: string
}

export type GetConsultantGvResponsibleParams = {
  personId: string
  country: Country
  companyId: CompanyId
  businessModelId: BUSINESS_MODELS
}

export enum HIERARCHIES_LEVEL_ID {
  COUNTRY = 0,
  GM = 1,
  RE = 2,
  GV = 3,
  SECTOR = 4,
  GROUP = 5,
}

export type Hierarchies = {
  cycle: number
  id: string
  levelId: number
}

export type ApprovedCandidate = {
  candidateId?: string
  personId?: string
}

export type PersonData = {
  approvedCandidate?: ApprovedCandidate
  birthday?: string
  businessModelId?: BUSINESS_MODELS
  countryId?: number
  createdAt?: string
  createdBy?: string
  createdFrom?: number
  fatherName?: string
  firstName?: string
  gender?: number
  lastName?: string
  motherName?: null
  name?: string
  nationalityId?: number
  nickname?: string
  occupationId?: number
  personId?: string
  recommendedSalesHierarchies?: Hierarchies[]
  registrationDate?: string
  registrationStatusId?: number
  registrationSubstatusId?: number
  sourceSystem?: number
  tenantId?: string
  updatedAt?: string
  updatedBy?: string
  emails?: EmailsType[]
  phones?: PhonesType[]
  addresses?: AddressesType[]
  documents?: DocumentType[]
  additionalInformation?: any
  naturaCode?: string
  messageKey?: string
  roles?: Array<PersonRoles>
}

export type PersonRoles = {
  businessModelId: number
  companyId: CompanyId
  createdAt: string
  endDate: string | null
  functionId: number
  personRolesId: string
  rejectionReasonId: string
  roleId: number
  salesHierarchyId: string
  sourceSystem: number
  startDate: string
  status: number
  substatus: number
  updatedAt: string
}

export type LeaderDataDetails = {
  phones: PhonesType[]
  emails: EmailsType[]
  firstName: string
  birthday: string
  countryId: number
  lastName: string
  name: string
}

export type LeaderCommercialStructure = {
  personCode: number
  personId: string
  structureLevelId: number
  structureName: string
  structureInfo: {
    group: {
      structureCode: number
      structureLevelId: number
      structureName: string
    }
    sector: {
      structureCode: number
      structureLevelId: number
      structureName: string
    }
  }
}

export type MyLeaderDataResponse = {
  personDetails: LeaderDataDetails
  commercialStructureDetails: LeaderCommercialStructure
}

export type PersonGvResponsible = {
  personCode: number
}

export type PersonDataResponse = {
  person: PersonData
}

export type LeaderDataResponse = {
  person: MyLeaderDataResponse
}

export interface GetPersonData {
  get: (data: GetPersonDataParams) => Promise<PersonData | PersonDataResponse | {} | null | unknown>
}

export interface GetPersonGvResponsible {
  get: (data: GetConsultantGvResponsibleParams) => Promise<PersonGvResponsible>
}

export interface GetMyLeaderData {
  get: (data: GetMyLeaderDataParams) => Promise<PersonData | PersonDataResponse | {} | null | unknown>
}
