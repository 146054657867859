import React from 'react'
import styled from 'styled-components'
import { Button, Intro, IButtonProps } from '@/main/components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

type StyleButtonType = {
  hasMarginRight?: boolean
} & IButtonProps

type StyledFloatingButtonContainerType = {
  isFloatingButton?: boolean
}

export const StyledButton = styled<React.FC<StyleButtonType>>(Button)`
  &.step-confirmation-button {
    margin-right: ${(props) => (props.hasMarginRight ? 16 : 0)}px;
  }
`

export const StyledStepConfirmationContainer = styled.div`

  .MuiDrawer-root > .MuiPaper-root {
    border: none;
  }
  --marginHorizontal: 0px;
  margin-left: var(--marginHorizontal);
  margin-right: var(--marginHorizontal);

  margin-top: 24px;
  margin-bottom: 40px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    --marginHorizontal: -64px;
    margin-top: 64px;
  }
`

export const StyledIntro = styled(Intro)`
  padding-bottom: 32px;
  text-align: left;

  @media (max-width: 600px) {
    margin-left: 16px
  }
`
