import { PersonRegisterStatus, PersonStatus } from '@/data/use-cases/person/remote-get-person-status'
import { COUNTRY_POLLING_DELAY, COUNTRY_POLLING_TIMEOUT } from '@/domain/models/country/country-polling'
import { Page } from '@/domain/models/page/page'
import { CompletedConfigs } from '@/domain/models/tenant/tenant-configs'
import { completeRegistrationRequest } from '@/infra/global-state/redux/store/ducks/complete-registration'
import { AuthenticatedUserContext, FormContext, FormContextProps, IdentityInfoContext } from '@/main/contexts'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { ConfirmationApi } from '@/main/pages/confirmation/api/make-confirmation-page-api'
import { ApprovedWrapper } from '@/main/pages/confirmation/approved'
import { getPersonStatus } from '@/main/pages/confirmation/get-person-status'
import { LoadingWrapper } from '@/main/pages/confirmation/loading'
import { PendingWrapper } from '@/main/pages/confirmation/pending'
import { RegisterConfirmation } from '@/main/pages/confirmation/register-confirmation'
import { RejectedWrapper } from '@/main/pages/confirmation/rejected'
import { usePolling } from '@/main/pages/confirmation/usePolling'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

type CompletedWrapperProps = {
  api: ConfirmationApi
}

export const CompletedWrapper: React.FC<CompletedWrapperProps> = ({ api }) => {
  const {
    tenantId,
    personId,
    countryId,
    businessModel,
    role,
    functionId,
    companyId,
    sourceSystem,
    authorization,
    isCN,
  } = useContext(IdentityInfoContext)
  const { sellerId, userRole } = useContext(AuthenticatedUserContext)
  const { person } = useContext<FormContextProps>(FormContext)

  const { shouldOnlyShowConfirmation, shouldCheckStatus } = useTenantConfigs(
    tenantId,
    Page.Completed
  ) as CompletedConfigs
  const dispatch = useDispatch()

  const [personResponseStatus, setPersonResponseStatus] = useState<PersonStatus>({
    personRegisterStatus: PersonRegisterStatus.LOADING,
  })

  const DELAY_IN_SECONDS: number = COUNTRY_POLLING_DELAY
  const TIMEOUT_IN_SECONDS: number = COUNTRY_POLLING_TIMEOUT[countryId] || COUNTRY_POLLING_TIMEOUT.DEFAULT
  const isLoadingOrDraft = (status): boolean =>
    status.personRegisterStatus === PersonRegisterStatus.LOADING ||
    status.personRegisterStatus === PersonRegisterStatus.DRAFT
  const isApprovedOrRejected = (status): boolean =>
    status.personRegisterStatus === PersonRegisterStatus.APPROVED ||
    status.personRegisterStatus === PersonRegisterStatus.REJECTED

  const setPersonStatusAsPending = () => {
    if (isLoadingOrDraft(personResponseStatus)) {
      setPersonResponseStatus({ personRegisterStatus: PersonRegisterStatus.PENDING })
    }
  }

  const { setStop, stop } = usePolling({
    callback: () =>
      personId &&
      getPersonStatus({
        personId,
        countryId,
        companyId,
        functionId,
        businessModel,
        role,
      })
        .then((personStatus) => {
          setPersonResponseStatus(personStatus)
        })
        .catch((error) => {
          console.error(error)
          setStop(true)
        }),
    delay: DELAY_IN_SECONDS,
    timeout: TIMEOUT_IN_SECONDS,
    shouldUsePolling: shouldCheckStatus,
    timeoutCallback: () => setPersonStatusAsPending(),
  })

  useEffect(() => {
    if (isApprovedOrRejected(personResponseStatus)) {
      setStop(true)
    }
  }, [personResponseStatus, setStop])

  useEffect(() => {
    if (personId && countryId && businessModel && role && functionId && sourceSystem && companyId) {
      dispatch(
        completeRegistrationRequest(
          personId,
          countryId,
          businessModel,
          role,
          functionId,
          sourceSystem,
          companyId,
          authorization,
          userRole,
          0,
          sellerId,
          person.additionalInformation?.introducerSellerId
        )
      )
    }
  }, [
    dispatch,
    personId,
    countryId,
    businessModel,
    role,
    functionId,
    sourceSystem,
    companyId,
    authorization,
    userRole,
    sellerId,
    person.additionalInformation?.introducerSellerId,
  ])

  const getComponentByStatus = (status: PersonRegisterStatus) => {
    if (!isCN || (status === PersonRegisterStatus.PENDING && stop)) {
      return <PendingWrapper />
    }
    if (status === PersonRegisterStatus.REJECTED) {
      return <RejectedWrapper />
    }
    if (isCN && status === PersonRegisterStatus.APPROVED) {
      return (
        <ApprovedWrapper
          companyId={companyId}
          countryId={countryId}
          approvedCandidateId={personResponseStatus?.approvedCandidate?.candidateId}
          personId={personId}
          paymentPlanId={personResponseStatus?.paymentPlan?.id}
        />
      )
    }
    return <LoadingWrapper duration={TIMEOUT_IN_SECONDS} />
  }

  return shouldOnlyShowConfirmation ? (
    <RegisterConfirmation getIndicationPerson={api.getIndication} />
  ) : (
    getComponentByStatus(personResponseStatus.personRegisterStatus)
  )
}
