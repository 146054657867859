import { Page } from '@/domain/models/page/page'
import { CompletedConfigs } from '@/domain/models/tenant/tenant-configs'
import { IdentityInfoContext } from '@/main/contexts'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import React, { useContext, useEffect, useState } from 'react'
import { Image } from './approved.styles'

type ImageDynamicLoadType = {
  alt: string
}

export const ImageDynamicLoad = ({ alt }: ImageDynamicLoadType) => {
  const [imageLoaded, setImage] = useState<{ default: string }>({ default: '' })
  const { tenantId } = useContext(IdentityInfoContext)
  const { companyIconName } = useTenantConfigs(tenantId, Page.Completed) as CompletedConfigs

  useEffect(() => {
    loadImage()
  }, [companyIconName])

  const loadImage = () => {
    import(`@/main/assets/pngs/${companyIconName}.png`).then((img) => {
      setImage(img)
    })
  }

  return <Image alt={alt} src={imageLoaded.default} />
}
