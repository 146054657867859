import {
  PersonStatus,
  RemoteGetPersonStatusParams, RemoteGetPersonStatusResponse,
} from '@/data/use-cases/person/remote-get-person-status'
import { makeRemoteGetPersonStatus } from '@/main/factories/remote/person/make-remote-get-person-status'

export const getPersonStatus = async (params: RemoteGetPersonStatusParams): Promise<PersonStatus> => {
  const remoteGetPersonStatus = makeRemoteGetPersonStatus()

  const getPersonStatusResponse : RemoteGetPersonStatusResponse = await remoteGetPersonStatus.getPersonStatus({
    personId: params.personId,
    companyId: params.companyId,
    functionId: params.functionId,
    role: params.role,
    businessModel: params.businessModel,
    countryId: params.countryId,
  })

  return getPersonStatusResponse.data
}
