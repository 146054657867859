import React, { ReactNode } from 'react'
import { StyledCardTitle } from './card-title.styles'
import { StyledSubtitle } from './Subtitle.styles'
import { StyledTitle } from './Title.styles'

export type CardTitleProps = {
  title: string | ReactNode
  subtitle?: string | ReactNode,
}

export const CardTitle: React.FC<CardTitleProps> = ({
  title,
  subtitle,
  ...props
}) => (
  <StyledCardTitle {...props} data-testid="CardTitle">
    <StyledTitle>{title}</StyledTitle>
    {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
  </StyledCardTitle>
)
