import {
    GetPersonGvResponsible,
    GetConsultantGvResponsibleParams,
    PersonGvResponsible,
  } from '@/domain/use-cases/person/get-person-data'
  import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
  import { NotFoundError } from '@/data/protocols/errors'
  import { UnexpectedError } from '@/domain/errors'
  import  { BUSINESS_MODELS } from '@/domain/models/person'
  import { Country } from '@/domain/models/country'
  import { CompanyId } from '@/domain/models/company/company-id'

  export type GetPersonGvResponsibleHeaders = {
    'x-api-key': string
    country: Country
    companyid: CompanyId
    'business-model-id': BUSINESS_MODELS
  }
  export class RemoteGetPersonGvResponsible implements GetPersonGvResponsible {
    constructor(
      private readonly url: string,
      private readonly httpGetClient: HttpGetClient<
        GetPersonGvResponsibleHeaders,
        PersonGvResponsible
      >,
      private readonly xApiKey: string,
    ) {}

    get = async (params: GetConsultantGvResponsibleParams): Promise<PersonGvResponsible> => {
      const headers: GetPersonGvResponsibleHeaders = {
        'x-api-key': this.xApiKey,
        country: params.country,
        'business-model-id': params.businessModelId,
        companyid: params.companyId,
      }
      const httpResponse = await this.httpGetClient.get({
        url: this.url.replace(/:personId/i, params.personId),
        headers,
      })
      switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
          return httpResponse.body
        case HttpStatusCode.notFound:
          throw new NotFoundError(httpResponse.body)
        default:
          throw new UnexpectedError()
      }
    }
  }