import React from 'react'
import styled, { css } from 'styled-components'
import MaterialLink from '@material-ui/core/Link'

type LinkType = 'primary' | 'secondary'

interface LinkProps {
  buttonType: LinkType
  children: React.ReactNode
  href: string
}

const WrapperToRemoveCustomPropsOfLink: React.FC<LinkProps> = ({ buttonType, ...props }) => <MaterialLink {...props} />

const StyledLink = styled(WrapperToRemoveCustomPropsOfLink)`
  color: #333;
  border-radius: 0.3rem;
  font-size: 1.6rem;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  ${(props) => props.buttonType === 'primary'
    && css`
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.141);
      background-color: #f4ab34;
      &:hover {
        background-color: #f6bc5d;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.141);
        text-decoration: none;
      }
    `}
  ${(props) => props.buttonType === 'secondary'
    && css`
      background-color: #fff;
      border: 1px solid #bbb;
      box-shadow: none;
      &:hover {
        box-shadow: none !important;
        text-decoration: none;
      }
    `}
  &.disabled {
    opacity: 0.5;
    background-color: #ccc;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.141);
  }
`

export const Link = (props: LinkProps) => <StyledLink {...props} />
