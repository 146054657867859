import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'
import { ValidationStatus } from '@/main/components/upload-file/upload-file'

export const useUploadFileStyles = makeStyles<Theme>((theme) => ({
  uploadContainer: {
    display: 'flex',
    width: '100%',
    borderRadius: '6px',
    boxShadow: '0px 1px 4px #00000024',
    minHeight: '80px',
    background: 'var(--global-light-surface) 0% 0% no-repeat padding-box',
    padding: ({ validationStatus }: { validationStatus: ValidationStatus }) => {
      if (validationStatus === undefined) {
        return `${theme.spacing(2)}px`
      }
      return `calc(${theme.spacing(2)}px - 2px)`
    },
    border: ({ validationStatus }: { validationStatus: ValidationStatus }) => {
      if (validationStatus === undefined) {
        return 'inherit'
      }
      return 'solid 2px'
    },
    borderColor: ({ validationStatus }: { validationStatus: ValidationStatus }) => {
      if (validationStatus === 'success') {
        return theme.palette.success.main
      }
      if (validationStatus === 'error') {
        return theme.palette.error.main
      }
      return 'none'
    },
  },
  statusIcon: {
    marginRight: theme.spacing(0.5),
  },
  thumbnailContent: {
    width: '80px',
    minWidth: '80px',
    height: '80px',
    margin: `0 ${theme.spacing(2)}px 0 0`,
  },
  thumbnail: {
    height: '100%',
    width: '100%',
    borderRadius: '5px',
    objectFit: 'cover',
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  subtitle: {
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
  },
}))
