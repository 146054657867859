import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteGetDigitalSpaceInfo } from '@/data/use-cases/my-profile/remote-get-digital-space-info'

export const makeGetDigitalSpaceInfo = (): RemoteGetDigitalSpaceInfo => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteGetDigitalSpaceInfo(httpGetClient, xApiKey)
}
