import { RemoteGetPersonGvResponsible } from '@/data/use-cases/person'
import { GetPersonGvResponsible } from '@/domain/use-cases/person'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeGetConsultantGvResponsible = (): GetPersonGvResponsible => {
  const apiUrl = `${'/people/:personId/gv-responsible'}`
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)
  const remoteGetConsultantInfo = new RemoteGetPersonGvResponsible(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteGetConsultantInfo
}