import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'

export enum SearchIndicationStatus {
  SUCCESS = 'SUCCESS',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
}

export type IndicationInfo = {
  sellerId?: string
  name?: string
}

export type PersonInfoInterface = {
  isSelected?: boolean
} & IndicationInfo

export type RemoteSearchIndicationParams = {
  personCode: string,
  companyId: CompanyId,
  countryId: CountryId
}

export type HttpClientHeaders = {
  'x-api-key': string
  companyId: CompanyId
  countryId: CountryId
}

export class RemoteSearchIndication {
  constructor(
    private readonly httpGetClient : HttpGetClient<HttpClientHeaders>,
    private readonly xApiKey: string,
  ) {}

  async search(params: RemoteSearchIndicationParams) : Promise<IndicationInfo> {
    const response = await this.httpGetClient.get({
      headers: {
        'x-api-key': this.xApiKey,
        companyId: params.companyId,
        countryId: params.countryId,
      },
      url: '/indication/search',
      query: {
        personCode: params.personCode,
      },
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body.data
      case HttpStatusCode.notFound:
        throw new Error('searchNotFound')
      default:
        throw new Error('searchError')
    }
  }
}
