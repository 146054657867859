import React, { useContext } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import {
  ClearIndicatorProps, components, LoadingIndicatorProps, OptionProps,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import {
  Typography,
} from '@/main/components/typography'
import { ThemeContext } from 'styled-components'
import { Spacing } from '@/main/components/spacing'
import { InputLabel } from '@/main/components/input'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { Icon } from '@/main/components/icon'
import { IIconProps } from '@naturacosmeticos/natds-web'
import { customStyles, ClearIndicatorIcon } from './search-async-select.styles'

export type SelectPromiseOptions = {
  value: string | number,
  label: string,
}

export type CustomSelectMessages = {
  label: string,
  placeholder: string,
  noOptions: string,
  loading: string,
  error?: string,
}

export type SearchAsyncSelectProps = {
  messages: CustomSelectMessages
  name: string,
  isDisabled?: boolean
  defaultValue?: SelectPromiseOptions
  optionIconProps?: Pick<IIconProps, 'name'>,
  handleSelectOptions?: (inputValue: SelectPromiseOptions) => void
  promiseOptions: (inputValue?: string) => Promise<SelectPromiseOptions[]>
} & Pick<ControllerProps<'input'>, | 'control' | 'rules'>

export const SearchAsyncSelect: React.FC<SearchAsyncSelectProps> = ({
  messages,
  name,
  isDisabled,
  defaultValue = null,
  optionIconProps,
  control,
  rules,
  handleSelectOptions,
  promiseOptions,
}) => {
  const {
    label,
    placeholder,
    noOptions,
    loading,
    error,
  } = messages

  const theme = useContext(ThemeContext)
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={(
        {
          onChange,
          onBlur,
          value,
          ref,
          name,
        },
        { invalid },
      ) => (
        <>
          <InputLabel
            id={`${name}-label-id`}
            htmlFor={name}
            error={!!invalid || !!error}
            color="primary"
          >
            {label}
          </InputLabel>
          <Spacing marginY="micro">
            <AsyncSelect
              id={name}
              name={name}
              aria-labelledby={`${name}-label-id`}
              styles={customStyles(theme, (invalid || !!error))}
              placeholder={placeholder}
              noOptionsMessage={() => noOptions}
              loadingMessage={() => loading}
              loadOptions={promiseOptions}
              openMenuOnFocus={false}
              openMenuOnClick={false}
              onChange={(value: SelectPromiseOptions) => {
                onChange(value)
                if (handleSelectOptions) {
                  handleSelectOptions(value)
                }
              }}
              onBlur={onBlur}
              value={value}
              ref={ref}
              isDisabled={isDisabled}
              isClearable
              components={{
                ClearIndicator: ({
                  innerProps: { ref, ...restInnerProps },
                }: ClearIndicatorProps<unknown, false>) => (
                  <div {...restInnerProps} ref={ref}>
                    <Spacing marginRight="tiny" display="flex">
                      <ClearIndicatorIcon
                        data-testid={`${name}-clear-indicator`}
                        name="outlined-action-cancel"
                        color="primary"
                        size="tiny"
                      />
                    </Spacing>
                  </div>
                ),
                LoadingIndicator: ({
                  innerProps: { ref, ...restInnerProps },
                }: LoadingIndicatorProps<unknown, false>) => (
                  <div {...restInnerProps} ref={ref}>
                    <Spacing marginRight="tiny" display="flex">
                      <ProgressIndicator size={24} />
                    </Spacing>
                  </div>
                ),
                Option: ({
                  children,
                  ...rest
                }: OptionProps<unknown, false>) => (
                  <components.Option {...rest}>
                    {optionIconProps ? (
                      <Spacing display="flex" marginRight="micro">
                        <Icon name={optionIconProps.name} size="tiny" />
                      </Spacing>
                    ) : <></>}
                    {children}
                  </components.Option>
                ),
              }}
            />
          </Spacing>
          {(invalid || error) && (
            <Typography aria-labelledby={`${name}-label-id`} variant="caption" color="error">
              {error}
              {/* @ts-ignore */}
              {!error && invalid.message}
            </Typography>
          )}
        </>
      )}
    />

  )
}
