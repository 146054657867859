export enum AddressOrder {
  STREET = 'STREET',
  NUMBER = 'NUMBER',
  COMPLEMENT = 'COMPLEMENT',
  REFERENCES = 'REFERENCES',
  COLOGNE = 'COLOGNE',
  NEIGHBORHOOD = 'NEIGHBORHOOD',
  CITY = 'CITY',
  STATE = 'STATE',
  COUNTRY = 'COUNTRY'
}
