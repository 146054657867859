import { HttpStatusCode, HttpPostClient } from '@/data/protocols/http'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'

export type SendLinkRequestHeaders = {
  'x-api-key': string
  tenantId: TenantId
  personId: string
}

export type SendLinkParams = {
  tenantId: TenantId
  personId: string
}

export class RemoteSendLink {
  constructor(
    private readonly httpPostClient: HttpPostClient<SendLinkRequestHeaders>,
    private readonly xApiKey: string,
  ) {}

  async send(params: SendLinkParams): Promise<void> {
    const response = await this.httpPostClient.post({
      headers: {
        'x-api-key': this.xApiKey,
        personId: params.personId,
        tenantId: params.tenantId,
      },
      url: '/link/send',
    })

    switch (response.statusCode) {
      case HttpStatusCode.noContent:
        return
      case HttpStatusCode.badRequest:
        throw new BadRequestError(response.body)
      default:
        throw new UnexpectedError()
    }
  }
}
