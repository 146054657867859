import React from 'react'
import { Drawer } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { Divider, Spacing } from '@/main/components'
import {
  StyledButton,
  StyledIntro,
  StyledStepConfirmationContainer,
} from './step-confirmation.styles'

type StepConfirmationPropsType = {
  title: string
  children?: any
  cancelText?: string
  isFloatingButton?: boolean
  onCancel?: (event) => void
  confirmText: string
  onConfirm?: (event) => void
}

export const StepConfirmation: React.FC<StepConfirmationPropsType> = ({
  title,
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
  isFloatingButton,
  children,
}) => {
  const isMinDeskSize = useMediaQuery(minDeviceSizeForMediaQueries.tablet)
  const renderButtons = () => {
    if (!isMinDeskSize && isFloatingButton) {
      return (
        <Drawer
          variant="permanent"
          anchor="bottom"
          PaperProps={{
            elevation: 20,
            variant: 'elevation',
            style: { padding: '16px' },
          }}
        >
          <StyledButton
            color="primary"
            size="large"
            variant="contained"
            onClick={onConfirm}
          >
            {confirmText}
          </StyledButton>
        </Drawer>
      )
    }

    return (
      <Spacing marginTop="semi">
        {cancelText && (
          <StyledButton
            color="primary"
            size="large"
            variant="outlined"
            className="step-confirmation-button"
            onClick={onCancel}
            hasMarginRight
          >
            {cancelText}
          </StyledButton>
        )}
        <StyledButton
          color="primary"
          size="large"
          className="step-confirmation-button"
          variant="contained"
          onClick={onConfirm}
        >
          {confirmText}
        </StyledButton>
      </Spacing>
    )
  }

  return (
    <>
      <StyledStepConfirmationContainer>
        <StyledIntro
          titleColor="textPrimary"
          titleComponent="h2"
          title={title}
        />
        {children}
        {renderButtons()}
      </StyledStepConfirmationContainer>
      <Spacing marginBottom="semi">
        <Divider />
      </Spacing>
    </>
  )
}
