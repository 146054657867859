import { Typography } from '@/main/components/typography'
import React, {
  KeyboardEvent,
  MouseEvent,
  useLayoutEffect,
  useState,
} from 'react'
import { HighlightedText } from './terms-agreement-label.styles'

export interface TermsAgreementLabelProps {
  normalText: string
  highlightedText: string
  onClick: (event: MouseEvent<HTMLElement>) => void
  onKeyPress: (event: KeyboardEvent) => void
}

export const TermsAgreementLabel: React.FC<TermsAgreementLabelProps> = ({
  normalText,
  highlightedText,
  onClick,
  onKeyPress,
}) => {
  const [terms, setTerms] = useState(<></>)

  useLayoutEffect(() => {
    const [beforeHighlightedText, afterHighlightedText] = normalText.split(highlightedText)

    const handleKeyPress = (event: KeyboardEvent<HTMLSpanElement>) => {
      if (event.key === 'Enter') {
        onKeyPress(event)
      }
    }

    setTerms(
      <Typography
        variant="body2"
        color="textSecondary"
      >
        {beforeHighlightedText}
        <HighlightedText
          role="button"
          tabIndex={0}
          onClick={onClick}
          onKeyPress={handleKeyPress}
        >
          {highlightedText}
        </HighlightedText>
        {afterHighlightedText}
      </Typography>,
    )
  }, [normalText, highlightedText, onClick, onKeyPress])

  return terms
}
