import React from 'react'
import { CustomSelectMessages, SearchAsyncSelect, SelectPromiseOptions } from '@/main/components/search-async-select/search-async-select'
import { ControllerProps, useFormContext } from 'react-hook-form'
import { useControlledTextFieldStyles } from '@/main/components/text-field/controlled-text-field.styles'

type SearchAddressFieldProps = {
  messages: CustomSelectMessages,
  name: string,
  defaultValue?: SelectPromiseOptions
  promiseOptions: (inputValue?: string) => Promise<SelectPromiseOptions[]>
  handleSelectOptions?: (inputValue: SelectPromiseOptions) => void
} & Pick<ControllerProps<'input'>, 'rules'>

export const SearchAddressField = ({
  messages,
  name,
  rules,
  defaultValue = null,
  promiseOptions,
  handleSelectOptions,
}: SearchAddressFieldProps) => {
  const { control } = useFormContext()
  const controlledTextFieldClasses = useControlledTextFieldStyles()
  return (
    <div className={controlledTextFieldClasses.container}>
      <SearchAsyncSelect
        defaultValue={defaultValue}
        messages={messages}
        control={control}
        rules={rules}
        name={name}
        promiseOptions={(inputValue: string) => promiseOptions(inputValue)}
        optionIconProps={{
          name: 'outlined-place-local',
        }}
        handleSelectOptions={(inputValue: SelectPromiseOptions) => handleSelectOptions(inputValue)}
      />
    </div>
  )
}
