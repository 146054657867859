import {
  HttpPostClient,
  HttpStatusCode,
} from '@/data/protocols/http'
import {
  ValidateAttachment,
  ValidateAttachmentHeader, ValidateAttachmentParams,
  ValidateAttachmentRequestBody,
  ValidateAttachmentResponseBody,
} from '@/domain/use-cases/attachment'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'

export class RemoteValidateAttachment implements ValidateAttachment {
  constructor(
    private readonly httpPostClient: HttpPostClient<ValidateAttachmentHeader, ValidateAttachmentRequestBody[], ValidateAttachmentResponseBody[]>,
    private readonly xApiKey: string,
  ) {
  }

  async validate(params: ValidateAttachmentParams): Promise<ValidateAttachmentResponseBody[]> {
    const {
      personId, attachments, tenantId, businessModel, role, functionId,
    } = params

    const url = `/attachment/validate/${personId}`
    const headers: ValidateAttachmentHeader = {
      'x-api-key': this.xApiKey,
      tenantid: tenantId,
      businessmodel: businessModel,
      role,
      function: functionId,
    }

    const response = await this.httpPostClient.post({ url, headers, body: attachments })

    return this.handleResponse(response.statusCode, response.body)
  }

   private handleResponse(statusCode: HttpStatusCode, body: ValidateAttachmentResponseBody[]): ValidateAttachmentResponseBody[] {
    switch (statusCode) {
      case HttpStatusCode.ok:
        return body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(body)
      default:
        throw new UnexpectedError()
    }
  }
}
