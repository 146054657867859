import styled from 'styled-components'
import {
  Input as DSInput,
} from '@naturacosmeticos/natds-web'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Input = styled(DSInput)`
  flex: 1;
  margin-right: ${({ theme }) => `${theme.sizes.small}px`};;
`
