import { HttpStatusCode } from '@/data/protocols/http'
import { PersonalDataMessages } from '@/domain/models/messages/messages'
import { DeliveryAddressForm } from '@/domain/models/personal-data/personal-data'
import { AddressConfigs, PersonalDataConfigs } from '@/domain/models/tenant/tenant-configs'
import { GetAddressByZipCodeParams, GetAddressByZipCodeResponse } from '@/domain/use-cases/address/zip-code'
import { CardTitle, Divider, Spacing, ZipCodeTextField } from '@/main/components'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { Skeleton } from '@/main/components/skeleton'
import { IdentityInfoContext } from '@/main/contexts'
import { useDebounce } from '@/main/hooks/debounce'
import React, { useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { Checkbox } from '@/main/components/checkbox'
import {
  CityField,
  CologneField,
  ComplementField,
  NeighborhoodField,
  NumberField,
  ReferencesField,
  StateField,
  StreetField,
} from '../fields/index'
import { GridSkeletonContainer, GridSkeletonField } from './delivery-address-fields.styles'
import { loadingSkeletonKeys } from '../utils/loading-skeleton'

type FieldValues = DeliveryAddressForm

type Page = {
  configs: PersonalDataConfigs
  messages: PersonalDataMessages
  addressConfigs: AddressConfigs
}

type ZipCodeFieldStatus = {
  isLoading: boolean
}

type DialogMessage = string

type Dialog = boolean

type Api = {
  getAddressByZipCodeApi: (params: GetAddressByZipCodeParams) => Promise<GetAddressByZipCodeResponse>
}

interface SearchAddressWithZipCodeFieldsProps {
  page: Page
  api: Api
  disableFieldsPersonalData?: boolean
  setDeliveryAddressFormat?: Function
}

export const DeliverySearchAddress = ({
  page,
  api: { getAddressByZipCodeApi },
  disableFieldsPersonalData,
  setDeliveryAddressFormat,
}: SearchAddressWithZipCodeFieldsProps) => {
  const { control, getValues, reset, watch, setValue, register } = useFormContext<FieldValues>()
  const { tenantId, businessModel, personId } = useContext(IdentityInfoContext)
  const [isEqualDeliveryResidential, setIsEqualDeliveryResidential] = useState<boolean>(true)

  const [deliveryAddress, setDeliveryAddress] = useState<GetAddressByZipCodeResponse>(null)
  const [zipCodeStatus, setZipCodeStatus] = useState<ZipCodeFieldStatus>({
    isLoading: false,
  })
  const [dialogMessage, setDialogMessage] = useState<DialogMessage>('')
  const [isDialogOpen, setIsDialogOpen] = useState<Dialog>(false)

  const messages = { ...page.messages.allocation.deliveryAddressWithZipCode }
  const configs = { ...page.configs.allocationStrategy.deliveryAddressWithZipCode }
  const { fieldsOrder } = page.addressConfigs

  const handleGetAddressByZipCode = async () => {
    const zipCodeValue = getValues().deliveryZipCode
    const isNaturaMex = tenantId === TenantId.NaturaMEX
    const isValidZipCodeLength =
      zipCodeValue?.length >= configs.deliveryZipCode.rules.minLength &&
      zipCodeValue?.length <= configs.deliveryZipCode.rules.maxLength

    if (isValidZipCodeLength) {
      try {
        setZipCodeStatus({
          isLoading: true,
        })
        const paramsGetAddress = {
          businessModel,
          code: isNaturaMex ? zipCodeValue.toString() : Number(zipCodeValue),
          tenantId,
        }

        if (isNaturaMex) {
          const addressResponse = await getAddressByZipCodeApi({ ...paramsGetAddress, personId })
          setValue('deliveryDistrict', addressResponse?.district)
          const formatAddress = fomatGetAddressNaturaMexico(addressResponse)
          setDeliveryAddressFormat(addressResponse)
          setDeliveryAddress(formatAddress)
        } else {
          const addressResponse = await getAddressByZipCodeApi(paramsGetAddress)
          setDeliveryAddress(addressResponse)
        }

        setZipCodeStatus({
          isLoading: false,
        })
      } catch (error) {
        setZipCodeStatus({
          isLoading: false,
        })
        if (error?.statusCode === HttpStatusCode.notFound) {
          setDialogMessage(messages.dialog.error.notFound)
          setIsDialogOpen(true)

          return
        }

        setDialogMessage(messages.dialog.error.unexpected)
        setIsDialogOpen(true)
      }
    }
  }
  const fomatGetAddressNaturaMexico = (address: any) => {
    if (address) {
      const { city, state, district, cologne } = address
      const addressResponse = {
        city: city?.geoStructureDescription,
        state: state?.geoStructureDescription,
        district: district?.geoStructureDescription,
        cologne,
      }
      return addressResponse
    }
    return null
  }
  const resetsAddressFieldsExceptZipCode = () => {
    reset({
      ...getValues(),
      deliveryStreet: null,
      deliveryCity: null,
      deliveryCologne: null,
      deliveryComplement: null,
      deliveryNeighborhood: null,
      deliveryNumber: null,
      deliveryReferences: null,
      deliveryState: null,
    })
  }

  const hideAllFieldsExceptZipCodeIfZipCodeIsInvalid = () => {
    const zipCodeValue = getValues().deliveryZipCode
    if (zipCodeValue) {
      setIsEqualDeliveryResidential(false)
    }
    const isInvalidZipCodeLength = zipCodeValue?.length < configs.deliveryZipCode.rules.minLength

    if (isInvalidZipCodeLength && deliveryAddress) {
      setDeliveryAddress(null)
      resetsAddressFieldsExceptZipCode()
    }
  }
  const clearZipCode = () => setValue('deliveryZipCode', '', { shouldValidate: true })

  const resetDialogMessage = () => {
    setIsDialogOpen(false)
    clearZipCode()
  }

  const checkIsEqualDeliveryResidential = (zipCodeWatch: any) => {
    const zipCode = zipCodeWatch
    const getvaluesAddressZipCode = getValues().deliveryZipCode
    if (zipCode && !getvaluesAddressZipCode) {
      setIsEqualDeliveryResidential(false)
    }
  }

  const { debouncedFunctionResult: getAddress } = useDebounce({
    customFunction: handleGetAddressByZipCode,
    delayInMilliSeconds: 500,
  })
  useEffect(() => {
    if (deliveryAddress) {
      reset({
        ...getValues(),
        deliveryStreet: deliveryAddress.street || null,
        deliveryNeighborhood: deliveryAddress.neighborhood || null,
        deliveryCity: deliveryAddress.city,
        deliveryState: deliveryAddress.state,
      })
    }
  }, [deliveryAddress, reset, getValues])
  useEffect(() => {
    register('district')
  }, [register])
  const zipCodeWatch = watch('deliveryZipCode')
  const isNaturaMX = TenantId.NaturaMEX === tenantId
  const disableFields = disableFieldsPersonalData && isNaturaMX
  useEffect(() => {
    getAddress()
    hideAllFieldsExceptZipCodeIfZipCodeIsInvalid()
    checkIsEqualDeliveryResidential(zipCodeWatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCodeWatch])
  const addressFieldComps = {
    STREET: !disableFields && (
      <StreetField
        id="deliveryStreet"
        messages={messages.deliveryStreet}
        configs={configs.deliveryStreet}
        control={control}
        address={deliveryAddress}
        key={1}
      />
    ),
    NUMBER: !disableFields && (
      <NumberField
        id="deliveryNumber"
        messages={messages.deliveryNumber}
        configs={configs.deliveryNumber}
        key={2}
      />
    ),
    COMPLEMENT: !disableFields && (
      <ComplementField
        id="deliveryComplement"
        messages={messages.deliveryComplement}
        configs={configs.deliveryComplement}
        control={control}
        key={3}
      />
    ),
    REFERENCES: !disableFields && (
      <ReferencesField
        id="deliveryReferences"
        messages={messages.deliveryReference}
        configs={configs.deliveryReference}
        control={control}
        key={4}
      />
    ),
    COLOGNE: (
      <CologneField
        id="deliveryCologne"
        messages={messages.deliveryCologne}
        configs={configs.deliveryCologne}
        control={control}
        address={deliveryAddress?.cologne}
        key={5}
      />
    ),
    CITY: (
      <CityField
        id="deliveryCity"
        messages={messages.deliveryCity}
        configs={configs.deliveryCity}
        control={control}
        key={6}
      />
    ),
    NEIGHBORHOOD: (
      <NeighborhoodField
        id="deliveryNeighborhood"
        messages={messages.deliveryNeighborhood}
        configs={configs.deliveryNeighborhood}
        control={control}
        address={deliveryAddress}
        key={7}
      />
    ),
    STATE: (
      <StateField
        id="deliveryState"
        messages={messages.deliveryState}
        configs={configs.deliveryState}
        control={control}
        key={8}
      />
    ),
  }

  return (
    <>
      <Checkbox
        color="secondary"
        label="La dirección de entrega es igual a la direccíon residencial"
        checked={isEqualDeliveryResidential}
        onChange={(e) => setIsEqualDeliveryResidential(e.target.checked)}
      />
      {!isEqualDeliveryResidential && (
        <>
          <Spacing marginY="semi">
            <Divider />
          </Spacing>
          <CardTitle title="Dirección de Entrega" />
          <ZipCodeTextField
            id="deliveryZipCode"
            label={messages.deliveryZipCode.label}
            placeholder={messages.deliveryZipCode.placeholder}
            rules={{
              pattern: {
                message: messages.deliveryZipCode.rules.pattern,
                value: configs.deliveryZipCode.rules.pattern,
              },
              maxLength: {
                message: messages.deliveryZipCode.rules.maxLength,
                value: configs.deliveryZipCode.rules.maxLength,
              },
              minLength: {
                message: messages.deliveryZipCode.rules.minLength,
                value: configs.deliveryZipCode.rules.minLength,
              },
              required: {
                message: messages.deliveryZipCode.rules.required,
                value: configs.deliveryZipCode.rules.required,
              },
            }}
            control={control}
            validChars={configs.deliveryZipCode.validChars}
            isLoading={zipCodeStatus.isLoading}
          />
          {deliveryAddress ? (
            <>
              {fieldsOrder &&
                fieldsOrder.map((Comp) => {
                  return addressFieldComps[Comp]
                })}
            </>
          ) : (
            zipCodeStatus.isLoading && (
              <GridSkeletonContainer aria-live="polite" aria-busy="true">
                {loadingSkeletonKeys.map((loading) => (
                  <GridSkeletonField key={loading.key}>
                    <Skeleton height="small" width="largexxx" variant="rect" />
                    <Skeleton height="mediumX" variant="rect" />
                  </GridSkeletonField>
                ))}
              </GridSkeletonContainer>
            )
          )}
        </>
      )}
      <DialogAlert
        messages={{
          closeButtonText: messages.dialog.closeButton,
          infoText: dialogMessage,
        }}
        show={isDialogOpen}
        closeDialogCallback={resetDialogMessage}
      />
    </>
  )
}
