import styled from 'styled-components'
import { TextParagraph, TextParagraphProps } from '@/main/components'

type StatusTextParagraphProps = {
    status?: string
    lowEmphasis?: boolean
    hasMarginBottom?: boolean
    isApproved?: boolean
  }

  type StatusTextParagraph = TextParagraphProps & StatusTextParagraphProps

  export const StyledTextParagraph = styled<React.FC<StatusTextParagraph>>(TextParagraph)`
    font-size: 1.6rem;
    line-height: 24px;
    letter-spacing: 0.51;
    text-align: 'center';
    color: #777;
  
    a {
      color: #227BBD;
    }
  `
type StyledTitleTextPropType = {
    hasMarginTop?: boolean
  }

export const StyledTitleText = styled<React.FC<StyledTitleTextPropType & StatusTextParagraph>>(StyledTextParagraph)`
    margin-top: ${(props) => (props.hasMarginTop ? 32 : 0)}px;
    margin-bottom: 0px;
    color: #333;
`
