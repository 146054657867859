import { PendingSignatureMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import {
  FormContext,
  IdentityInfoContext,
  NavigationContext,
  NavigationContextProps,
} from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import React, { useContext, useState } from 'react'
import { InitialDocumentPage } from '../initial-document/initial-document-page'
import { PendingSignaturePageApi } from './api/make-pending-signature-page-api'

type PendingSignaturePageProps = {
  api: PendingSignaturePageApi
}

export const PendingSignaturePage: React.FC<PendingSignaturePageProps> = ({ api }) => {
  const { onSubmit } = useContext(FormContext)
  const { goToNextPage } = useContext<NavigationContextProps>(NavigationContext)
  const {
    countryId, companyId, authorization,
  } = useContext(IdentityInfoContext)

  const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState('')
  const [isUnexpectedErrorDialogOpen, setIsUnexpectedErrorDialogOpen] = useState<boolean>(false)
  const { dialog } = usePageMessages(Page.PendingSignature).messages as PendingSignatureMessages

  const handleSubmit = async ({ documents }) => {
    const documentTypeId = documents[0].type
    const documentValue = documents[0].document
    await api.submit({
      countryid: countryId,
      companyid: companyId,
      documentValue,
      documentTypeId,
      authorization,
    })
    .then(async (person) => {
      await onSubmit(person)
      goToNextPage()
    })
    .catch((response) => {
      const messageKey = response.data?.error?.messageKey
      setUnexpectedErrorMessage(dialog[messageKey] || dialog.unexpectedError)
      setIsUnexpectedErrorDialogOpen(true)
    })
  }

  return (
    <>
      <InitialDocumentPage
        page={Page.PendingSignature}
        onSubmit={handleSubmit}
      />
      <DialogAlert
        show={isUnexpectedErrorDialogOpen}
        messages={{ infoText: unexpectedErrorMessage, closeButtonText: dialog.closeButtonText }}
        closeDialogCallback={() => setIsUnexpectedErrorDialogOpen(false)}
      />
    </>
  )
}
