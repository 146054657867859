import { CountryId } from '@/domain/models/country'
import {
  CardContent,
  CardHeader,
  CardTitle,
  ControlledAddressNumberField,
  ControlledTextField,
  GeoCommercialConfig,
  GeoCommercialStructure,
  SelectOption,
  ZipCodeTextField,
} from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { Container } from '@/main/components/pages-structures'
import { FormContext, FormContextProps, NavigationContext, NavigationContextProps } from '@/main/contexts'
import { adaptDefaultValues } from '@/main/pages/address/adapters/input-adapter'
import {
  adaptFormValuesWithAnotherDeliveryAddress,
  adaptFormValuesWithDeliveryAddressCopied,
} from '@/main/pages/address/adapters/output-adapter'
import { LegacyAddressPageProps } from '@/main/pages/address/address-page-props'
import { useAddressPageStyles } from '@/main/pages/address/address-page.styles'
import { AddressFormValues } from '@/main/pages/address/models'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Spacing } from '@naturacosmeticos/natds-web/dist/Components/Spacing'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

export function createZipCodeLengthValidation(lengthValue: number): (value: number) => boolean {
  return (value) => value.toString().length >= lengthValue
}

const geoCommercialLevelsAdapter = {
  level1: 'level1',
  level2: 'level2',
  level3: 'level3',
  level4: 'level4',
}

type ArgentinaGeoCommercialLevelsIds = {
  level1?: SelectOption
  level2?: SelectOption
  level3?: SelectOption
  level4?: SelectOption
}

type CustomArgentinaAddressPageProps = {
  shouldDisplayZipCodeLink: boolean
}

export const ArgentinaAddressPage: React.FC<LegacyAddressPageProps & CustomArgentinaAddressPageProps> = ({
  messages,
  shouldDisplayZipCodeLink,
}) => {
  const { person, onSubmit } = useContext<FormContextProps>(FormContext)
  const { goToPreviousPage = () => {}, goToNextPage = () => {} } = useContext<NavigationContextProps>(
    NavigationContext
  )

  const defaultValues = adaptDefaultValues<ArgentinaGeoCommercialLevelsIds>(
    person,
    geoCommercialLevelsAdapter
  )
  const formMethods = useForm<AddressFormValues & ArgentinaGeoCommercialLevelsIds>({
    mode: 'onTouched',
    defaultValues,
  })
  const {
    control,
    formState: { isValid, isSubmitting },
    getValues,
  } = formMethods
  const [copyAddress, setCopyAddress] = useState<boolean>(true)

  const zipCodeValidChars = new RegExp(/^\d{0,4}$/)
  const addressNumberValidChars = new RegExp(/^\d+$/)

  useEffect(() => {
    if (!defaultValues.level2) {
      return
    }
    setCopyAddress(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formRequiredRules = { required: messages.addressRequiredError }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formPatternRules = (message: string) => ({
    // pattern: { value: /^[ a-zA-ZÀ-ÿ0-9]*$/, message },
  })

  const addressPageClasses = useAddressPageStyles()

  const onNextButtonClick = () => {
    const formValues = getValues()
    const parsedValue = copyAddress
      ? adaptFormValuesWithDeliveryAddressCopied({ ...formValues, references: '' })
      : adaptFormValuesWithAnotherDeliveryAddress(
          { ...formValues, references: '', deliveryReferences: '' },
          geoCommercialLevelsAdapter
        )

    onSubmit(parsedValue, goToNextPage)
  }

  const geoCommercialConfig: GeoCommercialConfig = {
    level1: {
      id: 'level1',
      label: messages.geographicalLevels.level1,
      placeholder: messages.geographicalLevels.placeholders.level1,
    },
    level2: {
      id: 'level2',
      label: messages.geographicalLevels.level2,
      placeholder: messages.geographicalLevels.placeholders.level2,
    },
    level3: {
      id: 'level3',
      label: messages.geographicalLevels.level3,
      placeholder: messages.geographicalLevels.placeholders.level3,
    },
    level4: {
      id: 'level4',
      label: messages.geographicalLevels.level4,
      placeholder: messages.geographicalLevels.placeholders.level4,
    },
    requiredErrorMessage: messages.addressRequiredError,
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={onNextButtonClick}
      disableNextButton={!isValid}
      isLoading={isSubmitting}
    >
      <CardHeader title={messages.title} subtitle={messages.subtitle} />
      <CardContent>
        <FormProvider {...formMethods}>
          <ZipCodeTextField
            id="zipCode"
            link={{
              show: shouldDisplayZipCodeLink,
              text: messages.zipCode.link.text,
              url: messages.zipCode.link.url,
            }}
            control={control}
            rules={{
              minLength: {
                message: messages.zipCode.errorMessage,
                value: 4,
              },
              maxLength: {
                message: messages.zipCode.errorMessage,
                value: 4,
              },
              required: {
                message: messages.zipCode.requiredErrorMessage,
                value: true,
              },
            }}
            label={messages.zipCode.label}
            placeholder={messages.zipCode.placeholder}
            validChars={zipCodeValidChars}
          />
          <ControlledTextField
            id="street"
            label={messages.mainStreet.label}
            control={control}
            rules={{ ...formRequiredRules, ...formPatternRules(messages.mainStreet.errorMessage) }}
            placeholder={messages.placeholders.mainStreet}
            showAsterisk={false}
            required
          />
          <ControlledAddressNumberField
            id="number"
            label={messages.number.label}
            placeholder={messages.placeholders.number}
            rules={{
              required: {
                value: true,
                message: messages.addressRequiredError,
              },
            }}
            noNumberLabel={messages.checkBoxWithoutNumber}
            showAsterisk={false}
            validChars={addressNumberValidChars}
          />
          <ControlledTextField
            id="complement"
            label={messages.complementaryData.label}
            control={control}
            placeholder={messages.placeholders.complementaryData}
            optionalText={messages.optionalTextLabel}
          />

          <div className={addressPageClasses.copyAddressCheckbox}>
            <Checkbox
              color="secondary"
              label={messages.confirmDeliveryAddressAsResidenceAddress}
              checked={copyAddress}
              onChange={(e) => setCopyAddress(e.target.checked)}
            />
          </div>
          {!copyAddress && (
            <>
              <Spacing marginY="semi">
                <Divider />
              </Spacing>
              <CardTitle title={messages.delivery.title} />
              <ZipCodeTextField
                id="deliveryZipCode"
                link={{
                  show: shouldDisplayZipCodeLink,
                  text: messages.zipCode.link.text,
                  url: messages.zipCode.link.url,
                }}
                control={control}
                rules={{
                  maxLength: {
                    message: messages.zipCode.errorMessage,
                    value: 4,
                  },
                  required: {
                    message: messages.zipCode.requiredErrorMessage,
                    value: true,
                  },
                }}
                label={messages.zipCode.label}
                placeholder={messages.zipCode.placeholder}
                validChars={zipCodeValidChars}
              />
              <GeoCommercialStructure customConfig={geoCommercialConfig} countryId={CountryId.Argentina} />
              <ControlledTextField
                id="deliveryStreet"
                label={messages.mainStreet.label}
                control={control}
                rules={{ ...formRequiredRules, ...formPatternRules(messages.mainStreet.errorMessage) }}
                placeholder={messages.placeholders.mainStreet}
                showAsterisk={false}
                required
              />
              <ControlledAddressNumberField
                id="deliveryNumber"
                label={messages.number.label}
                placeholder={messages.placeholders.number}
                rules={{
                  required: {
                    value: true,
                    message: messages.addressRequiredError,
                  },
                }}
                noNumberLabel={messages.checkBoxWithoutNumber}
                showAsterisk={false}
                validChars={addressNumberValidChars}
              />
              <ControlledTextField
                id="deliveryComplement"
                label={messages.complementaryData.label}
                control={control}
                placeholder={messages.placeholders.complementaryData}
                optionalText={messages.optionalTextLabel}
              />
            </>
          )}
        </FormProvider>
      </CardContent>
    </Container>
  )
}
