import { IndicationInfo, RemoteSearchIndicationParams } from '@/data/use-cases/indication/remote-search-indication'
import { PersonData } from '@/domain/use-cases/person'
import { PreRegisterParams } from '@/domain/use-cases/pre-register'
import { makeRemoteSearchIndication } from '@/main/factories/remote/indication'
import { makeRemotePreRegister } from '@/main/factories/remote/pre-register'

export type PreRegisterPageApi = {
  complete: (params: PreRegisterParams) => Promise<PersonData>,
  searchIndication: (params: RemoteSearchIndicationParams) => Promise<IndicationInfo>
}

export const makePreRegisterPageApi = (): PreRegisterPageApi => {
  const remotePreRegisterComplete = makeRemotePreRegister()
  const remoteSearchIndication = makeRemoteSearchIndication()
  const complete = (params: PreRegisterParams) => remotePreRegisterComplete.complete(params)
  const searchIndication = (params: RemoteSearchIndicationParams) => remoteSearchIndication.search(params)

  return {
    complete,
    searchIndication,
  }
}
