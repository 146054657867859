import React from 'react'

const deliveryAddressType = { id: 1, description: 'DELIVERY ADDRESS' }
const mailingAddressType = { id: 2, description: 'HOME ADDRESS' }
export type AddressType = typeof deliveryAddressType | typeof mailingAddressType

type AddressTypeEnumType = {
  DELIVERY: typeof deliveryAddressType,
  MAILING: typeof mailingAddressType,
}
export const AddressTypeEnum: AddressTypeEnumType = {
  DELIVERY: deliveryAddressType,
  MAILING: mailingAddressType,
}

const deliveryAddressUse = { id: 1, description: 'Delivery Address' }
const mailingAddressUse = { id: 2, description: 'Mailing Address' }
const alternativeAddressUse = { id: 3, description: 'Eventual Address' }
export type AddressUse = typeof deliveryAddressUse | typeof mailingAddressUse | typeof alternativeAddressUse

type AddressUseEnumType = {
  DELIVERY: typeof deliveryAddressUse,
  MAILING: typeof mailingAddressUse,
  ALTERNATIVE: typeof alternativeAddressUse
}
export const AddressUseEnum: AddressUseEnumType = {
  DELIVERY: deliveryAddressUse,
  MAILING: mailingAddressUse,
  ALTERNATIVE: alternativeAddressUse,
}

export type BaseGeographicalLevelEnum = Geographical2LevelsEnum | Geographical3LevelsEnum | Geographical4LevelsEnum

export enum Geographical2LevelsEnum {
  COUNTRY = 0,
  REGION = 1,
  CITY = 2,
  STREET = 4,
  NUMBER = 5,
  COMPLEMENT = 6,
  REFERENCES = 7,
}

export enum Geographical3LevelsEnum {
  COUNTRY = 0,
  REGION = 1,
  CITY = 2,
  NEIGHBORHOOD = 3,
  STREET = 4,
  NUMBER = 5,
  COMPLEMENT = 6,
  REFERENCES = 7,
}

export enum Geographical4LevelsEnum {
  COUNTRY = 0,
  LEVEL1 = 1,
  LEVEL2 = 2,
  LEVEL3 = 3,
  LEVEL4 = 4,
  STREET = 5,
  NUMBER = 6,
  COMPLEMENT = 7,
  REFERENCES = 8,
}

export type GeographicalLevel = {
  level: BaseGeographicalLevelEnum;
  description: string;
  code?: string;
}

export type AddressesType = {
  addressType?: AddressType;
  addressUse?: AddressUse[];
  geographicalLevels?: GeographicalLevel[];
  zipCode?: number;
  latitude?: number;
  longitude?: number;
}

export type EmailsType = {
  email: string
  type?: number
  sequence?: string
  updatedAt: string
  createdAt: string
}

export type PhonesType = {
  areaCode?: string,
  countryCode?: string,
  phoneNumber?: string,
  type?: number
  sequence?: string
  updatedAt: string
  createdAt: string
}

export type IntroducerSellerInformation = {
  introducerSellerId: string
}

type PaymentPlanInfo = {
  isPrePaid: boolean,
  isDenied: boolean,
  isCredit: boolean
}

export type PersonData = {
  birthday?: string,
  gender?: number,
  nationalityId?: number,
  stateOfBirth?: string,
  addresses?: AddressesType[],
  additionalInformation?: IntroducerSellerInformation,
  introducerSellerInformation?: IntroducerSellerInformation,
  paymentPlan?: PaymentPlanInfo,
  emails?: EmailsType[]
  name?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  phones?: PhonesType[],
  ethnicityId?: number,
}

export type CompletedInformationData = {
  shouldSearchIndication?: boolean,
  shouldCheckStatus?: boolean,
  shouldOnlyShowConfirmation?: boolean,
}

export type FormContextProps = {
  onSubmit?: (data: any, callback?: any) => any
  person?: PersonData,
  completedInformation?: CompletedInformationData,
  hasDigitalStore?: boolean
}

export const FormContext = React.createContext<FormContextProps>({})
