import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteCompleteAttachment } from '@/data/use-cases/attachment/remote-complete-attachment'
import { CompleteAttachment } from '@/domain/use-cases/attachment/complete-attachment'

export const makeRemoteCompleteAttachment = (): CompleteAttachment => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY

  return new RemoteCompleteAttachment(
    httpPatchClient,
    xApiKey,
  )
}
