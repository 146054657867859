import produce, { Draft } from 'immer'

import { DigitalStoreTypes, DigitalStoreState } from './protocols'

export const initialState: DigitalStoreState = {
  error: false,
  helperTextKey: '',
  isAvailable: false,
  isLoading: false,
  savedDigitalStoreName: '',
  saved: false,
}

export const reducer = produce((draft: Draft<DigitalStoreState>, action) => {
  switch (action.type) {
    case DigitalStoreTypes.SAVE_DIGITAL_STORE_REQUEST_BRASIL:
      draft.saved = false
      draft.isLoading = true
      return
    case DigitalStoreTypes.GET_DIGITAL_STORE_INFO_REQUEST_BRASIL:
      draft.saved = false
      draft.isLoading = true
      draft.helperTextKey = ''
      return
    case DigitalStoreTypes.CHECK_DIGITAL_STORE_AVAILABILITY_REQUEST_BRASIL:
      draft.isLoading = true
      draft.saved = false
      return
    case DigitalStoreTypes.CHECK_DIGITAL_STORE_AVAILABILITY_SUCCESS:
      draft.isAvailable = action.payload.isAvailable
      draft.error = action.payload.error
      draft.helperTextKey = action.payload.helperTextKey
      draft.isLoading = false
      return
    case DigitalStoreTypes.CHECK_DIGITAL_STORE_AVAILABILITY_ERROR:
      draft.isAvailable = false
      draft.error = true
      draft.helperTextKey = action.payload.helperTextKey
      draft.isLoading = false
      return
    case DigitalStoreTypes.SAVE_DIGITAL_STORE_SUCCESS:
      draft.isLoading = false
      draft.saved = true
      draft.helperTextKey = action.payload.helperTextKey
      return
    case DigitalStoreTypes.SAVE_DIGITAL_STORE_ERROR:
      draft.helperTextKey = action.payload.helperTextKey
      draft.error = true
      draft.isAvailable = false
      draft.isLoading = false
      return
    case DigitalStoreTypes.GET_DIGITAL_STORE_INFO_SUCCESS:
      draft.savedDigitalStoreName = action.payload.digitalStoreName
      draft.saved = false
      draft.isLoading = false
      return
    case DigitalStoreTypes.SET_DIGITAL_STORE_HELPER_TEXT:
      draft.helperTextKey = action.payload.helperTextKey
      return
    case DigitalStoreTypes.RESET_DIGITAL_STORE_ON_CHANGE_SUCCESS:
      draft.isAvailable = false
      draft.error = false
      draft.helperTextKey = ''
      break
    default:
      break
  }
}, initialState)
