import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { AcceptAgreement } from '@/domain/use-cases/agreement'
import { RemoteAcceptAgreement } from '@/data/use-cases/agreement/remote-accept-agreement'

export const makeRemoteAcceptAgreement = (): AcceptAgreement => {
  const apiUrl = '/people/:personId/agreement/accept'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteAcceptAgreement(
    apiUrl,
    httpPatchClient,
    xApiKey,
  )
}
