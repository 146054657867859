import React from 'react'
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Typography,
} from '@naturacosmeticos/natds-web'

type MaintenanceDialog = {
  open: boolean,
  confirmButtonName: string,
  message?: string,
  onClickConfirmButton?: () => void,
  cancelButtonName?: string,
  onClickCancelButton?: () => void,
  Children?,
}

export const MaintenanceDialog = ({
  confirmButtonName,
  open,
  message,
  onClickConfirmButton,
  cancelButtonName,
  onClickCancelButton,
  Children,
}: MaintenanceDialog) => (
  <Dialog
    maxWidth="xs"
    open={open}
  >
    <DialogContent>
      {
        Children
          ? (
            <Children />
          )
          : (
            <Typography variant="body1">
              {message}
            </Typography>
          )
      }
    </DialogContent>
    <DialogActions>
      {cancelButtonName
      && (
        <Button
          onClick={onClickCancelButton}
          onFocusVisible={onClickCancelButton}
          size="medium"
          style={{ minWidth: '122px', }}
        >
          {cancelButtonName}
        </Button>
      )}
      <Button
        color="primary"
        onClick={onClickConfirmButton}
        onFocusVisible={onClickConfirmButton}
        size="medium"
        variant="contained"
        style={{ minWidth: '122px', }}
      >
        {confirmButtonName}
      </Button>
    </DialogActions>
  </Dialog>
)
