import { RemoteFetchDigitalStoreStatuses } from '@/data/use-cases/digital-store'
import { DigitalStoreStatus } from '@/domain/use-cases/digital-store/fetch-digital-store-statuses'
import { FetchDigitalStoreStatusesParams } from '@/domain/use-cases/digital-store'
import { makeRemoteFetchDigitalStoreStatuses } from '@/main/factories/remote'

export type DigitalStoreStatusPageApi = {
  getDigitalStoreStatuses: (params: FetchDigitalStoreStatusesParams) => Promise<{} | DigitalStoreStatus>
}
export const makeDigitalStoreStatusPageApi = (): DigitalStoreStatusPageApi => {
  const remoteDigitalStoreStatuses: RemoteFetchDigitalStoreStatuses = makeRemoteFetchDigitalStoreStatuses()
  const getDigitalStoreStatuses = remoteDigitalStoreStatuses.load

  return {
    getDigitalStoreStatuses,
  }
}
