export function setObjectInStorage(key: string, value: object) {
    const formatValue = JSON.stringify(value)
    localStorage.setItem(key,  formatValue)
}

export function getObjectInStorage(key: string) : object {
    const value = localStorage.getItem(key)
    if (value !== undefined && value !== '' && value !== 'undefined') {
        return JSON.parse(value)
    }
    return {}
}

export function removeItemInStorage(key: string) {
    localStorage.removeItem(key)
}
