import React, { createContext } from 'react'

export type CreditMiGrupoRedDataProps = {
  minimumOrder: number
  available: number
  limit: number
}

export type MiGroupRedPageContextValue = {
  isMiGroupRedPage: boolean
  creditMiGrupoRedData: CreditMiGrupoRedDataProps
}

type MiGroupProviderProps = {
  isMiGrupoRed: boolean
  creditMiGrupoRedData: CreditMiGrupoRedDataProps
}

export const MiGroupRedPageContext = createContext<MiGroupRedPageContextValue | undefined>(undefined)

export const MiGroupProvider: React.FC<MiGroupProviderProps> = ({ children, isMiGrupoRed, creditMiGrupoRedData }) => {
  return (
    <MiGroupRedPageContext.Provider value={{ isMiGroupRedPage: isMiGrupoRed, creditMiGrupoRedData }}>
      {children}
    </MiGroupRedPageContext.Provider>
  )
}
