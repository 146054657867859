import { GetTenantVendor } from '@/domain/use-cases/tenant/get-tenant-vendor'
import { getMercadoPagoDetails } from '@/data/use-cases/vendor/vendor-details/detail.MERCADO_PAGO'
import { VendorDetails, VendorLinkingSteps } from '@/domain/models/vendor/vendor-details/details.d'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { VendorId } from '@/domain/models/vendor/vendor-id'
import React from 'react'
import {
 avonMexConfigs, naturaArgConfigs, naturaChlConfigs, naturaColConfigs, naturaMexConfigs, naturaMysConfigs, naturaBraConfigs, naturaPerConfigs,
 naturaEcuConfigs,
} from '@/domain/models/tenant/tenant-configs'

export const vendorByTenantId: {[ key in TenantId ]: VendorId} = {
    [TenantId.NaturaARG]: naturaArgConfigs.digitalStore.vendorId,
    [TenantId.NaturaCOL]: naturaColConfigs.digitalStore.vendorId,
    [TenantId.NaturaPER]: naturaPerConfigs.digitalStore.vendorId,
    [TenantId.NaturaCHL]: naturaChlConfigs.digitalStore.vendorId,
    [TenantId.NaturaMYS]: naturaMysConfigs.digitalStore.vendorId,
    [TenantId.AvonMEX]: avonMexConfigs.digitalStore.vendorId,
    [TenantId.NaturaBRA]: naturaBraConfigs.digitalStore.vendorId,
    [TenantId.NaturaMEX]: naturaMexConfigs.digitalStore.vendorId,
    [TenantId.NaturaECU]: naturaEcuConfigs.digitalStore.vendorId,
    [TenantId.AvonBRA]: null,
}

const emptyVendor: () => VendorDetails = () => ({ name: '', brand: '', linkingSteps: () => <></> })

const vendorIdMapper: {[ key in VendorId ]: (linkingSteps: VendorLinkingSteps) => VendorDetails} = {
    [VendorId.MERCADO_PAGO]: getMercadoPagoDetails,
    [VendorId.EMPTY]: emptyVendor,
}

export const getTenantVendor: GetTenantVendor = (tenantId: TenantId, linkingSteps: VendorLinkingSteps) => {
    const vendorId = vendorByTenantId[tenantId]
    if (vendorId && vendorId !== null) {
        const getVendor = vendorIdMapper[vendorId]
        return getVendor(linkingSteps)
    }
    throw new Error(`TenantId ${tenantId} does not have vendor.`)
}
