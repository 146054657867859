import React from 'react'
import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { ControlledTextField } from '@/main/components'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import { ControllerProps, useFormContext } from 'react-hook-form'

export type EntryFieldTexts = {
  label: string,
  placeholder: string,
}

type EntryFieldProps = {
  texts: EntryFieldTexts,
  id: string,
  validChars?: RegExp
  mask?: Mask
  showAsteriskInRequiredLabel?: boolean,
  required?: boolean,
  disabled?: boolean,
  isUpperCase?: boolean,
  className?: string,
} & Pick<ControllerProps<'input'>, 'rules'>

export const EntryField = styled(({
  texts,
  id,
  validChars,
  required,
  rules,
  mask,
  disabled,
  className,
  showAsteriskInRequiredLabel,
  isUpperCase = false,
}: EntryFieldProps) => {
  const { control } = useFormContext()

  const {
    label,
    placeholder,
  } = texts

  return (
    <div className={className}>
      <ControlledTextField
        id={id}
        control={control}
        label={label}
        placeholder={placeholder}
        rules={rules}
        validChars={validChars}
        showAsterisk={showAsteriskInRequiredLabel}
        required={required}
        isUpperCase={isUpperCase}
        mask={mask}
        disabled={disabled}
      />
    </div>
  )
})`
  @media ${minDeviceSizeForMediaQueries.tablet} {
    flex: 1 0 ${({ theme }) => `calc(50% - ${theme.sizes.small}px)`};
    
    &:nth-child(odd) {
      padding-right: ${({ theme }) => `${theme.sizes.small}px`};
    }

    &:nth-child(even) {
      padding-left: ${({ theme }) => `${theme.sizes.small}px`};
    }

    > div {
      padding-bottom: 0;
    }

    .MuiFormHelperText-root:empty {
      display: none;
    }
  }
`
