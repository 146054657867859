import styled from 'styled-components'
import { Typography } from '@naturacosmeticos/natds-web'
import { TypographyWithHTMLParser } from '@/main/components/string-to-react'

export const Description = styled(Typography)`
  &.description{
    margin: 16px 0;
    white-space: pre-line;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`
export const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const HelpDescription = styled(TypographyWithHTMLParser)`
  strong {
    white-space: nowrap;
  }
`
