import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useContactStyles = makeStyles<Theme>((theme) => ({
  content: {
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0px`, 
  },
  textContent: {
    marginTop: '1rem'
  }
}))
