import { Theme } from '@naturacosmeticos/natds-react'
import { Theme as ThemeUi } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'
import { deviceSize } from '@/utils/device-sizes'

export const useBannerStyles = makeStyles<Theme & ThemeUi>((theme) => ({
  button: {
    background: `${theme.color.primary}`,
    margin: `${theme.spacing(1)}px`,
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    padding: '0',
    height: '85px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageArea: {
    position: 'relative',
    width: '86px',
    height: '85px',
  },
  imageFrame: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 2,
  },
  imageStyle: {
    position: 'absolute',
    top: '14px',
    left: '12px',
    zIndex: 1,
    width: '57px',
    borderRadius: '100%',
  },
  textWrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: `${theme.color.highEmphasis}`,
    marginTop: '10px',
  },
  textTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    [theme.breakpoints.down(deviceSize.laptop)]: {
      fontSize: '16px',
    },
  },
  textSubtitle: {
    fontSize: '16px',
    textAlign: 'left',
    [theme.breakpoints.down(deviceSize.laptop)]: {
      fontSize: '12px',
    },
  },
  imageLink: {
    margin: '18px 13px',
    alignSelf: 'baseline',
  },
}))
