import React from 'react'
import Slider from 'react-slick'
import { SLIDER_SETTINGS } from '@/main/pages/confirmation/loading/loading-slider/loading-slider-settings'
import { SlideContainer, SlideDescription, SlideInfo, SlideTitle } from './loading-slider.styles'
import { LoadingMessages } from '../loading-wrapper'
import { LoadingSlide } from '../loading'
import { ImageDynamicLoad } from './image-dynamic-load'

export const LoadingSlider = ({
  slidesItems,
  messages,
}: {
  slidesItems: LoadingSlide[]
  messages: LoadingMessages['carousel']
}) => (
  <Slider {...SLIDER_SETTINGS}>
    {slidesItems
      .filter(({ translationKey }) => Boolean(messages[translationKey]?.title))
      .map(({ name, translationKey }) => (
        <div key={name}>
          <SlideContainer>
            <ImageDynamicLoad name={name} image={messages[translationKey].image} />
            <SlideInfo>
              <SlideTitle variant="h6" color="textPrimary" className="slide_title">
                {messages[translationKey].title}
              </SlideTitle>
              <SlideDescription variant="body2">{messages[translationKey].description}</SlideDescription>
            </SlideInfo>
          </SlideContainer>
        </div>
      ))}
  </Slider>
)
