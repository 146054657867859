import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'

type GetCNContactsHeaders = {
    countryid: CountryId
    companyid: CompanyId
    'x-api-key': string
}

export type GetCnContactsParams = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
}

export type ResponseGetCNContacts = {
  email: {
    sequence: string
    value: string
  },
  sms: {
    sequence: string
    value: string
  }
}

export class RemoteGetCNContacts {
    constructor(
        private readonly httpGetClient: HttpGetClient<GetCNContactsHeaders, GetCnContactsParams>,
        private readonly xApiKey: string,
    ) {}

    get = async (params : GetCnContactsParams): Promise<ResponseGetCNContacts> => {
        const httpResponse = await this.httpGetClient.get({
          url: `/people/${params.personId}/masked-contacts`,
          headers: {
           'x-api-key': this.xApiKey,
            companyid: params.companyId,
            countryid: params.countryId,
         }
        })

        return this.handleResponse(httpResponse)
    }

    private handleResponse = (httpResponse) => {
        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
              return httpResponse.body
            case HttpStatusCode.noContent:
                return httpResponse.body
            case HttpStatusCode.badRequest:
              throw new BadRequestError(httpResponse.body)
            case HttpStatusCode.notFound:
              throw new NotFoundError(httpResponse.body)
            default:
              throw new UnexpectedError()
        }
    }
}
