import { AddressFormValues } from '@/main/pages/address/models'
import { GeoCommercialLevelsAdapter } from './geo-commercial-levels-adapter.d'

export const adaptFormValuesWithDeliveryAddressCopied = (formValues: AddressFormValues) => ({
  address: [
    {
      number: formValues.number,
      zipCode: formValues.zipCode,
      references: formValues.references,
      city: formValues.city,
      street: formValues.street,
      complement: formValues.complement,
      state: formValues.state && {
        code: formValues.state.value,
        description: formValues.state.label,
      },
      cologne: formValues.cologne,
    },
  ],
  copyAddress: true,
})

export const adaptFormValuesWithAnotherDeliveryAddress = <T>(formValues: AddressFormValues & T, levels: GeoCommercialLevelsAdapter) => ({
  address: [
    {
      ...adaptFormValuesWithDeliveryAddressCopied(formValues).address[0],
      deliveryZipCode: formValues.deliveryZipCode,
      deliveryStreet: formValues.deliveryStreet,
      deliveryNumber: formValues.deliveryNumber,
      deliveryReferences: formValues.deliveryReferences,
      deliveryComplement: formValues.deliveryComplement,
      [levels.level1]: formValues[levels.level1] && {
        code: formValues[levels.level1].value,
        description: formValues[levels.level1].label,
      },
      [levels.level2]: formValues[levels.level2] && {
        code: formValues[levels.level2].value,
        description: formValues[levels.level2].label,
      },
      [levels.level3]: formValues[levels.level3] && {
        code: formValues[levels.level3].value,
        description: formValues[levels.level3].label,
      },
      [levels.level4]: formValues[levels.level4] && {
        code: formValues[levels.level4].value,
        description: formValues[levels.level4].label,
      },
    },
  ],
  copyAddress: false,
})
