import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { CardContent, CardHeader } from '@/main/components'
import { FormControl } from '@naturacosmeticos/natds-web/dist/Components/FormControl'

export const StyledFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0 8px;
  grid-template-areas:
    "birthday birthday birthday gender gender gender"
    "nationality nationality nationality nationality nationality nationality"
    "ethnicity ethnicity ethnicity ethnicity ethnicity ethnicity"
    "state state state state state state"
    "establishmentId establishmentId establishmentId establishmentId establishmentId establishmentId"
    "admissionDate admissionDate admissionDate admissionDate admissionDate admissionDate"
    "pepVisibility pepVisibility pepVisibility pepVisibility pepVisibility pepVisibility"
    "pepName pepName pepName pepName pepName pepName"
    "pepStartDate pepStartDate pepStartDate pepStartDate pepStartDate pepStartDate"
    "pepEndDate pepEndDate pepEndDate pepEndDate pepEndDate pepEndDate"
    "terms terms terms terms terms terms";

  @media ${minDeviceSizeForMediaQueries.tablet} {
    grid-template-areas:
      "birthday birthday gender gender gender gender"
      "nationality nationality nationality nationality nationality nationality"
      "state state state state state state"
      "establishmentId establishmentId establishmentId admissionDate admissionDate admissionDate"
      "pepVisibility pepVisibility pepVisibility pepVisibility pepVisibility pepVisibility"
      "pepName pepName pepName pepName pepName pepName"
      "pepStartDate pepStartDate pepStartDate pepStartDate pepStartDate pepStartDate"
      "pepEndDate pepEndDate pepEndDate pepEndDate pepEndDate pepEndDate"
      "terms terms terms terms terms terms";
  }
`

export const StyledFormControl = styled(FormControl)`
  padding: 12px 0 0;
`

export const StyledCardHeader = styled(CardHeader)`
  padding: 0;
  margin-bottom: 16px;
`

export const StyledCardContent = styled(CardContent)`
  padding: 0;
`

const selectGridArea = (gridArea: string) => {
  switch (gridArea) {
    case 'birthday':
      return 'grid-area: birthday'
    case 'gender':
      return 'grid-area: gender'
    case 'ethnicity':
      return 'grid-area: ethnicity'
    case 'nationality':
      return 'grid-area: nationality'
    case 'state':
        return 'grid-area: state'
    case 'pepVisibility':
      return 'grid-area: pepVisibility'
    case 'pepName':
      return 'grid-area: pepName'
    case 'pepStartDate':
      return 'grid-area: pepStartDate'
    case 'pepEndDate':
      return 'grid-area: pepEndDate'
    case 'terms':
      return 'grid-area: terms'
    case 'establishmentId':
      return 'grid-area: establishmentId'
    case 'admissionDate':
      return 'grid-area: admissionDate'
    default:
      return null
  }
}

export const StyledFormItem = styled.div<{ gridArea?: string, flex?: boolean }>`
  ${(props) => selectGridArea(props.gridArea)};
  ${(props) => ((props.flex) ? `
    display: flex;
    align-items: center;
  ` : '')
  }
`
