import { HttpStatusCode, HttpPostClient } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'

import { CountryId } from '@/domain/models/country'
import { BadRequestError, ConflictError } from '@/data/protocols/errors'
import { BUSINESS_MODELS, ROLES, FUNCTION_IDS } from '@/domain/models/person'
import { CompleteRegistration, CompleteRegistrationParams } from '@/domain/use-cases/complete-registration'

export type RemoteCompleteRegistrationHttpRequestHeaders = {
  countryId: CountryId
  personId: string
  'x-api-key': string,
  businessModel: BUSINESS_MODELS,
  role: ROLES,
  functionId: FUNCTION_IDS,
  companyId: string,
  sourceSystem: string,
  authorization: string,
}

export type RemoteCompleteRegistrationResponseBody = {
  id: string,
  startDate: string
}

export type RemoteCompleteRegistrationHttpRequestResponseBody = {
  userRole?: string
  currentCreatedFrom: number
  registrantId?:string
  introducerSellerId?: string
}

export class RemoteCompleteRegistration implements CompleteRegistration {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient<
      RemoteCompleteRegistrationHttpRequestHeaders,
      RemoteCompleteRegistrationHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  complete = async (params: CompleteRegistrationParams): Promise<RemoteCompleteRegistrationResponseBody> => {
    const headers: RemoteCompleteRegistrationHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      countryId: params.countryId,
      personId: params.personId,
      businessModel: params.businessModel,
      role: params.role,
      functionId: params.functionId,
      companyId: params.companyId,
      sourceSystem: params.sourceSystem,
      authorization: params.authorization,
    }
    const body: RemoteCompleteRegistrationHttpRequestResponseBody = {
      userRole: params.userRole,
      currentCreatedFrom: params.currentCreatedFrom,
      registrantId: params.registrantId,
      introducerSellerId: params.introducerSellerId,
    }

    this.removeRegistrantIdWhenIsSelfRegistration(body, params.registrantId)

    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      headers,
      body,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return {
          id: httpResponse.body.id,
          startDate: httpResponse.body.startDate,
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.conflict:
        throw new ConflictError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }

  private removeRegistrantIdWhenIsSelfRegistration = (
    body: RemoteCompleteRegistrationHttpRequestResponseBody,
    registrantId: string,  
  ) => {
    if (registrantId === 'self-registration') {
      delete body.registrantId
    }
  }
}
