import { useEffect, useRef } from 'react'

type useIsInitialRenderResponse = {
  isInitialRender: boolean
}

export const useIsInitialRender = (): useIsInitialRenderResponse => {
  const isInitialRenderRef = useRef(true)
  useEffect(() => {
    isInitialRenderRef.current = false
  }, [])
  return { isInitialRender: isInitialRenderRef.current }
}
