import React from 'react'
import { ControlledAddressNumberField } from '@/main/components'
import { FieldsOrderProps } from '@/domain/models/tenant/tenant-configs/configs'

export const NumberField = (fieldsOrderProps: FieldsOrderProps) => {
  const { messages, configs, id } = fieldsOrderProps
  return (
    <>
      <ControlledAddressNumberField
        id={id || 'number'}
        label={messages.label}
        placeholder={messages.placeholder}
        noNumberLabel={messages.checkboxWithoutNumber}
        showAsterisk={false}
        rules={{
          required: {
            message: messages.rules.required,
            value: configs.rules.required,
          },
          maxLength: {
            message: messages.rules.maxLength,
            value: configs.rules.maxLength,
          },
        }}
        checkbox={{ color: 'primary' }}
        validChars={configs.validChars}
      />
    </>
  )
}
