import {
  ConvertCountryIdFromAlpha2ToAlpha3,
  ConvertCountryIdFromAlpha2ToAlpha3Params,
  ConvertCountryIdFromAlpha2ToAlpha3Output,
} from '@/domain/use-cases/country/convert-country-id-from-alpha-2-to-alpha-3'
import { CountryId } from '@/domain/models/country'
import { CountryIdAlpha2, CountryIdsAlpha2Key } from '@/domain/models/country/country-id-alpha-2'

export class LocalConvertCountryIdFromAlpha2ToAlpha3 implements ConvertCountryIdFromAlpha2ToAlpha3 {
  constructor(
    private readonly countryIdsAlpha2Keys: Map<CountryIdAlpha2, CountryIdsAlpha2Key>,
  ) { }

  convert = (params: ConvertCountryIdFromAlpha2ToAlpha3Params): ConvertCountryIdFromAlpha2ToAlpha3Output => {
    const countryKey = this.countryIdsAlpha2Keys.get(params.countryId)

    return CountryId[countryKey]
  }
}
