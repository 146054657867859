import React, { useState } from 'react'
import { IntroductionStep } from './introduction-step'
import { EmailStep } from './email-step'
import { AccountStep } from './account-step'
import { AccountLinkStep } from './account-link-step'
import { AccountResetStep } from './account-reset-step'

export enum Steps {
  INTRO,
  EMAIL,
  ACCOUNT,
  ACCOUNT_LINK,
  ACCOUNT_RESET
}

const StepsList = {
  [Steps.INTRO]: IntroductionStep,
  [Steps.EMAIL]: EmailStep,
  [Steps.ACCOUNT]: AccountStep,
  [Steps.ACCOUNT_LINK]: AccountLinkStep,
  [Steps.ACCOUNT_RESET]: AccountResetStep,
}

export const StepsConfirmation = () => {
  const [activeStep, setActiveStep] = useState(Steps.INTRO)

  const advanceStep = (numberOfSteps = 1) => {
    const nextStep = activeStep + numberOfSteps
    setActiveStep(nextStep)
  }

  const ActiveStep = StepsList[activeStep]
  return <ActiveStep advanceStep={advanceStep} />
}
