import React from 'react'
import styled from 'styled-components'
import { Typography }
  from '@naturacosmeticos/natds-web'

import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

const StyleBar = styled.div`
  --marginHorizontal: -16px;
  margin-left: var(--marginHorizontal);
  margin-right: var(--marginHorizontal);

  background: rgba(228,228,228,0.29);
  height: 32px;
  margin-bottom: 32px;
  padding: 4px 0;

  .MuiTypography-root {
    font-size: 18px;
  }
  @media ${minDeviceSizeForMediaQueries.tablet} {
    --marginHorizontal: -64px;
    .MuiTypography-root {
      font-size: 20px;
    }
  }
`

type EmailBarPropsType = {
  email: string,
}

export const EmailBar: React.FC<EmailBarPropsType> = ({ email }) => (
  <StyleBar>
    <Typography variant="h6">{email}</Typography>
  </StyleBar>
)
