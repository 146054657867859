import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { HttpPostClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'
import { FACELIFT_WEB_SOURCESYSTEM_ID } from '@/utils/constants'

type CheckValidationCodeHeaders = {    
    'x-api-key': string,
    sourcesystem: string
}

type CheckValidationCodeBody = {
  personId: string
  countryCode: CountryId
  companyId: CompanyId
  checkType: string
  verificationCode: string
  channel: string
  sequence: string
}

export type CheckValidationCodeParams = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
  verificationCode: string
  channel: string
  sequence: string
  checkType: string
}

export type ResponseRemoteCheckValidationCode = {
    code: string
    message: string
}

export class RemoteCheckValidationCode {
    constructor(
        private readonly httpPostClient: HttpPostClient<CheckValidationCodeHeaders, CheckValidationCodeBody>,
        private readonly xApiKey: string,
    ) {}
    
    async check(params: CheckValidationCodeParams): Promise<ResponseRemoteCheckValidationCode> {
        const { verificationCode, countryId, companyId, personId, channel, sequence, checkType } = params
        const httpResponse = await this.httpPostClient.post({
          url: '/validation-code/check',
          headers: {
            'x-api-key': this.xApiKey,
            sourcesystem: FACELIFT_WEB_SOURCESYSTEM_ID,
          },
          body: {
              verificationCode,
              personId,
              companyId,
              checkType,
              countryCode: countryId,
              channel,
              sequence
          }
        })

        return this.handleResponse(httpResponse)
    }

    private handleResponse = (httpResponse) => {
        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
            case HttpStatusCode.created:
            case HttpStatusCode.noContent:
                return httpResponse.body
            case HttpStatusCode.badRequest:
              throw new BadRequestError(httpResponse.body)
            case HttpStatusCode.notFound:
              throw new NotFoundError(httpResponse.body)
            default:
              throw new UnexpectedError()
        }
    }
}
