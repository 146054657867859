import {
  CompleteAttachmentParams,
  UploadAttachmentParams,
  UploadAttachmentResponseBody,
  UploadValidateAttachmentParams,
  ValidateAttachmentParams,
  ValidateAttachmentResponseBody,
} from '@/domain/use-cases/attachment'
import { GetPersonDataParams, PersonData } from '@/domain/use-cases/person'
import { makeGetPersonData } from '@/main/factories/remote'
import { makeRemoteValidateAttachment, makeRemoteUploadAttachment, makeRemoteCompleteAttachment } from '@/main/factories/remote/attachment'
import { makeRemoteUploadValidateAttachment } from '@/main/factories/remote/attachment/make-remote-upload-validate-attachment'

export type AttachmentsPageApi = {
  upload: (params: UploadAttachmentParams) => Promise<UploadAttachmentResponseBody>
  getPersonData: (data: GetPersonDataParams) => Promise<PersonData | {} | null | unknown>
  validate: (params: ValidateAttachmentParams) => Promise<ValidateAttachmentResponseBody[]>
  complete: (params: CompleteAttachmentParams) => Promise<void>
  uploadValidate: (params: UploadValidateAttachmentParams) => Promise<ValidateAttachmentResponseBody[]>
}
export const makeAttachmentsPageApi = (): AttachmentsPageApi => {
  const validator = makeRemoteValidateAttachment()
  const validate = (params: ValidateAttachmentParams) => validator.validate(params)

  const uploader = makeRemoteUploadAttachment()
  const upload = (params: UploadAttachmentParams) => uploader.upload(params)

  const personDataGetter = makeGetPersonData()
  const getPersonData = (data: GetPersonDataParams) => personDataGetter.get(data)

  const completeSetter = makeRemoteCompleteAttachment()
  const complete = (params: CompleteAttachmentParams) => completeSetter.complete(params)

  const uploaderValidate = makeRemoteUploadValidateAttachment()
  const uploadValidate = (params: UploadValidateAttachmentParams) => uploaderValidate.uploadValidate(params)


  return {
    upload, getPersonData, validate, complete, uploadValidate
  }
}
