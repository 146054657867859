import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'
import { deviceSize } from '@/utils/device-sizes'

const mediaQuery = `@media (max-width:${deviceSize.tablet}px)`

export const useDigitalSpaceStyles = makeStyles<Theme>((theme) => ({
  inlineCenter: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  storeContainer: {
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px 0`,
    [mediaQuery]: {
      flexFlow: 'column',
      alignItems: 'start',
    }
  },
  avatarContainer: {
    [mediaQuery]: {
      paddingBottom: theme.spacing(2)
    }
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  socialMediaIcons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: theme.spacing(1),
  },
  welcomeMessage: {
    width: '60%',
    marginTop: theme.spacing(0.5),
    [mediaQuery]: {
      width: '100%',
    }
  },
  shareDigitalSpace: {
    marginTop: theme.spacing(3),
    justifyContent: 'space-between',
    [mediaQuery]: {
      flexFlow: 'column',
      alignItems: 'start',
    }
  },
  visitMySpaceButton: {
    [mediaQuery]: {
      width: '100%',
      marginTop: theme.spacing(3),
    }
  },
  createDigitalSpace: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [mediaQuery]: {
      flexFlow: 'column',
      alignItems: 'unset',
    }
  },
  createDigitalSpaceButton: {
    [mediaQuery]: {
      width: '100%',
      marginTop: theme.spacing(3),
    }
  },
  digitalSpaceTitle: {
    display: 'flex',
    justifyContent: 'center',
  }
}))
