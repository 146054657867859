import React from 'react'

type IRenderElseIf = {
    conditional: boolean
    trueContent: JSX.Element
    falseContent: JSX.Element
}

const RenderElseIf = ({ conditional, trueContent, falseContent }: IRenderElseIf) => {
    if (conditional) {
        return trueContent
    } 
    return <>{falseContent}</>
}

export default RenderElseIf