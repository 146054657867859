import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useMyProfileStyles = makeStyles<Theme>((theme) => ({
  forcePadding: {
    paddingTop: `${theme.spacing(4)}px !important`,
  },
  container: {
    paddingTop: theme.spacing(4),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  iconSectionTitle: {
    paddingRight: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.primary,
  }
}))
