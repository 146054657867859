import React, { useContext, useEffect, useState } from 'react'
import { Container } from '@/main/components/pages-structures'
import {
  FormContext,
  FormContextProps,
  IdentityInfoContext,
  NavigationContext,
  NavigationContextProps,
} from '@/main/contexts'
import {
  CardHeader,
  CardContent,
} from '@/main/components'
import { IndicationInfo } from '@/data/use-cases/indication/remote-search-indication'
import {
  ProgressIndicator,
  Typography,
} from '@naturacosmeticos/natds-web'
import {
  IndicationInfoContainer,
  ProgressContainer,
  SearchContainer,
} from '@/main/pages/indication/indication-page.styles'
import {
  ModalWithPersonCheckbox,
} from '@/main/components/modal-with-person-checkbox'
import { IndicationMessages } from '@/domain/models/messages/messages'
import { TextFieldWithButton } from '@/main/components/text-field/text-field-with-button'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { useGetPersonData } from '../digital-store/hooks/use-get-person-data'
import { IndicationPageApi } from './api/make-indication-page-api'

type IndicationPageProps = {
  api: IndicationPageApi
}

const initialSelectedIndication : IndicationInfo = {
  sellerId: null,
  name: null,
}

export const IndicationPage: React.FC<IndicationPageProps> = ({ api }) => {
  const { onSubmit } = useContext<FormContextProps>(FormContext)
  const { goToPreviousPage, goToNextPage, bypassStep } = useContext<NavigationContextProps>(NavigationContext)

  const {
    personId,
    companyId,
    countryId,
    shouldCallCompleteEndpoint,
    isPossibleToBypass,
  } = useContext(IdentityInfoContext)

  const { personData, personDataLoading } = useGetPersonData({
    relations: ['additionalInformation'],
    personId,
    countryId,
    companyId,
  })

  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [isLoadingContainer, setIsLoadingContainer] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isIndicationSelected, setIsIndicationSelected] = useState(false)

  const [indicationInfo, setIndicationInfo] = useState<IndicationInfo>({})
  const [selectedIndication, setSelectedIndication] = useState<IndicationInfo>(initialSelectedIndication)
  const [sellerId, setSellerId] = useState<string>('')
  const [indicationErrorMessage, setIndicationErrorMessage] = useState<string>('')
  const isSearchButtonDisabled = sellerId.length === 0
  const validChars = new RegExp(/^\d{0,15}$/)

  const messages = usePageMessages(Page.Indication).messages as IndicationMessages

  useEffect(() => {
    const personSellerId = personData?.person?.additionalInformation?.introducerSellerId
    if (personSellerId) {
      if (!personDataLoading && isPossibleToBypass) {
        bypassStep()
        return
      }

      setIsLoadingContainer(true)
      api.searchIndication({
        personCode: personSellerId,
        companyId,
        countryId,
      }).then((indicationInfo: IndicationInfo) => {
        setSellerId(personSellerId)
        setSelectedIndication(indicationInfo)
        setIndicationErrorMessage('')
      }).catch((err) => {
        setIndicationErrorMessage(messages.modal[err.message])
      }).finally(() => {
        setIsLoadingContainer(false)
      })
    }
  }, [personData, personDataLoading])

  if (personDataLoading) {
    return (
      <ProgressContainer>
        <ProgressIndicator />
      </ProgressContainer>
    )
  }

  const onCloseModalIndication = () => {
    setIsModalOpen(false)
  }

  const openModalIndication = () => {
    setIsModalOpen(true)
  }

  const onSearchClick = () => {
    setIsSearchLoading(true)
    openModalIndication()
    api.searchIndication({
      personCode: sellerId,
      companyId,
      countryId,
    }).then((indicationInfo: IndicationInfo) => {
      setIndicationInfo(indicationInfo)
      setIndicationErrorMessage('')
    }).catch((err) => {
      setIndicationErrorMessage(messages.modal[err.message])
    }).finally(() => {
      setIsSearchLoading(false)
    })
  }

  const confirmIndication = () => {
    setSelectedIndication(indicationInfo)
    onCloseModalIndication()
    setIsIndicationSelected(false)
  }

  const onSubmitIndication = () => {
    const data = {
      introducerSellerId: selectedIndication?.sellerId ?? null,
      introducerSellerName: selectedIndication?.name ?? null,
    }

    if (shouldCallCompleteEndpoint) completeIndication()

    onSubmit(data, goToNextPage)
  }

  const completeIndication = () => {
    api.completeIndication({
      personId,
      countryId,
      companyId,
      introducerSellerId: selectedIndication?.sellerId,
    }).catch((err) => {
      setIndicationErrorMessage(messages.modal[err.message])
    })
  }

  const confirmationError = () => {
    onCloseModalIndication()
    setSelectedIndication({ sellerId: null, name: null })
    setSellerId('')
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={onSubmitIndication}
      onPreviousButtonClick={goToPreviousPage}
      isLoading={isLoadingContainer}
    >
      <CardHeader title={messages.title} subtitle={messages.subtitle} />
      <CardContent>
        <SearchContainer>
          <TextFieldWithButton
            id="seller-id-input"
            label={messages.label}
            buttonProps={{
              variant: 'outlined',
              buttonLabel: messages.searchButtonLabel,
              buttonOnClick: onSearchClick,
              shouldButtonBeDisabled: isSearchButtonDisabled,
              color: 'default',
            }}
            inputProps={{
              onChange: (event) => setSellerId(event.target.value),
              value: sellerId,
            }}
            validChars={validChars}
          />
        </SearchContainer>

        {selectedIndication?.sellerId && (
          <IndicationInfoContainer>
            <Typography variant="body2">{messages.indicationLabel}</Typography>
            <Typography
              variant="subtitle2"
            >
              {selectedIndication.name}
            </Typography>
          </IndicationInfoContainer>
        )}
      </CardContent>

      <ModalWithPersonCheckbox
        errorMessage={indicationErrorMessage}
        info={indicationInfo}
        isItemSelected={isIndicationSelected}
        isSearchLoading={isSearchLoading}
        onItemSelected={() => setIsIndicationSelected(!isIndicationSelected)}
        confirmationError={confirmationError}
        confirmSelection={confirmIndication}
        isModalOpen={isModalOpen}
        handleCloseModal={onCloseModalIndication}
        messages={messages.modal}
      />
    </Container>
  )
}
