import { HttpStatusCode } from '@/data/protocols/http'
import { SendCodeValidationOrigin } from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'
import { WorkflowType } from '@/data/use-cases/register-maintenance-security-validation/status-workflow'
import { ResponseRemoteCheckValidationCode } from '@/data/use-cases/register-maintenance-validation-code/remote-check-validation-code'
import { CHANNEL_STORAGE_NAME, ChannelStorage } from '@/domain/models/address'
import { CountryId } from '@/domain/models/country'
import { MaintenanceValidationCodeMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { BUSINESS_MODELS, FUNCTION_IDS, ROLES } from '@/domain/models/person'
import { PHONE_EMAIL_STORAGE_NAME } from '@/domain/models/person/phone-email-storge'
import { MaintenanceRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { SaveConsultantInfoParams } from '@/domain/use-cases/register-maintenance/save-consultant-info'
import { CountdownLink } from '@/main/components/countdown-link/countdown-link'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { getObjectInStorage } from '@/main/hooks/useStorage'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Icon, Spacing, Typography } from '@naturacosmeticos/natds-web'
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ControllerProps, FormProvider, useForm } from 'react-hook-form'
import { createUrlAddressMaintenance } from '../../../../utils/create-url-address-maintenance'
import { Container } from '../../register-maintenance-address/components/pages-structures'
import { DialogWithLoader } from '../../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { ValidationCodePageApi } from '../api/make-maintenance-validation-code-page-api'
import { ControlledTextField } from '../components/controlled-text-field'
import { validateValidationCodeResponse } from '../register-maintenance-validation-code-address/maintenance-validation-code-page'
import {
  ADD_QUANTITY_REQUESTS,
  APPROVED_STATUS,
  DELAY_TO_REQUEST_STATUS_WORKFLOW,
  MAX_LIMIT_RETRY_STATUS,
  REJECTED_STATUS,
  RUNNING_STATUS,
  TIME_TO_RETRY_STATUS,
} from '../utils/constants'

type FormInputs = {
  code: string
}

type CodeInputProps = {
  validChars: RegExp
} & Pick<ControllerProps<'input'>, 'rules'>

export type ValidationCodePageProps = {
  api: ValidationCodePageApi
}

export const MaintenanceValidationCodePagePhoneEmail = ({ api }: ValidationCodePageProps) => {
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true)
  const [inputValidation, setInputValidation] = useState<string>()
  const [infoMessage, setInfoMessage] = useState('')
  const [openSubmitLoading, setOpenSubmitLoading] = useState(false)
  const [quantityRetry, setQuantityRetry] = useState(0)

  const { personId, consultantId, countryId, companyId } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const { decorativeBoxInTokenValidation } = useTenantConfigs(
    tenantId,
    Page.MaintenanceRegister
  ) as MaintenanceRegisterConfigs

  const messages = usePageMessages(Page.MaintenanceValidationCode)
    .messages as MaintenanceValidationCodeMessages

  const codeInput: CodeInputProps = {
    rules: {
      required: true,
      minLength: {
        value: 6,
        message: messages.errorMessageValidationCode,
      },
      pattern: {
        value: new RegExp(/^\d{0,6}$/),
        message: messages.errorMessageValidationCode,
      },
    },
    validChars: new RegExp(/^\d{0,6}$/),
  }

  const formMethods = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  })

  const { watch, control } = formMethods

  const goRejectedPage = () => {
    const url = createUrlAddressMaintenance({
      country: countryId,
      url: `mfe-register/maintenancePhoneEmailRejected/${consultantId}`,
    })
    window.location.assign(url)
  }

  const goApprovedPage = () => {
    const url = createUrlAddressMaintenance({
      country: countryId,
      url: `mfe-register/maintenancePhoneEmailApproved/${consultantId}`,
    })
    window.location.assign(url)
  }

  const goGoBackToList = () => {
    const url = createUrlAddressMaintenance({
      country: countryId,
      url: 'list',
    })
    window.location.assign(url)
  }

  const goBackPreviousPage = () => {
    const url = createUrlAddressMaintenance({
      country: countryId,
      url: `mfe-register/maintenanceSecurityValidation/${personId}`,
    })
    window.location.assign(url)
  }

  const resendValidationCodeHandler = async (): Promise<void> => {
    const url = createUrlAddressMaintenance({
      country: countryId,
      url: `mfe-register/maintenanceSecurityValidation/${consultantId}`,
    })
    window.location.assign(url)
  }

  const handleSubmit = async () => {
    try {
      const dataStorage = getObjectInStorage(PHONE_EMAIL_STORAGE_NAME) as SaveConsultantInfoParams
      setOpenSubmitLoading(true)
      await api.saveConsultantInfoBasicData(dataStorage)

      if (countryId === CountryId.Peru) {
        setTimeout(() => {
          getStatusWorkflow()
        }, DELAY_TO_REQUEST_STATUS_WORKFLOW)

        return
      }

      goApprovedPage()
    } catch (err) {
      if (err && err.statusCode === HttpStatusCode.unauthorized) {
        goGoBackToList()
      } else {
        goRejectedPage()
      }
    }
  }

  const getStatusWorkflow = async () => {
    if (quantityRetry === MAX_LIMIT_RETRY_STATUS) goRejectedPage()
    try {
      const response = await api.statusWorkflow({
        personId: consultantId,
        businessModel: BUSINESS_MODELS.DIRECT_SALE,
        role: ROLES.CONSULTANT,
        functionId: FUNCTION_IDS.BEAUTY_CONSULTANT,
        countryId,
        sourceSystem: '99',
        companyId,
        workflowType: WorkflowType.PERSONAL_DATA_MAINTENANCE,
      })

      switch (response.status) {
        case APPROVED_STATUS: {
          goApprovedPage()
          break
        }

        case REJECTED_STATUS: {
          goRejectedPage()
          break
        }

        case RUNNING_STATUS: {
          setTimeout(() => {
            setQuantityRetry((prev) => prev + ADD_QUANTITY_REQUESTS)
            getStatusWorkflow()
          }, TIME_TO_RETRY_STATUS)
          break
        }

        default: {
          goRejectedPage()
          break
        }
      }
    } catch {
      goRejectedPage()
    }
  }

  const checkCode = async (code: string) => {
    try {
      setOpenSubmitLoading(true)
      const { channel, sequence } = getObjectInStorage(CHANNEL_STORAGE_NAME) as ChannelStorage
      const respoonse: ResponseRemoteCheckValidationCode = await api.checkValidationCode({
        personId: consultantId,
        countryId,
        companyId,
        verificationCode: code,
        checkType: SendCodeValidationOrigin.CHANGE_EMAIL_PHONE,
        channel,
        sequence,
      })

      validateValidationCodeResponse(respoonse)
      setDisableNextButton(false)
      setInfoMessage(messages.successMessageValidationCode)
      setInputValidation('success')
      setOpenSubmitLoading(false)
    } catch {
      setDisableNextButton(true)
      setInfoMessage(messages.errorMessageValidationCode)
      setInputValidation('error')
      setOpenSubmitLoading(false)
    }
  }

  const handleChange = () => {
    const codeValue = watch('code')
    if (codeValue.length === 6) {
      checkCode(codeValue)
    } else {
      setDisableNextButton(true)
      setInfoMessage(messages.errorMessageValidationCode)
      setInputValidation('error')
    }
  }

  return (
    <Container
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={handleSubmit}
      disableNextButton={disableNextButton}
      previousButtonLabel={messages.backButtonLabel}
      onPreviousButtonClick={goBackPreviousPage}
    >
      <Helmet>
        <title>{messages.title}</title>
      </Helmet>
      <Typography variant="h5">{messages.title}</Typography>
      <Spacing padding="tiny" />
      <Typography variant="body1">{messages.description}</Typography>
      <Spacing padding="standard" />
      <FormProvider {...formMethods}>
        <ControlledTextField
          customOnChange={handleChange}
          infoMessage={infoMessage}
          state={inputValidation}
          label={messages.inputValidationCodeLabel}
          id="code"
          data-testid="validation-code-input-field-test-id"
          rules={codeInput.rules}
          validChars={codeInput.validChars}
          control={control}
        />
      </FormProvider>
      <Spacing paddingTop="small">
        <CountdownLink
          countdown={300}
          timerMessageSeconds={messages.resendCodeMessageSeconds}
          timerMessageMinutes={messages.resendCodeMessageMinutes}
          linkText={messages.resendLink}
          onClick={resendValidationCodeHandler}
        />
      </Spacing>
      <Spacing padding="small" />
      <div
        style={{
          backgroundColor: decorativeBoxInTokenValidation && '#d0ebff',
          padding: decorativeBoxInTokenValidation && '15px',
          display: 'flex',
          borderRadius: '5px',
        }}
      >
        {decorativeBoxInTokenValidation && (
          <div style={{ marginRight: '10px' }}>
            <Icon size="tiny" name="outlined-alert-info" />
          </div>
        )}
        <div>
          <Typography variant={decorativeBoxInTokenValidation ? 'h6' : 'subtitle1'}>
            <ParseStringToReact stringToParse={messages.notReceivedTitle} />
          </Typography>
          {!decorativeBoxInTokenValidation && <Spacing padding="tiny" />}
          <Typography variant="body1">
            <ParseStringToReact stringToParse={messages.contactInformationText} />
          </Typography>
        </div>
      </div>
      <DialogWithLoader isOpen={openSubmitLoading} title={messages.waitingMessage} />
    </Container>
  )
}
