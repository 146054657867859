import styled from 'styled-components'
import { DialogContent as NatDsDialogContent, DialogActions } from '@/main/components/dialog'

export const DialogContent = styled(NatDsDialogContent)`
  max-height: 50vh;
  padding: 0.8rem 0;
  border-top: 0.1rem solid ${({ theme }) => theme.color.lowEmphasis};
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.lowEmphasis};
`

export const DialogActionsWithLink = styled(DialogActions)`
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
export const ContainerActions = styled.div`
  display: flex;
  justify-content: end;
`
export const LinkTerm = styled.a`
  font-size: ${({ theme }) => theme.sizes.small}px;
  color: ${({ theme }) => theme.color.link};
`