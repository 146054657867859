import React from 'react'
import {
  CarouselContainer,
  Content,
  DescriptionTexts,
  DurationText,
  Loader,
} from '@/main/pages/confirmation/loading/loading-styles'
import { LoadingSlider } from '@/main/pages/confirmation/loading/loading-slider'
import { LoadingMessages } from '@/main/pages/confirmation/loading/loading-wrapper'
import { StatusCardContainer } from '@/main/components/status-card-container'

export type LoadingSlide = {
  translationKey: string
  name: string
}

const slidesItems: LoadingSlide[] = [
  {
    translationKey: 'slide1',
    name: 'natural_formulas_icon',
  },
  {
    translationKey: 'slide2',
    name: 'no_animal_testing_icon',
  },
  {
    translationKey: 'slide3',
    name: 'safe_for_you_and_environment_icon',
  },
  {
    translationKey: 'slide4',
    name: 'commitment_to_climate_icon',
  },
  {
    translationKey: 'slide5',
    name: 'eco_friendly_packging_icon',
  },
  {
    translationKey: 'slide6',
    name: 'beware_of_the_origin_icon',
  },
]

type LoadingProps = {
  messages: LoadingMessages
}

export const Loading: React.FC<LoadingProps> = ({ messages }) => (
  <StatusCardContainer>
    <Content>
      <Loader data-testid="loading-loader" />
      <DescriptionTexts className="description_loading_text" variant="subtitle2">
        {messages.description.first}
      </DescriptionTexts>
      <DescriptionTexts className="description_loading_text" variant="subtitle2">
        {messages.description.second}
      </DescriptionTexts>
      <DurationText variant="body2" className="duration_text">
        {messages.durationText}
      </DurationText>
    </Content>

    <CarouselContainer>
      <LoadingSlider messages={messages.carousel} slidesItems={slidesItems} />
    </CarouselContainer>
  </StatusCardContainer>
)
