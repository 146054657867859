import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteAcceptDigitalSignature } from '@/data/use-cases/digital-signature/remote-accept-digital-signature'
import { AcceptDigitalSignature } from '@/domain/use-cases/digital-signature/accept-digital-signature'

export const makeRemoteAcceptDigitalSignature = (): AcceptDigitalSignature => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY

  return new RemoteAcceptDigitalSignature(
    httpPatchClient,
    xApiKey,
  )
}
