import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import {
  RemoteGetMultipleAddress,
} from '@/data/use-cases/register-maintenance/remote-get-multiple-address'

export const makeRemoteGetMultipleAddress = (): RemoteGetMultipleAddress => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteGetMultipleAddress = new RemoteGetMultipleAddress(
    httpPatchClient,
    xApiKey,
  )

  return remoteGetMultipleAddress
}
