import { ControlledTextField } from '@/main/components'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import React from 'react'
import { ControllerProps } from 'react-hook-form'
import { ProgressIndicator } from '../progress-indicator'
import { ControlledTextFieldWithLink } from '../text-field/text-field-with-link/controlled-text-field-with-link'
import { LoadingContainer } from './zip-code-text-field.styles'

export type ZipCodeTextFieldProps = {
  label: string
  id?: string
  placeholder?: string
  validChars?: RegExp
  mask?: Mask
  link?: {
    show?: boolean
    text?: string
    url?: string
  }
  disabled?: boolean
  isLoading?: boolean
  customOnChange?: (inputValue: string) => void
  customOnBlur?: (inputValue: string) => void
  required?: boolean
  showAsterisk?: boolean
} & Pick<ControllerProps<'input'>, 'rules' | 'control' | 'required'>

export const ZipCodeTextField: React.FC<ZipCodeTextFieldProps> = ({
  label,
  id = 'zipCode',
  placeholder,
  validChars,
  mask,
  link = { show: false },
  disabled,
  rules,
  control,
  isLoading,
  customOnChange,
  customOnBlur,
  required,
  showAsterisk,
}) => {
  return link.show ? (
    <ControlledTextFieldWithLink
      id={id}
      label={label}
      placeholder={placeholder}
      rules={rules}
      control={control}
      validChars={validChars}
      linkText={link?.text}
      href={link?.url}
      mask={mask}
      required={required}
      showAsterisk={showAsterisk}
      customOnBlur={customOnBlur}
      customOnChange={customOnChange}
    />
  ) : (
    <ControlledTextField
      id={id}
      label={label}
      placeholder={placeholder}
      rules={rules}
      control={control}
      validChars={validChars}
      mask={mask}
      showAsterisk={false}
      customOnChange={customOnChange}
      customOnBlur={customOnBlur}
      icon={
        isLoading ? (
          <LoadingContainer>
            <ProgressIndicator data-testid="loading" size={24} />
          </LoadingContainer>
        ) : null
      }
      disabled={disabled}
    />
  )
}
