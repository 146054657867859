import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId, Country } from '@/domain/models/country'
import { BUSINESS_MODELS, FUNCTION_IDS, PhoneType, ROLES } from '@/domain/models/person'
import { USER_PROFILE_TYPE } from '@/domain/models/person/user-profile-type'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { PersonRoles } from '@/domain/use-cases/person'
import { Toggles } from '@/main/pages/register-maintenance/maintenance-register-page'
import React, { createContext, ReactNode, useMemo, useState } from 'react'

interface IdentityInfoProviderProps {
  info: IdentityInfo
  children: ReactNode
}

type commercialStructure = {
  groupId: string
  sectorId: string
  gvId: string
  reId: string
  gmId: string
}

export type IdentityInfo = {
  personId: string
  commercialStructure?: commercialStructure
  country: Country
  countryId: CountryId
  cycle?: number
  toggles?: Toggles
  tenantId: TenantId
  shouldCreatePerson?: boolean
  businessModel?: BUSINESS_MODELS
  role?: ROLES
  functionId?: FUNCTION_IDS
  sourceSystem?: string
  companyId?: CompanyId
  authorization?: string
  userId?: string
  userName?: string
  phoneNumber?: string
  phoneType?: PhoneType
  email?: string
  shouldCallCompleteEndpoint?: boolean
  isPossibleToBypass?: boolean
  consultantId?: string
  goBackPage?: () => {}
  personalDataSearchAddressWithZipCodeToggle?: boolean
  disableFieldsPersonalData?: boolean
  disableNextButton?: boolean
  authorizationCodeMessageAvonMX?: boolean
  accessTokenNaturaWeb?: string
  loginId?: string
  externalComponent?: React.Component
  allRoles?: Array<ProfileData>
  isMiGrupoRed?: boolean
} & Profile

type Profile = {
  isCN?: boolean
  isCNCeased?: boolean
  isLeader?: boolean
  isGDN?: boolean
}

export type IdentityInfoContextData = {
  setProfileRoles: (roles: Array<PersonRoles>) => void
} & IdentityInfo

type ProfileData = {
  businessModel: BUSINESS_MODELS
  functionId: FUNCTION_IDS
  role: ROLES
}

export const IdentityInfoContext = createContext({} as IdentityInfoContextData)

export const IdentityInfoProvider = ({ info, children }: IdentityInfoProviderProps) => {
  const { countryId, businessModel, functionId, role, isCN } = info

  const [profilesData, setProfilesData] = useState<Array<ProfileData>>([{ businessModel, functionId, role }])

  const profileType = useMemo(
    () =>
      profilesData.map(({ businessModel, functionId, role }) =>
        getUserProfileType({ countryId, businessModel, functionId, role })
      ),
    [JSON.stringify(profilesData)]
  )

  const profile: Profile = useMemo(
    () => ({
      isCN: typeof isCN === 'boolean' ? isCN : profileType.includes(USER_PROFILE_TYPE.CN),
      isLeader: profileType.includes(USER_PROFILE_TYPE.LEADER),
      isGDN: profileType.includes(USER_PROFILE_TYPE.GDN),
    }),
    [profileType]
  )

  const setProfileRoles = (roles: Array<PersonRoles> = []) => {
    const rolesFinded = roles.filter((role) => !role.endDate)

    setProfilesData(
      rolesFinded.map((role) => ({
        businessModel: role.businessModelId,
        functionId: role.functionId,
        role: role.roleId,
      }))
    )
  }

  return (
    <IdentityInfoContext.Provider
      value={{
        ...info,
        ...profile,
        allRoles: profilesData,
        businessModel,
        functionId,
        role,
        setProfileRoles,
      }}
    >
      {children}
    </IdentityInfoContext.Provider>
  )
}

type GetRoleParams = {
  businessModel: BUSINESS_MODELS
  functionId: FUNCTION_IDS
  role: ROLES
  countryId: CountryId
}

function getUserProfileType({
  countryId,
  businessModel,
  functionId,
  role,
}: GetRoleParams): USER_PROFILE_TYPE {
  const profile = {
    [CountryId.Argentina]: {
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BEAUTY_CONSULTANT}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.CN,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.LEADER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.LEADER,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BUSINESS_DEVELOPMENT_MANAGER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.GDN,
    },
    [CountryId.Brazil]: {
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BEAUTY_CONSULTANT}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.CN,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.LEADER}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.LEADER,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BUSINESS_DEVELOPMENT_MANAGER}${ROLES.COLLABORATOR}`]: USER_PROFILE_TYPE.GDN,
    },
    [CountryId.Chile]: {
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BEAUTY_CONSULTANT}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.CN,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.LEADER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.LEADER,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BUSINESS_DEVELOPMENT_MANAGER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.GDN,
    },
    [CountryId.Colombia]: {
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BEAUTY_CONSULTANT}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.CN,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.LEADER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.LEADER,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BUSINESS_DEVELOPMENT_MANAGER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.GDN,
    },
    [CountryId.Mexico]: {
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BEAUTY_CONSULTANT}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.CN,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.LEADER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.LEADER,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BUSINESS_DEVELOPMENT_MANAGER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.GDN,
    },
    [CountryId.Malaysia]: {
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BEAUTY_CONSULTANT}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.CN,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BUSINESS_DEVELOPMENT_MANAGER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.GDN,
    },
    [CountryId.Peru]: {
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BEAUTY_CONSULTANT}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.CN,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.LEADER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.LEADER,
      [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BUSINESS_DEVELOPMENT_MANAGER}${ROLES.SALES_FORCE}`]: USER_PROFILE_TYPE.GDN,
    },
  }

  return profile[countryId]?.[`${businessModel}${functionId}${role}`]
}

export const exportToTest = {
  getUserProfileType,
}
