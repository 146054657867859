import styled from 'styled-components'
import { Typography } from '@/main/components/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.8rem 0;
`

export const AgreementContent = styled(Typography)`
  white-space: pre-wrap;
`
