import { MaintenancePhoneEmailCompletedMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenancePhoneEmailApprovedMessages(translate: TFunction): MaintenancePhoneEmailCompletedMessages {
  return {
    approvedIcon: translate('maintenancePhoneEmailApproved.approvedIcon'),
    title: translate('maintenancePhoneEmailApproved.title'),
    subtitle: translate('maintenancePhoneEmailApproved.subtitle'),
    myProfileButton: translate('maintenancePhoneEmailApproved.myProfileButton'),
  }
}