import { useCallback, useEffect, useState } from 'react'
import { SelectOption } from '@/main/components'
import { mapResponseSelectOption } from '@/utils/format-to-select/format-to-select'
import { LocalConvertCountryIdFromAlpha3ToAlpha2 } from '@/data/use-cases/country/local-convert-country-id-from-alpha-3-to-alpha-2'
import { COUNTRIES_IDS_KEYS, CountryId } from '@/domain/models/country'
import { State } from '@/domain/use-cases/address'

type useDomainParams = {
  getStates: ({ country: ConvertCountryIdFromAlpha3ToAlpha2Output }) => Promise<Array<State>>,
  countryId: CountryId,
  shouldFetch?: boolean,
  key?: keyof State
}

export const useDomainState = ({
 getStates, countryId, shouldFetch = true, key = 'id',
}: useDomainParams) => {
  const [selectPromise, setSelectPromise] = useState<Promise<SelectOption[]>>(null)
  const [states, setStates] = useState<SelectOption[]>([])

  const getStatesByKey = useCallback((key: keyof State = 'id', states: State[]): SelectOption[] => {
    const keys = {
      value: key,
      label: 'name',
    }
    const options = mapResponseSelectOption(states, keys)

    setStates(options)
    return options
  }, [])

  useEffect(() => {
    const country = new LocalConvertCountryIdFromAlpha3ToAlpha2(
      COUNTRIES_IDS_KEYS,
    ).convert({ countryId })

    if (shouldFetch) {
      setSelectPromise(getStates({
        country,
      }).then((response) => getStatesByKey(key, response)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch, countryId, key, getStatesByKey])

  return { selectPromise, states }
}
