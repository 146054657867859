import { SendLinkParams } from '@/data/use-cases/pre-register-confirmation/remote-send-link'
import { makeRemoteSendLink } from '@/main/factories/remote/pre-register-confirmation'

export type PreRegisterConfirmationApi = {
  sendLink: (params: SendLinkParams) => Promise<void>
}

export const makePreRegisterConfirmationApi = (): PreRegisterConfirmationApi => {
  const remoteSendLink = makeRemoteSendLink()
  const sendLink = (params: SendLinkParams) => remoteSendLink.send(params)

  return { sendLink }
}
