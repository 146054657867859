import produce, { Draft } from 'immer'

import { CompleteRegistrationTypes, CompleteRegistrationState } from './protocols'

export const initialState: CompleteRegistrationState = {
  error: false,
  isLoading: false,
}

export const reducer = produce((draft: Draft<CompleteRegistrationState>, action) => {
  switch (action.type) {
    case CompleteRegistrationTypes.COMPLETE_REGISTRATION_REQUEST:
      draft.isLoading = true
      break
    case CompleteRegistrationTypes.COMPLETE_REGISTRATION_SUCCESS:
      draft.error = action.payload.error
      draft.isLoading = false
      break
    case CompleteRegistrationTypes.COMPLETE_REGISTRATION_ERROR:
      draft.error = true
      draft.isLoading = false
      break
    default:
      break
  }
}, initialState)
