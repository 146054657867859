import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'

export const useMaintenancePersonalDataPage = makeStyles<Theme>(() => ({
  title: {
    marginBottom: '24px',
  },
  container: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  popover: {
    color: '#777',
    position: 'absolute',
    top: '9px',
    left: '80px',
    fontSize: '22px'
  },
  group:{
    position: 'relative'
  },
  '@global': {
    'input#nickname': {
      textTransform: 'capitalize'
    },
  },
}))
