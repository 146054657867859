import { HttpPostClient, HttpStatusCode } from '@/data/protocols/http'
import { CompanyId } from '@/domain/models/company/company-id'
import {
  AuthorizationCodeResponse,
  SendAuthorizationCode,
  SendAuthorizationCodeParams,
} from '@/domain/use-cases/authorization-code/authorization-code'
import { CountryId } from '@/domain/models/country'
import {
  BUSINESS_MODELS,
  FUNCTION_IDS,
  ROLES,
} from '@/domain/models/person'
import { UnexpectedError } from '@/domain/errors'
import { BadRequestError, ConflictError, ForbiddenError, NotFoundError } from '@/data/protocols/errors'

interface RemoteSendAuthorizationCodeDependencies {
  httpPostClient: HttpPostClient<AuthorizationCodeServiceHttpRequestHeaders, AuthorizationCodeServiceHttpRequestBody>
  xApiKey
}

type AuthorizationCodeServiceHttpRequestHeaders = {
  companyId: CompanyId
  userId: string
  countryId: CountryId
  authorization: string
  businessModel: BUSINESS_MODELS,
  role: ROLES,
  functionId: FUNCTION_IDS,
  sourceSystem: string,
}

type AuthorizationCodeServiceHttpRequestBody = {
  personId: string
}

export class RemoteSendAuthorizationCode implements SendAuthorizationCode {
  private readonly httpPostClient: HttpPostClient<AuthorizationCodeServiceHttpRequestHeaders, AuthorizationCodeServiceHttpRequestBody>
  private readonly xApiKey: string

  constructor(
    dependencies: RemoteSendAuthorizationCodeDependencies,
  ) {
    const { httpPostClient, xApiKey } = dependencies
    this.httpPostClient = httpPostClient
    this.xApiKey = xApiKey
  }

  async sendAuthorizationCode(sendAuthorizationCodeParams: SendAuthorizationCodeParams): Promise<AuthorizationCodeResponse> {
    const {
      personId,
      userId,
      countryId,
      companyId,
      authorization,
      businessModel,
      role,
      functionId,
      sourceSystem,
    } = sendAuthorizationCodeParams

    const headers = {
      companyId,
      userId,
      countryId,
      authorization,
      businessModel,
      role,
      functionId,
      sourceSystem,
      'x-api-key': this.xApiKey,
    }

    const body: AuthorizationCodeServiceHttpRequestBody = { personId }

    const response = await this.httpPostClient.post({
      headers,
      body,
      url: '/authorization-code/send',
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          shouldDisplayPage: Boolean(response.body?.showAuthorizationCodePage),
          phoneNumber: response.body?.phoneNumber,
          email: response.body?.email,
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError(response.body)
      case HttpStatusCode.forbidden:
        throw new ForbiddenError(response.body)
      case HttpStatusCode.notFound:
        throw new NotFoundError(response.body)
      case HttpStatusCode.conflict:
        throw new ConflictError(response.body)
      default:
        throw new UnexpectedError()
    }
  }
}
