import { PaymentAccountStatusSuccessMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getPaymentAccountStatusSuccessMessages(translate: TFunction): PaymentAccountStatusSuccessMessages {
  return {
    ctaButton: {
      link: translate('paymentAccountStatus.success.ctaButton.link'),
      text: translate('paymentAccountStatus.success.ctaButton.text'),
    },
    newConsultantInfo: {
      title: translate('paymentAccountStatus.success.newConsultantInfo.title'),
      text: translate('paymentAccountStatus.success.newConsultantInfo.text'),
    },
    alreadyIsConsultantInfo: {
      title: translate('paymentAccountStatus.success.alreadyIsConsultantInfo.title'),
      text: translate('paymentAccountStatus.success.alreadyIsConsultantInfo.text'),
    },
    button: translate('paymentAccountStatus.success.button'),
    reviewConsultant: {
      info: translate('paymentAccountStatus.success.reviewConsultantInfo'),
    },
    miNegocioUrl: translate('paymentAccountStatus.miNegocioUrl'),
  }
}
