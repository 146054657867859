import React from 'react'
import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { ExtraEntries as ExtraEntriesModel } from '@/domain/models/tenant/tenant-configs'
import { DigitalStoreMessages } from '@/domain/models/messages/messages'
import { FormProvider, UseFormMethods } from 'react-hook-form'
import { EntryField } from './entry-field'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    flex-direction: row;
  }
`

export type ExtraEntriesProps = {
  disableFields: boolean,
  fields: ExtraEntriesModel,
  fieldsTexts: DigitalStoreMessages['document']
  formMethods: UseFormMethods<any>
  shouldRender: boolean
}

export const ExtraEntries = ({
 fields, fieldsTexts, formMethods, disableFields, shouldRender,
}: ExtraEntriesProps) => (
  <>
    {shouldRender && (
    <Container>
      <FormProvider {...formMethods}>
        {(
            fields.map((document) => {
              const {
                name,
                validChars,
                mask,
                rules: documentRules,
                isUpperCase,
                required,
              } = document

              const {
                label,
                placeholder,
                rules: fieldRulesTexts,
              } = fieldsTexts[name]

              return (
                <EntryField
                  id={name}
                  key={name}
                  texts={{
                    label,
                    placeholder,
                  }}
                  mask={mask}
                  validChars={validChars}
                  required={required}
                  disabled={disableFields}
                  rules={{
                    required: fieldRulesTexts.required,
                    pattern: {
                      value: documentRules.pattern,
                      message: fieldRulesTexts.pattern,
                    },
                    minLength: {
                      value: documentRules.minLength,
                      message: fieldRulesTexts.minLength,
                    },
                    maxLength: {
                      value: documentRules.minLength,
                      message: fieldRulesTexts.maxLength,
                    },
                  }}
                  showAsteriskInRequiredLabel={false}
                  isUpperCase={isUpperCase}
                />
              )
            })
          )}
      </FormProvider>
    </Container>
    )}
  </>
)
