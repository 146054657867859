import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useContactStyles = makeStyles<Theme>((theme) => ({
  container: {
    minHeight: '100%',
  },
  contactContainer: {
    paddingTop: `${theme.spacing(1.3)}px`,
  },
  contactPhoneContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactCopyButton: {
    marginRight: `-${theme.spacing(1)}px`,
  },
}))
