import React, { useCallback, useEffect, useState } from 'react'
import i18n from 'i18next'

import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '@/infra/global-state/redux/store'
import { DigitalStoreState } from '@/infra/global-state/redux/store/ducks/digital-store'
import {
  checkDigitalStoreAvailabilityError,
  checkDigitalStoreAvailabilityRequest,
  getDigitalStoreInfoRequest,
  resetDigitalStoreStateOnChangeRequest,
  // resetDigitalStoreStateOnChangeRequest,
  setDigitalStorehelperTextKey,
} from '@/infra/global-state/redux/store/ducks/digital-store/actions'
import {
 CardHeader,
  ProgressIndicator as NatDsProgressIndicator,
} from '@naturacosmeticos/natds-web'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { Container } from '@/main/components/pages-structures'
import { CountryId } from '@/domain/models/country'
import { DigitalStoreMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { makeSaveDigitalStore } from '@/main/factories/remote/digital-store'
import { SaveDigitalStoreParams } from '@/domain/use-cases/digital-store/save-digital-store'
import { Country } from '@/domain/models/country/country'
// import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { CompanyId } from '@/domain/models/company/company-id'
import { getLanguage } from '@/infra/translation/i18next/get-language'
import {
  useDigitalStoreRenamePageStyles,
  Content,
} from './digital-store-rename-form.styles'
import NameValidation from '../../components/name-validation'
import { UrlPreview } from '../../components/url-preview'

type DigitalStoreRenamePageProps = {
  personId: string,
  onSave?: (savedName:string, isSavingDigitalStoreName:boolean) => void
}
const country = new Country('br')
export const ProgressIndicator = NatDsProgressIndicator

export const DigitalStoreRenameForm = ({ personId, onSave }: DigitalStoreRenamePageProps) => {
  const dispatch = useDispatch()
  const DigitalStoreRenamePageClasses = useDigitalStoreRenamePageStyles()
  const saveDigitalStoreRemote = makeSaveDigitalStore()

  const {
    error,
    isLoading,
    isAvailable,
    helperTextKey,
    savedDigitalStoreName,
  } = useSelector<ApplicationState, DigitalStoreState >(
    (state) => ({ ...state.digitalStore }),
  )
  useEffect(() => {
    i18n.changeLanguage(getLanguage(country.threeAlphaCountryCode, CompanyId.Natura))
  }, [])
  const messages = usePageMessages(Page.DigitalStore).messages as DigitalStoreMessages
  const getErrorMessages = useCallback((): string | undefined => {
    const errorIndex:string[] = ['unavailableName', 'invalidNameSpace', 'genericError', 'errorSaving']
    if (!!helperTextKey && errorIndex.includes(helperTextKey)) {
      return messages[helperTextKey]
    }
    return undefined
  }, [helperTextKey, messages])

  const [digitalStoreName, setDigitalStoreName] = useState(savedDigitalStoreName)
  const [lastCheckedDigitalStoreName, setLastCheckedDigitalStoreName] = useState(savedDigitalStoreName)

  useEffect(() => {
    setLastCheckedDigitalStoreName(digitalStoreName)
    // eslint-disable-next-line
  }, [isAvailable])

  useEffect(() => {
    dispatch(getDigitalStoreInfoRequest({ personId, countryId: 'BRA', companyId: 1 }))
  }, [dispatch, personId])

  useEffect(() => {
    setDigitalStoreName(savedDigitalStoreName)
  }, [savedDigitalStoreName])

  const shouldDisableInput = Boolean(savedDigitalStoreName) || isLoading
  const shouldDisableVerifyButton = shouldDisableInput || !digitalStoreName || error || isAvailable

  const showLoadWhileSavingDigitalStore = isLoading && !!isAvailable

  const countryId = CountryId.Brazil

  const checkAvailability = () => dispatch(
    checkDigitalStoreAvailabilityRequest(
      digitalStoreName,
      personId,
      countryId,
    ),
  )
  const handleSetHelperTextKey = (textKey: string) => dispatch(setDigitalStorehelperTextKey(textKey))

  const handleSaveDigitalStore = async () => {
    try {
      onSave('', true)
      const params: SaveDigitalStoreParams = {
        countryId,
        companyId: 1,
        personId,
        digitalStoreName,
        agreementId: null,
        accepted: null,
        extraEntries: {},
      }
     const savedDigitalStoreResponse = await saveDigitalStoreRemote.save(params)
     if (savedDigitalStoreResponse) {
       onSave(digitalStoreName, false)
     }
    } catch (error) {
      throw new Error(error)
    }
  }

  const checkNameCharacters = (name:string) => name.match(/^[a-z0-9_]+$/i) !== null

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDigitalStoreName(e.target.value)
    if (!checkNameCharacters(e.target.value)) {
      dispatch(checkDigitalStoreAvailabilityError('invalidNameSpace'))
    } else {
      dispatch(resetDigitalStoreStateOnChangeRequest())
    }
  }

  const handleSave = () => {
    handleSaveDigitalStore()
  }

  const digitalStoreTexts = usePageMessages(Page.DigitalStore).messages as DigitalStoreMessages
  const getTextFieldState = () => {
    if (error) return 'error'
    if (isAvailable) return 'success'
    return undefined
  }

  return (
    <Container
      nextButtonLabel={digitalStoreTexts.nextButtonLabel}
      onNextButtonClick={handleSave}
      disableNextButton={!isAvailable}
      showSkipButton={false}
      isLoading={showLoadWhileSavingDigitalStore}
    >
      <Content>
        <CardHeader
          title={digitalStoreTexts.title}
          classes={{
          root: DigitalStoreRenamePageClasses.cardHeader,
          title: DigitalStoreRenamePageClasses.title,
          subheader: DigitalStoreRenamePageClasses.subtitle,
        }}
          subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
          subheader={
            <ParseStringToReact stringToParse={digitalStoreTexts.description} />
        }
        />
        <NameValidation
          label={messages.nameLabel}
          tooltip={messages.nameTip}
          buttonLabel={messages.verifyButtonLabel}
          buttonIsLoading={isLoading}
          infoText={digitalStoreTexts.hint}
          errorMessage={getErrorMessages()}
          buttonOnClick={() => checkAvailability()}
          buttonOnChange={() => handleSetHelperTextKey('')}
          onChangeInput={handleOnChangeInput}
          shouldButtonBeDisabled={shouldDisableVerifyButton}
          disabled={shouldDisableInput}
          isSuccess={getTextFieldState() === 'success'}
          invalid={getTextFieldState() === 'error'}
          successMessage={getTextFieldState() === 'success' ? messages.availableName : undefined}
          inputValue={digitalStoreName}
        />
        <UrlPreview
          label={digitalStoreTexts.addressLabel}
          url={`${digitalStoreTexts.baseUrl}${lastCheckedDigitalStoreName}`}
          shouldHide={!isAvailable}
        />
      </Content>

    </Container>
  )
}
