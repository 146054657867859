import { FieldErrors } from 'react-hook-form'

export type StringOrObjectOfStrings = string | {[key:string]: string}

export function getFinalErrorMessage(errors: FieldErrors, errorMessage: StringOrObjectOfStrings | undefined, id: string): string {
  if (typeof errorMessage === 'string') {
    return errorMessage
  }
  if (errorMessage && errors[id]) {
    const { type } = errors[id]
    return errorMessage[type]
  }
  return ''
}
