import { RemoteGetAccountBindLink } from '@/data/use-cases/payment'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { GetAccountBindLink } from '@/domain/use-cases/payment'

export const makeGetAccountBindLink = (): GetAccountBindLink => {
  const apiUrl = '/payment-service/bind-account'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteGetAccountBindLink = new RemoteGetAccountBindLink(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteGetAccountBindLink
}
