import FormLabel from '@material-ui/core/FormLabel'
import { FormControl } from '@naturacosmeticos/natds-web/dist/Components/FormControl'
import React, { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'

import { useControlledSelectStyles } from '@/main/components/select/controlled-select.styles'
import { customStyles } from '@/main/components/select/select.styles'
import { FormHelperText } from '@naturacosmeticos/natds-web'
import { ThemeContext } from 'styled-components'

type PhoneTypeSelectProps = {
  id: string
  label: string
  placeholder: string
  errorMessage?: string
  disabled?: boolean
  required?: boolean
  options: { value: string; label: string }[]
  showAsterisk?: boolean
}

export const PhoneTypeSelect = ({
  id,
  label,
  placeholder,
  options,
  disabled,
  required,
  showAsterisk,
  errorMessage,
}: PhoneTypeSelectProps) => {
  const themeContext = useContext(ThemeContext)

  const { control, setError, clearErrors } = useFormContext()

  const controlSelectClasses = useControlledSelectStyles({ disabled })

  return (
    <FormControl
      className={controlSelectClasses.container}
      id={`controlled-select-${id}`}
      data-testid={`controlled-select-${id}`}
    >
      <FormLabel className={controlSelectClasses.label} required={required}>
        {label} {showAsterisk && '*'}
      </FormLabel>
      <Controller
        name={id}
        control={control}
        render={({ onChange, value, name }, { invalid }) => {
          const currentValue = options.find((option) => option.value === String(value))

          const controlRS = (provided, state) => ({
            ...customStyles(themeContext).control(provided, state),
            ...(invalid
              ? {
                  boxShadow: 'red 0 0 0 1px !important',
                }
              : {}),
          })

          return (
            <>
              <ReactSelect
                maxMenuHeight={130}
                name={name}
                id={id}
                styles={{ ...customStyles(themeContext), control: controlRS }}
                aria-label={label}
                aria-labelledby={label}
                placeholder={placeholder}
                menuPlacement="bottom"
                options={options}
                value={currentValue}
                blurInputOnSelect
                isDisabled={disabled}
                onChange={({ value }) => {
                  onChange(Number(value))

                  if (!options.map(({ value }) => value).includes(value)) {
                    setError(id, { type: 'manual', message: errorMessage })
                  } else {
                    clearErrors(id)
                  }
                }}
              />
              {invalid && (
                <FormHelperText state="error">
                  {/* @ts-ignore */}
                  {invalid?.message}
                </FormHelperText>
              )}
            </>
          )
        }}
      />
    </FormControl>
  )
}
