import React, { useContext } from 'react'
import ReactSelect from 'react-select'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { ThemeContext } from 'styled-components'
import { customStyles } from './select.styles'

export type SelectOption = { value: string | number, label: string, externalCode?: number }

export type SelectProps = {
  'data-testid'?: string
  'aria-label'?: string
  id?: string
  name?: string
  placeholder?: string
  value?: SelectOption
  onBlur?: () => void
  onChange?: (value?: any, actionMeta?: any) => void
  options: SelectOption[]
  isSearchable?: boolean
  disabled?: boolean
  isLoading?: boolean
}

const buildLoadingIndicator = ({ id }) => {
  const loaderDataTestId = id
    ? `${id}-select-loader`
    : 'select-loader'

  return () => (
    <span data-testid={loaderDataTestId}>
      <ProgressIndicator size={24} />
    </span>
  )
}

export const Select = React.forwardRef<any, SelectProps>(({
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  options,
  isSearchable = false,
  disabled = false,
  isLoading = false,
}, forwardedRef) => {
  const LoadingIndicator = buildLoadingIndicator({ id })

  const themeContext = useContext(ThemeContext)

  return (
    <ReactSelect
      maxMenuHeight={130}
      ref={forwardedRef}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      name={name}
      id={id}
      styles={customStyles(themeContext)}
      placeholder={placeholder}
      menuPlacement="bottom"
      options={options}
      value={value}
      isSearchable={isSearchable}
      blurInputOnSelect
      onChange={onChange}
      onBlur={onBlur}
      isDisabled={disabled}
      isLoading={isLoading}
      components={{ LoadingIndicator }}
    />
  )
})
