import React from 'react'
import { useSignatureTutorial } from '../../hooks/useSignatureTutorial'
import { SignatureTutorialFirstPage } from './signature-tutorial-first-page/signature-tutorial-first-page'
import { SignatureTutorialSecondPage } from './signature-tutorial-second-page/signature-tutorial-second-page'

export const SignatureTutorial = () => {
  const {
    tutorialStep,
  } = useSignatureTutorial()

  return (
    <>
      {tutorialStep === 0 && <SignatureTutorialFirstPage />}
      {tutorialStep === 1 && <SignatureTutorialSecondPage />}
    </>
  )
}
