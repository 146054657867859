import {
  HttpPostClient,
  HttpStatusCode,
} from '@/data/protocols/http'
import {
  UploadValidateAttachment,
  UploadValidateAttachmentHeader,
  UploadValidateAttachmentParams,
  UploadValidateAttachmentResponseBody,
  ValidateAttachmentResponseBody,
} from '@/domain/use-cases/attachment'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'

export class RemoteUploadValidateAttachment implements UploadValidateAttachment {
  constructor(
    private readonly httpPostClient: HttpPostClient<UploadValidateAttachmentHeader, FormData, UploadValidateAttachmentResponseBody>,
    private readonly xApiKey: string,
  ) {
  }

  async uploadValidate(params: UploadValidateAttachmentParams): Promise<ValidateAttachmentResponseBody[]> {
    const {
      personId, tenantid, firstName, lastName, birthday, documents, documentAttachment1, documentAttachment2, attachmentType1, attachmentType2
    } = params

    const bodyFormData = new FormData()
    bodyFormData.append('firstName', firstName)
    bodyFormData.append('lastName', lastName)
    bodyFormData.append('birthday', birthday)
    bodyFormData.append('documentsNumber', documents[0].number)
    bodyFormData.append('documentsTypeId', String(documents[0].typeId))
    bodyFormData.append('attachmentType1', String(attachmentType1))
    if (attachmentType2) bodyFormData.append('attachmentType2', String(attachmentType2))
    bodyFormData.append('documentAttachment1', documentAttachment1)
    if (documentAttachment2)  bodyFormData.append('documentAttachment2', documentAttachment2) 

    const url = `/document-validation/${personId}`
    const headers: UploadValidateAttachmentHeader = {
      'Content-Type': 'multipart/form-data',
      'x-api-key': this.xApiKey,
      tenantid
    }

    const response = await this.httpPostClient.post({ url, headers, body: bodyFormData })
  
    return this.handleResponse(response.statusCode, response.body)
  }

   private handleResponse(statusCode: HttpStatusCode, body: UploadValidateAttachmentResponseBody): ValidateAttachmentResponseBody[] {
    switch (statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return this.formartReponseBody(body)
      case HttpStatusCode.badRequest:
        throw new BadRequestError({})
      default:
        throw new UnexpectedError()
    }
  }

  private formartReponseBody(body: UploadValidateAttachmentResponseBody): ValidateAttachmentResponseBody[] {
    const { documents } = body
    return documents.map(item => {
      return {
        attachmentType: Number(item.typeId),
        sequence: item.url,
        isValid: item.validDocument
      }
    })

  }
}
