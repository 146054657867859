import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@/main/components/dialog'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { useDialogWithLoaderStyles } from './dialog-with-loader.styles'

type DialogWithLoaderProps = {
  isOpen: boolean,
  title?: string
}

export const DialogWithLoader: React.FC<DialogWithLoaderProps> = ({ isOpen, title}) => {
  const componentClasses = useDialogWithLoaderStyles()

  return (
    <Dialog open={isOpen}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent className={componentClasses.dialogContent}>
        <ProgressIndicator size={48} />
      </DialogContent>
    </Dialog>
  )
}
