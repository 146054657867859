import { RemoteGetMyLeaderInfo } from '@/data/use-cases/person'
import { GetMyLeaderData } from '@/domain/use-cases/person'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const myProfileRelations: string[] = ['additionalInformation']

export const makeGetMyLeaderInfo = (): GetMyLeaderData => {
  const apiUrl = `${'/people/:personId/leader-info'}`
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)
  const remoteGetConsultantInfo = new RemoteGetMyLeaderInfo(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteGetConsultantInfo
}
