import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import { Container } from '@/main/components/pages-structures'
import {
  IdentityInfoContext,
  NavigationContext,
  NavigationContextProps,
} from '@/main/contexts'
import {
  CardContent,
  CardHeader,
  ControlledSelect,
  SelectOption,
} from '@/main/components'
import {
  AttachmentsMessageOptions,
  AttachmentsMessages,
} from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import {
  UploadFile,
  ValidationResult,
} from '@/main/components/upload-file/upload-file'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { AttachmentsConfigs } from '@/domain/models/tenant/tenant-configs'
import { useForm } from 'react-hook-form'
import {
  Attachment,
  AttachmentType,
} from '@/domain/models/attachments/attachments'
import { ValidateAttachmentRequestBody } from '@/domain/use-cases/attachment'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { validateCountryIsEnableToNewOCR } from '@/domain/models/tenant/tenant-configs/helpers/validate-country-is-enable-to-new-ocr'
import { FUNCTION_IDS } from '@/domain/models/person/function-ids'
import { useAttachmentsPageStyles } from './attachments-page.styles'
import {
  SubtitleWithPopover,
  TitleWithTag,
} from './components'
import { DialogWithLoader } from './components/dialog-with-loader'
import { AttachmentsPageApi } from './api/make-attachments-page-api'
import { usePersonInfos } from './hooks/use-person-infos'

type DocumentAttachmentState = { name: string, attachments: Attachment[] }
type AttachmentsToValidateMapper = Map<AttachmentType, string>
const megaBytes = 1024 * 1024


type AttachmentsPageProps = {
  api: AttachmentsPageApi
}

export const AttachmentsPage: React.FC<AttachmentsPageProps> = ({ api }) => {
  const {
    personId, tenantId, functionId, businessModel, role, disableNextButton = true
  } = useContext(IdentityInfoContext)
  const { goToNextPage, goToPreviousPage } = useContext<NavigationContextProps>(NavigationContext)
  const componentClasses = useAttachmentsPageStyles()
  const { documents, canChooseDocument } = useTenantConfigs(tenantId, Page.Attachments) as AttachmentsConfigs
  const { setValue: setFormValues, control } = useForm<{ document: string }>({ mode: 'onChange' })
  const setFormValuesRef = useRef(setFormValues)
  const [documentAttachment, setDocumentAttachment] = useState<DocumentAttachmentState>({ name: '', attachments: [] })
  const [documentOptions, setDocumentOptions] = useState([])
  const { messages: initialMessages, getPageMessagesWithNewOptions } = usePageMessages(Page.Attachments, createMessageOptions(documentAttachment))
  const [messages, setMessages] = useState<AttachmentsMessages>(initialMessages as AttachmentsMessages)
  const [nextButtonLabel, setNextButtonLabel] = useState<string>(functionId !== FUNCTION_IDS.BEAUTY_CONSULTANT ? messages.nextButtonLabel : messages.validateButtonLabel)
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true)
  const [isValidateDialogOpen, setIsValidateDialogOpen] = useState<boolean>(false)
  const [documentsUploadNewOCR, setDocumentsUpoladNewOCR] = useState<{ attachmentType: number, file: File }[]>([])
  const [isUnexpectedErrorDialogOpen, setIsUnexpectedErrorDialogOpen] = useState<boolean>(false)
  const [attachmentsToValidate, setAttachmentsToValidate] = useState<AttachmentsToValidateMapper>(new Map())
  const {
    isLoading: isFetchingPersonData,
    documents: personDocuments,
    firstName,
    lastName,
    birthday,
  } = usePersonInfos({ getPersonData: api.getPersonData })
  const fileMaxSize = validateCountryIsEnableToNewOCR() ? 4 * megaBytes : 5 * megaBytes

  useEffect(() => {
    if (isFetchingPersonData || !personDocuments) {
      return
    }
    if (personDocuments.length === 0) {
      setIsUnexpectedErrorDialogOpen(true)
      return
    }

    const options = documents.map(({ name }) => createSelectOption(name))
    setDocumentOptions(options)

    const preRegisterDocument = personDocuments[0].type
    const selectedDocument = documents.find(({ type }) => type === preRegisterDocument) ?? documents[0]
    setFormValuesRef.current('document', createSelectOption(selectedDocument.name))
    setDocumentAttachment({ name: selectedDocument.name, attachments: selectedDocument.attachments })
  }, [documents, isFetchingPersonData, personDocuments])

  useEffect(() => {
    const newOptions = createMessageOptions(documentAttachment)
    const newMessages = getPageMessagesWithNewOptions(newOptions) as AttachmentsMessages
    setMessages(newMessages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentAttachment])

  useEffect(() => {
    const isReadyToValidate = isAttachmentsReadyToValidate(documentAttachment.attachments, attachmentsToValidate)
    setNextButtonDisabled(!isReadyToValidate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentAttachment, attachmentsToValidate])

  useEffect(() => {
    if (validateCountryIsEnableToNewOCR() &&
      documentAttachment.attachments.length > 0 &&
      documentAttachment.attachments.length === documentsUploadNewOCR.length) {
      setNextButtonDisabled(false)
    }
  }, [documentsUploadNewOCR, documentAttachment])

  useEffect(() => {
    if (!disableNextButton) {
      setNextButtonDisabled(disableNextButton)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsUploadNewOCR, documentAttachment])

  const setDocumentsUpoladNewOCRlessDuplicate = (attachmentType: number, file: File) => {
    const listFiltred = documentsUploadNewOCR.filter(item => item.attachmentType !== attachmentType)
    setDocumentsUpoladNewOCR([...listFiltred, { attachmentType, file }])
  }

  const uploadFile = async (attachmentType: number, file: File) => {
    setNextButtonLabel(messages.validateButtonLabel)
    if (validateCountryIsEnableToNewOCR()) {
      setDocumentsUpoladNewOCRlessDuplicate(attachmentType, file)
      return
    }

    try {
      const { downloadUrl } = await api.upload({
        attachment: file, attachmentType, personId, tenantId,
      })

      const newMapper = new Map(attachmentsToValidate)
      newMapper.set(attachmentType, downloadUrl)
      setAttachmentsToValidate(newMapper)
    } catch (e) {
      setIsUnexpectedErrorDialogOpen(true)
    }
  }

  const handleOnChangeDocument = ({ label }: SelectOption) => {
    const document = documents.find(({ name }) => name === label)
    setDocumentAttachment({ name: label, attachments: document.attachments })
    setNextButtonLabel(messages.validateButtonLabel)
    setNextButtonDisabled(true)
    setAttachmentsToValidate(new Map())
  }

  const handleOnNextButtonClick = async () => {
    if (!disableNextButton && attachmentsToValidate.size === 0) {
      setNextButtonLabel(messages.nextButtonLabel)
      await goToNextPage()
    } else if (nextButtonLabel === messages.validateButtonLabel) {
      await onValidateButtonClick()
    } else {
      onContinueButtonClick()
    }
  }

  const onValidateButtonClick = async () => {
    setIsValidateDialogOpen(true)

    try {
      let attachmentsResultValidated = []
      if (validateCountryIsEnableToNewOCR()) {
        const { attachmentType1, attachmentType2, documentAttachment1, documentAttachment2 } = formatAttachmentsToUploadValidateRequest(documentsUploadNewOCR)
        attachmentsResultValidated = await api.uploadValidate({
          personId, tenantid: tenantId,
          firstName,
          lastName,
          birthday,
          documents: formatDocumentsToUploadValidateRequest(personDocuments),
          attachmentType1, attachmentType2, documentAttachment1, documentAttachment2
        })
      } else {
        attachmentsResultValidated = await api.validate({
          personId, tenantId, businessModel, functionId, role, attachments: formatAttachmentToValidateForRequest(attachmentsToValidate),
        })
      }

      const attachmentTypes = []
      attachmentsResultValidated.forEach((attachmentResult) => {
        const oldAttachment = documentAttachment.attachments.find((attachment) => attachment.type === attachmentResult.attachmentType)
        const updatedAttachment = attachmentResult.isValid
          ? { ...oldAttachment, message: messages.succeedValidationMessage, status: 'success' }
          : { ...oldAttachment, message: messages.failedValidationMessage, status: 'error' }
        attachmentTypes.push(updatedAttachment)
      })
      setDocumentAttachment({ ...documentAttachment, attachments: attachmentTypes })

      if (attachmentsResultValidated.every(((attachment) => attachment.isValid))) {
        setNextButtonLabel(messages.nextButtonLabel)
      }
      setIsValidateDialogOpen(false)
    } catch (e) {
      setIsValidateDialogOpen(false)
      setIsUnexpectedErrorDialogOpen(true)
    }
  }

  const onContinueButtonClick = () => {
    api.complete({
      personId,
      tenantId,
      businessModel,
      functionId,
      role,
    })
      .then(goToNextPage)
      .catch(() => {
        setIsValidateDialogOpen(false)
        setIsUnexpectedErrorDialogOpen(true)
      })
  }

  const onSetAttachmentState = ({ status, message }: ValidationResult, index: number) => {
    const { type, title } = documentAttachment.attachments[index]
    const newAttachment = {
      type, title, status, message,
    }

    const attachmentTypes = [...documentAttachment.attachments]
    attachmentTypes[index] = newAttachment
    setDocumentAttachment({ name: documentAttachment.name, attachments: attachmentTypes })
  }

  const subtitleUploadFile = validateCountryIsEnableToNewOCR() ? messages.subtitleUploadFileNewOCR : messages.subtitleUploadFile

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={nextButtonLabel}
      onNextButtonClick={handleOnNextButtonClick}
      disableNextButton={nextButtonDisabled}
      isLoading={isFetchingPersonData}
    >
      <div>
        <CardHeader
          title={(<TitleWithTag componentClasses={componentClasses} title={messages.title} tag={documentAttachment.name} />)}
          subtitle={(<SubtitleWithPopover componentClasses={componentClasses} subtitle={messages.subtitle} popoverText={messages.subtitlePopover} />)}
        />
        <CardContent>
          {documents.length > 1 && canChooseDocument
            && (
              <ControlledSelect
                id="document"
                name="document"
                label={messages.dropdownLabel}
                aria-label={messages.dropdownLabel}
                options={documentOptions}
                control={control}
                onChange={handleOnChangeDocument}
              />
            )}
          <>
            {
              documentAttachment.attachments.length > 0
              && documentAttachment.attachments.map((attachment, index) => (
                <UploadFile
                  id={`attachment-${attachment.type}`}
                  title={attachment.title}
                  subtitle={subtitleUploadFile}
                  uploadButtonName={messages.uploadFileButtonName}
                  changeButtonName={messages.changeFileButtonName}
                  fileMaxSize={fileMaxSize}
                  maxSizeErrorMessage={subtitleUploadFile}
                  invalidFileFormatErrorMessage={messages.failedValidationMessage}
                  customMessage={attachment.message}
                  status={attachment.status}
                  setAttachmentState={(validation: ValidationResult) => onSetAttachmentState(validation, index)}
                  uploadCallback={(file) => uploadFile(attachment.type, file)}
                  dataTestId={`attachment-${attachment.type}`}
                  key={`attachment-${attachment.type}`}
                  className={componentClasses.uploadFile}
                />
              ))
            }
          </>
        </CardContent>

        <DialogWithLoader
          isOpen={isValidateDialogOpen}
          text={messages.validateDialogText}
          componentClasses={componentClasses}
        />

        <DialogAlert
          show={isUnexpectedErrorDialogOpen}
          messages={{ infoText: messages.unexpectedErrorMessage, closeButtonText: messages.unexpectedErrorButton }}
          closeDialogCallback={() => setIsUnexpectedErrorDialogOpen(false)}
        />
      </div>
    </Container>
  )
}

function createSelectOption(valueAndLabel: string): SelectOption {
  return { label: valueAndLabel, value: valueAndLabel }
}

function createMessageOptions(attachment: DocumentAttachmentState): AttachmentsMessageOptions {
  return { subtitleDocument: attachment.name }
}

function formatDocumentsToUploadValidateRequest(documents: any[]): { number: string, typeId: number }[] {
  return documents.map(item => {
    return {
      number: item.document,
      typeId: item.type
    }
  })
}

function formatAttachmentsToUploadValidateRequest(attachmentsToValidate: { attachmentType: number, file: File }[]): {
  attachmentType1: AttachmentType
  attachmentType2?: AttachmentType
  documentAttachment1: File
  documentAttachment2?: File
} {
  const attachmentType1 = attachmentsToValidate[0].attachmentType
  const attachmentType2 = attachmentsToValidate[1] ? attachmentsToValidate[1].attachmentType : null
  const documentAttachment1 = attachmentsToValidate[0].file
  const documentAttachment2 = attachmentsToValidate[1] ? attachmentsToValidate[1].file : null
  return { attachmentType1, attachmentType2, documentAttachment1, documentAttachment2 }
}

function formatAttachmentToValidateForRequest(attachmentsToValidate: AttachmentsToValidateMapper): ValidateAttachmentRequestBody[] {
  return Array.from(attachmentsToValidate, ([attachmentType, downloadUrl]) => ({
    attachmentType,
    downloadUrl,
  }))
}

function isAttachmentsReadyToValidate(attachments: Attachment[], attachmentsToValidate: AttachmentsToValidateMapper): boolean {
  const noAttachmentHasErrorStatus = attachments.every((attachment) => attachment.status !== 'error')
  const allAttachmentsAreBeingValidated = attachments.length === attachmentsToValidate.size
  return noAttachmentHasErrorStatus && allAttachmentsAreBeingValidated
}
