import React, { useEffect, useState } from 'react'

type ImageDynamicLoadType = {
  image: string
  name: string
}

export const ImageDynamicLoad = ({ name, image }: ImageDynamicLoadType) => {
  const [imageLoaded, setImage] = useState<{ default: string }>({ default: '' })

  useEffect(() => {
    loadImage()
  }, [image])

  const loadImage = () => {
    import(`@/main/assets/svgs/${image}.svg`).then((img) => {
      setImage(img)
    })
  }

  return <img src={imageLoaded.default} aria-label={name} data-testid={name} />
}
