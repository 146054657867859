import React, { useState } from 'react'

type SignatureProviderProps = {
  showDigitalSignatureContent: boolean,
  showTutorial: boolean,
  isTheFirstViewOfTutorial: boolean,
  showDialog: boolean,
  setShowDigitalSignatureContent?: Function,
  setShowTutorial?: Function,
  setIsTheFirstViewOfTutorial?: Function,
  setShowDialog?: Function,
}

export const SignatureContext = React.createContext<SignatureProviderProps>(undefined)

export const SignatureProvider: React.FC = ({ children }) => {
  const [showDigitalSignatureContent, setShowDigitalSignatureContent] = useState(true)
  const [isTheFirstViewOfTutorial, setIsTheFirstViewOfTutorial] = useState(true)
  const [showTutorial, setShowTutorial] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  return (
    <SignatureContext.Provider
      value={{
        showDigitalSignatureContent,
        setShowDigitalSignatureContent,
        showTutorial,
        setShowTutorial,
        isTheFirstViewOfTutorial,
        setIsTheFirstViewOfTutorial,
        showDialog,
        setShowDialog,
      }}
    >
      {children}
    </SignatureContext.Provider>
  )
}
