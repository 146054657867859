import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { CompleteDocumentation } from '@/domain/use-cases/documentation/complete-documentation'
import { RemoteCompleteDocumentation } from '@/data/use-cases/documentation/remote-complete-documentation'

export const makeRemoteCompleteDocumentation = (): CompleteDocumentation => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpPostClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteCompleteDocumentation(
    httpPostClient,
    xApiKey,
  )
}
