import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useAddressStyles = makeStyles<Theme>((theme) => ({
  container: {
    minHeight: '100%',
  },
  addressContainer: {
    padding: `${theme.spacing(1.5)}px 0`,
  },
  addressInformation: {
    paddingBottom: theme.spacing(2),
    wordBreak: 'break-word',
    textTransform: 'capitalize',
  },
  addressSubtitleSpace: {
    paddingBottom: theme.spacing(0.5),
  },
  modalHeader: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end !important',
    gap: '16px',
  },
}))
