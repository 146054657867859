import { makeRemoteAcceptDigitalSignature } from '@/main/factories/remote/digital-signature'
import { AcceptDigitalSignatureParams } from '@/domain/use-cases/digital-signature/accept-digital-signature'
import { GetAgreementByProductParams, GetAgreementByProductResponse } from '@/domain/use-cases/agreement'
import { makeRemoteGetAgreementByProduct } from '@/main/factories/remote/agreement'

export type DigitalSignaturePageApi = {
  accept: (params: AcceptDigitalSignatureParams) => Promise<void>
  getAgreementByProduct: (data: GetAgreementByProductParams) => Promise<GetAgreementByProductResponse>
}

export const makeDigitalSignaturePageApi = (): DigitalSignaturePageApi => {
  const acceptSetter = makeRemoteAcceptDigitalSignature()
  const accept = (params: AcceptDigitalSignatureParams) => acceptSetter.accept(params)

  const remoteGetAgreement = makeRemoteGetAgreementByProduct()
  const getAgreementByProduct = (data: GetAgreementByProductParams) => remoteGetAgreement.get(data)

  return {
    accept,
    getAgreementByProduct,
  }
}
