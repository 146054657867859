import { SelectOption } from '@/main/components'
import {
  GeographicalLevel,
  Geographical2LevelsEnum,
  Geographical3LevelsEnum,
  Geographical4LevelsEnum,
} from '@/main/contexts'

export class GeographicalLevelsMapperFactory {
  static createMapper(numberOfLevels: number, geographicalLevels: GeographicalLevel[]) : GeographicalLevelsMapper {
    if (numberOfLevels === 4) {
      return new FourGeographicalLevelsMapper(geographicalLevels)
    }
    if (numberOfLevels === 2) {
      return new TwoGeographicalLevelsMapper(geographicalLevels)
    }
    return new ThreeGeographicalLevelsMapper(geographicalLevels)
  }
}

interface GeographicalLevelsMapper {
  street(): string
  number(): string
  complement(): string
  references(): string
  geoCommercialStructureLevel1(): SelectOption
  geoCommercialStructureLevel2(): SelectOption | null
  geoCommercialStructureLevel3(): SelectOption
  geoCommercialStructureLevel4(): SelectOption | null
}

class TwoGeographicalLevelsMapper implements GeographicalLevelsMapper {
  constructor(private readonly geographicalLevels: GeographicalLevel[]) {
  }

  street(): string {
    return this.getGeographicalLevelDescription(Geographical2LevelsEnum.STREET)
  }

  number(): string {
    const number = this.getGeographicalLevelDescription(Geographical2LevelsEnum.NUMBER)
    return number === '' ? 'S/N' : number
  }

  complement(): string {
    return this.getGeographicalLevelDescription(Geographical2LevelsEnum.COMPLEMENT)
  }

  references(): string {
    return this.getGeographicalLevelDescription(Geographical2LevelsEnum.REFERENCES)
  }

  geoCommercialStructureLevel1(): SelectOption {
    const level1 = this.getGeographicalLevel(Geographical2LevelsEnum.REGION)
    return this.mapGeographicalLevelToSelectOption(level1)
  }

  geoCommercialStructureLevel2(): SelectOption {
    const level2 = this.getGeographicalLevel(Geographical2LevelsEnum.CITY)
    return this.mapGeographicalLevelToSelectOption(level2)
  }

  geoCommercialStructureLevel3(): null {
    return null
  }

  geoCommercialStructureLevel4(): null {
    return null
  }

  private getGeographicalLevelDescription(desiredLevel: Geographical2LevelsEnum): string {
    return this.getGeographicalLevel(desiredLevel)?.description
  }

  private getGeographicalLevel(desiredLevel: Geographical2LevelsEnum): GeographicalLevel {
    return this.geographicalLevels.find(({ level }) => desiredLevel === level)
  }

  private mapGeographicalLevelToSelectOption(geographicalLevel?: GeographicalLevel): SelectOption {
    return {
      label: geographicalLevel?.description,
      value: geographicalLevel?.code,
    }
  }
}

class ThreeGeographicalLevelsMapper implements GeographicalLevelsMapper {
  constructor(private readonly geographicalLevels: GeographicalLevel[]) {
  }

  street(): string {
    return this.getGeographicalLevelDescription(Geographical3LevelsEnum.STREET)
  }

  number(): string {
    const number = this.getGeographicalLevelDescription(Geographical3LevelsEnum.NUMBER)
    return number === '' ? 'S/N' : number
  }

  complement(): string {
    return this.getGeographicalLevelDescription(Geographical3LevelsEnum.COMPLEMENT)
  }

  references(): string {
    return this.getGeographicalLevelDescription(Geographical3LevelsEnum.REFERENCES)
  }

  geoCommercialStructureLevel1(): SelectOption {
    const level1 = this.getGeographicalLevel(Geographical3LevelsEnum.REGION)
    return this.mapGeographicalLevelToSelectOption(level1)
  }

  geoCommercialStructureLevel2(): SelectOption {
    const level2 = this.getGeographicalLevel(Geographical3LevelsEnum.CITY)
    return this.mapGeographicalLevelToSelectOption(level2)
  }

  geoCommercialStructureLevel3(): SelectOption {
    const level3 = this.getGeographicalLevel(Geographical3LevelsEnum.NEIGHBORHOOD)
    return this.mapGeographicalLevelToSelectOption(level3)
  }

  geoCommercialStructureLevel4(): null {
    return null
  }

  private getGeographicalLevelDescription(desiredLevel: Geographical3LevelsEnum): string {
    return this.getGeographicalLevel(desiredLevel)?.description
  }

  private getGeographicalLevel(desiredLevel: Geographical3LevelsEnum): GeographicalLevel {
    return this.geographicalLevels.find(({ level }) => desiredLevel === level)
  }

  private mapGeographicalLevelToSelectOption(geographicalLevel?: GeographicalLevel): SelectOption {
    return {
      label: geographicalLevel?.description,
      value: geographicalLevel?.code,
    }
  }
}

class FourGeographicalLevelsMapper implements GeographicalLevelsMapper {
  constructor(private readonly geographicalLevels: GeographicalLevel[]) {
  }

  street(): string {
    return this.getGeographicalLevelDescription(Geographical4LevelsEnum.STREET)
  }

  number(): string {
    const number = this.getGeographicalLevelDescription(Geographical4LevelsEnum.NUMBER)
    return number === '' ? 'S/N' : number
  }

  complement(): string {
    return this.getGeographicalLevelDescription(Geographical4LevelsEnum.COMPLEMENT)
  }

  references(): string {
    return this.getGeographicalLevelDescription(Geographical4LevelsEnum.REFERENCES)
  }

  geoCommercialStructureLevel1(): SelectOption {
    const level1 = this.getGeographicalLevel(Geographical4LevelsEnum.LEVEL1)
    return this.mapGeographicalLevelToSelectOption(level1)
  }

  geoCommercialStructureLevel2(): SelectOption {
    const level2 = this.getGeographicalLevel(Geographical4LevelsEnum.LEVEL2)
    return this.mapGeographicalLevelToSelectOption(level2)
  }

  geoCommercialStructureLevel3(): SelectOption {
    const level3 = this.getGeographicalLevel(Geographical4LevelsEnum.LEVEL3)
    return this.mapGeographicalLevelToSelectOption(level3)
  }

  geoCommercialStructureLevel4(): SelectOption {
    const level4 = this.getGeographicalLevel(Geographical4LevelsEnum.LEVEL4)
    return this.mapGeographicalLevelToSelectOption(level4)
  }

  private getGeographicalLevelDescription(desiredLevel: Geographical4LevelsEnum): string {
    return this.getGeographicalLevel(desiredLevel)?.description
  }

  private getGeographicalLevel(desiredLevel: Geographical4LevelsEnum): GeographicalLevel {
    return this.geographicalLevels.find(({ level }) => desiredLevel === level)
  }

  private mapGeographicalLevelToSelectOption(geographicalLevel?: GeographicalLevel): SelectOption {
    return {
      label: geographicalLevel?.description,
      value: geographicalLevel?.code,
    }
  }
}
