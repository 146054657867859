import React from 'react'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import { CardTitle } from '@/main/components'
import { CardTitleProps } from '@/main/components/card-title/card-title'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { Link } from '@naturacosmeticos/natds-web'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  letter-spacing: 0.51px;

  .styled-text-paragraph {
    margin-bottom: 16px;
  }
  .gutter {
    margin-bottom: 50px;
    text-align: left;
    @media ${minDeviceSizeForMediaQueries.tablet} {
      text-align: center;
    }
  }
`

export const StyledCard = styled(Card)`
  text-align: center;
  padding: 25px;
  margin: 0 0 74px;
  min-height: unset;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    padding: 40px 64px;
    margin: 0 auto;
    max-width: 608px;
    margin-top: 56px;
    margin-bottom: 0;
  }
`
export const StyledLink = styled(Link)`
  color: rgb(34, 123, 189);
`

type StyledCardTitleProps = {
  status?: string
} & CardTitleProps

const TextColorByStatus = {
  success: '#51912f',
  error: '#333',
  'not-linked': '#F2C406',
}

export const StyledCardTitle = styled<React.FC<StyledCardTitleProps>>(CardTitle)`
  h2 {
    color: ${(props) => (TextColorByStatus[props.status])};
  }
  margin-bottom: 0.8rem;
`
