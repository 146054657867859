import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Icon } from '@/main/components'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { useBanner } from './banner-context'

export const ConfirmSnackbar = () => {
  const { openedSnackbar, closeSnackbar } = useBanner()
  const {
    mgmBanner: {
      snackbar: { copyConfirm },
    },
  } = usePageMessages(Page.MyProfile).messages as MyProfileMessages

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={openedSnackbar}
      onClose={closeSnackbar}
      autoHideDuration={6000}
      message={<div style={{ fontSize: '14px' }}>{copyConfirm}</div>}
      action={<Icon name="outlined-action-done" size="tiny" />}
    />
  )
}
