import styled from 'styled-components'
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
} from '@material-ui/core'
import React from 'react'

export const CenteredCard = styled(Card)<{width?: string}>`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? props.width : 'clamp(300px,80%,800px)')};
`
export const StyledCardHeader = styled(CardHeader)`
  padding: 24px 16px;

  .MuiCardHeader-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.24px;
  }
`

export const StyledCardContent = styled(({ bordered, ...props }) => <CardContent {...props} />)`
  border-bottom: ${({ bordered }) => (bordered ? '1px solid #0000001F' : 'unset')};
  border-top: ${({ bordered }) => (bordered ? '1px solid #0000001F' : 'unset')};
  overflow-y: auto;
  max-height: 50vh;
  font-size: 16px;
  letter-spacing: 0.51px;
  line-height: 24px;
  padding: 8px 24px;
  white-space: pre-wrap;
`

export const StyledCardActions = styled(CardActions)`
  justify-content: flex-end;
  padding: 16px;
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18rem;
`
