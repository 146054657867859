import React, { useContext, useEffect, useMemo, useState } from 'react'
import { IdentityInfoContext } from '@/main/contexts'
import { ProfileConfigs } from '@/domain/models/tenant/tenant-configs'
import { Page } from '@/domain/models/page/page'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import MGMBannerStart from './images/mgm_thumbnail_default.png'
import { BannerContext } from './banner-context'
import { MGMBanner as MGMBannerComponent } from './mgm-banner'

const BannerProvider = () => {
  const [openedModal, setOpenModal] = useState(false)
  const [image, setImage] = useState<string>(MGMBannerStart)
  const [openedSnackbar, setOpenSnackbar] = useState(false)
  const { companyId, countryId, isCN, isLeader, isGDN } = useContext(IdentityInfoContext)
  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]
  const { mgmBanner } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const openModal = () => setOpenModal(true)
  const closeModal = () => setOpenModal(false)
  const openSnackbar = () => setOpenSnackbar(true)
  const closeSnackbar = () => setOpenSnackbar(false)

  const showBanner = useMemo(
    () =>
      mgmBanner?.show &&
      ((isCN && mgmBanner?.showToConsultant) ||
        (isLeader && mgmBanner?.showToLeader) ||
        (isGDN && mgmBanner?.showToManager)),
    [
      mgmBanner?.show,
      mgmBanner?.showToConsultant,
      mgmBanner?.showToLeader,
      mgmBanner?.showToManager,
      isCN,
      isLeader,
      isGDN,
    ]
  )

  useEffect(() => {
    if (mgmBanner?.image) {
      import(`./images/${mgmBanner.image}`).then((image) => {
        setImage(image.default)
      })
    }
  }, [mgmBanner?.image])

  return (
    <BannerContext.Provider
      value={{
        openedModal,
        openedSnackbar,
        image,
        openModal,
        closeModal,
        openSnackbar,
        closeSnackbar,
      }}
    >
      {showBanner && <MGMBannerComponent />}
    </BannerContext.Provider>
  )
}

export const MGMBanner = BannerProvider
