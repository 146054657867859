import * as React from 'react'
import { TextParagraph } from '@/main/components'

export const DigitalStoreStatusFooter: React.FunctionComponent<{ content?: string }> = ({
    content,
}) => (content ? (
  <footer data-testid="digitalStoreStatusFooter">
    <div>
      <TextParagraph content={content} />
    </div>
  </footer>
     ) : null)
