import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'
import { deviceSize } from '@/utils/device-sizes'

export const useGeoCommercialStructureStyles = makeStyles<Theme>((theme) => {
  const displayEqualOrWiderThenTablet = theme.breakpoints.up(deviceSize.tablet)

  return {
    formRow: {
      [displayEqualOrWiderThenTablet]: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-between',
      },
    },
    inputWithPair: {
      [displayEqualOrWiderThenTablet]: {
        flex: 1,
        paddingRight: theme.spacing(1),
      },
    },
  }
})
