import { CompleteAddressParams } from '@/data/use-cases/address/remote-complete-address'
import { GetAddressDataParams } from '@/data/use-cases/address/remote-get-address-data'
import { GetStatesParams, State } from '@/domain/use-cases/address'
import { makeRemoteCompleteAddress } from '@/main/factories/remote/address/make-remote-complete-address'
import { makeRemoteGetAddressData } from '@/main/factories/remote/address/make-remote-get-address-data'
import { makeRemoteGetStates } from '@/main/factories/remote/address/make-remote-get-states'
import { AddressFormValues } from '../models'

export type AddressPageApi = {
  getStates: (params: GetStatesParams) => Promise<Array<State>>
  getAddressData: (params: GetAddressDataParams) => Promise<AddressFormValues>
  complete: (params: CompleteAddressParams) => Promise<void>
}

export const makeAddressPageApi = (): AddressPageApi => {
  const remoteGetStates = makeRemoteGetStates()
  const getStates = (params: GetStatesParams) => remoteGetStates.get(params)

  const remoteGetAddressData = makeRemoteGetAddressData()
  const getAddressData = (params: GetAddressDataParams) => remoteGetAddressData.get(params)

  const remoteCompleteAddress = makeRemoteCompleteAddress()
  const complete = (params: CompleteAddressParams) => remoteCompleteAddress.complete(params)

  return { getStates, getAddressData, complete }
}
