import React from 'react'
import {
  TextParagraph, StatusIcon,
} from '@/main/components'
import { HelpInformation } from '@/main/components/help-information'
import { StatusType } from '@/domain/use-cases/digital-store'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { PaymentAccountStatusMessages } from '@/domain/models/messages/messages'
import {
  Container,
  StyledCard,
  StyledCardTitle,
} from './payment-account-status-default.styles'

const PaymentAccountStatus = {
  ERROR: 'error',
  NOT_LINKED: 'not-linked',
  SUCCESS: 'success',
}

const PaymentAccountStatusIconName = {
  [PaymentAccountStatus.ERROR]: 'error',
  [PaymentAccountStatus.NOT_LINKED]: 'warning',
  [PaymentAccountStatus.SUCCESS]: 'success',
}

type PaymentAcountStatusPageProps = {
  config: PaymentAccountStatusConfiguration,
  children?: any,
}

export type PaymentAccountStatusPageStatusType = 'error' | 'not-linked' | 'success'

export interface PaymentAccountStatusConfiguration {
  status: PaymentAccountStatusPageStatusType
}

export type PaymentAccountStatusPageDefaultMessagesType = {
  title: string
  subtitle: string
  contactInfoHelpTitle: string,
  contactInfoHelp: string
}

export const PaymentAccountStatusDefault: React.FC<PaymentAcountStatusPageProps> = ({ config, children }) => {
  const pageMessages = usePageMessages(Page.PaymentAccountStatus).messages as PaymentAccountStatusMessages

  const status = config.status === 'not-linked' ? 'notLinked' : config.status
  const messages: PaymentAccountStatusPageDefaultMessagesType = {
    title: pageMessages[status].title,
    subtitle: pageMessages[status].subtitle,
    contactInfoHelp: pageMessages.contactInfoHelp,
    contactInfoHelpTitle: pageMessages.contactInfoHelpTitle,
  }

  return (
    <Container>
      <StyledCard raised>
        <StatusIcon name={PaymentAccountStatusIconName[config.status] as StatusType} />
        <StyledCardTitle title={messages.title} status={config.status} />
        <TextParagraph content={messages.subtitle} className="styled-text-paragraph" />
        {children}
        <HelpInformation
          title={messages.contactInfoHelpTitle}
          content={messages.contactInfoHelp}
          hasMarginTop
        />
      </StyledCard>
    </Container>
  )
}
