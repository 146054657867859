import {
  GetPersonDataParams,
  PersonData,
} from '@/domain/use-cases/person'
import {
 useContext, useEffect, useRef, useState,
} from 'react'
import { IdentityInfoContext, IdentityInfoContextData } from '@/main/contexts'
import { DocumentType } from '@/domain/models/documents/document'

export type PersonInfoResult = {
  isLoading: boolean
  documents: Array<DocumentType>
  firstName?: string
  lastName?: string
  birthday?: string
  error?: Error
}

type UsePersonInfosParams = {
  getPersonData: (data: GetPersonDataParams) => Promise<PersonData | {} | null | unknown>
}

export const usePersonInfos = ({ getPersonData }: UsePersonInfosParams): PersonInfoResult => {
  const [personInfos, setPersonInfos] = useState<PersonInfoResult>({
    isLoading: true,
    documents: [],
    firstName: '',
    lastName: '',
    birthday: '',
    error: undefined,
  })
  const { personId, countryId, companyId } = useContext<IdentityInfoContextData>(IdentityInfoContext)

  const fetchPersonInfos = () => {
    if (!personId || !countryId || !companyId) {
      return
    }
    const parameters: GetPersonDataParams = {
      personId,
      countryId,
      companyId,
      relations: ['documents'],
    }
    getPersonData(parameters)
      .then((data: any) => {
        setPersonInfos({
          isLoading: false,
          documents: data.person.documents || [],
          firstName: data.person.firstName || '',
          lastName: data.person.lastName || '',
          birthday: data.person.birthday || '',
        })
      })
      .catch((error: Error) => {
        setPersonInfos({
          isLoading: false,
          documents: [],
          firstName: '',
          lastName: '',
          birthday: '',
          error,
        })
      })
  }

  const fetchPersonInfosRef = useRef(fetchPersonInfos)

  useEffect(() => {
    fetchPersonInfosRef.current()
  }, [])

  return personInfos
}
