import React from 'react'

import { StyledSectionTitle } from './SectionTitle.styles'

type Props = {
  title: string
}

// eslint-disable-next-line max-len
export const SectionTitle: React.FC<Props> = ({ title }) => (
  <StyledSectionTitle>{title}</StyledSectionTitle>
)
