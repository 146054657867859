import React, { useContext, useState } from 'react'
import { StepConfirmation } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-page-self-service-helper/step-confirmation'
import { EmailBar } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-page-self-service-helper/email-bar'
import { StepPropsType } from '@/main/pages/digital-store/pages/digital-store-status-page/vendors-linking-steps/mercado-pago/step'
import { useGetPersonData } from '@/main/pages/digital-store/hooks/use-get-person-data'
import { IdentityInfoContext } from '@/main/contexts'
import { ProgressIndicator } from '@/main/components/progress-indicator'

import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusEmailStepMessages } from '@/domain/models/messages/messages'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { NatDialog } from './nat-dialog/nat-dialog'

export const EmailStep: React.FC<StepPropsType> = ({ advanceStep }) => {
  const {
    personId, countryId, companyId, tenantId,
  } = useContext(IdentityInfoContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusEmailStep, pageMessagesOptions).messages as DigitalStoreStatusEmailStepMessages

  const [modalOpen, setModalOpen] = useState(false)

  const { personData, personDataLoading } = useGetPersonData({
    relations: ['emails'],
    personId,
    countryId,
    companyId,
  })
  const userEmail = personData?.person?.emails[0]?.email

  if (personDataLoading) return <ProgressIndicator />
  return (
    <StepConfirmation
      title={messages.confirmation.question}
      confirmText={messages.confirmation.affirmativeAnswer}
      cancelText={messages.confirmation.negativeAnswer}
      onConfirm={() => advanceStep()}
      onCancel={() => setModalOpen(true)}
    >
      <>
        <EmailBar email={userEmail} />
        <NatDialog modalOpen={modalOpen} setModalOpen={setModalOpen} messages={messages} />
      </>
    </StepConfirmation>
  )
}
