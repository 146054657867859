import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { reducer as DigitalStoreReducer } from './digital-store'
import { reducer as CompleteRegistrationReducer } from './complete-registration'

export const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    digitalStore: DigitalStoreReducer,
    completeRegistration: CompleteRegistrationReducer,
  })
