import { MyProfileMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMyProfile(translate: TFunction): MyProfileMessages {
  return {
    leader: {
      myLeader: translate('myProfilePage.leader.myLeader'),
      name: translate('myProfilePage.leader.name'),
      email: translate('myProfilePage.leader.email'),
      phone: translate('myProfilePage.leader.phone'),
      contact: translate('myProfilePage.leader.contact'),
      tooltipPhone: translate('myProfilePage.leader.tooltipPhone'),
    },
    container: {
      myData: translate('myProfilePage.container.myData'),
      myLeader: translate('myProfilePage.container.myLeader'),
      digitalSpace: translate('myProfilePage.container.digitalSpace'),
    },
    contact: {
      title: translate('myProfilePage.contact.title'),
      phone: translate('myProfilePage.contact.phone'),
      secondaryPhoneLabel: translate('myProfilePage.contact.secondaryPhoneLabel'),
      email: translate('myProfilePage.contact.email'),
      tooltipPhone: translate('myProfilePage.contact.tooltipPhone'),
    },
    addresses: {
      title: translate('myProfilePage.addresses.title'),
      zipCode: translate('myProfilePage.addresses.zipCode'),
      addressJoin: translate('myProfilePage.addresses.addressJoin'),
      reference: translate('myProfilePage.addresses.reference'),
      withoutInformation: translate('myProfilePage.addresses.withoutInformation'),
      modalTitle: translate('myProfilePage.addresses.modalTitle'),
      modalMessage: translate('myProfilePage.addresses.modalMessage'),
      modalNameButton: translate('myProfilePage.addresses.modalNameButton'),
    },
    consultantData: {
      naturaCode: translate('myProfilePage.consultantData.naturaCode'),
      enterSocialName: translate('myProfilePage.consultantData.enterSocialName'),
      enterRacialSelfDeclaration: translate('myProfilePage.consultantData.enterRacialSelfDeclaration'),
      racialSelfDeclarationLabel: translate('myProfilePage.consultantData.racialSelfDeclarationLabel'),
      gender: translate('myProfilePage.consultantData.gender'),
      group: translate('myProfilePage.consultantData.group'),
      sector: translate('myProfilePage.consultantData.sector'),
    },
    digitalSpace: {
      myDigitalSpace: translate('myProfilePage.digitalSpace.myDigitalSpace'),
      socialMediaShare: translate('myProfilePage.digitalSpace.socialMediaShare'),
      welcomeTitle: translate('myProfilePage.digitalSpace.welcomeTitle'),
      defaultWelcomeMessage: translate('myProfilePage.digitalSpace.defaultWelcomeMessage'),
      digitalSpaceShare: translate('myProfilePage.digitalSpace.digitalSpaceShare'),
      digitalSpaceButton: translate('myProfilePage.digitalSpace.digitalSpaceButton'),
      copyLinkTooltip: translate('myProfilePage.digitalSpace.copyLinkTooltip'),
      createNewSpaceMessage: translate('myProfilePage.digitalSpace.createNewSpaceMessage'),
      createNewSpaceButton: translate('myProfilePage.digitalSpace.createNewSpaceButton'),
    },
    mgmBanner: {
      linkAlt: translate('myProfilePage.mgmBanner.linkAlt'),
      startAlt: translate('myProfilePage.mgmBanner.startAlt'),
      subtitle: translate('myProfilePage.mgmBanner.subtitle'),
      title: translate('myProfilePage.mgmBanner.title'),
      modal: {
        title: translate('myProfilePage.mgmBanner.modal.title'),
        whatsapp: translate('myProfilePage.mgmBanner.modal.whatsapp'),
        email: translate('myProfilePage.mgmBanner.modal.email'),
        message: translate('myProfilePage.mgmBanner.modal.message'),
        welcomeMessage: translate('myProfilePage.mgmBanner.modal.welcomeMessage'),
        copyButton: translate('myProfilePage.mgmBanner.modal.copyButton'),
      },
      snackbar: {
        copyConfirm: translate('myProfilePage.mgmBanner.snackbar.copyConfirm'),
      },
    },
    editButton: translate('myProfilePage.editButton'),
    credit: {
      credit: translate('myProfilePage.credit.credit'),
      minimumOrder: translate('myProfilePage.credit.minimumOrder'),
      availableCredit: translate('myProfilePage.credit.availableCredit'),
      totalCredit: translate('myProfilePage.credit.totalCredit'),
    },
  }
}
