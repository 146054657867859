import { TFunction } from 'i18next'
import {
  PreRegisterConfirmationMessages,
} from '@/domain/models/messages/messages'

export function getPreRegisterConfirmationMessages(translate: TFunction): PreRegisterConfirmationMessages {
  return {
    title: translate('preRegisterConfirmation.title'),
    subtitle: translate('preRegisterConfirmation.subtitle'),
    instructions: {
      aboutLink: translate('preRegisterConfirmation.instructions.aboutLink'),
      aboutResending: translate('preRegisterConfirmation.instructions.aboutResending'),
    },
    resendLink: translate('preRegisterConfirmation.resendLink'),
    previousButtonLabel: translate('previousButtonLabel'),
    nextButtonLabel: translate('preRegisterConfirmation.nextButtonLabel'),
    timerMessage: translate('preRegisterConfirmation.timerMessage'),
  }
}
