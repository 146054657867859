import { PERSON_SUBSTATUS_ID } from '@/domain/models/person'
import { BadRequestError } from '@/data/protocols/errors'
import { useState } from 'react'
import { DigitalStorePageApi } from '../api/make-digital-store-page-api'
import { DigitalStoreHookContext } from '../types'

type saveParams = {
  accepted: boolean
  digitalStoreName: string
  agreementId: string
  extraEntries: {}
}

export type DigitalStoreSaveResults = {
  isSaved: boolean
  isSaving: boolean
  errorOnSave: boolean
  helperTextKey: string
  save: (params: saveParams) => Promise<void>
}

export type UseDigitalStoreSaveParams = {
  convergence: boolean
  api: Pick<
    DigitalStorePageApi,
    'save' |
    'updatePersonSubstatus'
  >
  context: DigitalStoreHookContext
}

const delay = (size: number) => new Promise((resolve) => {
  setTimeout(() => resolve(null), size)
})

export const useDigitalStoreSave = ({
  convergence,
  api,
  context,
}: UseDigitalStoreSaveParams): DigitalStoreSaveResults => {
  const {
    role,
    personId,
    companyId,
    functionId,
    sourceSystem,
    businessModel,
    countryId,
  } = context

  const save = async ({
    accepted,
    digitalStoreName,
    agreementId = '',
    extraEntries = {},
  }: saveParams) => {
    const onDone = (
      isSaved: boolean,
      errorOnSave: boolean,
      helperTextKey: string,
    ) => {
      setDigitalStoreSave({
        ...digitalStoreSave,
        isSaved,
        errorOnSave,
        helperTextKey,
        isSaving: false,
      })
    }

    try {
      setDigitalStoreSave({
        ...digitalStoreSave,
        isSaving: true,
      })

      const isSaved = await api.save({
        accepted,
        personId,
        countryId,
        companyId,
        agreementId,
        extraEntries,
        digitalStoreName,
      })

      if (convergence) {
        await delay(5000)
      } else {
        const substatusId = PERSON_SUBSTATUS_ID.FINAL_VALIDATION
        await api.updatePersonSubstatus({
          role,
          personId,
          countryId,
          companyId,
          functionId,
          substatusId,
          sourceSystem,
          businessModel,
        })
      }

      const hasError = !isSaved
      const helperTextKey = !isSaved ? 'errorSaving' : ''
      onDone(isSaved, hasError, helperTextKey)
    } catch (error) {
      console.warn('Error save')

      let helperTextKey: string
      switch (error.constructor) {
        case BadRequestError:
          helperTextKey = 'errorSaving'
          break
        default:
          helperTextKey = 'genericError'
      }

      const isSaved = false
      const hasError = true
      onDone(isSaved, hasError, helperTextKey)
    }
  }

  const [
    digitalStoreSave,
    setDigitalStoreSave,
  ] = useState<DigitalStoreSaveResults>({
    isSaved: false,
    isSaving: false,
    errorOnSave: false,
    helperTextKey: '',
    save,
  })

  return digitalStoreSave
}
