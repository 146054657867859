export enum FUNCTION_IDS {
  BEAUTY_CONSULTANT = 1,
  LEADER = 4,
  SALES_MANAGER = 8,
  COLLABORATOR = 9,
  GREAT_CONSULTANT = 10,
  ENTREPENEUR_CONSULTANT = 11,
  MANAGER = 12,
  DIRECTOR = 13,
  THIRD_PARTY = 14,
  SNAC = 15,
  FINAL_CONSUMER = 16,
  BUSINESS_DEVELOPMENT_MANAGER = 17,
  HEAD_OF_COMMERCIAL = 21
}
