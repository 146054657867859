export const racialSelfDeclarationOptions = [
    {
      value: 1,
      label: 'Amarela',
    },
    {
      value: 2,
      label: 'Branca',
    },
    {
      value: 3,
      label: 'Indígena',
    },
    {
      value: 4,
      label: 'Parda',
    },
    {
      value: 5,
      label: 'Preta',
    },
    {
      value: 6,
      label: 'Não sei me identificar',
    },
    {
      value: 7,
      label: 'Prefiro não responder',
    },
  ]
  