import {
  HttpPatchClient,
  HttpStatusCode,
} from '@/data/protocols/http'
import {
  AcceptDigitalSignature,
  AcceptDigitalSignatureParams, AgreementDigitalSignature,
} from '@/domain/use-cases/digital-signature/accept-digital-signature'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'

type AcceptDigitalSignatureHeader = {
  userid: string,
  'x-api-key': string
}

type AcceptDigitalSignatureBody = {
  personid: string,
  agreements: AgreementDigitalSignature[]
}

export class RemoteAcceptDigitalSignature implements AcceptDigitalSignature {
  constructor(
    private readonly httpPatchClient: HttpPatchClient<AcceptDigitalSignatureHeader, AcceptDigitalSignatureBody>,
    private readonly xApiKey: string,
  ) {
  }

  async accept(params: AcceptDigitalSignatureParams): Promise<void> {
    const httpResponse = await this.httpPatchClient.patch({
      url: '/digital-signature/accept',
      body: {
        agreements: params.agreements,
        personid: params.personId,
      },
      headers: {
        'x-api-key': this.xApiKey,
        userid: params.userId,
      },
    })
    this.handleResponse(httpResponse.statusCode)
  }

  private handleResponse(statusCode: HttpStatusCode) {
    switch (statusCode) {
      case HttpStatusCode.noContent:
        return
      case HttpStatusCode.badRequest:
        throw new BadRequestError(null)
      default:
        throw new UnexpectedError()
    }
  }
}
