import styled from 'styled-components'

interface Props {
  $spacingErrorStyle?: boolean
}

export const Container = styled.div<Props>`
  font-size: 1.6rem;
  margin: ${(props) => (props.$spacingErrorStyle ? '2.2rem 0px 5px 4px' : '0 4px')};
`
