import { HttpPostClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import {
  BadRequestError, ConflictError, ForbiddenError, NotFoundError,
} from '@/data/protocols/errors'
import {
  CompleteDocumentation,
  CompleteDocumentationRequestHeaders,
  CompleteDocumentationRequestBody,
  CompleteDocumentationRequestParams,
  CompleteDocumentationRequestResponse,
} from '@/domain/use-cases/documentation/complete-documentation'

export class RemoteCompleteDocumentation implements CompleteDocumentation {
  constructor(
    private readonly httpPostClient: HttpPostClient<
      CompleteDocumentationRequestHeaders,
      CompleteDocumentationRequestBody
    >,
    private readonly xApiKey: string,
  ) { }

  complete = async (params: CompleteDocumentationRequestParams):
    Promise<CompleteDocumentationRequestResponse> => {
      const { body, headers, path: { personId } } = params

      if (params.headers.authorization) {
        headers.authorization = params.headers.authorization
      }

      const httpResponse = await this.httpPostClient.post({
        url: `/documentation/complete/${personId}`,
        headers: {
          'x-api-key': this.xApiKey,
          ...headers,
        },
        body,
      })

      switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
          return httpResponse.body
        case HttpStatusCode.badRequest:
          throw new BadRequestError(httpResponse.body)
        case HttpStatusCode.forbidden:
          throw new ForbiddenError(httpResponse.body)
        case HttpStatusCode.notFound:
          throw new NotFoundError(httpResponse.body)
        case HttpStatusCode.conflict:
          throw new ConflictError(httpResponse.body)
        default:
          throw new UnexpectedError()
      }
    }
}
