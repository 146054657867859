import { Page } from '@/domain/models/page/page'
import {
  naturaArgConfigs,
  naturaPerConfigs,
  naturaColConfigs,
  naturaChlConfigs,
  naturaMysConfigs,
  naturaBraConfigs,
  naturaEcuConfigs,
  avonMexConfigs,
  PagesTenantConfigs,
  naturaMexConfigs,
  avonBraConfigs,
} from '@/domain/models/tenant/tenant-configs'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { GetTenantConfigsByPage } from '@/domain/use-cases/tenant/get-tenant-configs'

const configsByTenantId: {[ key in TenantId]?: PagesTenantConfigs} = {
  [TenantId.NaturaARG]: naturaArgConfigs,
  [TenantId.NaturaCOL]: naturaColConfigs,
  [TenantId.NaturaPER]: naturaPerConfigs,
  [TenantId.NaturaCHL]: naturaChlConfigs,
  [TenantId.NaturaMYS]: naturaMysConfigs,
  [TenantId.NaturaBRA]: naturaBraConfigs,
  [TenantId.NaturaMEX]: naturaMexConfigs,
  [TenantId.NaturaECU]: naturaEcuConfigs,
  [TenantId.AvonMEX]: avonMexConfigs,
  [TenantId.AvonBRA]: avonBraConfigs,
}

export const getTenantConfigsByPage: GetTenantConfigsByPage = (tenantId: TenantId, page: Page) => {
  const configs = configsByTenantId[tenantId]
  if (configs && configs[page]) {
    return configs[page]
  }
  throw new Error(`TenantId ${tenantId} does not have ${page} page configs.`)
}
