import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { SubmitPendingSignature, PendingSignatureResponse, PendingSignatureParams } from '@/domain/use-cases/pending-signature'

export type SubmitPendingSignatureHeaders = {
  countryid: CountryId
  companyid: CompanyId,
  'x-api-key': string,
  authorization: string
}

export class RemoteSubmitPendingSignature implements SubmitPendingSignature {
  constructor(
    private readonly httpGetClient: HttpGetClient<
    SubmitPendingSignatureHeaders
    >,
    private readonly xApiKey: string,
  ) {}

  submit = async (params: PendingSignatureParams): Promise<PendingSignatureResponse> => {
    const headers: SubmitPendingSignatureHeaders = {
      countryid: params.countryid,
      companyid: params.companyid,
      'x-api-key': this.xApiKey,
      authorization: params.authorization,
    }

    const httpResponse = await this.httpGetClient.get({
      url: `/pending-signature/person?documentValue=${params.documentValue}&documentTypeId=${params.documentTypeId}`,
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
