import {
    GetMyLeaderData,
    GetMyLeaderDataParams,
    MyLeaderDataResponse,
  } from '@/domain/use-cases/person/get-person-data'
  import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
  import { NotFoundError, ServerError, BadRequestError, UnprocessableEntityError } from '@/data/protocols/errors'
  import { UnexpectedError } from '@/domain/errors'
  import  { BUSINESS_MODELS } from '@/domain/models/person'
  import { CountryId } from '@/domain/models/country'
  import { CompanyId } from '@/domain/models/company/company-id'

  export type GetMyLeaderInfoHeaders = {
    'x-api-key': string
    'country-id': CountryId
    'company-id': CompanyId
    'business-model-id': BUSINESS_MODELS
    'user-id': string
    'user-name': string
    relations: string
  }
  export class RemoteGetMyLeaderInfo implements GetMyLeaderData {
    constructor(
      private readonly url: string,
      private readonly httpGetClient: HttpGetClient<
      GetMyLeaderInfoHeaders,
      MyLeaderDataResponse
      >,
      private readonly xApiKey: string,
    ) {}

    get = async (params: GetMyLeaderDataParams): Promise<MyLeaderDataResponse | {}> => {
      const headers: GetMyLeaderInfoHeaders = {
        'x-api-key': this.xApiKey,
        'country-id': params.countryId,
        'business-model-id': params.businessModelId,
        'company-id': params.companyId,
        'user-id': params.userId,
        'user-name': params.userName,
        relations: params.relations
      }
      const httpResponse = await this.httpGetClient.get({
        url: this.url.replace(/:personId/i, params.personId),
        headers,
      })
      switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
          return httpResponse.body
        case HttpStatusCode.notFound:
          throw new NotFoundError(httpResponse.body)
        case HttpStatusCode.serverError:
          return new ServerError(httpResponse.body)
        case HttpStatusCode.badRequest:
          return new BadRequestError(httpResponse.body)
        case HttpStatusCode.unprocessableEntity:
          return new UnprocessableEntityError(httpResponse.body)
        default:
          throw new UnexpectedError()
      }
    }
  }