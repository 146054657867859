import React, { FocusEvent } from 'react'
import { Controller, ControllerProps, FieldErrors } from 'react-hook-form'
import {
  Link, FormHelperText, InputLabel, Icon, TextField,
} from '@naturacosmeticos/natds-web'
import { useControlledTextFieldWithLinkStyles } from '@/main/components/text-field/text-field-with-link/controlled-text-field-with-link.styles'
import {
  CustomChangeEvent,
  handleOnChange,
} from '@/main/components/text-field/helper/handle-onchange'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import { getFinalErrorMessage, StringOrObjectOfStrings } from '../helper/error-message-getter'
import { getLabel } from '../helper/get-label'
import { validateIsFilled } from '../helper/validate-is-filled'

export type ControlledTextFieldWithLinkProps = {
  id: string;
  label: string;
  placeholder?: string;
  validChars?: RegExp;
  mask?: Mask;
  errors?: FieldErrors;
  errorMessage?: StringOrObjectOfStrings;
  linkText: string;
  href: string;
  isUpperCase?: boolean;
  required?: boolean;
  showAsterisk?: boolean;
  optionalText?: string;
  hasBlankLabel?: boolean;
  shouldValidateIsNotOnlySpaces?: boolean;
  customOnBlur?: (inputValue: string) => void
  customOnChange?: (inputValue: string) => void
} & Pick<ControllerProps<'input'>, 'rules' | 'control'>

export const ControlledTextFieldWithLink: React.FC<ControlledTextFieldWithLinkProps> = ({
  id,
  control,
  label,
  placeholder,
  rules,
  validChars,
  mask,
  errorMessage,
  errors,
  linkText,
  href,
  isUpperCase = false,
  required = false,
  showAsterisk = false,
  optionalText,
  hasBlankLabel,
  shouldValidateIsNotOnlySpaces = false, // this property should be used only when the field is required and has no other validations!
  customOnBlur,
  customOnChange,
}) => {
  const finalErrorMessage = getFinalErrorMessage(errors, errorMessage, id)
  const controlledTextFieldWithLinkClasses = useControlledTextFieldWithLinkStyles()

  if (required && shouldValidateIsNotOnlySpaces) {
    rules = { ...rules, validate: validateIsFilled }
    errorMessage = rules.required as string
  }

  const labelFormatted = getLabel({
    labelText: label,
    showAsterisk,
    required,
    optionalText,
    hasBlankLabel,
  })

  return (
    <div className={controlledTextFieldWithLinkClasses.container}>
      <Controller
        name={id}
        control={control}
        defaultValue=""
        rules={rules}
        render={(
          {
            onBlur, onChange, value, name, ref,
          },
          { invalid },
        ) => (
          <>
            <div className={controlledTextFieldWithLinkClasses.row}>
              <InputLabel
                className={controlledTextFieldWithLinkClasses.label}
                htmlFor={id}
                error={invalid}
              >
                {labelFormatted}
              </InputLabel>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                className={controlledTextFieldWithLinkClasses.link}
                color="default"
                component="a"
                href={href}
                underline="always"
                variant="body2"
                target="_blank"
              >
                {linkText}
              </Link>
            </div>
            <TextField
              ref={ref}
              onBlur={(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                onBlur()
                if (customOnBlur) {
                  customOnBlur(event.target.value)
                }
              }}
              value={value}
              name={name}
              id={id}
              data-testid={id}
              placeholder={placeholder}
              state={invalid ? 'error' : undefined}
              onChange={(event: CustomChangeEvent) => {
                  handleOnChange({
                  event, onChange, validChars, isUpperCase,
                })
                if (customOnChange) {
                  customOnChange(event.target.value)
                }
              }}
              error={invalid}
              mask={mask}
            />
            {invalid && (
              <FormHelperText error>
                <Icon
                  name="outlined-alert-cancel"
                  data-testid={`${id}-icon-error`}
                  size="micro"
                  className={controlledTextFieldWithLinkClasses.icon}
                />
                {
                  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                  // @ts-ignore
                  invalid.message || finalErrorMessage
                }
              </FormHelperText>
            )}
          </>
        )}
      />
    </div>
  )
}
