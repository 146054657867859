export type AddressStorage = {
    personId: string,
    address: AddressType
  }
  
export type AddressType = {
  city: string,
  complement: string,
  country: string,
  number: string,
  neighborhood: string,
  references: string,
  state: string,
  street: string,
  zipCode: string,
  addressType: number,
  addressUse: number,
  sequence: string,
  receiveBillingAtSameAddress: boolean
}
export const ADDRESS_STORAGE_NAME = 'address'
export const SEND_CODE_VALIDATION_ORIGIN = 'sendCodeValidationOrigin'

export const URL_PROFILE_CN = 'urlProfileCN'

export type ChannelStorage = {
  channel: string
  sequence: string
}

export const CHANNEL_STORAGE_NAME = 'channel'
