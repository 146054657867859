import styled from 'styled-components'
import { Typography } from '@naturacosmeticos/natds-web'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const Description = styled(Typography)`
  &.description{
    margin: 16px 0 24px 0;;
    text-align: center;

    @media ${minDeviceSizeForMediaQueries.tablet} {
      padding: 0 44px;
    }
  }
`
