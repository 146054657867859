import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useContactStyles = makeStyles<Theme>((theme) => ({
  contactContainer: {
    padding: `${theme.spacing(1.5)}px 0`,
  },
  contactSubtitleSpace: {
    paddingBottom: theme.spacing(0.5),
  },
  contactPhoneContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactCopyButton: {
    marginRight: `-${theme.spacing(1)}px`,
  },
  contactNameContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.text.primary,
  }
}))
