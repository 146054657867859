import styled from 'styled-components'

import { TextParagraph } from '@/main/components'

export const StyledUrlSection = styled.div`
  text-align: left;
  font-family: Roboto;
  line-height: 20px;
  color: ${({ theme }) => (theme as any).color.mediumEmphasis};
`

export const BoldTextParagraph = styled(TextParagraph)`
  margin-top: 0.8rem;
  font-weight: bold;
  font-size: 1.6rem;
  color: ${({ theme }) => (theme as any).color.highEmphasis};
  a {
    color: ${({ theme }) => (theme as any).color.link};
  }
`
