import React from 'react'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'
import { Link } from '@/main/components/Link/Link'

import { Container } from './TextParagraph.styles'

export type TextParagraphProps = {
  className?: string
  'data-testid'?: string
  content: string
}

const htmlToReactParser = new HtmlToReactParser()
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)

const processingInstructions = [
  {
    shouldProcessNode: (node) => node?.type === 'tag' && node?.name === 'a',
    processNode: (node, children) => <Link key="link" data-testid="link" {...node.attribs}>{ children }</Link>,
  },
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

export const TextParagraph: React.FC<TextParagraphProps> = ({ 'data-testid': testId, className, content }) => {
  if (content) {
    return (
      <Container className={className} data-testid={testId}>
        {htmlToReactParser.parseWithInstructions(content, () => true, processingInstructions)}
      </Container>
    )
  }

  return null
}
