import { BadRequestError, ForbiddenError, NotFoundError } from '@/data/protocols/errors'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import {
  GetDocumentation,
  GetDocumentationHeaders,
  GetDocumentationParams,
  GetDocumentationResponse,
} from '@/domain/use-cases/documentation/get-documentation'

type RemoteGetDocumentationHeaders = {
  'x-api-key': string
} & GetDocumentationHeaders

export class RemoteGetDocumentation implements GetDocumentation {
  constructor(
    private readonly httpGetClient: HttpGetClient<RemoteGetDocumentationHeaders, GetDocumentationResponse>,
    private xApiKey: string,
  ) { }

  get = async (params: GetDocumentationParams): Promise<GetDocumentationResponse> => {
    const { personId, tenantId } = params

    const httpResponse = await this.httpGetClient.get({
      url: `/documentation/get/${personId}`,
      headers: {
        tenantId,
        'x-api-key': this.xApiKey,
      },
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.forbidden:
        throw new ForbiddenError(httpResponse.body)
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
