import styled from 'styled-components'

import Typography, { TypographyProps } from '@material-ui/core/Typography'

import { TextParagraph } from '@/main/components/TextParagraph'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledIcon = styled.img``

export const StyledTitle = styled(Typography)<TypographyProps>`
  text-align: center;
  color: #618f3e;
  font-size: 2.4rem;
  font-weight: bold;
  margin-top: 1rem;
`

export const StyledDescription = styled(TextParagraph)`
  text-align: center;
  font-weight: normal;
  line-height: 21px;
  margin-top: 16px;
`
