import { CompanyId } from '@/domain/models/company/company-id'
import { CompanyName } from '@/domain/models/company/company-name'
import { getCountryByUrl } from '@/main/hooks/useCountryByUrl'
import { getObjectInStorage } from '@/main/hooks/useStorage'

export const getEloToggleByCountryByRegisters = () => {

    const currentCountry = getCountryByUrl()

    const eloCountries = process.env.ENABLE_THEME_ELO_TO_COUNTRY

    const availableCountries = eloCountries.split(',')

    const currentLocale = getObjectInStorage('currentLocale') as any
    
    const isActivatedToCountry = availableCountries.includes(currentCountry)

    const countryLocale = localStorage.getItem('country') as any

    const isActivatedToCountryStorage = availableCountries.includes(countryLocale?.toUpperCase())

    const currentCompanyStorage = localStorage.getItem('company') as any

    return (currentLocale?.companyId === CompanyId.Natura || currentCompanyStorage === CompanyName.Natura) 
        && (isActivatedToCountry || isActivatedToCountryStorage)
}
