import { RemoteCompleteAddress } from '@/data/use-cases/address/remote-complete-address'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteCompleteAddress = (): RemoteCompleteAddress => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteCompleteAddress(httpPatchClient, xApiKey)
}
