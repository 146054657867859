import { useCallback, useRef } from 'react'

interface UseDebounceProps {
  customFunction: Function,
  delayInMilliSeconds?: number
}

export const useDebounce = <T extends any>({
  customFunction,
  delayInMilliSeconds = 1000,
}: UseDebounceProps) => {
  const timeoutRef = useRef(null)

  const debouncedFunctionResult = useCallback((
    ...args: any
  ) => new Promise<T>((resolve) => {
    if (timeoutRef) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      resolve(customFunction(...args))
    }, delayInMilliSeconds)
  }), [customFunction, delayInMilliSeconds])

  return { debouncedFunctionResult }
}
