import { SendCodeValidationParams } from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'
import {
  CheckValidationCodeParams,
  ResponseRemoteCheckValidationCode,
} from '@/data/use-cases/register-maintenance-validation-code/remote-check-validation-code'
import { AcceptAuthorizationCodeParams } from '@/domain/use-cases/authorization-code/accept-authorization-code'
import {
  AuthorizationCodeResponse,
  SendAuthorizationCodeParams,
} from '@/domain/use-cases/authorization-code/authorization-code'
import {
  makeRemoteAcceptAuthorizationCode,
  makeRemoteSendAuthorizationCode,
} from '@/main/factories/remote/authorization-code'
import { makeRemoteSendCodeValidation } from '@/main/factories/remote/register-maintenance-security-validation/make-remote-send-code-validation'
import { makeRemoteCheckValidationCode } from '@/main/factories/remote/register-maintenance-validation-code/make-remote-check-validation-code'

export type AuthorizationCodePageApi = {
  acceptAuthorizationCode: (data: AcceptAuthorizationCodeParams) => Promise<boolean> | void
  sendAuthorizationCode: (
    sendAuthorizationCodeParams: SendAuthorizationCodeParams
  ) => Promise<AuthorizationCodeResponse>
  sendCodeValidation: (data: SendCodeValidationParams) => Promise<void>
  checkValidationCode: (data: CheckValidationCodeParams) => Promise<ResponseRemoteCheckValidationCode>
}

export const makeAuthorizationCodePageApi = ({ isHostedUIEnabled }): AuthorizationCodePageApi => {
  const remoteAcceptAuthorizationCode = makeRemoteAcceptAuthorizationCode()
  const remoteSendAuthorizationCode = makeRemoteSendAuthorizationCode({
    shouldRequestWithApiKey: isHostedUIEnabled,
  })
  const acceptAuthorizationCode = (params: AcceptAuthorizationCodeParams) =>
    remoteAcceptAuthorizationCode.accept(params)
  const sendAuthorizationCode = (params: SendAuthorizationCodeParams) =>
    remoteSendAuthorizationCode.sendAuthorizationCode(params)

  const remoteSendCodeValidation = makeRemoteSendCodeValidation()
  const sendCodeValidation = (params: SendCodeValidationParams) => remoteSendCodeValidation.send(params)

  const remoteCheckValidationCode = makeRemoteCheckValidationCode()
  const checkValidationCode = (params: CheckValidationCodeParams) => remoteCheckValidationCode.check(params)

  return { acceptAuthorizationCode, sendAuthorizationCode, sendCodeValidation, checkValidationCode }
}
