import { call, delay, put } from 'redux-saga/effects'
import { BadRequestError } from '@/data/protocols/errors'
import { CheckDigitalStoreAvailabilityParams } from '@/domain/use-cases/digital-store/check-digital-store-availability'
import { makeCheckDigitalStoreAvailability } from '@/main/factories/remote/digital-store'
import { UnexpectedError } from '@/domain/errors'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { DELAY } from '@/infra/global-state/redux/store/ducks/digital-store/sagas/reset-digital-store-state-on-change'
import {
  checkDigitalStoreAvailabilitySuccess,
  checkDigitalStoreAvailabilityError,
} from '../actions'

const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()

export function* checkDigitalStoreAvailability(action) {
  yield delay(DELAY)

  const {
 digitalStoreName, personId, country, companyId,
} = action.payload
  const countryId = country.length === 2 ? convertCountryIdFromAlpha2ToAlpha3Presenter.handle({ countryId: country }) : country
  try {
    const checkDigitalStoreAvailability = makeCheckDigitalStoreAvailability()
    const params: CheckDigitalStoreAvailabilityParams = {
      countryId,
      personId,
      digitalStoreName,
      companyId,
    }
    const isAvailable = yield call(checkDigitalStoreAvailability.check, params)

    const helperTextKey = isAvailable
      ? 'availableName'
      : 'unavailableName'
    const error = !isAvailable
    yield put(
      checkDigitalStoreAvailabilitySuccess(isAvailable, helperTextKey, error),
    )
  } catch (errorResponse) {
    let helperTextKey: string
    switch (errorResponse.constructor) {
      case BadRequestError:
        helperTextKey = 'invalidNameSpace'
        break
      case UnexpectedError:
      default:
        helperTextKey = 'genericError'
    }
    yield put(checkDigitalStoreAvailabilityError(helperTextKey))
  }
}
