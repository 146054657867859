import React from 'react'

import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'

import { CountryId } from '@/domain/models/country/country-id'
import { DigitalStoreStatusNotFound } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-not-found/digital-store-status-not-found'
import { DigitalStorePage } from '../../../../digital-store-page'

import { DigitalStoreStatusDefaultContent } from '../../digital-store-status-default-content'
import { DigitalStoreStatusPendingAgreements } from '../../digital-store-status-pending-agreements'
import { DigitalStoreStatusPayment } from '../../digital-store-status-payment'
import { makeDigitalStorePageApi } from '../../../../api/make-digital-store-page-api'

const getSelfHelpContentIfAllowed = () => {
  const { ENABLE_DIGITAL_STORE_WITH_MERCADO_PAGO_PERU } = process.env
  const isAllowedSelfHelpContent = ENABLE_DIGITAL_STORE_WITH_MERCADO_PAGO_PERU

  return isAllowedSelfHelpContent ? DigitalStoreStatusPayment : DigitalStoreStatusDefaultContent
}
  const defaultStatusComponents = {
    [DigitalStoreCurrentStatus.PENDING_AGREEMENTS]: DigitalStoreStatusPendingAgreements,
    __default__: DigitalStoreStatusDefaultContent,
  }

  const peruStatusComponents = {
    ...defaultStatusComponents,
    [DigitalStoreCurrentStatus.ACTIVE]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.PENDING_BANK_ACCOUNT]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.PENDING_PAYMENT_ACCOUNT]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.SUSPENDED]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.NOT_STARTED]: ({ onSave }) => <DigitalStorePage convergence onSave={onSave} api={makeDigitalStorePageApi()} />,
  }

  const getForPanelCND = () => window.location.replace(process.env.PANEL_CND)

  const mexicoStatusComponents = {
    ...defaultStatusComponents,
    [DigitalStoreCurrentStatus.ACTIVE]: process.env.ENABLE_CND_PANEL_REDIRECT_DS_STATUS_ACTIVE === 'true' ? getForPanelCND : getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.NOT_STARTED]: ({ onSave }) => <DigitalStorePage convergence onSave={onSave} api={makeDigitalStorePageApi()} />,
    [DigitalStoreCurrentStatus.SUSPENDED]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.CEASED]: getSelfHelpContentIfAllowed(),
  }

  const NotMappedCountryComponents = {
    __default__: DigitalStoreStatusNotFound,
  }

  const statusComponentPerCountry = {
    [CountryId.Peru]: peruStatusComponents,
    [CountryId.Malaysia]: defaultStatusComponents,
    [CountryId.Mexico]: mexicoStatusComponents,
    __default__: NotMappedCountryComponents,
  }

  export const getStoreStatusPageByStatus = ({ digitalStoreStatus, countryId, shouldRenderDigitalStorePage = false }) => {
    if (shouldRenderDigitalStorePage) return ({ onSave }) => <DigitalStorePage convergence onSave={onSave} api={makeDigitalStorePageApi()} />
    const statusComponents = statusComponentPerCountry[countryId] || statusComponentPerCountry.__default__
    return statusComponents[digitalStoreStatus]
      ? statusComponents[digitalStoreStatus]
      : statusComponents.__default__
  }
