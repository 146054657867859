import React from 'react'
import styled from 'styled-components'
import {
 DialogContent as NatDsDialogContent,
    Dialog,
    DialogActions,
    DialogContentText,
  } from '@/main/components/dialog'
import { Button } from '@/main/components/button'

const Content = styled(NatDsDialogContent)`
    max-height: 50vh;
    padding: 0.8rem 0;
`

export type SaveErrorDialogProps = {
    open?: boolean,
    message: string,
    okButtonLabel: string
    onClickOk: () => void
    shouldRender: boolean
}

const SaveErrorDialog = ({
 open, message, okButtonLabel, onClickOk, shouldRender,
}: SaveErrorDialogProps) => (
  <>
    {
      shouldRender && (
        <Dialog
          open={open}
          maxWidth="xs"
          scroll="paper"
          aria-describedby="dialog-content"
          onEscapeKeyDown={onClickOk}
          onBackdropClick={onClickOk}
        >
          <Content id="dialog-content">
            <DialogContentText>{message}</DialogContentText>
          </Content>
          <DialogActions>
            <Button
              color="primary"
              variant="text"
              onClick={onClickOk}
            >
              {okButtonLabel}
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
  </>
)

export default SaveErrorDialog
