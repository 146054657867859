import { CheckValidationCodeParams, ResponseRemoteCheckValidationCode } from '@/data/use-cases/register-maintenance-validation-code/remote-check-validation-code'
import { makeRemoteCheckValidationCode } from '@/main/factories/remote/register-maintenance-validation-code/make-remote-check-validation-code'
import { makeRemoteSaveAddress } from '@/main/factories/remote/register-maintenance/make-remote-save-addresses'
import { SaveAddressParams } from '@/data/use-cases/register-maintenance/remote-save-address'
import { makeRemoteGetStatusWorkflow } from '@/main/factories/remote/register-maintenance-security-validation/make-remote-get-status-workflow'
import { ResponseGetStatusWorkflow, StatusWorkflowParams } from '@/data/use-cases/register-maintenance-security-validation/status-workflow'
import { makeSaveConsultantInfoBasicData } from '@/main/factories/remote/register-maintenance/make-remote-save-consultant-info'
import { SaveConsultantInfoParams, SaveConsultantInfoResponse } from '@/domain/use-cases/register-maintenance/save-consultant-info'

export type ValidationCodePageApi = {
  checkValidationCode:(data: CheckValidationCodeParams) => Promise<ResponseRemoteCheckValidationCode>
  saveAddress: (data: SaveAddressParams) => Promise<void>
  statusWorkflow: (params: StatusWorkflowParams) => Promise<ResponseGetStatusWorkflow>
  saveConsultantInfoBasicData: (params: SaveConsultantInfoParams) => Promise<SaveConsultantInfoResponse>
}

export const makeValidationCodePageApi = (): ValidationCodePageApi => {

  const remoteSaveAddress = makeRemoteSaveAddress()
  const saveAddress = (params: SaveAddressParams) => remoteSaveAddress.update(params)

  const remoteCheckValidationCode = makeRemoteCheckValidationCode()
  const checkValidationCode = (params: CheckValidationCodeParams) => remoteCheckValidationCode.check(params)

  const remoteGetStatusWorkflow = makeRemoteGetStatusWorkflow()
  const statusWorkflow = (params: StatusWorkflowParams) => remoteGetStatusWorkflow.get(params)

  const remoteSaveConsultantInfoBasicData = makeSaveConsultantInfoBasicData()
  const saveConsultantInfoBasicData = (data: SaveConsultantInfoParams) => remoteSaveConsultantInfoBasicData.updateBasicData(data)

  return {
    saveAddress,
    checkValidationCode,
    statusWorkflow,
    saveConsultantInfoBasicData
  }
}
