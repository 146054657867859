import styled from 'styled-components'
import { Typography } from '@naturacosmeticos/natds-web'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { Card } from '@/main/components'

type TextProps = {
  center?: boolean
}

const AlignedToCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ContentSuccess = styled(Card)`
  ${AlignedToCenter}
  margin-top: ${({ theme }) => theme.sizes.semi}px;
  border-radius: ${({ theme }) => theme.sizes.tiny}rem;
  
  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin-top: ${({ theme }) => theme.sizes.huge}px;
    padding-top: ${({ theme }) => theme.sizes.semi}px;
    min-width: ${({ theme }) => (theme.sizes.veryHuge * 2.3)}px;
  }
`

export const SuccessTitle = styled(Typography)`
  &.success_title{
    color: ${({ theme }) => theme.color.success};
    font-weight: bold;
    margin-top: ${({ theme }) => theme.sizes.tiny}px;
    text-align: center;
  }
`

export const Subtitle = styled.div`
  margin-top: ${({ theme }) => theme.sizes.small}px;
`

export const Text = styled(Typography)<TextProps>`
  &.reference {
    margin-top: ${({ theme }) => theme.sizes.standard}px;
  }

  &.label {
    margin-bottom: ${({ theme }) => theme.sizes.tiny}px;
  }

  &.successColor {
    color: ${({ theme }) => theme.color.success};
  }
`

export const ActionContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizes.semi}px;
  margin-bottom: ${({ theme }) => theme.sizes.semi}px;
`