import React from 'react'
import { ControlledTextField } from '@/main/components'
import { FieldsOrderProps } from '@/domain/models/tenant/tenant-configs/configs'

export const StateField = (fieldsOrderProps: FieldsOrderProps) => {
  const { messages, configs, control, id, onChange } = fieldsOrderProps
  return (
    <>
      <ControlledTextField
        id={id || 'state'}
        customOnChange={onChange}
        label={messages.label}
        placeholder={messages.placeholder}
        control={control}
        rules={{
          maxLength: {
            message: messages.rules.maxLength,
            value: configs.rules.maxLength,
          },
          required: {
            message: messages.rules.maxLength,
            value: configs.rules.maxLength,
          },
        }}
        validChars={configs.validChars}
        disabled
      />
    </>
  )
}
