import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'
import { BUSINESS_MODELS, FUNCTION_IDS, ROLES } from '@/domain/models/person'
import {
  BadRequestError,
  NotFoundError,
  ServerError,
} from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { PaymentPlanId } from '@/domain/models/paymentPlan/payment-plan-id'

export type RemoteGetPersonStatusParams = {
  personId: string
  companyId: CompanyId
  countryId: CountryId
  role: ROLES
  businessModel: BUSINESS_MODELS
  functionId: FUNCTION_IDS,
}

export enum PersonRegisterStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  LOADING ='LOADING',
  DRAFT ='DRAFT',
}

export type PersonStatus = {
  personRegisterStatus: PersonRegisterStatus,
  approvedCandidate?: {
    candidateId: string,
    personId: string,
  },
  paymentPlan?: {
    createdAt?: Date
    creditLine?: number,
    description?: string,
    id: PaymentPlanId,
    personId: string,
  },
}

export type RemoteGetPersonStatusResponse = {
  data: PersonStatus
}

export class RemoteGetPersonStatus {
  constructor(
    private readonly httpGetClient : HttpGetClient,
    private readonly xApiKey: string,
) {}

  async getPersonStatus(params: RemoteGetPersonStatusParams) : Promise<RemoteGetPersonStatusResponse> {
    const response = await this.httpGetClient.get({
      headers: {
        'x-api-key': this.xApiKey,
        companyid: params.companyId,
        countryid: params.countryId,
        function: params.functionId,
        role: params.role,
        businessmodel: params.businessModel,
      },
      url: `/people/${params.personId}/status`,
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body
      case HttpStatusCode.notFound:
        throw new NotFoundError(response.body)
      case HttpStatusCode.serverError:
        throw new ServerError(response.body)
      case HttpStatusCode.badRequest:
        throw new BadRequestError(response.body)
      default:
        throw new UnexpectedError()
    }
  }
}
