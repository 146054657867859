import { GridContainer, GridItem } from '@naturacosmeticos/natds-web'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Page } from '@/domain/models/page/page'
import { MaintenanceRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { PhoneNumber } from '../fields/phone-number'
import { PhoneTypeSelect } from './phone-type-select'

type Messages = {
  label: string
  phoneType: {
    label: string
    placeholder: string
  }
  placeholder: string
  requiredErrorMessage: string
  firstDigitErrorMessage: string
  lengthErrorMessage: string
  invalidType: string
}

export type PhoneFieldsProps = {
  tenantId: TenantId
  id: string
  hasAsteriskInTheLabel?: boolean
  options: { value: string; label: string }[]
  messages: Messages
}

export const PhoneFields = ({
  tenantId,
  id,
  hasAsteriskInTheLabel,
  options,
  messages: phoneMessages,
}: PhoneFieldsProps) => {
  const { watch } = useFormContext()
  const type = watch(`${id}.type`)

  const { telephonesOptions } = useTenantConfigs(
    tenantId,
    Page.MaintenanceRegister
  ) as MaintenanceRegisterConfigs

  const phoneTypeOptionSelected = telephonesOptions?.find((option) => option.type === type)

  return (
    <GridContainer spacing={2} alignItems="flex-start">
      <GridItem md={6} sm={12} xs={12}>
        <PhoneTypeSelect
          id={`${id}.type`}
          label={phoneMessages.phoneType.label}
          placeholder={phoneMessages.phoneType.placeholder}
          options={options}
          showAsterisk={hasAsteriskInTheLabel}
          errorMessage={phoneMessages.invalidType}
        />
      </GridItem>
      <GridItem md={6} sm={12} xs={12}>
        <PhoneNumber
          id={`${id}.phoneNumber`}
          name={`${id}.phoneNumber`}
          messages={{ ...phoneMessages, label: phoneMessages.label }}
          rules={{ required: true }}
          options={{
            maxLength: phoneTypeOptionSelected?.maxLength,
            minLength: phoneTypeOptionSelected?.minLength,
          }}
          mask={phoneTypeOptionSelected?.mask}
          mobileRegex={phoneTypeOptionSelected?.validation}
          showAsterisk={hasAsteriskInTheLabel}
          disabled={!type}
        />
      </GridItem>
    </GridContainer>
  )
}
