import styled from 'styled-components'
import { Typography } from '@naturacosmeticos/natds-web'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => `${theme.sizes.small}px`};

  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin-bottom: 0;
  }
`

export const SlideInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`

export const SlideTitle = styled(Typography)`
  &.slide_title{
    font-weight: bold;
    margin-bottom: 8px;
    line-height: normal;
  }
`

export const SlideDescription = styled(Typography)`
  color: ${({ theme }) => theme.color.mediumEmphasis}
`
