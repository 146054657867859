import { PreRegisterMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import {
  NavigationContext,
  NavigationContextProps,
  FormContext,
  IdentityInfoContext,
  AuthenticatedUserContext,
} from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { PreRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { InitialDocumentPage } from '../initial-document/initial-document-page'
import { PreRegisterPageApi } from './api'

type PreRegisterPageProps = {
  api: PreRegisterPageApi
}

export const PreRegisterPage: React.FC<PreRegisterPageProps> = ({ api }) => {
  const { complete, searchIndication } = api
  const { onSubmit } = useContext(FormContext)
  const { goToNextPage } = useContext<NavigationContextProps>(NavigationContext)

  const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState('')
  const [isUnexpectedErrorDialogOpen, setIsUnexpectedErrorDialogOpen] = useState<boolean>(false)
  const [indicationParams, setIndicationParams] = useState({
    code: null,
    name: null,
    isRegisterPage: true,
  })
  const { tenantId, countryId, companyId, shouldCreatePerson, authorization } = useContext(
    IdentityInfoContext
  )

  const { authenticated, sellerId, userRole } = useContext(AuthenticatedUserContext)

  const { dialog } = usePageMessages(Page.PreRegister).messages as PreRegisterMessages

  const { enableIndicationLink } = useTenantConfigs(tenantId, Page.PreRegister) as PreRegisterConfigs

  const handleSubmit = async ({ documents, phoneNumber }) => {
    const { type, document } = documents[0]
    if (shouldCreatePerson) {
      await complete({
        documentValue: document,
        documentType: type,
        countryid: countryId,
        companyid: companyId,
        registrantId: authenticated ? sellerId : null,
        introducerSellerId: authenticated ? null : indicationParams.code,
        authorization,
        userRole,
        phoneNumber,
      })
        .then(async (response) => {
          await onSubmit(response)
          goToNextPage()
        })
        .catch((response) => {
          const messageKey = response.data?.error?.code === 'PR-0028' ? 'personRoleAlreadyExists' : response.data?.error?.messageKey
          setUnexpectedErrorMessage(dialog[messageKey] || dialog.unexpectedError)
          setIsUnexpectedErrorDialogOpen(true)
        })
    } else {
      await onSubmit({ documents })
      goToNextPage()
    }
  }

  const handleIndicationCode = useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const personCode = queryParams.get('cn')

    if (personCode && enableIndicationLink) {
      searchIndication({
        personCode,
        companyId,
        countryId,
      })
        .then((indicationInfo) => {
          setIndicationParams({ code: personCode, name: indicationInfo.name, isRegisterPage: true })
        })
        .catch(() => {})
    }
  }, [companyId, countryId, searchIndication])

  useEffect(() => {
    handleIndicationCode()
  }, [handleIndicationCode])

  return (
    <>
      <InitialDocumentPage
        page={Page.PreRegister}
        onSubmit={handleSubmit}
        indicationParams={indicationParams}
      />
      <DialogAlert
        show={isUnexpectedErrorDialogOpen}
        messages={{ infoText: unexpectedErrorMessage, closeButtonText: dialog.closeButtonText }}
        closeDialogCallback={() => setIsUnexpectedErrorDialogOpen(false)}
      />
    </>
  )
}
