import { LocalConvertCountryIdFromAlpha2ToAlpha3 } from '@/data/use-cases/country'
import { COUNTRIES_IDS_ALPHA_2_KEYS } from '@/domain/models/country/country-id-alpha-2'
import { ConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/presentation/presenters/country'

export const makeConvertCountryIdFromAlpha2ToAlpha3Presenter = () => {
  const localConvertCountryIdFromAlpha2ToAlpha3 = new LocalConvertCountryIdFromAlpha2ToAlpha3(
    COUNTRIES_IDS_ALPHA_2_KEYS,
  )
  const convertCountryIdFromAlpha2ToAlpha3Presenter = new ConvertCountryIdFromAlpha2ToAlpha3Presenter(localConvertCountryIdFromAlpha2ToAlpha3)
  return convertCountryIdFromAlpha2ToAlpha3Presenter
}
