import React from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import {
  TextFieldWithButton,
  TextFieldWithButtonProps,
} from '@/main/components/text-field/text-field-with-button'
import { ContainerTextField } from '@/main/components/text-field/controlled-text-field-with-button.styles'

export type ControlledTextFieldWithButtonProps = Pick<ControllerProps<'input'>, 'rules' | 'control'> & TextFieldWithButtonProps

export const ControlledTextFieldWithButton: React.FC<ControlledTextFieldWithButtonProps> = ({
 id, control, rules, ...props
}) => (
  <ContainerTextField>
    <Controller
      name={id}
      control={control}
      defaultValue=""
      rules={rules}
      render={(
          {
            onBlur, onChange, value, name, ref,
          },
          { invalid },
        ) => (
          <TextFieldWithButton
            {...props}
            inputProps={{
              ...props.inputProps,
              onBlur,
              onChange,
              value,
              ref,
            }}
            name={name}
            id={id}
            invalid={invalid}
          />
        )}
    />
  </ContainerTextField>
)
