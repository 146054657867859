import { Presenter } from '@/presentation/protocols/presenter'
import { ConvertCountryIdFromAlpha2ToAlpha3 } from '@/domain/use-cases/country/convert-country-id-from-alpha-2-to-alpha-3'
import { CountryIdAlpha2 } from '@/domain/models/country/country-id-alpha-2'
import { CountryId } from '@/domain/models/country'

export type ConvertCountryIdFromAlpha2ToAlpha3PresenterParams = {
  countryId: CountryIdAlpha2
}

export class ConvertCountryIdFromAlpha2ToAlpha3Presenter
  implements Presenter<ConvertCountryIdFromAlpha2ToAlpha3PresenterParams, CountryId> {
  constructor(
    private readonly convertCountryIdFromAlpha2ToAlpha3: ConvertCountryIdFromAlpha2ToAlpha3,
  ) { }

  handle(params: ConvertCountryIdFromAlpha2ToAlpha3PresenterParams): CountryId {
    const countryIdText = (params.countryId as string).toUpperCase()

    return this.convertCountryIdFromAlpha2ToAlpha3.convert({
      countryId: countryIdText as CountryIdAlpha2,
    })
  }
}
