import { RemoteCheckDigitalStoreAvailability } from '@/data/use-cases/digital-store'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeCheckDigitalStoreAvailability = () => {
  const apiUrl = '/digital-store/availability/:digitalStoreName'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteCheckDigitalStoreAvailability = new RemoteCheckDigitalStoreAvailability(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteCheckDigitalStoreAvailability
}
