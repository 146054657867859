import React, {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react'
import {
  Dialog,
  DialogTitle,
} from '@/main/components/dialog'
import { Button } from '@/main/components/button'
import { getEloToggleByCountry } from '@/utils/getEloToggle'
import { ContainerActions, DialogActionsWithLink, DialogContent, LinkTerm } from './dialog-agreements.styles'

enum DialogId {
  content = 'dialog-content'
}

export interface DialogAgreementsProps {
  title: string
  content: ReactNode
  closeButtonText: string
  acceptButtonText: string
  audibleTermLabel?: string
  audibleTermLink?: string
  open: boolean
  onAccept: () => void
  onOpen: (open: boolean) => void
}

export const DialogAgreements: React.FC<DialogAgreementsProps> = ({
  title,
  content,
  closeButtonText,
  acceptButtonText,
  audibleTermLabel,
  audibleTermLink,
  open,
  onAccept,
  onOpen,
}) => {
  const [agreementContent, setAgreementContent] = useState<ReactNode>(<></>)

  const handleClose = () => {
    onOpen(false)
  }

  const handleSetAgreementsContent = useCallback(() => {
    setAgreementContent(content)
  }, [content])

  useLayoutEffect(() => {
    handleSetAgreementsContent()
  }, [handleSetAgreementsContent])

  return (
    <Dialog
      open={open}
      maxWidth="md"
      scroll="paper"
      aria-describedby={DialogId.content}
      onEscapeKeyDown={handleClose}
      onBackdropClick={handleClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent id={DialogId.content}>
        {agreementContent}
      </DialogContent>
      <DialogActionsWithLink>
        <div>
          {audibleTermLink && audibleTermLabel !== '__STRING_NOT_TRANSLATED__' && <LinkTerm href={audibleTermLink}>{audibleTermLabel}</LinkTerm>}
        </div>
        <ContainerActions>
          <Button
            color="default"
            variant="text"
            onClick={handleClose}
          >
            {closeButtonText}
          </Button>
          <Button
            color="primary"
            variant={getEloToggleByCountry()? 'outlined' : 'contained'} 
            onClick={onAccept}
          >
            {acceptButtonText}
          </Button>
        </ContainerActions>
      </DialogActionsWithLink>
    </Dialog>
  )
}
