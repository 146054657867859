import { BadRequestError, NotFoundError, UnauthorizedError } from '@/data/protocols/errors'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'

type GetMultipleAddressHeaders = {
  countryid: CountryId
  companyid: CompanyId
  requesterPersonId: string
  'x-api-key': string
}

export type GetMultipleAddressParams = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
  requesterPersonId: string
  formatAllLevels?: boolean
}

export type Level = {
  label: string
  value: string
}

export type AddressPageForms = {
  city?: string
  cologne?: string
  complement?: string
  country?: string
  district?: string
  number?: string
  neighborhood?: string
  references?: string
  state?: string
  street?: string
  zipCode?: string
  level1?: Level
  level2?: Level
  level3?: Level
  level4?: Level | string
  urbanizacion?: string
  complementaryData?: string
  addressType?: string
  addressUse?: string
  createdAt?: string
  updatedAt?: string
  sequence?: string
  addressPlaceTypeId?: string
  addressPlaceTypeName?: string
}

export type MultipleAddress = {
  deliveryAddress: AddressPageForms
  otherAddresses: AddressPageForms[]
}

export class RemoteGetMultipleAddress {
  constructor(
    private readonly httpGetClient: HttpGetClient<GetMultipleAddressHeaders, MultipleAddress>,
    private readonly xApiKey: string
  ) {}

  get = async ({
    countryId,
    companyId,
    personId,
    requesterPersonId,
    formatAllLevels,
  }: GetMultipleAddressParams): Promise<MultipleAddress> => {
    const headers = {
      countryid: countryId,
      companyid: companyId,
      'x-api-key': this.xApiKey,
      requesterPersonId,
    }
    const httpResponse = await this.httpGetClient.get({
      url: `/address/multiple/${personId}`,
      headers,
      query: { formatAllLevels },
    })

    return this.handleResponse(httpResponse)
  }

  private handleResponse = (httpResponse) => {
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
