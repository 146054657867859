import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ControlledTextField } from '@/main/components'
import { NamesOptions } from '@/domain/models/tenant/tenant-configs'

export type SeparatedNamesMessages = {
  firstName: {
    label: string,
    placeholder: string,
  },
  lastName: {
    label: string,
    placeholder: string,
  },
  middleName: {
    label: string,
    placeholder: string,
  },
  requiredErrorMessage: string
}

export type SeparatedNamesCustomProps = {
  showAsteriskInRequiredLabel?: boolean
}

export type SeparatedNamesTextFieldProps = {
  messages: SeparatedNamesMessages
  customProps?: SeparatedNamesCustomProps
  namesOptions: Array<NamesOptions>
}

export const SeparatedNamesTextField: React.FC<SeparatedNamesTextFieldProps> = ({ messages, namesOptions, customProps = { showAsteriskInRequiredLabel: false } }) => {
  const { control } = useFormContext()
  const allowedCharsRegex = new RegExp(/^[a-zA-Z0-9(\\)\\#\\:\\,\\. ]*$/)

  return (
    <>
      {namesOptions.map((fieldName) => (
        <ControlledTextField
          id={fieldName}
          key={fieldName}
          label={messages[fieldName].label}
          control={control}
          placeholder={messages[fieldName].placeholder}
          rules={{ required: messages.requiredErrorMessage as string }}
          validChars={allowedCharsRegex}
          showAsterisk={customProps.showAsteriskInRequiredLabel}
          required
          shouldValidateIsNotOnlySpaces
        />
      ))}
    </>
  )
}
