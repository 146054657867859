import { TFunction } from 'i18next'
import { CompletedMessages } from '@/domain/models/messages/messages'

export const getCompletedMessages = (translate: TFunction): CompletedMessages => ({
  confirmation: {
    title: translate('completedPage.confirmation.title'),
    subtitle: translate('completedPage.confirmation.subtitle'),
    description: translate('completedPage.confirmation.description'),
    nextSteps: {
      step1: translate('completedPage.confirmation.nextSteps.step1'),
      contact: translate('completedPage.confirmation.nextSteps.contact'),
    },
    iconAlts: {
      confirmation: translate('completedPage.confirmation.iconAlts.confirmation'),
    },
    contactInfo: {
      title: translate('completedPage.confirmation.contactInfo.title'),
      phoneNumber: translate('completedPage.confirmation.contactInfo.phoneNumber'),
      email: translate('completedPage.confirmation.contactInfo.email'),
      openingHours: translate('completedPage.confirmation.contactInfo.openingHours'),
    },
    indicativePerson: translate('completedPage.confirmation.indicativePerson'),
    goToLandingPage: translate('completedPage.confirmation.goToLandingPage'),
    searchNotFound: translate('completedPage.confirmation.searchNotFound'),
    searchError: translate('completedPage.confirmation.searchError'),
  },
})
