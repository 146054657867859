import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { SaveConsultantInfo } from '@/domain/use-cases/register-maintenance/save-consultant-info'
import { RemoteSaveConsultantInfo } from '@/data/use-cases/register-maintenance/remote-save-consultant-info'

const axiosBaseUrl = process.env.REGISTERS_BFF_URL
const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)

export const makeSaveConsultantInfo = (): SaveConsultantInfo => {
  const apiUrl = '/people'
  const remoteSaveConsultantInfo = new RemoteSaveConsultantInfo(
    apiUrl,
    httpPatchClient,
    xApiKey,
  )

  return remoteSaveConsultantInfo
}

export const makeSaveConsultantInfoBasicData = (): SaveConsultantInfo => {
  const apiUrl = '/basic-data'
  const remoteSaveConsultantInfoBasicData = new RemoteSaveConsultantInfo(
    apiUrl,
    httpPatchClient,
    xApiKey,
  )

  return remoteSaveConsultantInfoBasicData
}
