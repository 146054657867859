import { HttpStatusCode } from '@/data/protocols/http'

export class ServerError extends Error {
  statusCode: HttpStatusCode
  data: any

  constructor(data) {
    super()
    this.name = 'ServerError'
    this.statusCode = 500
    this.data = data
  }
}
