import React, { useContext, useEffect, useState } from 'react'
import { useDigitalSpaceStyles } from '@/main/pages/my-profile/digital-space/digital-space.style'
import { CustomCard } from '@/main/pages/my-profile/components/custom-card'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import NatAvatar from '@/main/assets/pngs/nat-avatar.png'
import {
  Avatar, Card,
  IconButton,
  Link,
  Typography,
} from '@naturacosmeticos/natds-web'
import { Button, Icon, Tooltip } from '@/main/components'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { ProfileConfigs } from '@/domain/models/tenant/tenant-configs'
import { IdentityInfoContext } from '@/main/contexts'
import { DigitalSpaceInfo } from '@/data/use-cases/my-profile/remote-get-digital-space-info'
import { PersonDataResponse } from '@/domain/use-cases/person'
import { PhoneType } from '@/domain/models/person'
import { IconName } from '@naturacosmeticos/natds-icons'
import {
  generateFacebookApiUrl,
  generateWhatsAppApiUrl,
} from '@/main/pages/my-profile/digital-space/digital-space.service'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'

type SocialMedia = {
  icon: IconName
  href: string
  key: string
}

type DigitalSpaceProps = {
  digitalStore: DigitalSpaceInfo
  personData: PersonDataResponse
}

export const DigitalSpace: React.FC<DigitalSpaceProps> = ({ digitalStore, personData: { person } }) => {
  const {
    companyId,
    countryId,
  } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const {
    digitalSpace: {
      digitalSpaceBaseUrl,
      editMySpacePath,
      mySpaceDashboard,
      openDigitalSpaceUrl,
    },
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const componentClasses = useDigitalSpaceStyles()
  const { digitalSpace, editButton: editButtonName } = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const [socialMedias, setSocialMedias] = useState<SocialMedia[]>([])

  const { storeName, displayName, avatarUrl, about, facebookLink, instagramLink, youtubeLink, } = digitalStore || {}
  const { phones } = person

  const mapSocialMedia = (icon, href, key): SocialMedia => ({ icon, href, key, })
  const getSocialMedia = (): SocialMedia[] => {
    const socialMedia = []

    const whatsApp = phones?.find((phone) => phone.type === PhoneType.WHATSAPP)

    if (facebookLink) {
      socialMedia.push(mapSocialMedia('filled-brand-facebook', generateFacebookApiUrl(welcomeMessage, storeLink), 'facebook'))
    }
    if (instagramLink) {
      socialMedia.push(mapSocialMedia('filled-brand-instagram', instagramLink, 'instagram'))
    }
    if (whatsApp) {
      socialMedia.push(mapSocialMedia('filled-brand-whatsapp', generateWhatsAppApiUrl(`${welcomeMessage}. ${storeLink}`), 'whatsApp'))
    }
    if (youtubeLink) {
      socialMedia.push(mapSocialMedia('filled-brand-youtube', youtubeLink, 'youtube'))
    }

    return socialMedia
  }

  useEffect(() => {
    const socialMedias = getSocialMedia()
    setSocialMedias(socialMedias)
  }, [])

  const storeLink = `${digitalSpaceBaseUrl}${storeName}`
  const avatar = avatarUrl || NatAvatar
  const welcomeMessage = about || digitalSpace.defaultWelcomeMessage

  const openNewTabOnClick = (url) => {
    window.open(url, '_blank')
  }

  return digitalStore ? (
    <CustomCard title={digitalSpace.myDigitalSpace} editButtonName={editButtonName} editButtonLink={editMySpacePath}>
      <div className={`${componentClasses.inlineCenter} ${componentClasses.storeContainer}`}>
        <div className={`${componentClasses.inlineCenter} ${componentClasses.avatarContainer}`}>
          <Avatar size="huge" src={avatar} className={componentClasses.avatar} />
          <Typography variant="h6">
            {displayName}
          </Typography>
        </div>
        {socialMedias.length > 0 &&
          (
            <div>
              <Typography className={componentClasses.digitalSpaceTitle} variant="body2" color="textSecondary">
                {digitalSpace.socialMediaShare}
              </Typography>
              <div className={componentClasses.socialMediaIcons}>
                {
                  socialMedias.map(({ key, icon, href }) => (
                    <IconButton
                      data-testid={key}
                      key={key}
                      onClick={() => openNewTabOnClick(href)}
                    >
                      <Icon name={icon} size="tiny" />
                    </IconButton>
                  ))
                }
              </div>
            </div>
          )}
      </div>
      <div>
        <Typography variant="body2" color="textSecondary">
          {digitalSpace.welcomeTitle}
        </Typography>
        <Typography variant="body2" className={componentClasses.welcomeMessage}>
          {welcomeMessage}
        </Typography>
      </div>
      <div className={`${componentClasses.inlineCenter} ${componentClasses.shareDigitalSpace}`}>
        <div>
          <Typography variant="body2" color="textSecondary">
            {digitalSpace.digitalSpaceShare}
          </Typography>
          <div className={componentClasses.inlineCenter}>
            <Link
              href={storeLink}
              variant="caption"
              color="default"
              target="_blank"
            >
              {storeLink}
            </Link>
            <ButtonTooltipCopyIcon title={digitalSpace.copyLinkTooltip} storeLink={storeLink} componentClasses='' />
          </div>
        </div>
        <Button
          className={componentClasses.visitMySpaceButton}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => openNewTabOnClick(mySpaceDashboard)}
        >
          <Typography variant="subtitle2">
            {digitalSpace.digitalSpaceButton}
          </Typography>
        </Button>
      </div>
    </CustomCard>
  ) : (
    <OpenDigitalSpace
      componentClasses={componentClasses}
      title={digitalSpace.createNewSpaceMessage}
      buttonName={digitalSpace.createNewSpaceButton}
      createStoreLink={openDigitalSpaceUrl}
    />
  )
}

const OpenDigitalSpace = ({ createStoreLink, componentClasses, title, buttonName }) => {
  const onOpenSpaceClick = () => {
    window.open(createStoreLink, '_blank')
  }

  return (
    <Card className={`${componentClasses.createDigitalSpace} ${componentClasses.inlineCenter}`}>
      <Typography variant="body1">
        {title}
      </Typography>
      <Button
        className={componentClasses.createDigitalSpaceButton}
        variant="contained"
        size="medium"
        color="primary"
        onClick={onOpenSpaceClick}
      >
        <Typography variant="body2">
          {buttonName}
        </Typography>
      </Button>
    </Card>
  )
}

const ButtonTooltipCopyIcon = ({
  storeLink, componentClasses, title,
}) => (
  <Tooltip
    title={(
      <Typography variant="body2">
        {title}
      </Typography>
    )}
    enterTouchDelay={100}
    arrow
  >
    <IconButton
      data-testid="copy-link"
      className={componentClasses.contactCopyButton}
      onClick={() => navigator.clipboard.writeText(storeLink)}
    >
      <Icon name="outlined-action-copylink" size="tiny" />
    </IconButton>
  </Tooltip>
)
