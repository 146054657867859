import { TFunction } from 'i18next'
import { PreRegisterMessages, PreRegisterMessagesOptions } from '@/domain/models/messages/messages'

export function getPreRegisterMessages(
  translate: TFunction,
  options: PreRegisterMessagesOptions = {
    document: '',
    min: '',
    max: '',
    indicationCode: '',
    indicationName: '',
  }
): PreRegisterMessages {
  return {
    title: translate('preRegister.title'),
    subtitle: translate('preRegister.subtitle'),
    nextButtonLabel: translate('nextButtonLabel'),
    selectType: {
      label: translate('preRegister.selectType.label'),
    },
    document: {
      label: translate('preRegister.document.label'),
      placeholder: translate('preRegister.document.placeholder', { document: options.document }),
      rules: {
        required: translate('preRegister.document.rules.required'),
        minLength: translate('preRegister.document.rules.minLength', { min: options.min }),
        maxLength: translate('preRegister.document.rules.maxLength', { max: options.max }),
        pattern: translate('preRegister.document.rules.pattern'),
      },
    },
    link: {
      text: translate('preRegister.link.text'),
    },
    phone: {
      label: translate('preRegister.phoneType.label'),
      options: {
        cellular: {
          label: translate('preRegister.phoneType.cellular.label'),
          phoneNumber: {
            placeholder: translate('preRegister.phoneType.cellular.phoneNumber.placeholder'),
            errorMessage: {
              firstDigit: translate('preRegister.phoneType.cellular.phoneNumber.firstDigit'),
              length: translate('preRegister.phoneType.cellular.phoneNumber.length'),
            },
          },
        },
        line: {
          label: translate('preRegister.phoneType.line.label'),
          phoneNumber: {
            placeholder: translate('preRegister.phoneType.line.phoneNumber.placeholder'),
            errorMessage: {
              firstDigit: translate('preRegister.phoneType.line.phoneNumber.firstDigit'),
              length: translate('preRegister.phoneType.line.phoneNumber.length'),
            },
          },
        },
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    dialog: {
      personRoleAlreadyExists: translate('preRegister.dialog.personRoleAlreadyExists'),
      badPayerFound: translate('preRegister.dialog.badPayerFound'),
      closeButtonText: translate('unexpectedErrorDialog.button'),
      unexpectedError: translate('unexpectedErrorDialog.message'),
      personHasDebts: translate('preRegister.dialog.personHasDebts'),
    },
    indicationMessage: translate('preRegister.indicationMessage', {
      code: options.indicationCode,
      name: options.indicationName,
    }),
  }
}
