import styled from 'styled-components'

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-top: 12px;
`

export const SelectedPersonContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.sizes.tiny}px`};
`
