import { SelectOption } from '@/main/components'
import { makeRemoteGetGeoCommercialStructure } from '@/main/factories/remote/address'
import { CountryId } from '@/domain/models/country'

type FetchGeoCommercialParams = {
  level: number,
  parentCode?: number | string
  countryId?: CountryId
}

export const fetchGeoCommercialStructure = ({ level, parentCode, countryId = CountryId.Colombia }: FetchGeoCommercialParams): Promise<Array<SelectOption>> => (
  makeRemoteGetGeoCommercialStructure()
    .fetch({
      level,
      parentCode,
      countryCode: countryId,
      companyId: 1,
    })
)
