import {
  BadRequestError,
  ForbiddenError,
  NotFoundError,
} from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import {
  HttpPostClient,
  HttpResponse,
  HttpStatusCode,
} from '@/data/protocols/http'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import {
  BFFGetPointData,
  GetLatitudeLongitude,
  GetLatitudeLongitudeParams,
  GetLatitudeLongitudeResponse,
} from '@/domain/use-cases/address/latitude-longitude/get-latitude-longitude'

type GetLatitudeLongitudeRequestHeaders = {
  tenantid: TenantId
  'x-api-key': string
}

type GetLatitudeLongitudeRequestBody = {
  address: BFFGetPointData
}

export class RemoteGetLatitudeLongitude implements GetLatitudeLongitude {
  constructor(
    private readonly httpPostClient: HttpPostClient<
      GetLatitudeLongitudeRequestHeaders,
      GetLatitudeLongitudeRequestBody,
      GetLatitudeLongitudeResponse
    >,
    private readonly xApiKey: string,
  ){}
  
  get = async (params: GetLatitudeLongitudeParams): Promise<GetLatitudeLongitudeResponse> => {
    const { tenantId, address } = params

    this.removeNumberPropertyIfItsNaN(address)
    
    const response = await this.httpPostClient.post({
      url:'/address/get-point',
      headers: {
        tenantid: tenantId,
        'x-api-key': this.xApiKey,
      },
      body: { address },
    })

    return this.handleResponse(response)
  }

  private handleResponse(response: HttpResponse): GetLatitudeLongitudeResponse {
    const { statusCode, body } = response

    switch (statusCode) {
      case HttpStatusCode.ok:
        return body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(body.error)
      case HttpStatusCode.forbidden:
        throw new ForbiddenError(body.error)
      case HttpStatusCode.notFound:
        throw new NotFoundError(body.error)
      default:
        throw new UnexpectedError()
    }
  }

  private removeNumberPropertyIfItsNaN(address: BFFGetPointData): void {
    const isNotANumber = Number.isNaN(address.number)

    if (isNotANumber) {
      delete address.number
    }
  }
}
