import React, { useContext } from 'react'
import { AuthenticatedUserContext, IdentityInfoContext } from '@/main/contexts'
import { useI18n } from '@/main/hooks/useI18n'
import { AddressMessages } from '@/main/pages/address/models'
import { CountryId } from '@/domain/models/country'
import { ChileAddressPage } from '@/main/pages/address/chl/address-page.chl'
import { ColombiaAddressPage } from '../col/address-page.col'
import { ArgentinaAddressPage } from '../arg/address-page.arg'

const addressPageStrategy = (countryId, messages, authenticated) => {
  switch (countryId) {
    case CountryId.Argentina:
      return () => <ArgentinaAddressPage messages={messages} shouldDisplayZipCodeLink={!authenticated} />
    case CountryId.Colombia:
      return () => <ColombiaAddressPage messages={messages} />
    case CountryId.Chile:
      return () => (
        <ChileAddressPage
          messages={messages}
          shouldDisplayAlternativeFlowButton={
            authenticated && process.env.ENABLE_ALTERNATIVE_ADDRESS_FLOW_CHILE === 'true'
          }
        />
      )
    default:
      throw new Error(`${countryId} is not configured`)
  }
}

export const AddressPageWrapper = () => {
  const { countryId } = useContext(IdentityInfoContext)
  const { authenticated } = useContext(AuthenticatedUserContext)
  const { translate } = useI18n()

  const messages: AddressMessages = {
    title: translate('addressPage.title'),
    subtitle: translate('addressPage.subtitle'),
    mainStreet: {
      label: translate('addressPage.mainStreet.label'),
      errorMessage: translate('addressPage.mainStreet.errorMessage'),
    },
    number: {
      label: translate('addressPage.number.label'),
      errorMessage: translate('addressPage.number.errorMessage'),
    },
    complementaryData: {
      label: translate('addressPage.complementaryData.label'),
      errorMessage: translate('addressPage.complementaryData.errorMessage'),
    },
    checkBoxWithoutNumber: translate('addressPage.checkBoxWithoutNumber'),
    referencesHelperText: translate('addressPage.referencesHelperText'),
    references: {
      label: translate('addressPage.references.label'),
      errorMessage: translate('addressPage.references.errorMessage'),
    },
    confirmDeliveryAddressAsResidenceAddress: translate(
      'addressPage.confirmDeliveryAddressAsResidenceAddress'
    ),
    nextButtonLabel: translate('nextButtonLabel'),
    previousButtonLabel: translate('previousButtonLabel'),
    skipButtonLabel: translate('addressPage.skipButtonLabel'),
    alternativeFlowButtonLabel: translate('addressPage.alternativeFlowButtonLabel'),
    alternativeFlowDialogSucess: {
      infoText: translate('addressPage.alternativeFlowDialogSucess.infoText'),
      closeButtonText: translate('addressPage.alternativeFlowDialogSucess.closeButtonText'),
    },
    alternativeFlowDialogFail: {
      infoText: translate('addressPage.alternativeFlowDialogFail.infoText'),
      closeButtonText: translate('addressPage.alternativeFlowDialogFail.closeButtonText'),
    },
    optionalTextLabel: translate('optionalTextLabel'),
    addressRequiredError: translate('requiredErrorMessage'),
    zipCode: {
      label: translate('address.zipCode.label'),
      errorMessage: translate('address.zipCode.digitsError'),
      placeholder: translate('address.placeholders.zipCode'),
      link: {
        text: translate('address.zipCode.link.text'),
        url: translate('address.zipCode.link.url'),
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    geographicalLevels: {
      level1: translate('geographicalLevels.level1'),
      level2: translate('geographicalLevels.level2'),
      level3: translate('geographicalLevels.level3'),
      level4: translate('geographicalLevels.level4'),
      placeholders: {
        level1: translate('geographicalLevels.placeholders.level1'),
        level2: translate('geographicalLevels.placeholders.level2'),
        level3: translate('geographicalLevels.placeholders.level3'),
        level4: translate('geographicalLevels.placeholders.level4'),
      },
    },
    delivery: {
      title: translate('addressPage.delivery.title'),
    },
    placeholders: {
      number: translate('addressPage.placeholders.number'),
      mainStreet: translate('addressPage.placeholders.mainStreet'),
      complementaryData: translate('addressPage.placeholders.complementaryData'),
      references: translate('addressPage.placeholders.references'),
    },
  }

  const AddressPage = addressPageStrategy(countryId, messages, authenticated)

  return AddressPage ? <AddressPage /> : null
}
