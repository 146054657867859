import { Dialog } from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { Icon } from '@/main/components'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { IdentityInfoContext } from '@/main/contexts'
import { companyNameById } from '@/domain/models/company/company-name-by-id'
import { useModalStyles } from './modal.styles'
import WhatsAppIcon from './images/whatsapp-icon.svg'
import EmailIcon from './images/email-icon.svg'
import MessageIcon from './images/message-icon.svg'
import { useBanner } from './banner-context'

export const MgmModal = () => {
  const {
    modal,
    paper,
    titleWrapper,
    title,
    closeModal,
    messagersWrapper,
    messager,
    inputWrapper,
    inputText,
    inputButton,
  } = useModalStyles()
  const { openedModal, closeModal: closeModalAction, openSnackbar } = useBanner()
  const {
    mgmBanner: {
      modal: { title: titleMessage, whatsapp, email, message, welcomeMessage, copyButton },
    },
  } = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const { loginId, countryId, companyId } = useContext(IdentityInfoContext)

  const registerUrl = useMemo(() => {
    const url = new URL(process.env.ACCOUNTS_REGISTER_V3_URL)
    url.searchParams.append('country', countryId)
    url.searchParams.append('company', companyNameById[companyId])
    url.searchParams.append('campaign', 'mgm')
    url.searchParams.append('indicativePersonCode', loginId)

    return url
  }, [])

  const messagers = [
    {
      icon: WhatsAppIcon,
      text: whatsapp,
      href: (() => {
        const url = new URL('https://wa.me/')
        url.searchParams.append('text', `${welcomeMessage}: ${encodeURI(registerUrl.href)}`)

        return url.href
      })(),
    },
    {
      icon: EmailIcon,
      text: email,
      href: `mailto:?body=${welcomeMessage}: ${encodeURIComponent(registerUrl.href)}`,
    },
    {
      icon: MessageIcon,
      text: message,
      href: `sms:?body=${welcomeMessage}: ${encodeURIComponent(registerUrl.href)}`,
    },
  ]

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${welcomeMessage}: ${encodeURI(registerUrl.href)}`)
    openSnackbar()
    closeModalAction()
  }

  return (
    <Dialog
      open={openedModal}
      className={modal}
      PaperProps={{
        className: paper,
      }}
    >
      <div className={titleWrapper}>
        <span className={title}>{titleMessage}</span>
        <Icon
          name="outlined-navigation-close"
          size="tiny"
          onClick={closeModalAction}
          className={closeModal}
        />
      </div>
      <div className={messagersWrapper}>
        {messagers.map(({ icon, text, href }) => (
          <a key={text} className={messager} href={href} target="black" onClick={closeModalAction}>
            <img src={icon} alt={text} />
            <div>{text}</div>
          </a>
        ))}
      </div>
      <div className={inputWrapper}>
        <div className={inputText}>{`${welcomeMessage}: ${encodeURI(registerUrl.href)}`}</div>
        <button type="button" className={inputButton} onClick={copyToClipboard}>
          {copyButton}
        </button>
      </div>
    </Dialog>
  )
}
