import React from 'react'
import { Button, Card, ICardProps } from '@/main/components'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { ActionsLayout } from '@/main/components/Layout/ActionsLayout'
import {
  useContainerStyles,
  ActionButton,
  LoadingContainer,
  CenteredLoadingContainer,
} from '@/main/components/pages-structures/container.styles'

type handleFunctionType = () => void

type ContainerProps = {
  showSkipButton?: boolean
  showAlternativeFlowButton?: boolean
  skipButtonLabel?: string
  onSkipButtonClick?: handleFunctionType
  disableSkipButton?: boolean
  disableAlternativeFlowButton?: boolean
  nextButtonLabel?: string
  onNextButtonClick?: handleFunctionType
  disableNextButton?: boolean
  previousButtonLabel?: string
  alternativeFlowButtonLabel?: string
  onPreviousButtonClick?: handleFunctionType
  onAlternativeFlowButtonClick?: handleFunctionType
  isLoading?: boolean
  nextButtonTextInline?: boolean
  nextButtonDataTestId?: string
} & ICardProps

export const Container: React.FC<ContainerProps> = ({
  nextButtonLabel = '',
  nextButtonDataTestId = 'next-button',
  onNextButtonClick,
  disableNextButton = false,
  previousButtonLabel,
  onPreviousButtonClick,
  showSkipButton = false,
  skipButtonLabel,
  onSkipButtonClick,
  disableSkipButton = false,
  showAlternativeFlowButton = false,
  alternativeFlowButtonLabel,
  onAlternativeFlowButtonClick,
  disableAlternativeFlowButton = false,
  isLoading = false,
  children: cardChildren,
  nextButtonTextInline,
  ...cardProps
}) => {
  const hasPreviousButton = Boolean(previousButtonLabel)
  const classes = useContainerStyles({ hasPreviousButton, showSkipButton, showAlternativeFlowButton, nextButtonTextInline })

  if (isLoading) {
    return (
      <LoadingContainer data-testid="loading-container">
        <CenteredLoadingContainer>
          <ProgressIndicator />
        </CenteredLoadingContainer>
      </LoadingContainer>
    )
  }

  return (
    <>
      <Card className={classes.card} {...cardProps} data-testid="main">
        {showSkipButton && (
          <Button
            key="skip"
            data-testid="skip-button"
            className={classes.skipButton}
            onClick={onSkipButtonClick}
            disabled={disableSkipButton}
          >
            {skipButtonLabel}
          </Button>
        )}
        <div key="card-children" className={classes.cardChildren}>
          {cardChildren}
        </div>
      </Card>
      <ActionsLayout className={classes.actionsLayout} role="navigation" hasPreviousButton={hasPreviousButton}>
        {hasPreviousButton && (
          <ActionButton
            data-testid="previous-button"
            onClick={onPreviousButtonClick}
            color="primary"
            size="large"
            variant="outlined"
            className={classes.previousButton}
          >
            {previousButtonLabel}
          </ActionButton>
        )}
        {showAlternativeFlowButton && (
          <Button
            variant="outlined"
            size="large"
            key="alternativeFlow"
            color="primary"
            data-testid="alternative-flow-button"
            className={classes.alternativeFlowButton}
            onClick={onAlternativeFlowButtonClick}
            disabled={disableAlternativeFlowButton}
          >
            {alternativeFlowButtonLabel}
          </Button>
        )}
        <ActionButton
          data-testid={nextButtonDataTestId}
          className={`${classes.nextButton} ${classes.buttonInline}`}
          onClick={onNextButtonClick}
          disabled={disableNextButton}
          color="primary"
          size="large"
          variant="contained"
        >
          {nextButtonLabel}
        </ActionButton>
      </ActionsLayout>
    </>
  )
}
