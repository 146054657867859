import { RemoteGetAddress } from '@/data/use-cases/address/remote-get-address/remote-get-address'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteGetAddress = (): RemoteGetAddress => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteGetAddress(httpGetClient, xApiKey)
}
