import {
  ADDRESS_STORAGE_NAME,
  AddressStorage,
  CHANNEL_STORAGE_NAME,
  URL_PROFILE_CN,
} from '@/domain/models/address'
import { CountryId } from '@/domain/models/country'
import { MaintenanceAddressCompletedMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { AddressConfigs, ProfileConfigs } from '@/domain/models/tenant/tenant-configs'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import ApprovedIcon from '@/main/assets/svgs/approved_icon_thin.svg'
import { StatusCardContainer } from '@/main/components/status-card-container'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { getObjectInStorage, removeItemInStorage } from '@/main/hooks/useStorage'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { capitalizeWords } from '@/utils/capitalize-words'
import { createUrlAddressMaintenance } from '@/utils/create-url-address-maintenance'
import { Button, Icon } from '@naturacosmeticos/natds-react'
import React, { useContext } from 'react'
import {
  ActionContainer,
  ContentSuccess,
  DescriptionContainer,
  Divider,
  InfoContainer,
  StatusContainer,
  Subtitle,
  SuccessTitle,
  Text,
  Warning,
} from './register-maintenance-address-approved.styles'

export const MaintenanceAddressApproved: React.FC = () => {
  const { personId, companyId, countryId, consultantId } = useContext(IdentityInfoContext)

  const addressStorage = getObjectInStorage(ADDRESS_STORAGE_NAME) as AddressStorage

  const messages = usePageMessages(Page.MaintenanceAddressApproved)
    .messages as MaintenanceAddressCompletedMessages

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]
  const { zipCode, showAlertOnApprovedPage } = useTenantConfigs(tenantId, Page.Address) as AddressConfigs

  const {
    address: { getAddress },
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const redirectToProfile = () => {
    removeItemInStorage(ADDRESS_STORAGE_NAME)
    removeItemInStorage(CHANNEL_STORAGE_NAME)
    if (consultantId === personId) {
      const countries = [CountryId.Peru, CountryId.Colombia, CountryId.Chile, CountryId.Ecuador]
      const url = countries.includes(countryId) ? 'nuevo-perfil' : `mfe-register/profile/${personId}`

      const urlProfile = createUrlAddressMaintenance({
        url,
        country: countryId,
      })

      window.location.assign(urlProfile)
    } else {
      window.location.assign(localStorage.getItem(URL_PROFILE_CN) || '/webfv')
    }
  }

  const address = {
    zipCode: `${addressStorage?.address?.zipCode?.replace(zipCode.regex, zipCode.regexOption) || ''}`,
    streetNumberComplement: capitalizeWords(
      `${addressStorage?.address.street || ''}, ${addressStorage?.address.number || ''}, ${
        addressStorage?.address.complement || ''
      }`
    ),
    neighborhoodCityState: capitalizeWords(
      `${addressStorage?.address.neighborhood || ''}, ${addressStorage?.address.city || ''}, ${
        addressStorage?.address.state || ''
      }`
    ),
    refences: capitalizeWords(`${addressStorage?.address.references || ''}`),
  }

  return (
    <StatusCardContainer>
      <ContentSuccess>
        <img
          alt={messages.approvedIcon}
          src={ApprovedIcon}
          aria-label="approved-image"
          data-testid="approved-image"
        />
        <SuccessTitle variant="h5" className="success_title">
          {messages.title}
        </SuccessTitle>
        <Subtitle>
          <Text align="center" variant="subtitle1" dangerouslySetInnerHTML={{ __html: messages.subtitle }} />
        </Subtitle>
        <div>
          {showAlertOnApprovedPage && (
            <Warning>
              <div className="warning-image">
                <Icon color="neutral900" name="outlined-alert-warning" className="warning-icon" />
              </div>

              <Text className="warning-text" variant="body1" color="textPrimary">
                {messages.warning}
              </Text>
            </Warning>
          )}

          <Divider />
          <Text align="center" variant="body1" color="textSecondary">
            {messages.description}
          </Text>
        </div>
        <DescriptionContainer>
          <InfoContainer>
            <Text variant="body2" className="label" color="textSecondary">
              {messages?.addressLabel}
            </Text>
            {address.zipCode && <Text variant="h6">{address.zipCode}</Text>}
            <Text variant="h6">{capitalizeWords(getAddress(addressStorage.address))}</Text>
            <Text variant="h6" className="reference">
              {address.refences}
            </Text>
          </InfoContainer>
          <StatusContainer>
            <Text className="label" variant="body2" color="textSecondary">
              {messages.statusLabel}
            </Text>
            <Text className="successColor" variant="body2">
              {messages.approvedStatus}
            </Text>
          </StatusContainer>
        </DescriptionContainer>
      </ContentSuccess>
      <ActionContainer>
        <Button onClick={redirectToProfile}>{messages.myProfileButton}</Button>
      </ActionContainer>
    </StatusCardContainer>
  )
}
