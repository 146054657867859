import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'
import { deviceSize } from '@/utils/device-sizes'

export const usePreRegisterStyles = makeStyles<Theme>((theme) => {
  const displayEqualOrSmallerThenMobileXL = theme.breakpoints.down(deviceSize.mobileXL)
  const documentGridItem = {
    [displayEqualOrSmallerThenMobileXL]: {
      padding: theme.spacing(0),
    },
  }

  return {
    documentGridItemSelect: {
      ...documentGridItem,
      paddingRight: theme.spacing(0.5),
    },
    documentGridItemTextField: {
      ...documentGridItem,
      paddingLeft: theme.spacing(0.5),
      height: '100px',
    },
  }
})
