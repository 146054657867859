import {
  IndicationInfo,
  RemoteSearchIndicationParams,
} from '@/data/use-cases/indication/remote-search-indication'
import { CompletePersonalDataParams } from '@/data/use-cases/personal-data/remote-complete-personal-data'
import { GetPersonalDataParams } from '@/data/use-cases/personal-data/remote-get-personal-data'
import { PersonalDataForm } from '@/domain/models/personal-data/personal-data'
import {
  AddressResponse,
  GetAddressParams,
  GetPredictionsParams,
  GetPredictionsResponse,
} from '@/domain/use-cases/address'
import { GetLatitudeLongitudeParams, GetLatitudeLongitudeResponse } from '@/domain/use-cases/address/latitude-longitude/get-latitude-longitude'
import { GetAddressByZipCodeParams, GetAddressByZipCodeResponse } from '@/domain/use-cases/address/zip-code'
import { PersonData } from '@/domain/use-cases/person'
import { makeRemoteGetAddress, makeRemoteGetPredictions } from '@/main/factories/remote/address'
import { makeRemoteGetLatitudeLongitude } from '@/main/factories/remote/address/latitude-longitude'
import { makeRemoteGetAddressByZipCode } from '@/main/factories/remote/address/zip-code'
import { makeRemoteSearchIndication } from '@/main/factories/remote/indication'
import { makeRemoteCompletePersonalData } from '@/main/factories/remote/person-data/make-complete-person-data'
import { makeRemoteGetPersonalData } from '@/main/factories/remote/person-data/make-get-person-data'

export type PersonalDataApi = {
  getIndication: (params: RemoteSearchIndicationParams) => Promise<IndicationInfo>
  getLatitudeLongitude: (params: GetLatitudeLongitudeParams) => Promise<GetLatitudeLongitudeResponse>
  getAddress: (params: GetAddressParams) => Promise<AddressResponse>
  getPredictions: (params: GetPredictionsParams) => Promise<GetPredictionsResponse>
  getAddressByZipCode: (params: GetAddressByZipCodeParams) => Promise<GetAddressByZipCodeResponse>
  getPersonalData: (params: GetPersonalDataParams) => Promise<PersonalDataForm>
  completePersonalData: (params: CompletePersonalDataParams) => Promise<PersonData>
}

export const makePersonalDataApi = (): PersonalDataApi => {
  const remoteGetIndication = makeRemoteSearchIndication()
  const getIndication = (params: RemoteSearchIndicationParams) => remoteGetIndication.search(params)

  const remoteGetLatitudeLongitude = makeRemoteGetLatitudeLongitude()
  const getLatitudeLongitude = (params: GetLatitudeLongitudeParams) => remoteGetLatitudeLongitude.get(params)

  const remoteGetAddress = makeRemoteGetAddress()
  const getAddress = (params: GetAddressParams) => remoteGetAddress.get(params)

  const remoteGetPredictions = makeRemoteGetPredictions()
  const getPredictions = (params: GetPredictionsParams) => remoteGetPredictions.get(params)

  const remoteGetAddressByZipCode = makeRemoteGetAddressByZipCode()
  const getAddressByZipCode = (params: GetAddressByZipCodeParams) => remoteGetAddressByZipCode.get(params)

  const remoteGetPersonalData = makeRemoteGetPersonalData()
  const getPersonalData = (params: GetPersonalDataParams) => remoteGetPersonalData.get(params)

  const remoteCompletePersonalData = makeRemoteCompletePersonalData()
  const completePersonalData = (params: CompletePersonalDataParams) =>
    remoteCompletePersonalData.complete(params)

  return {
    getIndication,
    getLatitudeLongitude,
    getAddress,
    getPredictions,
    getPersonalData,
    completePersonalData,
    getAddressByZipCode,
  }
}
