import { AcceptTerms, AcceptTermsProps } from '@/main/components/accept-terms/accept-terms'
import React from 'react'

type DefaultAcceptTermsProps = AcceptTermsProps

export const DefaultAcceptTermsPreRegister: React.FC<DefaultAcceptTermsProps> = ({
  personId,
  countryId,
  companyId,
  products,
  setAgreementId,
  setUserNeedsToAcceptTerms,
  shouldHide,
  termsInfo,
  checkbox,
  agreements,
  shouldFetch,
  isDialog,
  dialogInfo,
}) => (
  <AcceptTerms
    dialogInfo={dialogInfo}
    shouldFetch={shouldFetch}
    agreements={agreements}
    personId={personId}
    countryId={countryId}
    companyId={companyId}
    products={products}
    setAgreementId={setAgreementId}
    setUserNeedsToAcceptTerms={setUserNeedsToAcceptTerms}
    shouldHide={shouldHide}
    termsInfo={termsInfo}
    checkbox={checkbox}
    isDialog={isDialog}
  />
)
