/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PersonDataResponse } from '@/domain/use-cases/person'
import React, { useCallback, useEffect } from 'react'
import { registerApplication, start } from 'single-spa'

type MFEGrowthPlanProps = {
  personData: PersonDataResponse
  token: string
  country: string
  cycle: number
}

// @ts-ignore
const appImport = () => System.import(process.env.GROWTHPLAN_FE)

const MFEGrowthPlan = ({ personData, token, country, cycle }: MFEGrowthPlanProps) => {
  const initializeMFE = useCallback(() => {
    const domElement = document.getElementById('camino-crecimiento-mfe')
    const name = 'cn-development-growthplan-fe'
    const mfeVersion = '2.0'
    const personCode = personData?.person?.approvedCandidate?.candidateId

    const customProps = {
      domElement,
      version: mfeVersion,
      country,
      user: {
        personCode: Number(personCode),
        accessToken: token,
      },
      structure: {
        cycle: String(cycle),
      },
    }

    registerApplication({
      name,
      app: appImport,
      activeWhen: ['/'],
      customProps,
    })
    start()
  }, [country, cycle, personData?.person?.approvedCandidate?.candidateId, token])

  useEffect(() => {
    initializeMFE()
  }, [initializeMFE])

  return <div id="camino-crecimiento-mfe" />
}

export default MFEGrowthPlan
