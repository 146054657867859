import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { ValidateAttachment } from '@/domain/use-cases/attachment'
import { RemoteValidateAttachment } from '@/data/use-cases/attachment/remote-validate-attachment'

export const makeRemoteValidateAttachment = (): ValidateAttachment => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpClient = new AxiosHttpClient(axiosBaseUrl)
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY

  return new RemoteValidateAttachment(
    httpClient,
    xApiKey,
  )
}
