import { RemoteGetPredictions } from '@/data/use-cases/address/predictions'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteGetPredictions = (): RemoteGetPredictions => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteGetPredictions(httpGetClient, xApiKey)
}
