import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { UploadAttachment } from '@/domain/use-cases/attachment'
import { RemoteUploadAttachment } from '@/data/use-cases/attachment/remote-upload-attachment'

export const makeRemoteUploadAttachment = (): UploadAttachment => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY

  return new RemoteUploadAttachment(
    httpPatchClient,
    xApiKey,
  )
}
