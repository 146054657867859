import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteGetAgreementByProduct } from '@/data/use-cases/agreement/remote-get-agreement-by-product'
import { GetAgreementByProduct } from '@/domain/use-cases/agreement'

export const makeRemoteGetAgreementByProduct = (): GetAgreementByProduct => {
  const apiUrl = '/people/:personId/agreement'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteGetAgreementByProduct = new RemoteGetAgreementByProduct(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteGetAgreementByProduct
}
