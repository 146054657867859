import { useEffect, useState } from 'react'

const MILLISECONDS = 1000
const secondsToMilliseconds = (seconds: number): number => seconds * MILLISECONDS

type UsePollingParams = {
  callback: Function,
  timeoutCallback: Function,
  delay: number,
  timeout: number,
  shouldUsePolling: boolean
}

export const usePolling = ({
  callback = () => {},
  delay = 4.5,
  timeout = 10,
  shouldUsePolling = true,
  timeoutCallback = () => {},
}: UsePollingParams) => {
  const [stop, setStop] = useState(false)

  useEffect(() => {
    function pollingTick() {
      callback()
    }

    let pollingId
    if (delay !== null && !stop && shouldUsePolling) {
      pollingId = setInterval(pollingTick, secondsToMilliseconds(delay))
    }
    return () => clearInterval(pollingId)
  }, [delay, stop, shouldUsePolling, callback])

  useEffect(() => {
    function timeoutTick() {
      setStop(true)
      timeoutCallback()
    }

    let timeOutId
    if (timeout !== null && shouldUsePolling && !stop) {
      timeOutId = setTimeout(timeoutTick, secondsToMilliseconds(timeout))
    }
    return () => clearTimeout(timeOutId)
  }, [timeout, shouldUsePolling, stop])

  return {
    setStop,
    stop,
  }
}
