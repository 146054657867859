import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'

export const useControlledTextFieldWithLinkStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
  row: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  label: {
    flex: 1,
    paddingRight: theme.spacing(1),
  },
  link: {
      marginTop: theme.spacing(-0.5),
      fontSize: 12,
  },
  icon: {
    marginRight: 4,
  },
}))
