import styled from 'styled-components'

export const GridSkeletonContainer = styled.div`
  margin-top: 1.6rem;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1.6rem;
`

export const GridSkeletonField = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  gap: 0.4rem;
`
