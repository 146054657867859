import { DigitalStoreStatusEmailStepMessageOptions } from '@/domain/models/messages/messages.d'
import { DigitalStoreStatusEmailStepMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getDigitalStoreStatusEmailStepMessages(translate: TFunction, options: DigitalStoreStatusEmailStepMessageOptions): DigitalStoreStatusEmailStepMessages {
  return {
    title: translate('digitalStoreStatus.steps.email.title', options),
    helpBot: {
      text: translate('digitalStoreStatus.steps.email.helpBot.text', options),
      link: translate('digitalStoreStatus.steps.email.helpBot.link', options),
      buttonLabel: translate('digitalStoreStatus.steps.email.helpBot.buttonLabel', options),
    },
    hint: translate('digitalStoreStatus.steps.email.hint', options),
    confirmation: {
      question: translate('digitalStoreStatus.steps.email.confirmation.question', options),
      affirmativeAnswer: translate('digitalStoreStatus.steps.email.confirmation.affirmativeAnswer', options),
      negativeAnswer: translate('digitalStoreStatus.steps.email.confirmation.negativeAnswer', options),
    },
  }
}
