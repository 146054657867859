import React from 'react'
import {
  StyledTextParagraph,
  StyledTitleText,
} from './help-information.styles'

type HelpInformationPropType = {
  title: string,
  content: string,
  hasMarginTop?: boolean
}

export const HelpInformation = ({ title, content, hasMarginTop }: HelpInformationPropType) => (
  <>
    <StyledTitleText content={title} hasMarginTop={hasMarginTop} />
    <StyledTextParagraph content={content} lowEmphasis />
  </>
)
