import { TFunction } from 'i18next'
import { IndicationMessages } from '@/domain/models/messages/messages'

export const getIndicationMessages = (translate: TFunction): IndicationMessages => ({
  title: translate('indicationPage.title'),
  subtitle: translate('indicationPage.subtitle'),
  label: translate('indicationPage.label'),
  searchButtonLabel: translate('indicationPage.searchButtonLabel'),
  indicationLabel: translate('indicationPage.indicationLabel'),
  nextButtonLabel: translate('nextButtonLabel'),
  previousButtonLabel: translate('previousButtonLabel'),
  modal: {
    labelCode: translate('indicationPage.modal.labelCode'),
    searchNotFound: translate('indicationPage.modal.searchIndicationNotFound'),
    searchError: translate('indicationPage.modal.searchIndicationError'),
    okButtonLabel: translate('indicationPage.modal.okButtonLabel'),
    cancelButtonLabel: translate('indicationPage.modal.cancelButtonLabel'),
    confirmButtonLabel: translate('indicationPage.modal.confirmButtonLabel'),
    alertTitle: translate('indicationPage.modal.alertTitle'),
    instructionsTitle: translate('indicationPage.modal.instructionsTitle'),
  },
})
