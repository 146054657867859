import styled from 'styled-components'
import { Link } from '@naturacosmeticos/natds-web'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const StyledIntroductionContainer = styled.section`
  background: #E4E4E47B;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: center;

  > img {
    height: 66px;
    border-radius: 4px;
  }
  > span {
    text-align: left;
    padding: 0 16px;
    font-size: 12px
  }

  @media (max-width: 600px) {
    padding: 8px;
    
    > span {
      padding-left: 8px;
    }
  }
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.link};
  cursor: pointer;
  justify-content: center;
  width: 100%;
  display: flex;
  line-height: 1.5;
`

export const StyledContainer = styled.div`
  --marginHorizontal: -16px;
  margin-left: var(--marginHorizontal);
  margin-right: var(--marginHorizontal);
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0 32px;
  

  @media ${minDeviceSizeForMediaQueries.tablet} {
    --marginHorizontal: -64px;
    margin-top: 64px;
    padding: 0 64px;
  }
`
