import { DigitalStoreStatusAccountLinkStepMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'
import { DigitalStoreStatusAccountLinkStepMessageOptions } from '../../../../domain/models/messages/messages.d'

export function getDigitalStoreStatusAccountLinkStepMessages(translate: TFunction, options: DigitalStoreStatusAccountLinkStepMessageOptions): DigitalStoreStatusAccountLinkStepMessages {
  return {
    title: translate('digitalStoreStatus.steps.accountLink.title', options),
    buttonConfirmLabel: translate('digitalStoreStatus.steps.accountLink.buttonConfirmLabel', options),
    hint: {
        text: translate('digitalStoreStatus.steps.accountLink.hint.text', options),
        imageAlt: translate('digitalStoreStatus.steps.accountLink.hint.imageAlt', options),
    },
  }
}
