import { HttpStatusCode } from '@/data/protocols/http'

export class ForbiddenError extends Error {
  statusCode: HttpStatusCode
  data: any

  constructor(data) {
    super()
    this.name = 'ForbiddenError'
    this.statusCode = 403
    this.data = data
  }
}
