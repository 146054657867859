import { RemoteFetchDigitalStoreStatuses } from '@/data/use-cases/digital-store'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteFetchDigitalStoreStatuses = (): RemoteFetchDigitalStoreStatuses => {
  const apiUrl = '/digital-store/fetch-statuses'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteFetchDigitalStoreStatuses = new RemoteFetchDigitalStoreStatuses(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteFetchDigitalStoreStatuses
}
