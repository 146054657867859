import { TFunction } from 'i18next'
import {
  PendingSignatureMessages, PreRegisterMessagesOptions,
} from '@/domain/models/messages/messages'
import { getPreRegisterMessages } from './messages.preRegister'

export const getPendingSignatureMessages = (translate: TFunction, options: PreRegisterMessagesOptions = {
 document: '', min: '', max: '', indicationCode: '', indicationName: '',
}): PendingSignatureMessages => ({
  ...getPreRegisterMessages(translate, options),
  dialog: {
    pendingSignature: translate('preRegister.dialog.pendingSignature'),
    documentNotRegistered: translate('preRegister.dialog.documentNotRegistered'),
    closeButtonText: translate('unexpectedErrorDialog.button'),
    unexpectedError: translate('unexpectedErrorDialog.message'),
  },
})
