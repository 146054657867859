import { DigitalStoreStatusAccountResetStepMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'
import { DigitalStoreStatusAccountResetStepMessageOptions } from '../../../../domain/models/messages/messages.d'

export function getDigitalStoreStatusAccountResetStepMessages(translate: TFunction, options: DigitalStoreStatusAccountResetStepMessageOptions): DigitalStoreStatusAccountResetStepMessages {
  return {
    title: translate('digitalStoreStatus.steps.accountReset.title', options),
    buttonConfirmLabel: translate('digitalStoreStatus.steps.accountReset.buttonConfirmLabel', options),
    accountInformations: {
        emailLabel: translate('digitalStoreStatus.steps.accountReset.accountInformations.emailLabel', options),
        keyLabel: translate('digitalStoreStatus.steps.accountReset.accountInformations.keyLabel', options),
        keyLabelHint: translate('digitalStoreStatus.steps.accountReset.accountInformations.keyLabelHint', options),
    },
    hint: {
        text: translate('digitalStoreStatus.steps.accountReset.hint.text', options),
        imageAlt: translate('digitalStoreStatus.steps.accountReset.hint.imageAlt', options),
    },
  }
}
