import {
  HttpResponse,
  HttpGetParams,
  HttpGetClient,
  HttpPostParams,
  HttpPostClient,
  HttpPatchParams,
  HttpPatchClient,
} from '@/data/protocols/http'
import axios, { AxiosResponse } from 'axios'

export class AxiosHttpClient implements HttpGetClient, HttpPostClient, HttpPatchClient {
  constructor(private readonly baseUrl: string = '') { }

  async get(params: HttpGetParams): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse
    try {
      axiosResponse = await axios.get(this.useUrl(params.url), {
        headers: params.headers,
        params: params.query,
      })
    } catch (error) {
      axiosResponse = error.response
    }
    return this.adapt(axiosResponse)
  }

  async post(params: HttpPostParams): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse
    try {
      axiosResponse = await axios.post(this.useUrl(params.url), params.body, {
        headers: params.headers,
      })
    } catch (error) {
      axiosResponse = error.response
    }
    return this.adapt(axiosResponse)
  }

  async patch(params: HttpPatchParams): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse
    try {
      axiosResponse = await axios.patch(this.useUrl(params.url), params.body, { headers: params.headers })
    } catch (error) {
      axiosResponse = error.response
    }
    return this.adapt(axiosResponse)
  }

  private useUrl(url) {
    return `${this.baseUrl}${url}`
  }

  private adapt(axiosResponse: AxiosResponse): HttpResponse {
    if (axiosResponse) {
      return {
        statusCode: axiosResponse.status,
        body: axiosResponse.data,
      }
    }

    return {
      statusCode: undefined,
      body: undefined,
    }
  }
}
