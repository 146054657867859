import {
  HttpPostClient,
  HttpStatusCode,
} from '@/data/protocols/http'
import {
  UploadAttachment,
  UploadAttachmentHeader,
  UploadAttachmentParams,
  UploadAttachmentResponseBody,
} from '@/domain/use-cases/attachment'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'

export class RemoteUploadAttachment implements UploadAttachment {
  constructor(
    private readonly httpPostClient: HttpPostClient<UploadAttachmentHeader, FormData, UploadAttachmentResponseBody>,
    private readonly xApiKey: string,
  ) {
  }

  async upload(params: UploadAttachmentParams): Promise<UploadAttachmentResponseBody> {
    const {
      personId, tenantId, attachment, attachmentType,
    } = params

    const bodyFormData = new FormData()
    bodyFormData.append('attachment', attachment)
    bodyFormData.append('attachmentType', String(attachmentType))

    const url = `/attachment/upload/${personId}`
    const headers: UploadAttachmentHeader = {
      'Content-Type': 'multipart/form-data',
      'x-api-key': this.xApiKey,
      tenantid: tenantId,
    }

    const response = await this.httpPostClient.post({ url, headers, body: bodyFormData })

    return this.handleResponse(response.statusCode, response.body)
  }

   private handleResponse(statusCode: HttpStatusCode, body: UploadAttachmentResponseBody): UploadAttachmentResponseBody {
    switch (statusCode) {
      case HttpStatusCode.ok:
        return body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(body)
      default:
        throw new UnexpectedError()
    }
  }
}
