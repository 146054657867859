import { Card } from '@/main/components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { Typography } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'

type TextProps = {
  center?: boolean
}

const AlignedToCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ContentSuccess = styled(Card)`
  ${AlignedToCenter}
  margin-top: ${({ theme }) => theme.sizes.semi}px;
  border-radius: ${({ theme }) => theme.sizes.tiny}rem;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin-top: ${({ theme }) => theme.sizes.huge}px;
    padding-top: ${({ theme }) => theme.sizes.semi}px;
    min-width: ${({ theme }) => theme.sizes.veryHuge * 2.3}px;
  }
`

export const SuccessTitle = styled(Typography)`
  &.success_title {
    color: ${({ theme }) => theme.color.success};
    font-weight: bold;
    margin-top: ${({ theme }) => theme.sizes.tiny}px;
    text-align: center;
  }
`

export const Subtitle = styled.div`
  margin-top: ${({ theme }) => theme.sizes.small}px;
`

export const Divider = styled.div`
  margin: 24px 48px;
  border-bottom: 1px solid ${({ theme }) => theme.color.neutral300};
`

export const DescriptionContainer = styled.div`
  width: 100%;
  ${AlignedToCenter}
  align-items: baseline;
  flex-direction: column-reverse;
  padding: ${({ theme }) => theme.sizes.standard}px 0;
  margin-top: ${({ theme }) => theme.sizes.small}px;
  margin-bottom: ${({ theme }) => theme.sizes.medium}px;
  background-color: ${({ theme }) => theme.color.background};

  @media ${minDeviceSizeForMediaQueries.tablet} {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
`

export const Text = styled(Typography)<TextProps>`
  &.reference {
    margin-top: ${({ theme }) => theme.sizes.standard}px;
  }

  &.label {
    margin-bottom: ${({ theme }) => theme.sizes.tiny}px;
    padding: 0;
  }

  &.successColor {
    color: ${({ theme }) => theme.color.success};
  }
`

export const InfoContainer = styled.div`
  max-width: 350px;
  margin-top: ${({ theme }) => theme.sizes.standard}px;
  margin-left: ${({ theme }) => theme.sizes.standard}px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin-top: 0;
  }
`

export const StatusContainer = styled.div`
  margin-left: ${({ theme }) => theme.sizes.standard}px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin: 0;
    text-align: right;
    margin-right: ${({ theme }) => theme.sizes.standard}px;
  }
`

export const ActionContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizes.semi}px;
  margin-bottom: ${({ theme }) => theme.sizes.semi}px;
`

export const Warning = styled.div`
  display: flex;
  max-width: 400px;
  margin-top: ${({ theme }) => theme.sizes.standard}px;
  align-items: center;
  justify-content: center;

  .warning-image {
    background-color: ${({ theme }) => theme.color.primary};
    width: ${({ theme }) => theme.sizes.largeXX}px;
    height: ${({ theme }) => theme.sizes.semiX}px;
    margin-right: ${({ theme }) => theme.sizes.small}px;
    border-radius: 100%;
    display: flex;
    justify-content: center;

    .warning-icon {
      margin-top: ${({ theme }) => theme.sizes.micro}px;
    }
  }
`
