import React from 'react'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { IconPopover } from '@/main/components'
import { getEloToggleByCountry } from '@/utils/getEloToggle'

type SubtitleWithPopoverProps = {
  componentClasses: ClassNameMap,
  subtitle: string,
  popoverText: string,
}

export const SubtitleWithPopover: React.FC<SubtitleWithPopoverProps> = ({ subtitle, popoverText, componentClasses }) => (
  <>
    <span>{subtitle}</span>
    <IconPopover
      iconName="outlined-action-help"
      iconSize="tiny"
      iconColor={getEloToggleByCountry() ? 'default' : 'primary'}
      popoverText={popoverText}
      className={componentClasses.subtitleIcon}
    />
  </>
)
