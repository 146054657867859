import {
  Dialog,
} from '@/main/components/dialog'
import React, { ReactNode } from 'react'
import {
  DialogContent,
} from './signature-dialog.styles'

export interface SignatureDialogProps {
  children: ReactNode
  fullScreen?: boolean
}

const SignatureDialog: React.FC<SignatureDialogProps> = ({
  children,
  fullScreen,
}) => (
  <Dialog
    data-testid="signature-dialog"
    fullScreen={fullScreen}
    open
  >
    <DialogContent className="dialog-content">
      {children}
    </DialogContent>
  </Dialog>
)

export default SignatureDialog
