import { HttpStatusCode } from '@/data/protocols/http'

export class UnprocessableEntityError extends Error {
  statusCode: HttpStatusCode
  data: any

  constructor(data) {
    super()
    this.name = 'UnprocessableEntity'
    this.statusCode = 422
    this.data = data
  }
}
