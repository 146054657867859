import { HttpPatchClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { BadRequestError } from '@/data/protocols/errors'
import {
  AcceptAgreement,
  AcceptAgreementParams,
  AcceptAgreementResponse,
} from '@/domain/use-cases/agreement'

type AcceptAgreementRequestHeaders = {
  'x-api-key': string
}

type AcceptAgreementRequestBody = {
  agreementId: string
}

export class RemoteAcceptAgreement
  implements AcceptAgreement {
  constructor(
    private readonly url: string,
    private readonly httpPatchClient: HttpPatchClient<
      AcceptAgreementRequestHeaders,
      AcceptAgreementRequestBody
    >,
    private readonly xApiKey: string,
  ) { }

  accept = async (
    { personId, agreementId }: AcceptAgreementParams,
  ): Promise<AcceptAgreementResponse> => {
    const headers: AcceptAgreementRequestHeaders = {
      'x-api-key': this.xApiKey,
    }

    const body: AcceptAgreementRequestBody = {
      agreementId,
    }

    const httpResponse = await this.httpPatchClient.patch({
      url: this.url.replace(/:personId/i, personId),
      headers,
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
