import React from 'react'
import styled from 'styled-components'

type CardContentProps = {
  children: JSX.Element | JSX.Element[]
}

const ContentContainer = styled.div`
  padding: 16px;

  &:last-child {
    padding-bottom: 24px;
  }
`

export const CardContent : React.FC<CardContentProps> = ({ children }) => (
  <ContentContainer>
    {children}
  </ContentContainer>
)
