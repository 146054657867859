import styled from 'styled-components'
import { Typography } from '@naturacosmeticos/natds-web'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin-top: 12rem;
  }
`

export const DescriptionTexts = styled(Typography)`
  &.description_loading_text{
    margin: ${({ theme }) => `${theme.sizes.micro}px`} 0;
    font-weight: bold;
  }
`

export const Loader = styled(ProgressIndicator)`
  margin-bottom: ${({ theme }) => `${theme.sizes.semi}px`};
`

export const DurationText = styled(Typography)`
  &.duration_text{
    margin: ${({ theme }) => `${theme.sizes.standard}px`} 0 ${({ theme }) => `${theme.sizes.micro}px`} 0;
  }
`

export const CarouselContainer = styled.div`
  margin: ${({ theme }) => `${theme.sizes.medium}px`} 0;
  padding-top: ${({ theme }) => `${theme.sizes.medium}px`};
  max-width: 280px;
  border-top: 1px solid;
  border-top-color: ${({ theme }) => theme.color.lowEmphasis};

  @media ${minDeviceSizeForMediaQueries.tablet} {
    max-width: 300px;
  }
`
