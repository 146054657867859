import styled from 'styled-components'
import { Button, Typography } from '@/main/components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => `${theme.color.background}`};
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Header = styled.div`
  display: grid;
  height: 5.6rem;
  grid-template-columns: 20% 60% 20%;
  grid-template-areas:
    ". title clearButton";
`

export const Title = styled(Typography)`
  grid-area: title;
  align-self: center;
  justify-self: center;
`

export const ClearButton = styled(Button)`
  grid-area: clearButton;
  align-self: center;
  justify-self: end;
  margin-right: 0.5rem;
`

export const Main = styled.main`
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 6.0rem 0 8.5rem;
`

const DelimiterContainer = styled.div`
  width: 100%;
  max-width: 73.6rem; 
`

export const MainDelimiter = styled(DelimiterContainer)`
  display: flex;
  justify-content: center;
  position: relative;
`

export const SignatureLine = styled.hr`
  width: 85%;
  position: absolute;
  bottom: 30%;
  border: 0.1rem solid ${({ theme }) => `${theme.color.mediumEmphasis}`};
  margin: 0;
`

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 8rem;
  background-color: ${({ theme }) => `${theme.color.onPrimary}`};
  box-shadow: 0 -0.1rem 0.4rem rgba(0, 0, 0, .29);
  position: absolute;
  bottom: 0;
`

export const FooterDelimiter = styled(DelimiterContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
`

export const NavigationButton = styled(Button)`
  width: 100%;
`
