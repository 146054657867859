import React from 'react'

import { StyledCard, StyledCardProps } from './card.styles'

interface Props {
  className?: string
}

type CardProps = StyledCardProps & Props

export const Card: React.FC<CardProps> = (props) => <StyledCard {...props} />
