import { RemoteSaveDigitalStore } from '@/data/use-cases/digital-store'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { SaveDigitalStore } from '@/domain/use-cases/digital-store'

export const makeSaveDigitalStore = ():SaveDigitalStore => {
  const apiUrl = '/digital-store/save/:digitalStoreName'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteSaveDigitalStore = new RemoteSaveDigitalStore(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteSaveDigitalStore
}
