import React from 'react'
import {
  DigitalStorePage,
  DigitalStoreStatusPage,
  DigitalStoreRenamePage,
  PaymentAccountStatusPage,
  DocumentationPage,
  AuthorizationCodePage,
  CompletedWrapper,
  AttachmentsPage,
  PreRegisterPage,
  PersonalDataPage,
  DigitalSignaturePage,
  AddressPageSwitcher,
  PreRegisterConfirmationPage,
  PendingSignaturePage,
  MaintenanceRegisterPage,
  MaintenancePersonalDataPage,
  MaintenanceAddressPage,
  MyProfile,
  MaintenanceAddressApproved,
  MaintenanceAddressRejected,
  MaintenancePhoneEmailApproved,
} from '@/main/pages'
import { Page } from '@/domain/models/page/page'
import { makeDigitalSignaturePageApi } from '@/main/pages/digital-signature/api'
import { makeAttachmentsPageApi } from '@/main/pages/attachments/api/make-attachments-page-api'
import { makeDigitalStorePageApi } from '@/main/pages/digital-store/api/make-digital-store-page-api'
import { makeDocumentationPageApi } from '@/main/pages/documentation/api'
import { makePersonalDataApi } from '@/main/pages/personal-data/api'
import { makeConfirmationApi } from '@/main/pages/confirmation/api'
import { makeAuthorizationCodePageApi } from '@/main/pages/authorization-code/api/make-authorization-code-api'
import { makePreRegisterConfirmationApi } from '@/main/pages/pre-register-confirmation/api/make-pre-register-confirmation-api'
import { makeRegisterMaitenancePageApi } from '@/main/pages/register-maintenance/api/make-register-maintenance-page-api'
import { makeDigitalStoreStatusPageApi } from './pages/digital-store/pages/digital-store-status-page/api/make-digital-store-status-page-api'
import { SignatureProvider } from './pages/digital-signature/context/SignatureContext'
import { makePendingSignaturePageApi } from './pages/pending-signature/api/make-pending-signature-page-api'
import { makePreRegisterPageApi } from './pages/pre-register/api'
import { IndicationPage } from './pages/indication/indication-page'
import { makeIndicationPageApi } from './pages/indication/api/make-indication-page-api'
import { makeAddressPageApi } from './pages/address/api'
import { makeMyProfileDataApi } from './pages/my-profile/api/my-profile-person-data-api'
import { makeRegisterMaintenanceAddressPageApi } from './pages/register-maintenance-address/api/make-register-maintenance-address-page-api'
import { MaintenanceSecurityValidation } from './pages/register-maintenance-security-validation'
import { makeSecurityValidationPageApi } from './pages/register-maintenance-security-validation/api/make-maintenance-security-validation-page-api'
import { makeValidationCodePageApi } from './pages/register-maintenance-validation-code/api/make-maintenance-validation-code-page-api'
import { MaintenanceValidationCodePagePhoneEmail } from './pages/register-maintenance-validation-code/register-maintenance-validation-code-phone-email/maintenance-validation-code-page-phone-email'
import { MaintenanceValidationCodePage } from './pages/register-maintenance-validation-code'
import { MaintenancePhoneEmailRejected } from './pages/register-maintenance-phone-email-rejected'

type PageSwitcherProps = {
  page: Page
  isHostedUIEnabled?: boolean
}

const getPageComponent = (page: Page, isHostedUIEnabled?: boolean) => {
  const pageMapper = {
    [Page.PreRegister]: <PreRegisterPage api={makePreRegisterPageApi()} />,
    [Page.PersonalData]: <PersonalDataPage api={makePersonalDataApi()} />,
    [Page.DigitalSignaturePage]: (
      <SignatureProvider>
        <DigitalSignaturePage api={makeDigitalSignaturePageApi()} />
      </SignatureProvider>
    ),
    [Page.Address]: <AddressPageSwitcher api={makeAddressPageApi()} />,
    [Page.DigitalStore]: <DigitalStorePage api={makeDigitalStorePageApi()} />,
    [Page.Documentation]: <DocumentationPage api={makeDocumentationPageApi()} />,
    [Page.Indication]: <IndicationPage api={makeIndicationPageApi()} />,
    [Page.AuthorizationCode]: (
      <AuthorizationCodePage api={makeAuthorizationCodePageApi({ isHostedUIEnabled })} />
    ),
    [Page.Completed]: <CompletedWrapper api={makeConfirmationApi()} />,
    [Page.PreRegisterConfirmation]: <PreRegisterConfirmationPage api={makePreRegisterConfirmationApi()} />,
    [Page.DigitalStoreBrasil]: <DigitalStoreRenamePage />,
    [Page.DigitalStoreStatus]: <DigitalStoreStatusPage api={makeDigitalStoreStatusPageApi()} />,
    [Page.PaymentAccountStatus]: <PaymentAccountStatusPage />,
    [Page.Attachments]: <AttachmentsPage api={makeAttachmentsPageApi()} />,
    [Page.PendingSignature]: <PendingSignaturePage api={makePendingSignaturePageApi()} />,
    [Page.MaintenanceRegister]: <MaintenanceRegisterPage api={makeRegisterMaitenancePageApi()} />,
    [Page.MaintenancePersonalData]: <MaintenancePersonalDataPage api={makeRegisterMaitenancePageApi()} />,
    [Page.MyProfile]: <MyProfile api={makeMyProfileDataApi()} />,
    [Page.MaintenanceAddress]: <MaintenanceAddressPage api={makeRegisterMaintenanceAddressPageApi()} />,
    [Page.MaintenanceSecurityValidation]: (
      <MaintenanceSecurityValidation api={makeSecurityValidationPageApi()} />
    ),
    [Page.MaintenanceValidationCode]: <MaintenanceValidationCodePage api={makeValidationCodePageApi()} />,
    [Page.MaintenanceValidationCodePhoneEmail]: <MaintenanceValidationCodePagePhoneEmail api={makeValidationCodePageApi()} />,
    [Page.MaintenanceAddressApproved]: <MaintenanceAddressApproved />,
    [Page.MaintenanceAddressRejected]: <MaintenanceAddressRejected />,
    [Page.MaintenancePhoneEmailApproved]: <MaintenancePhoneEmailApproved />,
    [Page.MaintenancePhoneEmailRejected]: <MaintenancePhoneEmailRejected />,
    // When add a page: be sure the component is coming from the path '@/main/pages',
    // otherwise you can't use the describe.each in the unit test
  }
  return pageMapper[page]
}

export const PageSwitcher: React.FC<PageSwitcherProps> = ({ page, isHostedUIEnabled }) => {
  const pageComponent = getPageComponent(page, isHostedUIEnabled)
  if (pageComponent) {
    return pageComponent
  }
  throw new Error(`The ${page} page is not expected in this Micro-Front-End api`)
}
