import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import { createRootReducer, rootSaga } from './ducks'
import { history } from './history'

const sagaMiddleware = createSagaMiddleware()

const reduxStore = createStore(
  createRootReducer(history),
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware),
  ),
)

sagaMiddleware.run(rootSaga)

export const store = reduxStore
