import {
  regexToValidateAllSpecialStreetCharactersForMexico,
  regexToValidateCommonSpecialCharactersForMexico,
} from '@/domain/models/tenant/tenant-configs/helpers'
import { AddressOrder, AddressPageType } from '@/domain/models/address'
import { AgreementProduct } from '../../agreement/agreement-product'
import { Document } from '../../documents/documents'
import { PhoneType } from '../../person'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalSignaturePageConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'
import { AttachmentType } from '../../attachments/attachments'
import { CompanyName } from '../../company/company-name'
import { companyIconName } from '../../company/company-icon-name'

const personalData: PersonalDataConfigs = {
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [/[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 11,
    },
    {
      type: PhoneType.LINE,
      mask: [/[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 10,
    },
  ],
  allowedCharsRegex: new RegExp(/^[a-zA-Z ]+$/),
  allocationStrategy: {
    searchAddress: {},
    searchAddressWithZipCode: {
      shouldGetLocation: true,
      zipCode: {
        rules: {
          required: true,
          maxLength: 8,
          minLength: 8,
          pattern: new RegExp(/^\d{0,8}$/),
        },
        validChars: new RegExp(/^\d{0,8}$/),
        shouldDisplayLink: () => false,
      },
      city: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      neighborhood: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        disabledWhenLocationFound: true,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      cologne: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      complement: {
        validChars: new RegExp(/^[A-Za-z0-9. ]*$/),
        rules: {
          required: false,
          maxLength: 150,
        },
      },
      number: {
        validChars: new RegExp(/^\d+$/),
        rules: {
          required: true,
          maxLength: 15,
        },
        hasCheckboxWithoutAddressNumber: true,
      },
      reference: {
        validChars: new RegExp(/^[A-Za-z0-9. ]*$/),
        rules: {
          required: true,
          maxLength: 40,
        },
      },
      state: {
        validChars: regexToValidateCommonSpecialCharactersForMexico,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
      street: {
        validChars: regexToValidateAllSpecialStreetCharactersForMexico,
        disabledWhenLocationFound: true,
        rules: {
          required: true,
          maxLength: 150,
        },
      },
    },
  },
  shouldUseSeparatedNames: false,
  recommender: {
    shouldDisplay: () => true,
    shouldShowRecommenderByReferralLink: true,
  },
  shouldUsePhoneSelect: false,
  showAsteriskInRequiredLabel: false,
}

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.Cpf,
      name: 'CPF',
      mask: [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
      ],
      required: true,
      rules: {
        pattern: /[\d]*/,
        minLength: 14,
        maxLength: 14,
      },
    },
  ],
  enableIndicationLink: true,
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.Cnh,
      name: 'CNH',
      attachments: [
        { title: 'Frente', type: AttachmentType.CnhFront },
        { title: 'Verso', type: AttachmentType.CnhBack },
      ],
    },
    {
      type: Document.Cie,
      name: 'CIE',
      attachments: [
        { title: 'Frente', type: AttachmentType.CieFront },
        { title: 'Verso', type: AttachmentType.CieBack },
      ],
    },
    {
      type: Document.Rg,
      name: 'RG',
      attachments: [
        { title: 'Frente', type: AttachmentType.RgFront },
        { title: 'Verso', type: AttachmentType.RgBack },
      ],
    },
    {
      type: Document.Crnm,
      name: 'CRNM',
      attachments: [
        { title: 'Frente', type: AttachmentType.CrnmFront },
        { title: 'Verso', type: AttachmentType.CrnmBack },
      ],
    },
  ],
  canChooseDocument: true,
}

const digitalStore: DigitalStoreConfigs = null

const documentation: DocumentationConfigs = {
  agreementProducts: [AgreementProduct.AVON_PRIVACY_POLICY],
  shouldHaveBirthState: false,
}

const digitalSignaturePage: DigitalSignaturePageConfigs = null

const authorizationCode: AuthorizationCodeConfigs = {
  phoneNumberHyphenIndex: 2,
}

const address: AddressConfigs = {
  pageType: AddressPageType.SearchedAddress,
  shouldDisableTheFilledInput: true,
  zipCode: {
    rules: {
      maxLength: 8,
      pattern: new RegExp(/^\d{0,8}$/),
    },
    shouldDisplayLink: () => false,
  },
  reference: {
    maxLength: 40,
  },
  complement: {
    pattern: new RegExp(/^[A-Za-z0-9. ]*$/),
  },
  validateSpecialCharacters: new RegExp(/^[a-zA-Z0-9\\\-()\\#\\_\\/\\:\\,\\. ]*$/),
  validateAllSpecialCharacters: new RegExp(/^[a-zA-Z0-9áéíóúÁÉÍÓÚ'\\\-\\(\\)\\#\\_\\/\\:\\,\\. ]*$/),
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.NEIGHBORHOOD,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ],
  showAlertOnApprovedPage: true,
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = null

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const profile: ProfileConfigs = null

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: false,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  shouldShowIndicativePerson: true,
  shouldShowContactInfos: false,
  shouldShowConfirmationDescription: true,
  goToClickButton: {
    show: false,
  },
  companyIconName: companyIconName[CompanyName.Avon],
}

export const avonBraConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  digitalStore,
  address,
  digitalSignaturePage,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
