import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { Button } from '@/main/components'

export const StyledSuccessInformationContainer = styled.div`
  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin: 0 -64px;
  }
`

export const StyledButtonContainer = styled.div`

  @media ${minDeviceSizeForMediaQueries.tablet} {
    display: flex;
    justify-content: space-between;
  }
`

export const StyledButton = styled(Button)`
  &.payment-account-success-button {
    margin-top: ${({ theme }) => `${theme.sizes.tiny}px`};
  }
  letter-spacing: 0.51px;
  width: 100%;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    width: unset;
    flex: 1;
    &.payment-account-success-button {
      margin: ${({ theme }) => `${theme.sizes.tiny}px`} ${({ theme }) => `${theme.sizes.micro}px`};
    }
  }
`
