import React, { useContext, useState, useEffect, useCallback } from 'react'
import { AgreementProduct } from '@/domain/models/agreement/agreement-product'
import { CheckboxOrder, DocumentationProduct } from '@/domain/models/tenant/tenant-configs'
import { Agreement } from '@/domain/use-cases/agreement'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { IdentityInfoContext, AuthenticatedUserContext } from '@/main/contexts'
import { AcceptTerms } from '@/main/components/accept-terms'
import { makeRemoteGetAgreementByProduct } from '@/main/factories/remote/agreement'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { DocumentationMessages } from '@/domain/models/messages/messages'
import { Control, Controller } from 'react-hook-form'
import { FormControl, Spacing } from '@naturacosmeticos/natds-web'
import { StyledFormItem } from '../documentation-page.styles'

type AgreementRender = {
  checkboxOrder: CheckboxOrder
  agreement: Agreement[]
  shouldOpenInitialDialog?: boolean
}
type AgreementCheckBox = AgreementRender[]

type AgreementConfigsOrder = {
  product: AgreementProduct
  shouldOpenInitialDialog?: boolean
}
type HandleGetAgreementByProduct = {
  products: DocumentationProduct[]
}
type HandleSetAgreementsCheckBoxAndData = {
  agreements?: Agreement[]
  products?: DocumentationProduct[]
  openedCheckboxDialog?: {
    first: boolean
    second: boolean
  }
}
type Agreements = {
  checkbox: AgreementCheckBox
  data: string[] | number[]
}

const agreementsInitialState: Agreements = {
  checkbox: null,
  data: null,
}

type PropsTerms = {
  agreement: HandleSetAgreementsCheckBoxAndData
  shouldAcceptTermsWhenAuthenticated: boolean
  control: Control<Record<string, any>>
  messages: DocumentationMessages
  setProductsAgreementsId: (id: string[]) => void
}

export const AcceptTermsProducts: React.FC<PropsTerms> = ({
  agreement,
  shouldAcceptTermsWhenAuthenticated,
  control,
  messages,
  setProductsAgreementsId,
}) => {
  const { personId, countryId, companyId, tenantId } = useContext(IdentityInfoContext)
  const [agreements, setAgreements] = useState<Agreements>(agreementsInitialState)
  const isMex = tenantId === TenantId.NaturaMEX
  const { authenticated } = useContext(AuthenticatedUserContext)
  const shouldAcceptTerms = !authenticated || shouldAcceptTermsWhenAuthenticated
  const handleSetAgreementsCheckboxAndData = useCallback(
    ({ agreements, products, openedCheckboxDialog }: HandleSetAgreementsCheckBoxAndData) => {
      const agreementsDocumentsIds = agreements.map((agreement) => agreement.agreementId)
      const firstAgreements: AgreementConfigsOrder[] = products.map(
        ({ checkboxOrder, name }) =>
          checkboxOrder === 'first' && {
            product: name,
          }
      )
      const secondAgreements: AgreementConfigsOrder[] = products.map(
        ({ checkboxOrder, name }) =>
          checkboxOrder === 'second' && {
            product: name,
          }
      )

      const first = []
      firstAgreements.forEach((firstAgreement) => {
        const agreement = agreements.find((agreement) => agreement.product === firstAgreement.product)
        if (agreement) {
          first.push(agreement)
        }
      })

      const second = []

      secondAgreements.forEach((secondAgreement) => {
        const agreement = agreements.find((agreement) => agreement.product === secondAgreement.product)
        if (agreement) {
          second.push(agreement)
        }
      })

      const agreementCheckBox: AgreementCheckBox = [
        {
          checkboxOrder: 'first',
          agreement: first,
          shouldOpenInitialDialog: openedCheckboxDialog.first,
        },
        {
          checkboxOrder: 'second',
          agreement: second,
          shouldOpenInitialDialog: openedCheckboxDialog.second,
        },
      ]
      if (agreementsDocumentsIds) {
        setProductsAgreementsId(agreementsDocumentsIds)
      }
      setAgreements({ checkbox: agreementCheckBox, data: agreementsDocumentsIds })
    },
    [setProductsAgreementsId]
  )
  const getAgreementsProducts = useCallback(
    async ({ products }: HandleGetAgreementByProduct) => {
      const productNames = products.map((product) => product.name)
      const remoteGetAgreementByProduct = makeRemoteGetAgreementByProduct()
      let agreementResponse: Agreement[]
      try {
        const { agreements } = await remoteGetAgreementByProduct.get({
          personId,
          countryId,
          products: productNames,
          companyId,
        })
        agreementResponse = agreements
      } catch {
        console.warn('Error get terms')
      }
      return agreementResponse
    },
    [personId, countryId, companyId]
  )

  const handleSetAgreements = useCallback(async () => {
    const { products, openedCheckboxDialog } = agreement
    const agreementsResponse = await getAgreementsProducts({ products })
    handleSetAgreementsCheckboxAndData({
      agreements: agreementsResponse,
      products,
      openedCheckboxDialog,
    })
  }, [agreement, getAgreementsProducts, handleSetAgreementsCheckboxAndData])
  useEffect(() => {
    if (isMex) {
      handleSetAgreements()
    }
  }, [handleSetAgreements, isMex])
  return (
    <>
      <StyledFormItem gridArea="terms">
        <FormControl>
          {!agreements.checkbox && shouldAcceptTerms ? (
            <Spacing marginTop="small" mt="micro">
              <ProgressIndicator data-testid="accept-terms-loading" size={32} />
            </Spacing>
          ) : (
            agreements.checkbox.map(({ checkboxOrder, agreement, shouldOpenInitialDialog }) => (
              <Controller
                key={`${checkboxOrder}key`}
                name={`${checkboxOrder}Checkbox`}
                control={control}
                rules={{ required: messages.requiredError }}
                render={({ onChange, onBlur, value, ref }) => (
                  <AcceptTerms
                    agreements={agreement}
                    isDialog={false}
                    shouldFetch={false}
                    shouldHide={!agreement}
                    initialDialogState={shouldOpenInitialDialog}
                    dialogInfo={{
                      title: messages.modal.title,
                      acceptText: messages.modal.titleAcceptLabel,
                      closeText: messages.modal.titleCloseLabel,
                    }}
                    checkbox={{
                      color: 'secondary',
                      normalLabel: messages.agreementsCheckbox[checkboxOrder].normalText,
                      highlightedLabel: messages.agreementsCheckbox[checkboxOrder].highlightedText,
                      onChange: (e) => {
                        onBlur()
                        onChange(e)
                      },
                      onBlur,
                      value,
                      inputRef: ref,
                    }}
                  />
                )}
              />
            ))
          )}
        </FormControl>
      </StyledFormItem>
    </>
  )
}
