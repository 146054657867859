import { PaymentAccountStatusHeaderMessageOptions } from '@/domain/models/messages/messages.d'
import { PaymentAccountStatusMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getPaymentAccountStatusMessages(translate: TFunction, options: PaymentAccountStatusHeaderMessageOptions): PaymentAccountStatusMessages {
  return {
    contactInfoHelp: translate('paymentAccountStatus.contactInfoHelp', options),
    contactInfoHelpTitle: translate('paymentAccountStatus.contactInfoHelpTitle', options),
    error: {
      title: translate('paymentAccountStatus.error.title', options),
      subtitle: translate('paymentAccountStatus.error.subtitle', options),
    },
    notLinked: {
      title: translate('paymentAccountStatus.notLinked.title', options),
      subtitle: translate('paymentAccountStatus.notLinked.subtitle', options),
    },
    success: {
      title: translate('paymentAccountStatus.success.title', options),
      subtitle: translate('paymentAccountStatus.success.subtitle', options),
    },
  }
}
