import styled from 'styled-components'

export const StyledFormItem = styled.div<{ gridArea?: string; flex?: boolean }>`
  grid-area: terms;
  ${(props) =>
    props.flex
      ? `
    display: flex;
    align-items: center;
  `
      : ''}
`
