import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteGetStatusWorkflow } from '@/data/use-cases/register-maintenance-security-validation/status-workflow'

export const makeRemoteGetStatusWorkflow = (): RemoteGetStatusWorkflow => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY

  return new RemoteGetStatusWorkflow(
    httpGetClient,
    xApiKey
  )
}
