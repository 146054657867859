import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'
import { deviceSize } from '@/utils/device-sizes'

export const usePhoneFieldsStyles = makeStyles<Theme>((theme) => {
  const displayEqualOrWiderThenTablet = theme.breakpoints.up(deviceSize.tablet)

  return {
    formRow: {
      [displayEqualOrWiderThenTablet]: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
    },
    phoneTypeInput: {
      [displayEqualOrWiderThenTablet]: {
        paddingRight: theme.spacing(1),
        width: '100%',
      },
    },
    phoneNumberInput: {
      [displayEqualOrWiderThenTablet]: {
        width: '100%',
      },
    },
    phoneNumberInputWithSelect: {
      [displayEqualOrWiderThenTablet]: {
        paddingLeft: theme.spacing(1),
        width: '100%',
        paddingTop: '16px',
      },
    },

  }
})
