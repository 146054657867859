import { ParseStringToReact } from '@/main/components/string-to-react'
import { FVTextField, useControlledTextFieldStyles } from '@/main/components/text-field/controlled-text-field.styles'
import { getLabel } from '@/main/components/text-field/helper/get-label'
import { CustomChangeEvent, handleOnChange } from '@/main/components/text-field/helper/handle-onchange'
import { Typography } from '@naturacosmeticos/natds-web'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import React, { FocusEvent } from 'react'
import { Controller, ControllerProps, FieldErrors } from 'react-hook-form'

import { getFinalErrorMessage, StringOrObjectOfStrings } from './helper/error-message-getter'
import { validateIsFilled } from './helper/validate-is-filled'

export type ControlledTextFieldProps = {
  id: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  infoMessage?: StringOrObjectOfStrings
  validChars?: RegExp
  infoText?: string
  mask?: Mask
  errors?: FieldErrors
  showAsterisk?: boolean
  optionalText?: string
  isUpperCase?: boolean
  hasBlankLabel?: boolean
  icon?: Object
  type?: string
  shouldValidateIsNotOnlySpaces?: boolean
  state?: string
  customOnChange?: (inputValue: string) => void
  customOnBlur?: (inputValue: string) => void
} & Pick<ControllerProps<'input'>, 'rules' | 'control'>

export const ControlledTextField: React.FC<ControlledTextFieldProps> = ({
  id,
  control,
  label,
  placeholder,
  required,
  disabled,
  rules,
  infoMessage,
  validChars,
  infoText,
  mask,
  state,
  errors,
  showAsterisk = true,
  optionalText,
  isUpperCase = false,
  hasBlankLabel,
  icon,
  shouldValidateIsNotOnlySpaces = false, // this property should be used only when the field is required and has no other validations!
  customOnChange,
  customOnBlur,
}) => {
  if (required && shouldValidateIsNotOnlySpaces) {
    rules = { ...rules, validate: validateIsFilled }
    infoMessage = rules.required as string
  }
  const controlledTextFieldClasses = useControlledTextFieldStyles()

  const finalErrorMessage = getFinalErrorMessage(errors, infoMessage, id)
  const labelFormatted = getLabel({
    labelText: label,
    showAsterisk,
    required,
    optionalText,
    hasBlankLabel,
  })

  return (
    <div className={controlledTextFieldClasses.container}>
      <Controller
        name={id}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ onBlur, onChange, value, name, ref }) => (
          <>
            <FVTextField
              ref={ref}
              onBlur={(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                onBlur()
                if (customOnBlur) {
                  customOnBlur(event.target.value)
                }
              }}
              onChange={(event: CustomChangeEvent) => {
                handleOnChange({
                  event,
                  onChange,
                  validChars,
                  isUpperCase,
                })
                if (customOnChange) {
                  customOnChange(event.target.value)
                }
              }}
              value={value}
              name={name}
              id={id}
              data-testid={id}
              label={labelFormatted}
              placeholder={placeholder}
              disabled={disabled}
              state={state}
              helpText={finalErrorMessage}
              mask={mask}
              icon={icon}
            />
            {infoText && (
              <Typography variant="caption" color="textSecondary">
                <ParseStringToReact stringToParse={infoText} />
              </Typography>
            )}
          </>
        )}
      />
    </div>
  )
}
