import React, { useContext } from 'react'
import { useI18n } from '@/main/hooks/useI18n'
import { AuthenticatedUserContext } from '@/main/contexts'
import { Pending } from './pending'

export type PendingMessages = {
  title: string,
  subtitle: string,
  defaultInstructions: {
    description: string
    helpDescription: string
  },
  authenticatedInstructions: {
    description: string,
    helpDescription: string
  },
  helpTitle: string,
  helpAnchorText: string,
  helpHref: string,
  iconAlts: {
    clockIcon: string
  }
}

export const PendingDescriptionKey = {
  authenticated: 'authenticatedInstructions',
  default: 'defaultInstructions',
}

export const PendingWrapper = () => {
  const { translate } = useI18n()
  const {
    authenticated,
  } = useContext(AuthenticatedUserContext)

  const getDescriptionTranslationKey = () => {
    if (authenticated) {
      return PendingDescriptionKey.authenticated
    }
    return PendingDescriptionKey.default
  }
  const descriptionTranslationKey = getDescriptionTranslationKey()

  const messages: PendingMessages = {
    title: translate('completedPage.pending.title'),
    subtitle: translate('completedPage.pending.subtitle'),
    defaultInstructions: {
      description: translate('completedPage.pending.defaultInstructions.description'),
      helpDescription: translate('completedPage.pending.defaultInstructions.helpDescription'),
    },
    authenticatedInstructions: {
      description: translate('completedPage.pending.authenticatedInstructions.description'),
      helpDescription: translate('completedPage.pending.authenticatedInstructions.helpDescription'),
    },
    helpTitle: translate('completedPage.pending.helpTitle'),
    helpAnchorText: translate('completedPage.pending.helpAnchorText'),
    helpHref: translate('completedPage.pending.helpHref'),
    iconAlts: {
      clockIcon: translate('completedPage.pending.iconAlts.clockIcon'),
    },
  }

  return <Pending messages={messages} descriptionTranslationKey={descriptionTranslationKey} />
}
