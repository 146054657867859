import React from 'react'

export type AuthenticatedUserProps = {
  authenticated?: boolean
  sellerId?: string
  isAttendance?: boolean
  userRole?: string
}

export const AuthenticatedUserContext = React.createContext<AuthenticatedUserProps>({})
