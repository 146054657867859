import styled from 'styled-components'
import { makeStyles } from '@naturacosmeticos/natds-web'

export const useDigitalStoreRenamePageStyles = makeStyles((theme) => ({
  cardHeader: {
    display: 'block',
    padding: 0,
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  subtitle: {
    marginTop: theme.spacing(3),
  },
  form: {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: theme.spacing(6),
  },
  formControl: {
    marginRight: theme.spacing(),
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -theme.spacing(1.5),
  },
  tooltip: {
    padding: theme.spacing(2),
  },
  tooltipArrow: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #00000014',
    boxShadow: '0px 9px 12px #00000024',
  },
  tooltipIcon: {
    marginLeft: theme.spacing(),
  },
  hintText: {
    paddingTop: theme.spacing(),
  },
  verifyButton: {
    height: theme.spacing(7),
  },
}))

export const Content = styled.div`
  display: grid;
  grid-row-gap: ${(props) => props.theme.spacing * 2}px;
`
