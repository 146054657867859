import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { SendAuthorizationCode } from '@/domain/use-cases/authorization-code/authorization-code'
import { RemoteSendAuthorizationCode } from '@/data/use-cases/authorization-code'

interface MakeRemoteSendAuthorizationCodeParams {
  shouldRequestWithApiKey: boolean
}

export const makeRemoteSendAuthorizationCode = ({ shouldRequestWithApiKey }: MakeRemoteSendAuthorizationCodeParams): SendAuthorizationCode => {
    const axiosBaseUrl = shouldRequestWithApiKey ? process.env.REGISTERS_BFF_URL : process.env.REGISTERS_BFF_URL_AUTHENTICATED
    const xApiKey = shouldRequestWithApiKey ? process.env.REGISTERS_BFF_X_API_KEY : null
    const httpPostClient = new AxiosHttpClient(axiosBaseUrl)

    return new RemoteSendAuthorizationCode(
      { httpPostClient, xApiKey },
    )
}
