import { useEffect, useState } from 'react'
import { buildTheme, Theme } from '@naturacosmeticos/natds-react'
import { CompanyId } from '@/domain/models/company/company-id'
import { companyNameById } from '@/domain/models/company/company-name-by-id'
import { ColorScheme } from '@/domain/models/theme-ds/color-scheme'
import { getEloToggleByCountry } from '@/utils/getEloToggle'

const BEAUTY_CONSULTANT = 'consultoriaDeBeleza'

export const useFVTheme = (companyId: CompanyId): Theme => {
  const company = companyNameById[companyId]
  const defaultTheme = buildTheme(company, ColorScheme.LIGHT)
  const [fvTheme, setTheme] = useState<Theme>(defaultTheme)
  

  useEffect(() => {
    let newTheme = buildTheme(company, ColorScheme.LIGHT)
    if (getEloToggleByCountry()) {
      const beautyConsultantTheme = buildTheme(BEAUTY_CONSULTANT, ColorScheme.LIGHT)
      newTheme = beautyConsultantTheme
    }

    if (company === 'avon'){
      const beautyConsultantTheme = buildTheme('avon_v2', ColorScheme.LIGHT)
      newTheme = beautyConsultantTheme
    }
    setTheme(newTheme)
  }, [company])

  return fvTheme
}
