import { Button } from '@/main/components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@/main/components/dialog'
import { getEloToggleByCountry } from '@/utils/getEloToggle'
import React, { useEffect, useState } from 'react'

enum DialogId {
  infoText = 'dialog-content-info-text'
}

export interface DialogAlertProps {
  show?: boolean,
  showReturnFirstPageButton?: boolean
  goNextPage?: boolean
  messages?: {
    infoText: string,
    closeButtonText: string
    returnFirstPageButtonText?: string
  },
  closeDialogCallback?: () => void
  returnFirstPageCallback?: () => void
  nextPageCallback?: () => void
}

const showDialogInitialState = false

export const DialogAlert: React.FC<DialogAlertProps> = ({
  show,
  showReturnFirstPageButton = false,
  goNextPage = false,
  messages,
  closeDialogCallback = () => {},
  returnFirstPageCallback = () => {},
  nextPageCallback = () => {},
}) => {
  const [showDialog, setShowDialog] = useState(showDialogInitialState)

  const onClose = () => {
    setShowDialog(false)
    closeDialogCallback()
  }

  const onReturnFirstPage = () => {
    setShowDialog(false)
    returnFirstPageCallback()
  }

  const onNextPage = () => {
    setShowDialog(false)
    nextPageCallback()
  }

  useEffect(() => {
    if (show) {
      setShowDialog(show)
    }
  }, [show])

  return (
    <Dialog
      aria-describedby={DialogId.infoText}
      maxWidth="xs"
      open={showDialog}
      onEscapeKeyDown={onClose}
      onBackdropClick={onClose}
    >
      <DialogContent>
        <DialogContentText id={DialogId.infoText}>
          {messages.infoText}
        </DialogContentText>
        <DialogActions>
          {showReturnFirstPageButton && (
          <Button
            color="primary"
            variant={getEloToggleByCountry() ? 'outlined' : 'contained'}
            onClick={onReturnFirstPage}
          >
            {messages.returnFirstPageButtonText}
          </Button>
          )}

          {!goNextPage ? (
            <Button
              color="primary"
              variant={getEloToggleByCountry() ? 'outlined' : 'contained'}
              onClick={onClose}
            >
              {messages.closeButtonText}
            </Button>
          ) :           (
            <Button
              color="primary"
              variant={getEloToggleByCountry() ? 'outlined' : 'contained'}
              onClick={onNextPage}
            >
              {messages.closeButtonText}
            </Button>
)}
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
