import { CountryIdAlpha2 } from '@/domain/models/country'
import React from 'react'
import { PaymentAccountStatusDefault } from './payment-account-status-default/payment-account-status-default'
import { PaymentAccountStatusSuccess } from './payment-account-status-success/payment-account-status-success'

const countriesWithPaymentAccountStatusPage = [
  CountryIdAlpha2.Peru,
]

const PaymentAccountStatus = {
  SUCCESS: 'success',
  ERROR: 'error',
  NOT_LINKED: 'not-linked',
}

const PaymentAccountStatusComponent = {
  [PaymentAccountStatus.SUCCESS]: <PaymentAccountStatusSuccess />,
  [PaymentAccountStatus.ERROR]: <PaymentAccountStatusDefault config={{ status: 'error' }} />,
  [PaymentAccountStatus.NOT_LINKED]: <PaymentAccountStatusDefault config={{ status: 'not-linked' }} />,
}

export const PaymentAccountStatusPage: React.FC = () => {
  const isCountryWithPaymentAccountStatusPage = (countryId): boolean => countriesWithPaymentAccountStatusPage.includes(countryId.toUpperCase())

  const pathnameParts = window.location.pathname.split('/')
  const countryId = pathnameParts[1]
  const status = pathnameParts[3]

  return isCountryWithPaymentAccountStatusPage(countryId) ? <>{PaymentAccountStatusComponent[status]}</> : null
}
