import { HttpPostClient, HttpStatusCode } from '@/data/protocols/http'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'

export type RemoteCompleteIndicationParams = {
  companyId: CompanyId
  countryId: CountryId
  personId: string
  introducerSellerId: string
}

export type HttpClientHeaders = {
  'x-api-key': string
  companyId: CompanyId
  countryId: CountryId
  authorization: string
}

export type HttpPostClientBody = {
  introducerSellerId: string
}

export type CompleteIndicationResponse = {
  personId: string
  additionalInformation: {
    introducerSellerId: string
  }
}

export type HttpPostClientResponse = {
  data: CompleteIndicationResponse
}

export class RemoteCompleteIndication {
  constructor(
    private readonly httpPostClient: HttpPostClient<
      HttpClientHeaders,
      HttpPostClientBody,
      HttpPostClientResponse
    >,
    private readonly xApiKey: string,
  ) {}

  async complete(params: RemoteCompleteIndicationParams): Promise<CompleteIndicationResponse> {
    const response = await this.httpPostClient.post({
      url: `/indication/complete/${params.personId}`,
      headers: {
        'x-api-key': this.xApiKey,
        companyId: params.companyId,
        countryId: params.countryId,
        authorization: this.xApiKey,
      },
      body: {
        introducerSellerId: params.introducerSellerId,
      },
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body.data
      case HttpStatusCode.badRequest:
        throw new Error('tenant not found')
      default:
        throw new Error('completeError')
    }
  }
}
