import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { UploadValidateAttachment } from '@/domain/use-cases/attachment'
import { RemoteUploadValidateAttachment } from '@/data/use-cases/attachment/remote-upload-validate-attachment'

export const makeRemoteUploadValidateAttachment = (): UploadValidateAttachment => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY

  return new RemoteUploadValidateAttachment(
    httpPatchClient,
    xApiKey,
  )
}
