import styled from 'styled-components'
import { Icon } from '@naturacosmeticos/natds-web'

export const CheckIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.success};
`

export const SuccessText = styled.div`
  color: ${({ theme }) => theme.color.success};
`
