import { CompanyId } from '@/domain/models/company/company-id'
import { getObjectInStorage } from '@/main/hooks/useStorage'

export const getEloToggleByCountryMyProfile = () => {
  const eloCountries = process.env.ENABLE_THEME_ELO_TO_COUNTRY

  const availableCountries = eloCountries.split(',')

  const naturaRegister = getObjectInStorage('@naturaRegister:auth') as any

  return naturaRegister?.country && availableCountries.includes(naturaRegister.country) && naturaRegister?.companyId === CompanyId.Natura
}
