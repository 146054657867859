import { Document } from '@/domain/models/documents/documents'
import { PendingSignatureMessages, PreRegisterMessages, DocumentationMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { Documents, PreRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import {
  CardContent,
  CardHeader,
  ControlledSelect,
  ControlledTextField,
  FormControl,
  Typography,
} from '@/main/components'
import { ExternalLink } from '@/main/components/external-link/external-link'
import { Container } from '@/main/components/pages-structures'
import { AuthenticatedUserContext, IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { GridContainer, GridItem, Spacing } from '@naturacosmeticos/natds-web'
import React, { useContext, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { usePreRegisterStyles } from '../pre-register/pre-register.styles'
import { createPhoneNumberLengthValidation, PhoneFields } from './fields/index'
import { DefaultAcceptTermsPreRegister as AcceptTerms } from './fields/accept-terms-default/default-accept-terms'
import { StyledFormItem } from './initial-document-page.styles'

type FormInputs = {
  curp: string
  phoneNumber?: string
}

export type FormParsedValues = {
  documents: {
    type: Document
    document: string
  }[]
  phoneNumber: string
}

const showLoadingInitialState = false

type IndicationParams = {
  name: string
  code: string
  isRegisterPage?: boolean
}

type PreRegisterProps = {
  page: Page
  onSubmit: (values: FormParsedValues) => Promise<void>
  indicationParams?: IndicationParams
  HasPhoneNumber?: JSX.Element
}

export const InitialDocumentPage: React.FC<PreRegisterProps> = ({
  page = Page.PreRegister,
  onSubmit,
  indicationParams,
}) => {
  const messages = usePageMessages(Page.Documentation).messages as DocumentationMessages
  const beforeHighlightedText = messages.checkboxLabel?.split(messages?.checkboxLabelLink)
  const { tenantId } = useContext(IdentityInfoContext)
  const { authenticated } = useContext(AuthenticatedUserContext)

  const preRegisterClasses = usePreRegisterStyles()

  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
  })

  const { getValues, formState, control, errors } = formMethods

  const { link: linkConfig, documents, phones, shouldUsePhoneSelect, shouldAcceptTerms } = useTenantConfigs(
    tenantId,
    Page.PreRegister
  ) as PreRegisterConfigs

  const { phone } = usePageMessages(Page.PreRegister).messages as PreRegisterMessages

  const hasManyDocuments = documents.length > 1
  const [showLoading, setShowLoading] = useState(showLoadingInitialState)
  const [documentSelected, setDocumentSelected] = useState<Documents>(documents[0])
  const [userNeedsToAcceptTerms, setUserNeedsToAcceptTerms] = useState(true)

  const ALL_COLUMNS = 12
  const HALF_OF_THE_COLUMNS = 6
  const documentGridItemColumnSize = hasManyDocuments ? HALF_OF_THE_COLUMNS : ALL_COLUMNS

  const {
    nextButtonLabel,
    title,
    subtitle,
    document: documentMessages,
    selectType,
    link: linkMessage,
    indicationMessage,
  } = usePageMessages(page, {
    document: documentSelected.placeholder,
    min: documentSelected.rules.minLength,
    max: documentSelected.rules.maxLength,
    indicationCode: indicationParams?.code,
    indicationName: indicationParams?.name,
  }).messages as PreRegisterMessages | PendingSignatureMessages

  const parsedFormValues = (): FormParsedValues => {
    const values = getValues()
    return {
      documents: [
        {
          type: documentSelected.type,
          document: values[`document-${documentSelected.type}`],
        },
      ],
      phoneNumber: values.phoneNumber,
    }
  }

  const handleSetDocumentSelected = ({ value }) => {
    const document = documents.find((document) => document.type === value)
    setDocumentSelected(document)
  }

  const handleSubmit = async () => {
    setShowLoading(true)
    await onSubmit(parsedFormValues())
    setShowLoading(false)
  }

  const validateField = (value: string) => {
    if (documents[0].type === Document.Cpf) {
      value = value.trim().replace(/[^a-zA-Z0-9 ]/g, '')
      const validateMinLength = value.length + 3 === documentSelected.rules.minLength
      return validateMinLength ? true : documentMessages.rules.pattern
    }

    return (
      !documentSelected.rules.validate ||
      documentSelected.rules.validate(value) ||
      documentMessages.rules.pattern
    )
  }

  const formatPhoneOptions = () => {
    return phones.map((phone) => ({
      ...phone,
      phoneType: phone.type,
      validations: {
        firstDigit: phone.firstDigitValidation ? phone.firstDigitValidation : () => true,
        length: createPhoneNumberLengthValidation(phone.length),
      },
    }))
  }
  return (
    <Container
      nextButtonLabel={nextButtonLabel}
      onNextButtonClick={handleSubmit}
      isLoading={showLoading}
      disableNextButton={!formState.isValid}
    >
      <CardHeader title={title} subtitle={subtitle} />
      <CardContent>
        <FormProvider {...formMethods}>
          <GridContainer>
            {hasManyDocuments && (
              <GridItem lg={6} md={6} sm={6} xs={12} className={preRegisterClasses.documentGridItemSelect}>
                <ControlledSelect
                  id="selectType"
                  name="selectType"
                  data-testid="selectTypeTest"
                  label={selectType.label}
                  aria-label={selectType.label}
                  defaultValue={{
                    value: documents[0].type,
                    label: documents[0].name,
                  }}
                  options={documents.map((document) => ({
                    value: document.type,
                    label: document.name,
                  }))}
                  control={control}
                  onChange={handleSetDocumentSelected}
                />
              </GridItem>
            )}

            <GridItem
              lg={documentGridItemColumnSize}
              md={documentGridItemColumnSize}
              sm={documentGridItemColumnSize}
              xs={12}
              className={preRegisterClasses.documentGridItemTextField}
            >
              <ControlledTextField
                id={`document-${documentSelected.type}`}
                key={`document-${documentSelected.type}`}
                validChars={documentSelected.validChars}
                mask={documentSelected.mask}
                label={documentMessages.label}
                hasBlankLabel={hasManyDocuments}
                placeholder={documentMessages.placeholder}
                control={control}
                rules={{
                  required: documentMessages.rules.required,
                  pattern: {
                    value: documentSelected.rules.pattern,
                    message: documentMessages.rules.pattern,
                  },
                  minLength: {
                    value: documentSelected.rules.minLength,
                    message: documentMessages.rules.minLength,
                  },
                  maxLength: {
                    value: documentSelected.rules.maxLength,
                    message: documentMessages.rules.maxLength,
                  },
                  validate: (value: string) => validateField(value),
                }}
                isUpperCase={documentSelected.isUpperCase}
              />
            </GridItem>

            {linkConfig && (
              <ExternalLink
                spacingError={!!errors[`document-${documentSelected.type}`]}
                text={linkMessage.text}
                href={linkConfig.href}
              />
            )}
            {!!phones && indicationParams.isRegisterPage && (
              <GridItem
                lg={documentGridItemColumnSize}
                md={documentGridItemColumnSize}
                sm={documentGridItemColumnSize}
                xs={12}
                className={preRegisterClasses.documentGridItemTextField}
              >
                <PhoneFields
                  messages={phone}
                  shouldShowPhoneSelect={shouldUsePhoneSelect}
                  options={formatPhoneOptions()}
                />
              </GridItem>
            )}
            {shouldAcceptTerms && indicationParams.isRegisterPage && (
              <StyledFormItem gridArea="terms">
                <FormControl>
                  <Controller
                    name="acceptedAgreements"
                    control={control}
                    rules={{ required: 'error' }}
                    render={({ onChange, onBlur, value, ref }) => (
                      <AcceptTerms
                        isDialog={false}
                        dialogInfo={{
                          title: '',
                          acceptText: '',
                          closeText: '',
                        }}
                        shouldHide={!userNeedsToAcceptTerms}
                        setUserNeedsToAcceptTerms={setUserNeedsToAcceptTerms}
                        checkbox={{
                          color: 'secondary',
                          normalLabel: beforeHighlightedText[0],
                          highlightedLabel: messages.checkboxLabelLink,
                          acceptTermsAriaLabel: 'ccccc',
                          onChange: (e) => {
                            onBlur()
                            onChange(e)
                          },
                          onBlur,
                          value,
                          inputRef: ref,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </StyledFormItem>
            )}
          </GridContainer>
        </FormProvider>

        {indicationParams?.name && indicationParams?.code && !authenticated && (
          <Spacing mt="small">
            <Typography variant="body2" data-testid="indication-label">
              {indicationMessage}
            </Typography>
          </Spacing>
        )}
      </CardContent>
    </Container>
  )
}
