import React from 'react'
import { Button, Typography } from '@naturacosmeticos/natds-web'
import { LetterAvatar } from '@/main/components'
import { Modal } from '@/main/components/modal'
import {
  SelectionCheckbox,
  InfoLabel, NameText,
  ModalBodyContainer,
} from '@/main/components/modal-with-person-checkbox'
import { ModalPersonMessages } from '@/domain/models/messages/messages'
import { getEloToggleByCountry } from '@/utils/getEloToggle'

export type Info = {
  sellerId?: string
  name?: string
}

export type ModalWithPersonCheckboxProps = {
  errorMessage?: string
  info?: Info
  isItemSelected?: boolean
  isSearchLoading?: boolean
  onItemSelected?: () => void
  confirmationError?: () => void
  confirmSelection?: () => void
  handleCloseModal?: () => void
  isModalOpen?: boolean,
  messages: ModalPersonMessages
}

export const ModalWithPersonCheckbox: React.FC<ModalWithPersonCheckboxProps> = ({
 errorMessage, info, isItemSelected, isSearchLoading = false, onItemSelected, confirmationError,
confirmSelection, isModalOpen, handleCloseModal, messages,
}) => {
  const isConfirmationButtonDisabled = !isItemSelected

  const modalTitle = <Typography variant="h6">{errorMessage ? messages.alertTitle : messages.instructionsTitle}</Typography>

  const ModalBody = () => (
    errorMessage ? <Typography variant="body1">{errorMessage}</Typography>
      : (
        <ModalBodyContainer>
          <LetterAvatar name={info.name} dataTestId="modal-checkbox-avatar" />
          <InfoLabel htmlFor="modal-checkbox-result">
            <NameText variant="body1">{info.name}</NameText>
            <Typography variant="body2">
              {messages.labelCode}
              {' '}
              {info.sellerId}
            </Typography>
          </InfoLabel>
          <SelectionCheckbox
            color={getEloToggleByCountry() ? 'primary' :'secondary'}
            name="modal-checkbox-result"
            size="medium"
            defaultChecked={isItemSelected}
            onChange={onItemSelected}
          />
        </ModalBodyContainer>
      )
  )

  const buttonsModal = errorMessage ? (
    <Button
      onClick={confirmationError}
      variant={getEloToggleByCountry() ? 'outlined': 'contained'}
      color="primary"
    >
      {messages.okButtonLabel}
    </Button>
  ) : (
    <>
      <Button
        aria-label="close"
        onClick={handleCloseModal}
      >
        {messages.cancelButtonLabel}
      </Button>
      <Button
        disabled={isConfirmationButtonDisabled}
        onClick={confirmSelection}
        variant={getEloToggleByCountry() ? 'outlined': 'contained'}
        color="primary"
      >
        {messages.confirmButtonLabel}
      </Button>
    </>
  )

  return (
    <Modal
      dataTestId="modal-with-checkbox-search-result"
      modalOpened={isModalOpen}
      content={<ModalBody />}
      title={modalTitle}
      closeModal={handleCloseModal}
      buttons={buttonsModal}
      isLoading={isSearchLoading}
      width="clamp(300px,80%,440px)"
      bordered={false}
    />
  )
}
