import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteValidIfEmailAlreadyExistInfo } from '@/data/use-cases/register-maintenance/remote-get-valid-if-email-already-exist-info'
import { ValidIfEmailAlreadyExisInfo } from '@/domain/use-cases/register-maintenance/valid-if-email-already-exist'

const axiosBaseUrl = process.env.REGISTERS_BFF_URL
const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
const httpPatchClient = new AxiosHttpClient(axiosBaseUrl)

export const makeGetValidaIfEmailAlreadyExist = (): ValidIfEmailAlreadyExisInfo => {
  const apiUrl = '/email'
  const remoteGetValidaIfEmailAlreadyExist = new RemoteValidIfEmailAlreadyExistInfo(
    apiUrl,
    httpPatchClient,
    xApiKey,
  )

  return remoteGetValidaIfEmailAlreadyExist
}
