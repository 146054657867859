export type SignaturePadData = {
  timeValues: Array<number>
  xValues: Array<number>
  yValues: Array<number>
}

export type SignaturePadParams = {
  canvas: HTMLElement
  brushSize: number
  splashText: string,
  bgColor: string,
  brushColor: string,
}

export type SignatureData = {
  biometricTime: Array<number>,
  biometricX: Array<number>,
  biometricY: Array<number>,
  canvasHeight: number,
  canvasWidth: number,
  signatureImage: string,
}

export interface MakeSignaturePad {
  setSize: (width: number, height: number) => void
  clear: () => void
  getData: (getDurations: boolean, inSeconds: boolean) => SignaturePadData,
  toDataURL: () => string
}

export const makeSignaturePad = (signaturePadParams: SignaturePadParams): MakeSignaturePad => {
  const {
    canvas,
    brushSize,
    splashText,
    bgColor,
    brushColor,
  } = signaturePadParams

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new SignaturePad({
    canvas,
    brushSize,
    splashText,
    bgColor,
    brushColor,
  })
}
