import { makeRemoteSearchIndication } from '@/main/factories/remote/indication'
import {
  IndicationInfo,
  RemoteSearchIndicationParams,
} from '@/data/use-cases/indication/remote-search-indication'
import {
  makeRemoteCompleteIndication,
} from '@/main/factories/remote/indication/make-remote-complete-indication'
import {
  CompleteIndicationResponse,
  RemoteCompleteIndicationParams,
} from '@/data/use-cases/indication/remote-complete-indication'

export type IndicationPageApi = {
  searchIndication: (params: RemoteSearchIndicationParams) => Promise<IndicationInfo>
  completeIndication:
    (params: RemoteCompleteIndicationParams) => Promise<CompleteIndicationResponse>
}

export const makeIndicationPageApi = (): IndicationPageApi => {
  const remoteSearchIndication = makeRemoteSearchIndication()
  const remoteCompleteIndication = makeRemoteCompleteIndication()

  const searchIndication = (
    params: RemoteSearchIndicationParams,
  ) => remoteSearchIndication.search(params)
  const completeIndication = (
    params: RemoteCompleteIndicationParams,
  ) => remoteCompleteIndication.complete(params)

  return { searchIndication, completeIndication }
}
