
import { Hierarchies, HIERARCHIES_LEVEL_ID } from '@/domain/use-cases/person'
import { getObjectInStorage } from '@/main/hooks/useStorage'

export const validateStructureCodeSegmentation = (authStructureCode = getObjectInStorage('recommendedSalesHierarchies') as Hierarchies[]) => {

  const structureCodes = process.env.ENABLE_ADDRESS_MAINTENANCE_SEGMENTATION
  const maintenanceAddressToggle = process.env.ENABLE_ADDRESS_MAINTENANCE_ADDRESS === 'true'
  const gvId = authStructureCode?.find(item=>item?.levelId === HIERARCHIES_LEVEL_ID.GV)?.id  

  if (!maintenanceAddressToggle) {
    return false
  }
  if (!structureCodes) {
    return true
  }
  if (!gvId){
    return false 
  }
  const structureCodesArray = structureCodes.split(',')

  return structureCodesArray.includes(gvId)
}