import {
  ResponseGetStatusWorkflow,
  StatusWorkflowParams,
} from '@/data/use-cases/register-maintenance-security-validation/status-workflow'
import {
  GetConsultantGvResponsibleParams,
  GetMyLeaderDataParams,
  GetPersonDataParams,
  MyLeaderDataResponse,
  PersonData,
  PersonDataResponse,
  PersonGvResponsible,
} from '@/domain/use-cases/person/get-person-data'
import {
  CnCreditDataResponse,
  CnCreditDataResponseError,
  GetCnCreditDataParams,
} from '@/domain/use-cases/person/minnegocio/get-cn-credit-data'
import {
  SaveConsultantInfoParams,
  SaveConsultantInfoResponse,
  ValidIfEmailAlreadyExistParams,
  ValidIfEmailAlreadyExistResponse,
} from '@/domain/use-cases/register-maintenance/save-consultant-info'
import { makeRemoteGetStatusWorkflow } from '@/main/factories/remote/register-maintenance-security-validation/make-remote-get-status-workflow'
import { makeGetCnCredit } from '@/main/factories/remote/register-maintenance/make-remote-get-cn-credit'
import { makeGetConsultantGvResponsible } from '@/main/factories/remote/register-maintenance/make-remote-get-consultant-gv-responsible'
import { makeGetConsultantInfo } from '@/main/factories/remote/register-maintenance/make-remote-get-consultant-info'
import { makeGetMyLeaderInfo } from '@/main/factories/remote/register-maintenance/make-remote-get-my-leader-info'
import {
  makeSaveConsultantInfo,
  makeSaveConsultantInfoBasicData,
} from '@/main/factories/remote/register-maintenance/make-remote-save-consultant-info'
import { makeGetValidaIfEmailAlreadyExist } from '@/main/factories/remote/register-maintenance/make-remote-valida-if-email-already-exist-params'

export type RegisterMaitenancePageApi = {
  getCnCredit: (
    data: GetCnCreditDataParams
  ) => Promise<CnCreditDataResponse | CnCreditDataResponseError | {} | null | unknown>
  getConsultantInfo: (
    data: GetPersonDataParams
  ) => Promise<PersonData | PersonDataResponse | {} | null | unknown>
  getMyLeaderInfo: (
    data: GetMyLeaderDataParams
  ) => Promise<MyLeaderDataResponse | PersonDataResponse | {} | null | unknown>
  saveConsultantInfo: (data: SaveConsultantInfoParams) => Promise<SaveConsultantInfoResponse>
  saveConsultantInfoBasicData: (data: SaveConsultantInfoParams) => Promise<SaveConsultantInfoResponse>
  getValidIfEmailAlreadyExist: (
    data: ValidIfEmailAlreadyExistParams
  ) => Promise<ValidIfEmailAlreadyExistResponse>
  getConsultantGvResponsible: (
    data: GetConsultantGvResponsibleParams
  ) => Promise<PersonGvResponsible>
  statusWorkflow: (params: StatusWorkflowParams) => Promise<ResponseGetStatusWorkflow>
}

export const makeRegisterMaitenancePageApi = (): RegisterMaitenancePageApi => {
  const remoteGetConsultantInfo = makeGetConsultantInfo()
  const getConsultantInfo = (data: GetPersonDataParams) => remoteGetConsultantInfo.get(data)

  const remoteSaveConsultantInfo = makeSaveConsultantInfo()
  const saveConsultantInfo = (data: SaveConsultantInfoParams) => remoteSaveConsultantInfo.update(data)

  const remoteGetMyLeaderInfo = makeGetMyLeaderInfo()
  const getMyLeaderInfo = (data: GetMyLeaderDataParams) => remoteGetMyLeaderInfo.get(data)

  const remoteGetCnCredit = makeGetCnCredit()
  const getCnCredit = (data: GetCnCreditDataParams) => remoteGetCnCredit.get(data)

  const remoteSaveConsultantInfoBasicData = makeSaveConsultantInfoBasicData()
  const saveConsultantInfoBasicData = (data: SaveConsultantInfoParams) =>
    remoteSaveConsultantInfoBasicData.updateBasicData(data)

  const remoteGetConsultantGvResponsible = makeGetConsultantGvResponsible()
  const getConsultantGvResponsible = (data: GetConsultantGvResponsibleParams) =>
    remoteGetConsultantGvResponsible.get(data)

  const remoteValidIfEmailAlreadyExistParams = makeGetValidaIfEmailAlreadyExist()
  const getValidIfEmailAlreadyExist = (data: ValidIfEmailAlreadyExistParams) =>
    remoteValidIfEmailAlreadyExistParams.valid(data)

  const remoteGetStatusWorkflow = makeRemoteGetStatusWorkflow()
  const statusWorkflow = (params: StatusWorkflowParams) => remoteGetStatusWorkflow.get(params)

  return {
    getCnCredit,
    getConsultantGvResponsible,
    getConsultantInfo,
    getMyLeaderInfo,
    getValidIfEmailAlreadyExist,
    saveConsultantInfo,
    saveConsultantInfoBasicData,
    statusWorkflow,
  }
}
