import { DigitalStoreStatusPendingAgreementsMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'
import { DigitalStoreStatusPendingAgreementsMessageOptions } from '../../../../domain/models/messages/messages.d'
import { getDigitalStoreStatusAllStatusMessages } from './messages.digitalStoreStatusAllStatusMessages'

export function getDigitalStoreStatusPendingAgreementsMessages(translate: TFunction, options: DigitalStoreStatusPendingAgreementsMessageOptions): DigitalStoreStatusPendingAgreementsMessages {
  return {
    digitalStoreUrl: translate('digitalStoreStatus.link', options),
    digitalStoreDigitalSpaceAddress: translate('digitalStoreStatus.linkTitle', options),
    digitalStoreTermsInfo: translate('digitalStoreStatus.terms.info', options),
    digitalStoreTermsAcceptance: translate('digitalStoreStatus.terms.acceptance.text', options),
    digitalStoreTermsAcceptanceLink: translate('digitalStoreStatus.terms.acceptance.link', options),
    digitalStoreTermsAcceptanceButton: translate('digitalStoreStatus.terms.acceptance.buttonLabel', options),
    digitalStoreTermsAcceptanceModalTitle: translate('digitalStoreStatus.terms.acceptance.modal.title', options),
    digitalStoreTermsAcceptanceModalAccept: translate('digitalStoreStatus.terms.acceptance.modal.accept', options),
    digitalStoreTermsAcceptanceModalClose: translate('digitalStoreStatus.terms.acceptance.modal.close', options),
    contactInfoHelpTitle: translate('digitalStoreStatus.contactInfoHelp.title', options),
    contactInfoHelp: translate('digitalStoreStatus.contactInfoHelp.text', options),
    errorOnSave: translate('digitalStoreStatus.errorOnSave', options),
    statusMessages: getDigitalStoreStatusAllStatusMessages(translate, options),
  }
}
