import styled from 'styled-components'
import { Icon } from '@/main/components/icon'
import { IThemeWebBase } from '@naturacosmeticos/natds-web/dist/Themes/IThemeWeb'

export const customStyles = (theme: IThemeWebBase, isInvalid: boolean) => ({
  control: (provided, state) => ({
    ...provided,
    // eslint-disable-next-line no-nested-ternary
    border: isInvalid
      ? `0.1rem solid ${theme.color.alert}`
      : state.isFocused
        ? `0.1rem solid ${theme.color.primary}`
        : `0.1rem solid ${theme.color.mediumEmphasis}`,
    boxShadow: 'none',
    borderRadius: '0.4rem',
    fontSize: '1.6rem',
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontHeight: '2.1rem',
    letterSpacing: '0.015rem',
    color: theme.color.onBackground,
    height: '5.6rem',
    transition: '0.2s all ease',
    '&:active': {
      border: isInvalid
        ? `0.1rem solid ${theme.color.alert}`
        : `0.1rem solid ${theme.color.primary}`,
    },
    '&:hover': {
      // eslint-disable-next-line no-nested-ternary
      border: isInvalid
        ? `0.1rem solid ${theme.color.alert}`
        : state.isFocused
          ? `0.1rem solid ${theme.color.primary}`
          : `0.1rem solid ${theme.color.onBackground}`,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.color.mediumEmphasis,
    opacity: 1,
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    padding: '1.3rem 0.8rem',
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontHeight: '2.1rem',
    letterSpacing: '0.015rem',
    backgroundColor: state.isFocused || state.isSelected ? theme.color.primaryLightest : undefined,
    color: state.isSelected && theme.color.onSecondaryLight,
    transition: '0.2s all ease',
    '&:not(:last-child)': {
      borderBottom: `0.1rem solid ${theme.color.lowEmphasis}`,
    },
    ':active': {
      backgroundColor: theme.color.primaryLightest,
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '1.2rem',
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontHeight: '2.1rem',
    letterSpacing: '0.015rem',
    color: theme.color.highEmphasis,
    padding: '1.3rem 1.4rem',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontSize: '1.2rem',
    color: theme.color.highEmphasis,
    fontFamily: '\'Roboto\', sans-serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontHeight: '2.1rem',
    letterSpacing: '0.015rem',
    padding: '1.3rem 1.4rem',
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '-0.4rem',
    color: theme.color.onBackground,
    height: 'auto',
    boxShadow: 'none',
    border: `0.1rem solid ${theme.color.mediumEmphasis}`,
    transition: '0.2s all ease',
    zIndex: 999,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.color.onBackground,
    fontWeight: theme.typography.fontWeightRegular,
    fontHeight: '2.1rem',
    letterSpacing: '0.015rem',
  }),
  input: (provided) => ({
    ...provided,
    color: theme.color.onBackground,
    fontWeight: theme.typography.fontWeightRegular,
    fontHeight: '2.1rem',
    letterSpacing: '0.015rem',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
})

export const ClearIndicatorIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`
