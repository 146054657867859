import React from 'react'
import { useI18n } from '@/main/hooks/useI18n'
import { Rejected } from './rejected'

export type RejectedMessages = {
  title: string,
  description: string,
  helpTitle: string,
  helpDescription: string,
  helpAnchorText: string,
  helpHref: string,
}

export const RejectedWrapper = () => {
  const { translate } = useI18n()

  const messages: RejectedMessages = {
    title: translate('completedPage.rejected.title'),
    description: translate('completedPage.rejected.description'),
    helpTitle: translate('completedPage.rejected.helpTitle'),
    helpDescription: translate('completedPage.rejected.helpDescription'),
    helpAnchorText: translate('completedPage.rejected.helpAnchorText'),
    helpHref: translate('completedPage.rejected.helpHref'),
  }

  return <Rejected messages={messages} />
}
