import React, { useState } from 'react'

interface SignatureTutorialProviderProps {
  tutorialStep: number,
  setTutorialStep: Function
}

export const SignatureTutorialContext = React.createContext<SignatureTutorialProviderProps>(undefined)

export const SignatureTutorialProvider: React.FC = ({ children }) => {
  const [tutorialStep, setTutorialStep] = useState(0)

  return (
    <SignatureTutorialContext.Provider value={{ tutorialStep, setTutorialStep }}>
      {children}
    </SignatureTutorialContext.Provider>
  )
}
