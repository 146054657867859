import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { createUseStyles, Theme } from '@naturacosmeticos/natds-react'
import { Typography } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'

export const useMaintenanceSecurityValidation = createUseStyles((theme: Theme) => ({
  subtitle: {
    marginTop: theme.size.semi,
    marginBottom: theme.size.semi,
  },
  emailRadioButton: {
    marginTop: theme.size.semi,
  },
  wrapperInputs: {
    '&& label, && input[type=radio]': {
      margin: 0,
    },
  },
}))

type TextProps = {
  center?: boolean
}

const AlignedToCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DescriptionContainer = styled.div`
  width: 100%;
  ${AlignedToCenter}
  align-items: baseline;
  flex-direction: column-reverse;
  padding: ${({ theme }) => theme.sizes.standard}px 0;
  margin-left: 20px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 400px;
  align-items: center;
  justify-content: center;
`

export const Text = styled(Typography)<TextProps>`
  &.reference {
    margin-top: ${({ theme }) => theme.sizes.standard}px;
  }

  &.label {
    margin-bottom: ${({ theme }) => theme.sizes.tiny}px;
  }

  &.successColor {
    color: ${({ theme }) => theme.color.success};
  }
`

export const TypographyLink = styled(Typography)<TextProps>`
  margin-top: ${({ theme }) => theme.sizes.standard}px !important;
`

export const StyledLink = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.color.link};
`

export const WarningIcon = styled.div`
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.primary};

  i {
    margin-top: ${({ theme }) => theme.sizes.tiny}px;
  }
`

export const Title = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`

export const StyleButtons = styled.div`
  margin: 10px;
`
