import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { GetDocumentation } from '@/domain/use-cases/documentation/get-documentation'
import { RemoteGetDocumentation } from '@/data/use-cases/documentation/remote-get-documentation'

export const makeRemoteGetDocumentation = (): GetDocumentation => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteGetDocumentation(
    httpGetClient,
    xApiKey,
  )
}
