function firstDigitValidation(value: string, firstDigitValue: string): boolean {
  return value[0] === firstDigitValue
}

const notDigitsInPhoneNumber = [/-/, /_/g, / /g]
function lengthValidation(value: string, lengthValue: number): boolean {
  let onlyDigits = value
  notDigitsInPhoneNumber.forEach((char: RegExp) => {
    onlyDigits = onlyDigits.replace(char, '')
  })
  return onlyDigits.length >= lengthValue
}

export type ValidateFunction = (value: string) => boolean

export function createPhoneNumberFirstDigitValidation(firstDigitValue: string): (value: string) => boolean {
  return (value) => firstDigitValidation(value, firstDigitValue)
}

export function createPhoneNumberLengthValidation(lengthValue: number): (value: string) => boolean {
  return (value) => lengthValidation(value, lengthValue)
}
