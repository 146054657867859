import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { GetPredictions, GetPredictionsParams, GetPredictionsResponse } from '@/domain/use-cases/address'

type GetPredictionsHttpRequestHeaders = {
  'x-api-key': string,
  sessiontoken: string,
  tenantid: TenantId
}

type GetPredictionsQuery = {
  input: string,
}

type GetPredictionsHttpRequestResponse = GetPredictionsResponse

export class RemoteGetPredictions implements GetPredictions {
  constructor(
    private readonly httpGetClient: HttpGetClient<
      GetPredictionsHttpRequestHeaders,
      GetPredictionsHttpRequestResponse
    >,
    private readonly xApiKey: string,
  ) { }

  get = async (params: GetPredictionsParams): Promise<GetPredictionsResponse> => {
    const {
      input,
      sessionToken,
      tenantId,
    } = params

    const headers: GetPredictionsHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      sessiontoken: sessionToken,
      tenantid: tenantId,
    }

    const query: GetPredictionsQuery = {
      input,
    }

    const httpResponse = await this.httpGetClient.get({
      url: '/address/predictions',
      headers,
      query,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      default:
        throw new UnexpectedError()
    }
  }
}
