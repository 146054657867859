import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { SubmitPendingSignature } from '@/domain/use-cases/pending-signature'
import { RemoteSubmitPendingSignature } from '@/data/use-cases/pending-signature'

export const makeRemotePendingSignature = (): SubmitPendingSignature => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY

  const remoteGetPersonData = new RemoteSubmitPendingSignature(
    httpGetClient,
    xApiKey,
  )
  return remoteGetPersonData
}
