import styled from 'styled-components'
import { Container } from '@/main/components/pages-structures'

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const MainContainer = styled(Container)`
  margin-bottom: 1rem;
`
