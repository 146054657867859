import styled from 'styled-components'
import { Typography } from '@naturacosmeticos/natds-web'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { Card } from '@/main/components'

type TextProps = {
  center?: boolean
}

const AlignedToCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const WarningIcon = styled.div`
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.primary};

  i {
    margin-top: ${({ theme }) => theme.sizes.tiny}px;
  }
`

export const ContentRejected = styled(Card)`
  ${AlignedToCenter}
  margin-top: ${({ theme }) => theme.sizes.semi}px;
  border-radius: ${({ theme }) => theme.sizes.tiny}rem;
  
  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin-top: ${({ theme }) => theme.sizes.huge}px;
    padding-top: ${({ theme }) => theme.sizes.semi}px;
    max-width: ${({ theme }) => (theme.sizes.veryHuge * 2.5)}px;
  }
`
export const StatusContainer = styled.div`
  margin-left: ${({ theme }) => theme.sizes.standard}px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin: 0;
    text-align: right;
    margin-right: ${({ theme }) => theme.sizes.standard}px;
  }
`
export const ActionContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizes.semi}px;
  margin-bottom: ${({ theme }) => theme.sizes.semi}px;
`
export const Text = styled(Typography)<TextProps>`
  &.reference {
    margin-top: ${({ theme }) => theme.sizes.standard}px;
  }

  &.label {
    margin-bottom: ${({ theme }) => theme.sizes.tiny}px;
  }

  &.successColor {
    color: ${({ theme }) => theme.color.success};
  }
`

export const InfoContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizes.standard}px;
  margin-left: ${({ theme }) => theme.sizes.standard}px;
  margin-right: ${({ theme }) => theme.sizes.standard}px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin-top: 0;
  }
`

export const DescriptionContainer = styled.div`
  width: 100%;
  ${AlignedToCenter}
  align-items: baseline;
  flex-direction: column-reverse;
  padding: ${({ theme }) => theme.sizes.standard}px 0;
  margin-top: ${({ theme }) => theme.sizes.small}px;
  margin-bottom: ${({ theme }) => theme.sizes.medium}px;
  background-color: ${({ theme }) => theme.color.background};

  @media ${minDeviceSizeForMediaQueries.tablet} {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
`

export const Title = styled.div`
  margin-top: ${({ theme }) => theme.sizes.small}px;
  padding-left: ${({ theme }) => theme.sizes.standard}px;
  padding-right: ${({ theme }) => theme.sizes.standard}px;
`