import { TFunction } from 'i18next'
import {
  DigitalSignatureMessages,
} from '@/domain/models/messages/messages'

export function getDigitalSignatureMessages(translate: TFunction): DigitalSignatureMessages {
  return {
    title: translate('digitalSignaturePage.title'),
    subtitle: translate('digitalSignaturePage.subtitle'),
    signatureButton: translate('digitalSignaturePage.signatureButton'),
    previewOfContractButton: translate('digitalSignaturePage.previewOfContractButton'),
    previousButtonLabel: translate('previousButtonLabel'),
    nextButtonLabel: translate('nextButtonLabel'),
    agreementsCheckbox: {
      first: {
        normalText: translate('digitalSignaturePage.agreementsCheckbox.first.normalText'),
        highlightedText: translate('digitalSignaturePage.agreementsCheckbox.first.highlightedText'),
      },
      second: {
        normalText: translate('digitalSignaturePage.agreementsCheckbox.second.normalText'),
        highlightedText: translate('digitalSignaturePage.agreementsCheckbox.second.highlightedText'),
      },
    },
    signaturePadBoard: {
      title: translate('digitalSignaturePage.signaturePadBoard.title'),
      clearButton: translate('digitalSignaturePage.signaturePadBoard.clearButton'),
      previousButton: translate('digitalSignaturePage.signaturePadBoard.previousButton'),
      nextButton: translate('digitalSignaturePage.signaturePadBoard.nextButton'),
    },
    dialog: {
      title: {
        agreements: {
          first: translate('digitalSignaturePage.dialog.title.agreements.first'),
          second: translate('digitalSignaturePage.dialog.title.agreements.second'),
        },
      },
      acceptButton: translate('digitalSignaturePage.dialog.acceptButton'),
      closeButton: translate('digitalSignaturePage.dialog.closeButton'),
    },
    tutorial: {
      firstPage: {
        title: translate('digitalSignaturePage.tutorial.firstPage.title'),
        description: translate('digitalSignaturePage.tutorial.firstPage.description'),
      },
      secondPage: {
        title: translate('digitalSignaturePage.tutorial.secondPage.title'),
        description: translate('digitalSignaturePage.tutorial.secondPage.description'),
      },
      previousButton: translate('digitalSignaturePage.tutorial.previousButton'),
      nextButton: translate('digitalSignaturePage.tutorial.nextButton'),
    },
  }
}
