import React from 'react'
import styled from 'styled-components'
import { TextParagraph } from '@/main/components'

const Label = styled(TextParagraph)`
  font-size: 1.2rem;
`

const Url = styled(TextParagraph)`
  font-weight: bold;
  color: #333333;
  font-size: 1.4rem;
`

const Container = styled.div`
  font-family: Roboto;
  line-height: 20px;
  color: #777777;
`

export type UrlPreviewProps = {
  label: string
  url: string
  shouldHide?: boolean
}

export const UrlPreview = ({ label, url, shouldHide }: UrlPreviewProps) => (
  <>
    {!shouldHide && (
      <Container>
        <Label content={label} />
        <Url content={url} />
      </Container>
    )}
  </>
)
