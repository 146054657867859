import { BadRequestError } from '@/data/protocols/errors'
import { HttpGetClient, HttpResponse, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CountryIdAlpha2 } from '@/domain/models/country/country-id-alpha-2'
import { GetStates, GetStatesParams, State } from '@/domain/use-cases/address'

type GetStatesHttpRequestHeaders = {
  country: CountryIdAlpha2
  'x-api-key': string
}

export class RemoteGetStates implements GetStates {
  constructor(
    private readonly httpGetClient: HttpGetClient<
      GetStatesHttpRequestHeaders,
      Array<State>
    >,
    private readonly xApiKey: string,
  ) {}

  get = async (params: GetStatesParams): Promise<Array<State>> => {
    const url = '/country/domain-states'
    const headers = {
      country: params.country,
      'x-api-key': this.xApiKey,
    }

    const response = await this.httpGetClient.get({ url, headers })

    return this.handleResponse(response)
  }

  private handleResponse(response: HttpResponse) {
    const { statusCode, body } = response
    switch (statusCode) {
      case HttpStatusCode.ok:
        return body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(body.error)
      default:
        throw new UnexpectedError()
    }
  }
}
