import { SelectOption } from '@/main/components/select'
import { PhoneType } from '@/domain/models/person/phone-type'
import { PhoneNumberMessages, PhoneTypeOptionProps, PhoneTypeOptionsMessages } from '../phone-fields'

export type PhoneTypeSelectOption = SelectOption & { phoneNumber: Pick<PhoneTypeOptionProps, 'mask' | 'validations'> & PhoneNumberMessages}
interface PhoneTypeOptionsAndDefaultValue {
  phoneTypeOptions: PhoneTypeSelectOption[],
  phoneTypeDefaultValue: PhoneTypeSelectOption,
  phoneTypeDisabled: boolean
}

const mapPhoneTypeToMessageKey: Map<PhoneType, keyof PhoneTypeOptionsMessages> = new Map([
  [PhoneType.CELLULAR, 'cellular'],
  [PhoneType.LINE, 'line'],
])

export function getPhoneTypesProps(phoneTypeMessages: PhoneTypeOptionsMessages, options: PhoneTypeOptionProps[]): PhoneTypeOptionsAndDefaultValue {
  const phoneTypeOptions = options.map(({ phoneType, mask, validations }) => {
    const { label, phoneNumber } = phoneTypeMessages[mapPhoneTypeToMessageKey.get(phoneType)]
    return {
      value: phoneType,
      label,
      phoneNumber: {
        mask,
        validations,
        ...phoneNumber,
      },
    }
  })

  return {
    phoneTypeOptions,
    phoneTypeDefaultValue: phoneTypeOptions.find((option) => option.value === PhoneType.CELLULAR),
    phoneTypeDisabled: phoneTypeOptions.length < 2,
  }
}
