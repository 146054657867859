import React, { useContext } from 'react'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { Typography } from '@/main/components/typography'
import { IdentityInfoContext } from '@/main/contexts'
import { CompanyId } from '@/domain/models/company/company-id'
import { useGetPersonData } from '@/main/pages/digital-store/hooks/use-get-person-data'
import { StepConfirmation } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-page-self-service-helper/step-confirmation'
import { StepPropsType } from '@/main/pages/digital-store/pages/digital-store-status-page/vendors-linking-steps/mercado-pago/step'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { DigitalStoreStatusAccountStepMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { EmailBar } from '../../../components/digital-store-status/digital-store-status-page-self-service-helper/email-bar'

export const AccountStep: React.FC<StepPropsType> = ({ advanceStep }) => {
  const { countryId, personId, tenantId } = useContext(IdentityInfoContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusAccountStep, pageMessagesOptions).messages as DigitalStoreStatusAccountStepMessages

  const { personData, personDataLoading } = useGetPersonData({
    relations: ['emails'],
    personId,
    countryId,
    companyId: CompanyId.Natura,
  })

  const userEmail = personData?.person?.emails[0]?.email

  if (personDataLoading) return <ProgressIndicator />
  return (
    <StepConfirmation
      title={messages.confirmation.question}
      confirmText={messages.confirmation.affirmativeAnswer}
      cancelText={messages.confirmation.negativeAnswer}
      onConfirm={() => advanceStep()}
      onCancel={() => advanceStep(2)}
    >
      <Typography color="textSecondary" align="center" style={{ marginBottom: '18px' }}>
        {messages.hint}
      </Typography>
      <EmailBar email={userEmail} />
    </StepConfirmation>
  )
}
