import { HttpStatusCode } from '@/data/protocols/http'
import {
  CheckDigitalStoreAvailability,
  CheckDigitalStoreAvailabilityParams,
} from '@/domain/use-cases/digital-store/check-digital-store-availability'
import { UnexpectedError } from '@/domain/errors'
import 'regenerator-runtime/runtime'
import { HttpGetClient } from '@/data/protocols/http/http-get-client'
import { CountryId } from '@/domain/models/country'
import { BadRequestError } from '@/data/protocols/errors'
import { CompanyId } from '@/domain/models/company/company-id'

export type CheckDigitalStoreAvailabilityHttpRequestHeaders = {
  countryId: CountryId
  personId: string
  'x-api-key': string
  companyId: CompanyId
}

export type CheckDigitalStoreAvailabilityHttpRequestResponseBody = {
  isAvailable: boolean
}

export class RemoteCheckDigitalStoreAvailability
  implements CheckDigitalStoreAvailability {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
      CheckDigitalStoreAvailabilityHttpRequestHeaders,
      CheckDigitalStoreAvailabilityHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  check = async (
    params: CheckDigitalStoreAvailabilityParams,
  ): Promise<boolean> => {
    const headers: CheckDigitalStoreAvailabilityHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      countryId: params.countryId,
      personId: params.personId,
      companyId: params.companyId,
    }
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(/:digitalStoreName/i, params.digitalStoreName),
      headers,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body.isAvailable
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
