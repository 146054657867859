import { LevelName } from '@/domain/models/address'
import { Page } from '@/domain/models/page/page'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import { CardContent, CardHeader, ControlledTextField } from '@/main/components'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { Container } from '@/main/components/pages-structures'
import { IdentityInfoContext, NavigationContext, NavigationContextProps } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { AddressFormValues, AddressMessages } from '@/main/pages/address/models'
import { FormControl, GridContainer, GridItem } from '@naturacosmeticos/natds-web'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AddressPageApi } from '../api'
import { useAddressData } from '../hooks/use-address-data'

type SimpleAddressPageProps = {
  api: Pick<AddressPageApi, 'getAddressData' | 'complete'>
}

export const SimpleAddressPage: React.FC<SimpleAddressPageProps> = ({ api }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isUnexpectedErrorDialogOpen, setIsUnexpectedErrorDialogOpen] = useState(false)

  const { tenantId, businessModel, role, functionId, personId } = useContext(IdentityInfoContext)
  const { data, isLoading: isLoadingGet } = useAddressData(api)

  const { street, complementaryData, city, state } = useTenantConfigs(
    tenantId,
    Page.Address
  ) as AddressConfigs

  const formMethods = useForm<AddressFormValues>({
    mode: 'onTouched',
  })
  const { control, formState, getValues, reset } = formMethods

  const canGoToNextPage = formState.isValid

  const messages = usePageMessages(Page.Address).messages as AddressMessages

  const { goToNextPage, goToPreviousPage } = useContext<NavigationContextProps>(NavigationContext)

  function getFormRulesWithCurrentMessage(inputMaxLenght) {
    return {
      required: messages.addressRequiredError,
      maxLength: {
        value: inputMaxLenght,
        message: messages.rules.maxLength.replace('{maxLength}', inputMaxLenght.toString()),
      },
    }
  }

  useEffect(() => {
    if (Object.values(data).some((value) => value)) {
      reset(data)
    }
  }, [data, reset])

  const handleNextButtonClick = async () => {
    setIsLoading(true)
    const values = getValues()
    const params = {
      personId,
      tenantId,
      businessModel,
      role,
      functionId,
      data: values,
    }

    await api
      .complete(params)
      .then(() => goToNextPage())
      .catch(() => setIsUnexpectedErrorDialogOpen(true))
      .finally(() => setIsLoading(false))
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={handleNextButtonClick}
      disableNextButton={!canGoToNextPage}
      isLoading={isLoading || isLoadingGet}
    >
      <CardHeader title={messages.title} subtitle={messages.subtitle} />
      <CardContent>
        <FormProvider {...formMethods}>
          <FormControl>
            <ControlledTextField
              id={LevelName.Street}
              label={messages.mainStreet.label}
              control={control}
              rules={getFormRulesWithCurrentMessage(street.maxLength)}
              placeholder={messages.placeholders.mainStreet}
              showAsterisk={false}
              required
            />
            <ControlledTextField
              id={LevelName.ComplementaryData}
              label={null}
              control={control}
              rules={getFormRulesWithCurrentMessage(complementaryData.maxLength)}
              placeholder={messages.placeholders.complementaryData}
              showAsterisk={false}
              required={false}
            />
            <GridContainer className="natds1" justify="space-between" spacing={3}>
              <GridItem className="natds2" md={6} sm={6} xs={6}>
                <ControlledTextField
                  id={LevelName.City}
                  control={control}
                  rules={getFormRulesWithCurrentMessage(city.maxLength)}
                  placeholder={messages.placeholders.city}
                  label={messages.city}
                  showAsterisk={false}
                  required
                />
              </GridItem>
              <GridItem className="natds2" md={6} sm={6} xs={6}>
                <ControlledTextField
                  id={LevelName.State}
                  control={control}
                  rules={getFormRulesWithCurrentMessage(state.maxLength)}
                  placeholder={messages.placeholders.state}
                  label={messages.state}
                  showAsterisk={false}
                  required
                />
              </GridItem>
            </GridContainer>
          </FormControl>
        </FormProvider>
      </CardContent>
      <DialogAlert
        show={isUnexpectedErrorDialogOpen}
        messages={{
          infoText: messages.unexpectedError,
          closeButtonText: messages.closeButtonText,
        }}
        closeDialogCallback={() => setIsUnexpectedErrorDialogOpen(false)}
      />
    </Container>
  )
}
