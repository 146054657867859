import { TFunction } from 'i18next'
import { AttachmentsMessageOptions, AttachmentsMessages } from '@/domain/models/messages/messages'

export const getAttachmentsMessages = (translate: TFunction, options: AttachmentsMessageOptions): AttachmentsMessages => ({
    title: translate('attachments.title'),
    subtitle: translate('attachments.subtitle', options),
    subtitlePopover: translate('attachments.subtitlePopover'),
    previousButtonLabel: translate('previousButtonLabel'),
    nextButtonLabel: translate('nextButtonLabel'),
    validateButtonLabel: translate('attachments.validateButtonLabel'),
    dropdownLabel: translate('attachments.dropdownLabel'),
    subtitleUploadFile: translate('attachments.subtitleUploadFile'),
    subtitleUploadFileNewOCR: translate('attachments.subtitleUploadFileNewOCR'),
    uploadFileButtonName: translate('attachments.fileButtonLabel.upload'),
    changeFileButtonName: translate('attachments.fileButtonLabel.change'),
    validateDialogText: translate('attachments.validateDialogText'),
    failedValidationMessage: translate('attachments.validationMessage.failed'),
    succeedValidationMessage: translate('attachments.validationMessage.succeed'),
    unexpectedErrorButton: translate('unexpectedErrorDialog.button'),
    unexpectedErrorMessage: translate('unexpectedErrorDialog.message'),
})
