import { HttpStatusCode } from '@/data/protocols/http'

export class ConflictError extends Error {
  statusCode: HttpStatusCode

  data: any

  constructor(data) {
    super()
    this.name = 'ConflictError'
    this.statusCode = 409
    this.data = data
  }
}
