import { enumToArray } from '@/utils/enum-to-array'

export enum PhoneTypeId {
  RESIDENTIAL = 2,
  MOBILE = 1,
  WHATSAPP = 11,
  MESSAGE = 5,
}

export const PHONE_TYPES = enumToArray(PhoneTypeId).map((phoneType) => ({
  id: phoneType,
  label: PhoneTypeId[phoneType],
}))
