// the choice of using px comes from a DS suggestion
export const customStyles = (theme) => ({
  container: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'no-drop !important' : provided.cursor,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: theme.color.surface,
    borderRadius: '4px !important',
    boxShadow: state.isFocused ? `${theme.color.primary} 0 0 0 2px !important` : `${theme.color.lowEmphasis} 0 0 0 1px !important`,
    border: 0,
    boxSizing: 'border-box !important',
    width: '100%',
    minHeight: `${theme.sizes.mediumX}px !important`,
    height: 'auto !important',
    lineHeight: '24px !important',
    fontSize: '14px !important',
    color: `${theme.color.highEmphasis} !important`,
    transition: '0.2s all ease !important',
    padding: '0 0 0 16px !important',
    '&:hover': {
      boxShadow: state.isFocused ? `${theme.color.primary} 0 0 0 2px !important` : `${theme.color.mediumEmphasis} 0 0 0 1px !important`,
    },
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    color: theme.color.highEmphasis,
    fontSize: '14px !important',
    padding: '1rem !important',
    backgroundColor: state.isSelected ? `${theme.color.primary}29` : null,
    '&:hover': {
      backgroundColor: `${theme.color.highlight}0a`,
    },
  }),
  menu: (provided) => ({
    ...provided,
    padding: 0,
    boxShadow: `0px 8px 10px ${theme.color.highlight}24`,
    border: 'none',
    borderRadius: '4px',
    background: theme.color.surface,
    'z-index': '999',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    boxShadow: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? `${theme.color.lowEmphasis} !important` : `${theme.color.highlight} !important`,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    lineHeight: '24px !important',
    padding: '0 !important',
    margin: '0 !important',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.color.mediumEmphasis,
    opacity: 0.42,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  }),
})
