import { MaintenancePhoneEmailRejectedMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenancePhoneEmailRejectedMessages(translate: TFunction): MaintenancePhoneEmailRejectedMessages {
  return {
    rejectededIcon: translate('maintenancePhoneEmailRejected.rejectededIcon'),
    title: translate('maintenancePhoneEmailRejected.title'),
    description: translate('maintenancePhoneEmailRejected.description'),
    myProfileButton: translate('maintenancePhoneEmailRejected.myProfileButton'),
  }
}