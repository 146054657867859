import {
  AcceptAuthorizationCode,
  AcceptAuthorizationCodeParams,
} from '@/domain/use-cases/authorization-code/accept-authorization-code'

import { CountryId } from '@/domain/models/country'
import { HttpPostClient, HttpStatusCode } from '@/data/protocols/http'
import { BUSINESS_MODELS, FUNCTION_IDS, ROLES } from '@/domain/models/person'
import { CompanyId } from '@/domain/models/company/company-id'

type AcceptAuthorizationCodeHttpRequestHeaders = {
  countryId: CountryId
  'x-api-key': string
  functionId: FUNCTION_IDS
  sourceSystem: string
  companyId: CompanyId
  role: ROLES
  businessModel: BUSINESS_MODELS
  userId: string
}

type AcceptAuthorizationCodeHttpRequestBody = {
  personId: string,
  confirmationCode: string
}

export class RemoteAcceptAuthorizationCode implements AcceptAuthorizationCode {
  kongXApiKey: string = process.env.REGISTERS_BFF_X_API_KEY

  constructor(private readonly httpPostClient: HttpPostClient<AcceptAuthorizationCodeHttpRequestHeaders, AcceptAuthorizationCodeHttpRequestBody>) {
  }

  accept = async (params: AcceptAuthorizationCodeParams): Promise<boolean> => {
    const httpResponse = await this.httpPostClient.post({
      url: '/authorization-code/accept',
      body: {
        confirmationCode: params.confirmationCode,
        personId: params.personId,
      },
      headers: {
        countryId: params.countryId,
        'x-api-key': this.kongXApiKey,
        functionId: params.functionId,
        sourceSystem: params.sourceSystem,
        companyId: params.companyId,
        role: params.role,
        businessModel: params.businessModel,
        userId: params.userId,
      },
    })
    return httpResponse.statusCode === HttpStatusCode.noContent
  }
}
