import { LevelName } from '@/domain/models/address'
import { CountryId } from '@/domain/models/country'
import { Page } from '@/domain/models/page/page'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { ControlledTextField, GeoCommercialConfig, GeoCommercialStructure } from '@/main/components'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { AddressMessages } from '@/main/pages/address/models'
import { GridContainer, GridItem } from '@naturacosmeticos/natds-web'
import React, { useContext, useEffect } from 'react'
import { Control, useFormContext } from 'react-hook-form'
import { FormAddress } from '../../maintenance-address-page'

export const EcuadorAddressForm = ({
  shouldNotResetOnce,
  control,
  mustResetLevels,
}: {
  shouldNotResetOnce: boolean
  mustResetLevels: boolean
  control: Control<FormAddress>
}) => {
  const { watch, reset } = useFormContext()
  const { countryId, companyId } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const { number, street, reference, complement } = useTenantConfigs(tenantId, Page.Address) as AddressConfigs
  const addressMessages = usePageMessages(Page.Address, {
    maximumCharactersForRoad: street?.maxLength,
    maximumCharactersForReferences: reference?.maxLength,
    maximumCharactersForNumber: number?.rules?.maxLength,
    maximumCharactersForComplement: complement?.rules?.maxLength,
  }).messages as AddressMessages

  const { geographicalLevels } = addressMessages

  const geoCommercialConfig: GeoCommercialConfig = {
    level1: {
      id: 'level1',
      label: geographicalLevels.level1.toUpperCase(),
      placeholder: geographicalLevels.placeholders.level1,
    },
    level2: {
      id: 'level2',
      label: geographicalLevels.level2.toUpperCase(),
      placeholder: geographicalLevels.placeholders.level2,
    },
    level3: {
      id: 'level3',
      label: geographicalLevels.level3.toUpperCase(),
      placeholder: geographicalLevels.placeholders.level3,
    },
    requiredErrorMessage: addressMessages.addressRequiredError,
  }

  const formRules = { required: addressMessages.addressRequiredError }

  const {
    level1,
    level2,
    level3,
    level4,
    complement: complementValue,
    number: numberValue,
    references: referencesValue,
    street: streetValue,
  } = watch()

  useEffect(() => {
    if (mustResetLevels && (complementValue || numberValue || referencesValue || streetValue)) {
      reset({
        level1,
        level2,
        level3,
        level4: '',
        street: '',
        number: '',
        references: '',
        complement: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level1, level2, level3])

  return (
    <>
      <GeoCommercialStructure
        customConfig={geoCommercialConfig}
        countryId={CountryId.Ecuador}
        shouldNotResetOnce={shouldNotResetOnce}
        hasAsteriskInTheLabel
        onePerLine
      />

      <ControlledTextField
        id={LevelName.Level4}
        label={addressMessages.mainStreet.label}
        control={control}
        rules={{
          ...formRules,
          pattern: {
            value: street?.rules?.pattern,
            message: addressMessages.mainStreet.invalidChars,
          },
          maxLength: {
            message: addressMessages.mainStreet.errorMessage,
            value: street?.maxLength,
          },
        }}
        placeholder={addressMessages.placeholders.mainStreet}
        required
        shouldValidateIsNotOnlySpaces
      />

      <GridContainer className="natds1" justify="space-between" spacing={1}>
        <GridItem className="natds2" md={6} sm={6} xs={6}>
          <ControlledTextField
            id={LevelName.Number}
            label={addressMessages.number.label}
            placeholder={addressMessages.placeholders.number}
            control={control}
            noValueCheckbox
            noValueLabel={addressMessages.checkBoxWithoutNumber}
            formType="number"
            required
            rules={{
              ...formRules,
              pattern: {
                value: number?.rules?.pattern,
                message: addressMessages.number.invalidChars,
              },
              maxLength: {
                message: addressMessages.number.errorMessage,
                value: number?.rules.maxLength,
              },
            }}
            validChars={number && number.validChars}
          />
        </GridItem>

        <GridItem className="natds2" md={6} sm={6} xs={6}>
          <ControlledTextField
            id={LevelName.Complement}
            label={addressMessages.complementaryData.label}
            control={control}
            placeholder={addressMessages.placeholders.complementaryData}
            noValueCheckbox
            noValueLabel={addressMessages.checkBoxWithoutComplementaryData}
            required
            rules={{
              ...formRules,
              pattern: {
                value: complement?.rules?.pattern,
                message: addressMessages.complementaryData.invalidChars,
              },
              maxLength: {
                message: addressMessages.complementaryData.errorMessage,
                value: complement?.rules.maxLength,
              },
            }}
            validChars={complement?.validChars}
          />
        </GridItem>
      </GridContainer>

      <ControlledTextField
        id={LevelName.References}
        label={addressMessages.references.label}
        control={control}
        rules={{
          ...formRules,
          pattern: {
            value: reference?.rules?.pattern,
            message: addressMessages.references.invalidChars,
          },
          maxLength: {
            message: addressMessages.references.errorMessage,
            value: reference.maxLength,
          },
        }}
        placeholder={addressMessages.placeholders.references}
        required
        shouldValidateIsNotOnlySpaces
      />
    </>
  )
}
