import { BadRequestError } from '@/data/protocols/errors'
import { useState } from 'react'
import { DigitalStoreHookContext } from '../types'
import { DigitalStorePageApi } from '../api/make-digital-store-page-api'

export type DigitalStoreAvailabilityResults = {
  isChecking: boolean
  isAvailable: boolean
  helperTextKey: string
  verificationAttempts: number
  errorOnCheckAvailability: boolean
  check: (
    newDigitalStoreName: string
  ) => Promise<void>
}

export type UseDigitalStoreAvailabilityParams = {
  api: Pick<
    DigitalStorePageApi,
    'checkNameAvailability'
  >
  context: DigitalStoreHookContext
}

export const useDigitalStoreAvailability = ({
  api,
  context,
}: UseDigitalStoreAvailabilityParams): DigitalStoreAvailabilityResults => {
  const {
    personId,
    countryId,
    companyId,
  } = context

  const check = async (newDigitalStoreName: string) => {
    const onDone = (
      errorOnCheckAvailability: boolean,
      isAvailable: boolean,
      helperTextKey: string,
    ) => {
      const verificationAttempts = digitalStoreAvailability.verificationAttempts + 1

      setDigitalStoreAvailability({
        ...digitalStoreAvailability,
        errorOnCheckAvailability,
        isAvailable,
        helperTextKey,
        isChecking: false,
        verificationAttempts,
      })
    }

    try {
      setDigitalStoreAvailability({
        ...digitalStoreAvailability,
        isChecking: true,
      })

      const isAvailable = await api.checkNameAvailability({
        personId,
        countryId,
        companyId,
        digitalStoreName: newDigitalStoreName,
      })

      const hasError = !isAvailable
      const helperTextKey = isAvailable ? 'availableName' : 'unavailableName'
      onDone(hasError, isAvailable, helperTextKey)
    } catch (errorResponse) {
      console.warn('Error checkAvailability')

      let helperTextKey: string
      switch (errorResponse.constructor) {
        case BadRequestError:
          helperTextKey = 'invalidNameSpace'
          break
        default:
          helperTextKey = 'genericError'
      }

      onDone(true, false, helperTextKey)
    }
  }

  const [
    digitalStoreAvailability,
    setDigitalStoreAvailability,
  ] = useState<DigitalStoreAvailabilityResults>({
    errorOnCheckAvailability: false,
    isChecking: false,
    helperTextKey: '',
    isAvailable: false,
    verificationAttempts: 0,
    check,
  })

  return digitalStoreAvailability
}
