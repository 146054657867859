import React from 'react'
import { RadioButtonProps } from '@naturacosmeticos/natds-react'
import { Controller, ControllerProps } from 'react-hook-form'
import { FVRadioButton } from './fv-radio-button'

export type ControlledFVRadioButtonProps = {
  label: string
} & Partial<RadioButtonProps> &
  Pick<ControllerProps<'input'>, 'control'>

export const ControlledFVRadioButton: React.FC<ControlledFVRadioButtonProps> = ({
  control,
  required,
  value,
  onChange,
  id = 'controlled-fv-radio-button-id',
  className = 'controlled-fv-radio-button-class',
  testID = 'controlled-fv-radio-button-test-id',
  name = 'controlledFVRadioButton',
  ...props
}) => {
  const controllerRender = ({
    ref,
    value: controllerValue,
    name: controllerName,
    onChange: controllerOnChange,
  }) => (
    <>
      <FVRadioButton
        id={id}
        testID={testID}
        className={className}
        name={controllerName}
        ref={ref}
        onChange={onChange || controllerOnChange}
        value={value}
        checked={controllerValue === value}
        {...props}
      />
    </>
  )

  const rules = { required }

  return <Controller control={control} name={name} rules={rules} render={controllerRender} />
}
