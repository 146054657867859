import { createContext, useContext } from 'react'

type BannerContextType = {
  openedModal: boolean
  openedSnackbar: boolean
  image: string
  openModal: () => void
  closeModal: () => void
  openSnackbar: () => void
  closeSnackbar: () => void
}

export const BannerContext = createContext<BannerContextType>({} as BannerContextType)

export const useBanner = () => {
  return useContext(BannerContext)
}
