import { Theme } from '@material-ui/core/styles'
import { makeStyles, TextField } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'

export const useControlledTextFieldStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
}))

export const FVTextField = styled(TextField)`
  ${({ isELOTheme, theme }) => isELOTheme ? 
  `
    .MuiInputBase-root.Mui-disabled {
      color: ${theme.color.lowEmphasis};
      -webkit-text-fill-color: ${theme.color.lowEmphasis};
    }

    .MuiFormLabel-root.Mui-disabled {
      color: ${theme.color.lowEmphasis};
    }
  ` : `
    .MuiInputBase-root.Mui-disabled {
      color: ${theme.color.highEmphasis};
      -webkit-text-fill-color: ${theme.color.highEmphasis};
      background-color: ${theme.color.neutral100};
    }

    .MuiFormLabel-root.Mui-disabled {
      color: ${theme.color.mediumEmphasis};
    }
  `}
`
