import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'

type GetStatusAddressCacheHeaders = {
    'x-api-key': string
}

export type GetStatusAddressCacheParams = {
    personId: string,
}


export type StatusAddressCacheResponse = {
    exist: boolean,
}

export class RemoteGetStatusAddressCache {
    constructor(
        private readonly httpGetClient: HttpGetClient<GetStatusAddressCacheHeaders, StatusAddressCacheResponse>,
        private readonly xApiKey: string,
    ) {}

    get = async ({
        personId,
      }: GetStatusAddressCacheParams): Promise<StatusAddressCacheResponse> => {
        const headers = {
          'x-api-key': this.xApiKey,
        }
        const httpResponse = await this.httpGetClient.get({
          url: `/verify-update-address-cache/${personId}`,
          headers,
        })

        return this.handleResponse(httpResponse)
    }

    private handleResponse = (httpResponse) => {
        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
              return httpResponse.body
            case HttpStatusCode.badRequest:
              throw new BadRequestError(httpResponse.body)
            case HttpStatusCode.notFound:
              throw new NotFoundError(httpResponse.body)
            default:
              throw new UnexpectedError()
        }
    }
}
