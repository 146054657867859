import { makeGetPersonData } from '@/main/factories/remote'
import { useHttpRequest } from '@/main/hooks/useHttpRequest'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'

const getPersonData = makeGetPersonData()

export type useGetPersonDataParams = {
  relations?: string[]
  personId: string
  countryId: CountryId
  companyId: CompanyId
}

export const useGetPersonData = (
  params: useGetPersonDataParams,
) => {
  const { data, error, isLoading } = useHttpRequest(
    {
      countryId: params.countryId,
      personId: params.personId,
      companyId: params.companyId,
      relations: params.relations || [],
    },
    {
      client: getPersonData.get, shouldFetchOnStart: true,
    },
  )

  return {
    personData: data,
    personDataError: error,
    personDataLoading: isLoading,
  }
}
