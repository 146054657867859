import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ControlledSelect } from '@/main/components'

export const GenderField = ({
    messages,
    id,
    options,
  }) => {
    const { control } = useFormContext()

    const {
      label,
      placeholder,
      requiredMessage,
    } = messages

    return (
      <ControlledSelect
        aria-label={label}
        id={id}
        label={label}
        placeholder={placeholder}
        options={options}
        rules={{
          required: requiredMessage,
        }}
        control={control}
      />
    )
  }
