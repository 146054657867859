import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'

type GetDigitalSpaceInfoHeaders = {
  countryid: CountryId
  companyid: CompanyId
  accesstoken: string
  'x-api-key': string
}

export type GetDigitalSpaceInfoParams = {
  countryId: CountryId
  companyId: CompanyId
  personCode: string
  accessToken: string
  personId: string
}

export type DigitalSpaceInfo = {
  displayName: string
  personCode?: string
  about?: string
  avatarUrl?: string
  introHeader?: string
  storeName: string
  storeStatus: string
  instagramLink?: string
  youtubeLink?: string
  facebookLink?: string
}

export class RemoteGetDigitalSpaceInfo {
  constructor(
    private readonly httpGetClient: HttpGetClient<GetDigitalSpaceInfoHeaders, DigitalSpaceInfo>,
    private readonly xApiKey: string,
  ) {}

  get = async ({
    countryId,
    companyId,
    personCode,
    accessToken,
    personId
  }: GetDigitalSpaceInfoParams): Promise<DigitalSpaceInfo> => {
    const headers = {
      countryid: countryId,
      companyid: companyId,
      accesstoken: accessToken,
      personId,
      'x-api-key': this.xApiKey,
    }

    const httpResponse = await this.httpGetClient.get({
      url: `/digital-space/info/${personCode}`,
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.notFound:
        return null
      default:
        throw new UnexpectedError()
    }
  }
}
