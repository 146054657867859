import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

type StatusBarProps = {
 status: string
}

export const StatusBar = styled.div<StatusBarProps>`
  position: fixed;
  width: 100%;
  height: 5px;
  background-color: ${({ status, theme }) => theme.color[status]};
`
