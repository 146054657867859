import { DigitalStoreStatusNotFoundMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getDigitalStoreStatusNotFoundMessages(translate: TFunction): DigitalStoreStatusNotFoundMessages {
  return {
    title: translate('notFoundPage.title'),
    subtitle: translate('notFoundPage.subtitle'),
    buttonLabel: translate('notFoundPage.buttonLabel'),
  }
}
