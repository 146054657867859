import { Page } from '@/domain/models/page/page'
import { store } from '@/infra/global-state/redux/store'
import { history } from '@/infra/global-state/redux/store/history'
import { getLanguage } from '@/infra/translation/i18next/get-language'
import { createGenerateClassName } from '@material-ui/core/styles'
import { StylesProvider } from '@material-ui/styles'
import { ThemeProvider as FVThemeProvider } from '@naturacosmeticos/natds-react'
import { Provider as ThemeProvider } from '@naturacosmeticos/natds-web'
import { ConnectedRouter } from 'connected-react-router'
import { match } from 'react-router-dom'
import React from 'react'
import { Helmet } from 'react-helmet'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { initI18n } from '../infra/translation/i18next/i18n'
import { BaseLayout } from './components/Layout/BaseLayout'
import { GlobalStyle } from './components/Layout/GlobalStyle'
import {
  AuthenticatedUserContext,
  AuthenticatedUserProps,
  FormContext,
  FormContextProps,
  IdentityInfo,
  IdentityInfoProvider,
  NavigationContext,
  NavigationContextProps,
  RouterMatchContext,
  MiGroupProvider,
  CreditMiGrupoRedDataProps,
} from './contexts'
import { TenantConfigContext, TenantConfigProps } from './contexts/tenant-config-context'
import { useFVTheme } from './hooks/useFVTheme'
import { useTheme } from './hooks/useTheme'
import { PageSwitcher } from './page-switcher'

type Props = {
  identityInfo?: IdentityInfo
  authenticatedUser?: AuthenticatedUserProps
  page?: Page
  tenantConfig?: TenantConfigProps
  routerMatch?: match
  isMiGrupoRed: boolean
  creditMiGrupoRedData?: CreditMiGrupoRedDataProps
} & FormContextProps &
  NavigationContextProps

const generateClassName = createGenerateClassName({
  productionPrefix: 'registers-fe',
})

const Root: React.FC<Props> = ({
  page,
  identityInfo = {} as IdentityInfo,
  authenticatedUser = {},
  onSubmit,
  person,
  goToNextPage,
  goToPreviousPage,
  bypassStep,
  completedInformation,
  hasDigitalStore,
  tenantConfig = {},
  routerMatch,
  isMiGrupoRed,
  creditMiGrupoRedData,
}) => {
  const companyId = identityInfo?.companyId
  const language = getLanguage(identityInfo?.countryId, companyId)
  const i18n = initI18n(language)
  const currentTheme = useTheme(companyId).light
  const fvTheme = useFVTheme(companyId)

  return (
    <div role="treeitem">
      <FVThemeProvider theme={fvTheme} cssPrefix="registers-fe">
        <ThemeProvider theme={currentTheme} cssPrefix="registers-fe">
          <StylesProvider generateClassName={generateClassName} injectFirst>
            <StyledThemeProvider theme={currentTheme}>
              <Provider store={store}>
                <MiGroupProvider isMiGrupoRed={isMiGrupoRed} creditMiGrupoRedData={creditMiGrupoRedData}>
                  <IdentityInfoProvider info={identityInfo}>
                    <RouterMatchContext.Provider value={routerMatch}>
                      <TenantConfigContext.Provider value={tenantConfig}>
                        <I18nextProvider i18n={i18n}>
                          <NavigationContext.Provider
                            value={{
                            goToNextPage: () => {
                              localStorage.setItem('LastNavigationAction', 'Next')
                              goToNextPage()
                            },
                            goToPreviousPage: () => {
                              localStorage.setItem('LastNavigationAction', 'Previous')
                              goToPreviousPage()
                            },
                            bypassStep,
                          }}
                          >
                            <FormContext.Provider
                              value={{
                              onSubmit,
                              person,
                              completedInformation,
                              hasDigitalStore,
                            }}
                            >
                              <AuthenticatedUserContext.Provider value={authenticatedUser}>
                                <ConnectedRouter history={history}>
                                  <BaseLayout>
                                    <Helmet>
                                      <link
                                        href="https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@400;500&display=swap"
                                        rel="stylesheet"
                                      />
                                      <link
                                        rel="stylesheet"
                                        href="https://cdn.jsdelivr.net/npm/@naturacosmeticos/natds-icons@latest/dist/natds-icons.css"
                                      />
                                    </Helmet>
                                    <GlobalStyle />
                                    <PageSwitcher
                                      page={page}
                                      isHostedUIEnabled={tenantConfig.isHostedUIEnabled}
                                    />
                                  </BaseLayout>
                                </ConnectedRouter>
                              </AuthenticatedUserContext.Provider>
                            </FormContext.Provider>
                          </NavigationContext.Provider>
                        </I18nextProvider>
                      </TenantConfigContext.Provider>
                    </RouterMatchContext.Provider>
                  </IdentityInfoProvider>
                </MiGroupProvider>
              </Provider>
            </StyledThemeProvider>
          </StylesProvider>
        </ThemeProvider>
      </FVThemeProvider>
    </div>
  )
}

export default Root
