import React from 'react'
import { CardHeader } from '@naturacosmeticos/natds-web'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { ClassNameMap } from '@naturacosmeticos/natds-web/node_modules/@material-ui/styles'
import { CardHeaderClassKey } from '@naturacosmeticos/natds-web/node_modules/@material-ui/core'

type HeaderProps = {
  title: string
  classes: Partial<ClassNameMap<CardHeaderClassKey>>
  subheader: string
}

export const Header = ({ title, classes, subheader }: HeaderProps) => (
  <CardHeader
    title={title}
    classes={classes}
    subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
    subheader={
      <ParseStringToReact stringToParse={subheader} />
    }
  />
)
