import { RemoteGetPersonData } from '@/data/use-cases/person'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { GetPersonData } from '@/domain/use-cases/person'

export const makeGetPersonData = (): GetPersonData => {
  const apiUrl = '/people/:personId'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteGetPersonData = new RemoteGetPersonData(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteGetPersonData
}
