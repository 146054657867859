import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteCheckValidationCode } from '@/data/use-cases/register-maintenance-validation-code/remote-check-validation-code'

export const makeRemoteCheckValidationCode = (): RemoteCheckValidationCode => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteCheckValidationCode(
    httpGetClient,
    xApiKey,
  )
}
