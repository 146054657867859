import React, { useContext } from 'react'
import { Typography } from '@naturacosmeticos/natds-web'
import { StepPropsType } from '@/main/pages/digital-store/pages/digital-store-status-page/vendors-linking-steps/mercado-pago/step'

import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusIntroductionStepMessages } from '@/domain/models/messages/messages'
import { IdentityInfoContext } from '@/main/contexts'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import {
  StyledContainer,
  StyledIntroductionContainer,
  StyledLink,
} from './introduction-step.styles'

export const IntroductionStep: React.FC<StepPropsType> = ({ advanceStep }) => {
  const { tenantId } = useContext(IdentityInfoContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusIntroductionStep, pageMessagesOptions).messages as DigitalStoreStatusIntroductionStepMessages

  return (
    <StyledContainer>
      <StyledIntroductionContainer>
        <img src={vendor.brand} alt={vendor.name} />
        <span>
          <Typography variant="subtitle1">
            {messages.title}
          </Typography>
          <Typography color="textSecondary">
            {messages.text}
          </Typography>
          <StyledLink
            align="center"
            variant="body1"
            underline="none"
            onClick={() => advanceStep()}
          >
            {messages.buttonStartLabel}
          </StyledLink>
        </span>
      </StyledIntroductionContainer>
    </StyledContainer>
  )
}
