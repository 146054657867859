export enum CompleteRegistrationTypes {
  COMPLETE_REGISTRATION_REQUEST = '@complete-registration/COMPLETE_REGISTRATION_REQUEST',
  COMPLETE_REGISTRATION_SUCCESS = '@complete-registration/COMPLETE_REGISTRATION_SUCCESS',
  COMPLETE_REGISTRATION_ERROR = '@complete-registration/COMPLETE_REGISTRATION_ERROR',
}

export interface CompleteRegistrationState {
  readonly error: boolean
  readonly isLoading: boolean
}
