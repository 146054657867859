import { DigitalStoreStatusAccountStepMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'
import { DigitalStoreStatusAccountStepMessageOptions } from '../../../../domain/models/messages/messages.d'

export function getDigitalStoreStatusAccountStepMessages(translate: TFunction, options: DigitalStoreStatusAccountStepMessageOptions): DigitalStoreStatusAccountStepMessages {
  return {
    hint: translate('digitalStoreStatus.steps.account.hint', options),
    confirmation: {
      question: translate('digitalStoreStatus.steps.account.confirmation.question', options),
      affirmativeAnswer: translate('digitalStoreStatus.steps.account.confirmation.affirmativeAnswer', options),
      negativeAnswer: translate('digitalStoreStatus.steps.account.confirmation.negativeAnswer', options),
    },
  }
}
