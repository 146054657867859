export enum PERSON_SUBSTATUS_ID {
  DUPLICATED_EMAIL = 1,
  SALES_HIERARCHY_NOT_FOUND = 2,
  NAME_VALIDATION = 4,
  PENDING_DATA = 8,
  CEP_VALIDATION_FAIL = 16,
  TERMS_OF_ACCEPTANCE = 35,
  EMAIL_RESTRICTION = 36,
  DOCUMENT_VALIDATION_FAIL = 39,
  ADDRESS_INCOMPLETE = 66,
  FINAL_VALIDATION = 67,
  PENDING_DIGITAL_STORE = 73,
}
