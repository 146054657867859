import { SendCodeValidationParams } from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'
import { makeRemoteSendCodeValidation } from '@/main/factories/remote/register-maintenance-security-validation/make-remote-send-code-validation'
import { GetCnContactsParams, ResponseGetCNContacts } from '@/data/use-cases/register-maintenance-security-validation/remote-get-cn-contacts'
import { makeRemoteGetCNContacts } from '@/main/factories/remote/register-maintenance-security-validation/make-remote-get-cn-contacts'

export type SecurityValidationPageApi = {
  getCNContacts: (data: GetCnContactsParams) => Promise<ResponseGetCNContacts>
  sendCodeValidation: (data: SendCodeValidationParams) => Promise<void>
}

export const makeSecurityValidationPageApi = (): SecurityValidationPageApi => {

  const remoteGetCNContacts = makeRemoteGetCNContacts()
  const getCNContacts = (params: GetCnContactsParams) => remoteGetCNContacts.get(params)

  const remoteSendCodeValidation = makeRemoteSendCodeValidation()
  const sendCodeValidation = (params: SendCodeValidationParams) => remoteSendCodeValidation.send(params)

  return {
    getCNContacts,
    sendCodeValidation
  }
}
