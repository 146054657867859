import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { PersonalDataForm } from '@/domain/models/personal-data/personal-data'

export type GetPersonalDataHeaders = {
  countryid: CountryId,
  companyid: CompanyId,
  'x-api-key': string,
}

export type GetPersonalDataParams = {
  countryid: CountryId,
  companyid: CompanyId,
  personId: string,
}

export class RemoteGetPersonalData {
  constructor(
    private readonly httpGetClient: HttpGetClient<GetPersonalDataHeaders, PersonalDataForm>,
    private readonly xApiKey: string,
  ) {}

  get = async ({
    countryid,
    companyid,
    personId,
  }: GetPersonalDataParams): Promise<PersonalDataForm> => {
    const headers: GetPersonalDataHeaders = {
      countryid,
      companyid,
      'x-api-key': this.xApiKey,
    }

    const httpResponse = await this.httpGetClient.get({
      url: `/personal-data/get/${personId}`,
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
