import { HttpPostClient, HttpStatusCode } from '@/data/protocols/http'
import { BadRequestError, ConflictError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { PreRegisterParams, PreRegisterComplete } from '@/domain/use-cases/pre-register'
import { PersonData } from '@/domain/use-cases/person'
import { Document } from '@/domain/models/documents/documents'

export type PreRegisterCompleteHeaders = {
  countryid: CountryId
  companyid: CompanyId
  'x-api-key': string
  authorization: string
}
type PreRegisterCompleteBody = {
  documentType: Document
  documentValue: string
  registrantId: string
  introducerSellerId: string
  phoneNumber?: string

  userRole?: string
}

type PreRegisterDocument = {
  type: Document
  document: string
}
type CompletePreRegisterEndpointResponse = {
  personId: string
  documents: PreRegisterDocument[]
  phoneNumber?: string
  additionalInformation: any
}

type CompletePreRegisterEndpointConflictErrorResponse = { error: { code: string } }

export class RemotePreRegisterComplete implements PreRegisterComplete {
  constructor(
    private readonly httpPostClient: HttpPostClient<
      PreRegisterCompleteHeaders,
      PreRegisterCompleteBody,
      CompletePreRegisterEndpointResponse | CompletePreRegisterEndpointConflictErrorResponse
    >,
    private readonly xApiKey: string
  ) {}

  complete = async ({
    countryid,
    companyid,
    authorization,
    documentType,
    documentValue,
    registrantId,
    introducerSellerId,
    phoneNumber,
    userRole,
  }: PreRegisterParams): Promise<PersonData> => {
    const headers: PreRegisterCompleteHeaders = {
      countryid,
      companyid,
      'x-api-key': this.xApiKey,
      authorization,
    }

    const body: PreRegisterCompleteBody = {
      documentType,
      documentValue,
      registrantId,
      introducerSellerId,
      phoneNumber,
      userRole,
    }

    const httpResponse = await this.httpPostClient.post({
      url: '/pre-register/complete',
      headers,
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body as CompletePreRegisterEndpointResponse
      case HttpStatusCode.conflict:
        throw new ConflictError(httpResponse.body)
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
