import { all, takeLatest } from 'redux-saga/effects'
import {
  checkDigitalStoreAvailability,
  saveDigitalStore,
  getDigitalStoreInfo,
  resetDigitalStoreStateOnChange,
} from '@/infra/global-state/redux/store/ducks/digital-store/sagas'
import {
  completeRegistration,
} from '@/infra/global-state/redux/store/ducks/complete-registration/sagas'
import { DigitalStoreTypes } from './digital-store/protocols'
import { CompleteRegistrationTypes } from './complete-registration'

export function* rootSaga() {
  yield all([
    takeLatest(
      DigitalStoreTypes.CHECK_DIGITAL_STORE_AVAILABILITY_REQUEST_BRASIL,
      checkDigitalStoreAvailability,
    ),
    takeLatest(
      DigitalStoreTypes.SAVE_DIGITAL_STORE_REQUEST_BRASIL,
      saveDigitalStore,
    ),
    takeLatest(
      DigitalStoreTypes.GET_DIGITAL_STORE_INFO_REQUEST_BRASIL,
      getDigitalStoreInfo,
    ),
    takeLatest(
      DigitalStoreTypes.RESET_DIGITAL_STORE_ON_CHANGE_REQUEST,
      resetDigitalStoreStateOnChange,
    ),
    takeLatest(
      CompleteRegistrationTypes.COMPLETE_REGISTRATION_REQUEST,
      completeRegistration,
    ),
  ])
}
