import { ADDRESS_STORAGE_NAME } from '@/domain/models/address/address-storge'
import { removeItemInStorage } from '@/main/hooks/useStorage'

const SOURCESYSTEM_MINEGOCIO = '50'

export const redirectToProfile = (personId, sourceSystem = null) => {
  removeItemInStorage(ADDRESS_STORAGE_NAME)
  if (sourceSystem && sourceSystem === SOURCESYSTEM_MINEGOCIO) {
    window.location.assign('/nuevo-perfil')
  } else {
    window.location.assign(`/webfv/mfe-register/profile/${personId}`)
  }
}

export const redirectToHome = () => {
  removeItemInStorage(ADDRESS_STORAGE_NAME)
  window.location.assign('/webfv')
}

export const redirectToFirstPage = () => {
  window.location.assign('/auto/pre-register')
}
