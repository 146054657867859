import { CHANNEL_STORAGE_NAME, SEND_CODE_VALIDATION_ORIGIN, URL_PROFILE_CN } from '@/domain/models/address'
import { CountryId } from '@/domain/models/country'
import { MaintenancePhoneEmailCompletedMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { PHONE_EMAIL_STORAGE_NAME } from '@/domain/models/person/phone-email-storge'
import ApprovedIcon from '@/main/assets/svgs/approved_icon_thin.svg'
import { StatusCardContainer } from '@/main/components/status-card-container'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { removeItemInStorage } from '@/main/hooks/useStorage'
import { createUrlAddressMaintenance } from '@/utils/create-url-address-maintenance'
import { Button } from '@naturacosmeticos/natds-react'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import {
  ActionContainer,
  ContentSuccess,
  Subtitle,
  SuccessTitle,
  Text,
} from './register-maintenance-phone-email-approved.styles'

export const MaintenancePhoneEmailApproved: React.FC = () => {
  const { personId, consultantId, countryId } = useContext(IdentityInfoContext)

  const messages = usePageMessages(Page.MaintenancePhoneEmailApproved)
    .messages as MaintenancePhoneEmailCompletedMessages

  const redirectToProfile = () => {
    removeItemInStorage(PHONE_EMAIL_STORAGE_NAME)
    removeItemInStorage(CHANNEL_STORAGE_NAME)
    removeItemInStorage(SEND_CODE_VALIDATION_ORIGIN)
    if (consultantId === personId) {
      const url =
        countryId === CountryId.Peru || countryId === CountryId.Colombia || countryId === CountryId.Chile
          ? 'nuevo-perfil'
          : `mfe-register/profile/${personId}`

      const urlProfile = createUrlAddressMaintenance({
        url,
        country: countryId,
      })

      window.location.assign(urlProfile)
    } else {
      window.location.assign(localStorage.getItem(URL_PROFILE_CN) || '/webfv')
    }
  }

  return (
    <StatusCardContainer>
      <ContentSuccess>
        <img
          alt={messages.approvedIcon}
          src={ApprovedIcon}
          aria-label="approved-image"
          data-testid="approved-image"
        />
        <Helmet>
          <title>{messages.title}</title>
        </Helmet>
        <SuccessTitle variant="h5" className="success_title">
          {messages.title}
        </SuccessTitle>
        <Subtitle>
          <Text align="center" variant="subtitle1">
            {messages.subtitle}
          </Text>
        </Subtitle>
        <ActionContainer>
          <Button onClick={redirectToProfile}>{messages.myProfileButton}</Button>
        </ActionContainer>
      </ContentSuccess>
    </StatusCardContainer>
  )
}
