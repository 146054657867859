import { useContext } from 'react'
import { SignatureTutorialContext } from '../context/SignatureTutorialContext'

export const useSignatureTutorial = () => {
  const { tutorialStep, setTutorialStep } = useContext(SignatureTutorialContext)

  const goToTutorialPreviousStep = () => {
    if (tutorialStep > 0) {
      const newTutorialStep = tutorialStep - 1
      setTutorialStep(newTutorialStep)
    }
  }

  const goToTutorialNextStep = () => {
    if (tutorialStep < 2) {
      const newTutorialStep = tutorialStep + 1
      setTutorialStep(newTutorialStep)
    }
  }

  const resetTutorialStep = () => {
    setTutorialStep(0)
  }

  return {
    tutorialStep,
    goToTutorialPreviousStep,
    goToTutorialNextStep,
    resetTutorialStep,
  }
}
