import {
  CenteredLoadingContainer,
  LoadingContainer,
} from '@/main/components/pages-structures/container.styles'
import { ProgressIndicator } from '@naturacosmeticos/natds-web'
import React from 'react'

type MyProfileLoaderProps = {
  loading: boolean
  children: React.ReactNode
}

export const MyProfileLoader: React.FC<MyProfileLoaderProps> = ({ children, loading }) => {
  if (loading) {
    return (
      <LoadingContainer>
        <CenteredLoadingContainer>
          <ProgressIndicator />
        </CenteredLoadingContainer>
      </LoadingContainer>
    )
  }

  return <>{children}</>
}
