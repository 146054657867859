import { CustomChangeEvent } from '@/main/components/text-field/helper/handle-onchange'

type handleValidCharsProps = {
  event: CustomChangeEvent,
  validChars?: RegExp
}

export const handleValidChars = ({
  event,
  validChars,
}: handleValidCharsProps): boolean => {
  const { value } = event.currentTarget

  const shouldUpdateValue = !validChars || validChars.test(value) || value === ''

  return shouldUpdateValue
}
