import { PendingSignatureParams, PendingSignatureResponse } from '@/domain/use-cases/pending-signature'
import { makeRemotePendingSignature } from '@/main/factories/remote/pending-signature'

export type PendingSignaturePageApi = {
  submit: (data: PendingSignatureParams) => Promise<PendingSignatureResponse>
}

export const makePendingSignaturePageApi = (): PendingSignaturePageApi => {
  const pendingSignatureGetter = makeRemotePendingSignature()
  const submit = (data: PendingSignatureParams) => pendingSignatureGetter.submit(data)

  return {
    submit,
  }
}
