import { DigitalStoreStatusIntroductionStepMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'
import { DigitalStoreStatusIntroductionStepMessageOptions } from '../../../../domain/models/messages/messages.d'

export function getDigitalStoreStatusIntroductionStepMessages(translate: TFunction, options: DigitalStoreStatusIntroductionStepMessageOptions): DigitalStoreStatusIntroductionStepMessages {
  return {
    title: translate('digitalStoreStatus.steps.introduction.title', options),
    text: translate('digitalStoreStatus.steps.introduction.text', options),
    buttonStartLabel: translate('digitalStoreStatus.steps.introduction.buttonStartLabel', options),
  }
}
