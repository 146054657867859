import React from 'react'
import { ControlledSelect } from '@/main/components'
import { FieldsOrderProps } from '@/domain/models/tenant/tenant-configs/configs'

export const CologneField = (fieldsOrderProps: FieldsOrderProps) => {
  const { messages, configs, control, address, id } = fieldsOrderProps
  return (
    <>
      <ControlledSelect
        id={id || 'cologne'}
        label={messages.label}
        placeholder={messages.placeholder}
        rules={{
          maxLength: {
            message: messages.rules.maxLength,
            value: configs.rules.maxLength,
          },
          required: {
            message: messages.rules.required,
            value: configs.rules.required,
          },
        }}
        control={control}
        options={address?.map((option) => {
          return {
            label: option.cologne || option,
            value: option.cologne || option,
            externalCode: option.externalCode || '',
          }
        })}
      />
    </>
  )
}
