import { DigitalStoreStatusDefaultContentMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'
import { DigitalStoreStatusDefaultContentMessageOptions } from '../../../../domain/models/messages/messages.d'
import { getDigitalStoreStatusAllStatusMessages } from './messages.digitalStoreStatusAllStatusMessages'

export function getDigitalStoreStatusDefaultContentMessages(translate: TFunction, options: DigitalStoreStatusDefaultContentMessageOptions): DigitalStoreStatusDefaultContentMessages {
  return {
    digitalStoreUrl: translate('digitalStoreStatus.link', options),
    digitalStoreDigitalSpaceAddress: translate('digitalStoreStatus.linkTitle', options),
    contactInfoHelpTitle: translate('digitalStoreStatus.contactInfoHelp.title', options),
    contactInfoHelp: translate('digitalStoreStatus.contactInfoHelp.text', options),
    statusMessages: getDigitalStoreStatusAllStatusMessages(translate, options),
  }
}
