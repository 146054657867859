import React from 'react'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { Dialog, DialogContent, DialogTitle } from '@/main/components/dialog'
import { Spacing } from '@/main/components/spacing'
import { ProgressIndicator } from '@/main/components/progress-indicator'

type DialogWithLoaderProps = {
  componentClasses: ClassNameMap,
  text: string,
  isOpen: boolean,
}

export const DialogWithLoader: React.FC<DialogWithLoaderProps> = ({ componentClasses, isOpen, text }) => (
  <Dialog open={isOpen}>
    <DialogTitle>
      {text}
    </DialogTitle>
    <Spacing
      className="natds2"
      display="flex"
      marginBottom="standard"
    >
      <DialogContent className={componentClasses.dialogContent}>
        <ProgressIndicator size={48} />
      </DialogContent>
    </Spacing>
  </Dialog>
)
