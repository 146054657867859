import { DigitalStoreCurrentTypes } from '@/domain/models/digital-store'
import { DigitalStoreStatusAllStatusMessages, DigitalStoreStatusCurrentStatusMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'
import { DigitalStoreStatusAllStatusMessageOptions } from '../../../../domain/models/messages/messages.d'

const getMessagesForActiveStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.APPROVED,
  title: translate('digitalStoreStatus.statusMessages.active.title', options),
  description: translate('digitalStoreStatus.statusMessages.active.description', options),
  footer: translate('digitalStoreStatus.statusMessages.active.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.active.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.active.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.active.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.active.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.active.warning.message', options),
  },
})

const getMessagesForCeasedStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.REJECTED,
  title: translate('digitalStoreStatus.statusMessages.ceased.title', options),
  description: translate('digitalStoreStatus.statusMessages.ceased.description', options),
  footer: translate('digitalStoreStatus.statusMessages.ceased.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.ceased.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.ceased.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.ceased.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.ceased.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.ceased.warning.message', options),
  },
})

const getMessagesForDeniedBankAccountStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.REJECTED,
  title: translate('digitalStoreStatus.statusMessages.deniedBankAccount.title', options),
  description: translate('digitalStoreStatus.statusMessages.deniedBankAccount.description', options),
  footer: translate('digitalStoreStatus.statusMessages.deniedBankAccount.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.deniedBankAccount.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.deniedBankAccount.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.deniedBankAccount.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.deniedBankAccount.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.deniedBankAccount.warning.message', options),
  },
})

const getMessagesForDeniedPaymentAccountStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.REJECTED,
  title: translate('digitalStoreStatus.statusMessages.deniedPaymentAccount.title', options),
  description: translate('digitalStoreStatus.statusMessages.deniedPaymentAccount.description', options),
  footer: translate('digitalStoreStatus.statusMessages.deniedPaymentAccount.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.deniedPaymentAccount.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.deniedPaymentAccount.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.deniedPaymentAccount.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.deniedPaymentAccount.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.deniedPaymentAccount.warning.message', options),
  },
})

const getMessagesForNotStartedStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: undefined,
  title: translate('digitalStoreStatus.statusMessages.notStarted.title', options),
  description: translate('digitalStoreStatus.statusMessages.notStarted.description', options),
  footer: translate('digitalStoreStatus.statusMessages.notStarted.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.notStarted.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.notStarted.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.notStarted.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.notStarted.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.notStarted.warning.message', options),
  },
})

const getMessagesForPendingStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.PENDING,
  title: translate('digitalStoreStatus.statusMessages.pending.title', options),
  description: translate('digitalStoreStatus.statusMessages.pending.description', options),
  footer: translate('digitalStoreStatus.statusMessages.pending.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.pending.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.pending.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.pending.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.pending.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.pending.warning.message', options),
  },
})

const getMessagesForPendingAgreementsStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.PENDING,
  title: translate('digitalStoreStatus.statusMessages.pendingAgreements.title', options),
  description: translate('digitalStoreStatus.statusMessages.pendingAgreements.description', options),
  footer: translate('digitalStoreStatus.statusMessages.pendingAgreements.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.pendingAgreements.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.pendingAgreements.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.pendingAgreements.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.pendingAgreements.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.pendingAgreements.warning.message', options),
  },
})

const getMessagesForPendingBankAccountStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.PENDING,
  title: translate('digitalStoreStatus.statusMessages.pendingBankAccount.title', options),
  description: translate('digitalStoreStatus.statusMessages.pendingBankAccount.description', options),
  footer: translate('digitalStoreStatus.statusMessages.pendingBankAccount.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.pendingBankAccount.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.pendingBankAccount.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.pendingBankAccount.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.pendingBankAccount.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.pendingBankAccount.warning.message', options),
  },
})

const getMessagesForPendingFranchiseAgreementStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.APPROVED,
  title: translate('digitalStoreStatus.statusMessages.pendingFranchiseAgreement.title', options),
  description: translate('digitalStoreStatus.statusMessages.pendingFranchiseAgreement.description', options),
  footer: translate('digitalStoreStatus.statusMessages.pendingFranchiseAgreement.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.pendingFranchiseAgreement.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.pendingFranchiseAgreement.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.pendingFranchiseAgreement.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.pendingFranchiseAgreement.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.pendingFranchiseAgreement.warning.message', options),
  },
})

const getMessagesForPendingPaymentAccountStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.PENDING,
  title: translate('digitalStoreStatus.statusMessages.pendingPaymentAccount.title', options),
  description: translate('digitalStoreStatus.statusMessages.pendingPaymentAccount.description', options),
  footer: translate('digitalStoreStatus.statusMessages.pendingPaymentAccount.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.pendingPaymentAccount.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.pendingPaymentAccount.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.pendingPaymentAccount.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.pendingPaymentAccount.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.pendingPaymentAccount.warning.message', options),
  },
})

const getMessagesForSuspendedStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.REJECTED,
  title: translate('digitalStoreStatus.statusMessages.suspended.title', options),
  description: translate('digitalStoreStatus.statusMessages.suspended.description', options),
  footer: translate('digitalStoreStatus.statusMessages.suspended.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.suspended.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.suspended.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.suspended.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.suspended.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.suspended.warning.message', options),
  },
})

const getMessagesForUnableToSellStatus = (translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusCurrentStatusMessages => ({
  type: DigitalStoreCurrentTypes.PENDING,
  title: translate('digitalStoreStatus.statusMessages.unableToSell.title', options),
  description: translate('digitalStoreStatus.statusMessages.unableToSell.description', options),
  footer: translate('digitalStoreStatus.statusMessages.unableToSell.footer', options),
  shortTitle: translate('digitalStoreStatus.statusMessages.unableToSell.shortTitle', options),
  subtitle: translate('digitalStoreStatus.statusMessages.unableToSell.subtitle', options),
  message: translate('digitalStoreStatus.statusMessages.unableToSell.message', options),
  warning: {
    title: translate('digitalStoreStatus.statusMessages.unableToSell.warning.title', options),
    message: translate('digitalStoreStatus.statusMessages.unableToSell.warning.message', options),
  },
})

export function getDigitalStoreStatusAllStatusMessages(translate: TFunction, options: DigitalStoreStatusAllStatusMessageOptions): DigitalStoreStatusAllStatusMessages {
  return ({
    ACTIVE: getMessagesForActiveStatus(translate, options),
    CEASED: getMessagesForCeasedStatus(translate, options),
    DENIED_BANK_ACCOUNT: getMessagesForDeniedBankAccountStatus(translate, options),
    DENIED_PAYMENT_ACCOUNT: getMessagesForDeniedPaymentAccountStatus(translate, options),
    NOT_STARTED: getMessagesForNotStartedStatus(translate, options),
    PENDING: getMessagesForPendingStatus(translate, options),
    PENDING_AGREEMENTS: getMessagesForPendingAgreementsStatus(translate, options),
    PENDING_BANK_ACCOUNT: getMessagesForPendingBankAccountStatus(translate, options),
    PENDING_FRANCHISE_AGREEMENT: getMessagesForPendingFranchiseAgreementStatus(translate, options),
    PENDING_PAYMENT_ACCOUNT: getMessagesForPendingPaymentAccountStatus(translate, options),
    SUSPENDED: getMessagesForSuspendedStatus(translate, options),
    UNABLE_TO_SELL: getMessagesForUnableToSellStatus(translate, options),
  })
}
