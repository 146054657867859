import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const Container = styled.div`
text-align: left;
margin-bottom: 4rem;

@media ${minDeviceSizeForMediaQueries.tablet} {
  h4 {
   margin-left: -64px;
  }

}

h4 {
    font-size: 2rem;
    letter-spacing: 0.16px;
    margin-bottom: 1.6rem;
}
&:last-child {
    font-size: 1.6rem;
    line-height: 24px;
    letter-spacing: 0.51;
    text-align: 'center';
    a {
      color: ${({ theme }) => theme.color.link};
    }
}
`
