import React, { useState, useEffect } from 'react'
import {
  Spacing, Typography, Icon,
} from '@naturacosmeticos/natds-web'
import {
  CountdownLinkContainer, LinkWithoutDecoration,
} from '@/main/components/countdown-link/countdown-link.styles'

type CountdownLinkProps = {
  onClick: () => Promise<any>
  timerMessageMinutes?: string
  timerMessageSeconds: string
  linkText: string
  countdown?: number
}

export const CountdownLink: React.FC<CountdownLinkProps> = ({
  onClick,
  timerMessageSeconds,
  timerMessageMinutes,
  linkText,
  countdown = 30,
}) => {
  const [updatedCountdown, setUpdatedCountdown] = useState(countdown)
  const [showCountdown, setShowCountdown] = useState(false)

  const countdownCodeMessage = () => {  
    if (updatedCountdown >= 60) {
      const minutes = new Date(updatedCountdown * 1000).toISOString().substring(14, 19)
      return timerMessageMinutes.replace('{countdown}', minutes.toString())
    }
    return timerMessageSeconds.replace('{countdown}', updatedCountdown.toString())
  }

  useEffect(() => {
    let timer
    if (showCountdown) {
      timer = setTimeout(() => {
        setUpdatedCountdown(updatedCountdown - 1)
      }, 1000)

      if (updatedCountdown === 0) {
        setShowCountdown(false)
      }
    }

    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCountdown, updatedCountdown])

  useEffect(() => {
    startCountdown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const startCountdown = () => {
    setUpdatedCountdown(countdown)
    setShowCountdown(true)
  }

  const onLinkClick = async () => {
    if (!showCountdown) {
      startCountdown()
      await onClick()
    }
  }

  return (
    <CountdownLinkContainer>
      {showCountdown && (
        <Spacing display="flex" mb="small">
          <Spacing mr="tiny">
            <Icon
              data-testid="status-icon"
              name="outlined-action-clock"
              size="tiny"
              color="primary"
            />
          </Spacing>

          <Spacing mt="micro">
            <Typography data-testid="timer-text-message" variant="body2">
              {countdownCodeMessage()}
            </Typography>
          </Spacing>
        </Spacing>
      )}

      {!showCountdown && (
        <LinkWithoutDecoration
          onClick={onLinkClick}
          variant="body1"
        >
          {linkText}
        </LinkWithoutDecoration>
      )}
    </CountdownLinkContainer>
  )
}
