import { RemoteGetMyProfile } from '@/data/use-cases/my-profile/remote-get-my-profile-data'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeMyProfileGetData = (): RemoteGetMyProfile => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteGetMyProfile(httpGetClient, xApiKey)
}
