import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { NotFoundError, ServerError, BadRequestError, UnprocessableEntityError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors' 
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { CnCreditDataResponse, CnCreditDataResponseError, GetCnCreditData, GetCnCreditDataParams } from '@/domain/use-cases/person/minnegocio/get-cn-credit-data'

  export type GetCnCreditHeaders = {
    'x-api-key': string
    'countryId': CountryId
    'companyId': CompanyId
    'authorization': string
  }
  export class RemoteGetCnCredit implements GetCnCreditData {
    constructor(
      private readonly url: string,
      private readonly httpGetClient: HttpGetClient<GetCnCreditHeaders, CnCreditDataResponse>,
      private readonly xApiKey: string,
    ) {}

    get = async (params: GetCnCreditDataParams): Promise<CnCreditDataResponse | CnCreditDataResponseError | {}> => {
      const headers: GetCnCreditHeaders = {
        'x-api-key': this.xApiKey,
        'countryId': params.countryId,        
        'companyId': params.companyId,
        'authorization': params.authorization,
      }
      const httpResponse = await this.httpGetClient.get({
        url: '/person/credit-data',
        headers,
      })
      switch (httpResponse.statusCode) {
        case HttpStatusCode.ok:
          return httpResponse.body
        case HttpStatusCode.notFound:
          throw new NotFoundError(httpResponse.body)
        case HttpStatusCode.serverError:
          return new ServerError(httpResponse.body)
        case HttpStatusCode.badRequest:
          return new BadRequestError(httpResponse.body)
        case HttpStatusCode.unprocessableEntity:
          return new UnprocessableEntityError(httpResponse.body)
        default:
          throw new UnexpectedError()
      }
    }
  }