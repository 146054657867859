import React from 'react'
import { DigitalStoreTermsEnum, DigitalStoreAcceptTermsStrategy } from '@/domain/models/digital-store/digital-store-terms-type'
import { AcceptTermsProps } from '@/main/components/accept-terms/accept-terms'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { Spacing } from '@/main/components/spacing'
import { DefaultAcceptTerms } from './accept-terms-strategy/default-accept-terms'

const Loader = () => (
  <Spacing marginTop="small">
    <ProgressIndicator
      data-testid="accept-terms-loading"
      size={24}
    />
  </Spacing>
)

export type AgreementsTermsProps = {
  isLoading: boolean,
  shouldHide: boolean,
  acceptTermsProps: AcceptTermsProps,
  acceptTermsStrategy: DigitalStoreAcceptTermsStrategy,
}

export const AgreementsTerms = ({
 acceptTermsStrategy, acceptTermsProps, shouldHide, isLoading,
}: AgreementsTermsProps) => {
  const acceptTermsStrategies = {
    [DigitalStoreTermsEnum.DEFAULT]: <DefaultAcceptTerms {...acceptTermsProps} />,
    [DigitalStoreTermsEnum.EMPTY]: <></>,
  }

  return (
    <>
      {
        !shouldHide && (
        <div data-testid="digital-store-terms-wrapper">
          {
            isLoading ? <Loader /> : (
              acceptTermsStrategies[acceptTermsStrategy] || <DefaultAcceptTerms {...acceptTermsProps} />
            )
          }
        </div>
        )
      }
    </>
  )
}
