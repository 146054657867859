import {
  useReducer, useState, useRef, useEffect,
 } from 'react'
import { mutate } from 'swr'

const REQUEST_STATUS_TYPE = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
}

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case REQUEST_STATUS_TYPE.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        error: null,
      }
    case REQUEST_STATUS_TYPE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload,
        error: null,
      }
    case REQUEST_STATUS_TYPE.FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

type Options = {
  client?: any
  initialData?: any
  shouldFetchOnStart?: boolean
  onSuccessCallback?: Function
  onErrorCallback?: Function
  onBeforeRequestCallback?: Function
  mutateBeforeRequest?: any[]
  mutateOnSuccess?: any[]
  mutateOnError?: any[]
}

interface Output<DataT, ErrorT> {
  data: DataT
  isLoading: boolean
  hasError: boolean
  error: ErrorT
  doRequest: Function
}

const defaultOptions = {
  client: null,
  initialData: undefined,
  shouldFetchOnStart: false,
  onSuccessCallback: undefined,
  onErrorCallback: undefined,
  onBeforeRequestCallback: undefined,
  mutateBeforeRequest: [],
  mutateOnSuccess: [],
  mutateOnError: [],
}

 export const useHttpRequest = <DataT = any, ErrorT = any>(
   initialParams: any = {},
   initialOptions: Options = defaultOptions,
 ): Output<DataT, ErrorT> => {
   const isAllowedToFetch = useRef(false)
   const [state, dispatch] = useReducer(dataFetchReducer, {
     isLoading: false,
     hasError: false,
     data: initialOptions.initialData,
     error: null,
   })
   const [params, setParams] = useState<any>(
     initialParams,
   )
   const [options, setOptions] = useState<Options>({
     ...defaultOptions,
     ...initialOptions,
   })

   useEffect(() => {
     if (options.shouldFetchOnStart) {
       isAllowedToFetch.current = true
     }

     // eslint-disable-next-line
   }, [])

   useEffect(() => {
     const fetchData = async () => {
       dispatch({
         type: REQUEST_STATUS_TYPE.FETCH_REQUEST,
       })
       try {
         const { client } = initialOptions
         if (options.onBeforeRequestCallback) {
           options.onBeforeRequestCallback()
         }
         if (options.mutateBeforeRequest) {
           options.mutateBeforeRequest.forEach((mutateItem) => {
             mutate(mutateItem.key, mutateItem.data, mutateItem.shouldRevalidate)
           })
         }
         const data = await client(params)
         if (options.onSuccessCallback) {
           options.onSuccessCallback(data)
         }
         if (options.mutateOnSuccess) {
           options.mutateOnSuccess.forEach((mutateItem) => {
             mutate(mutateItem.key, mutateItem.data, mutateItem.shouldRevalidate)
           })
         }

         dispatch({
           type: REQUEST_STATUS_TYPE.FETCH_SUCCESS,
           payload: data,
         })
       } catch (error) {
         if (options.onErrorCallback) {
           options.onErrorCallback(error)
         }
         if (options.mutateOnError) {
           options.mutateOnError.forEach((mutateItem) => {
             mutate(mutateItem.key, mutateItem.data, mutateItem.shouldRevalidate)
           })
         }
         handleErrorResponse(error)
       }

       function handleErrorResponse(error) {
        dispatch({
          type: REQUEST_STATUS_TYPE.FETCH_ERROR,
          payload: {
            status: error.statusCode,
            data: { ...error?.data?.error },
          },
        })
       }
     }

     if (isAllowedToFetch.current) {
       fetchData()
     }
     // eslint-disable-next-line
   }, [params])

   function doRequest(params = {}, options = {}) {
     isAllowedToFetch.current = true
     setOptions({ ...initialOptions, ...options })
     setParams({ ...initialParams, ...params })
   }

   return {
      data: state.data,
      isLoading: state.isLoading,
      hasError: state.hasError,
      error: state.error,
     doRequest,
   }
 }
