import React, { ReactNode } from 'react'
import { CardTitle } from '@/main/components'
import styled from 'styled-components'

type CardHeaderProps = {
  title: string | ReactNode,
  subtitle?: string | ReactNode,
}

const HeaderContainer = styled.div`
  padding: 16px
`

export const CardHeader : React.FC<CardHeaderProps> = ({ title, subtitle }) => (
  <HeaderContainer>
    <CardTitle title={title} subtitle={subtitle} />
  </HeaderContainer>
)
