import { HttpStatusCode } from '@/data/protocols/http'
import { PersonalDataMessages } from '@/domain/models/messages/messages'
import { SearchAddressWithZipCodeForm } from '@/domain/models/personal-data/personal-data'
import { AddressConfigs, PersonalDataConfigs } from '@/domain/models/tenant/tenant-configs'
import { GetAddressByZipCodeParams, GetAddressByZipCodeResponse } from '@/domain/use-cases/address/zip-code'
import { ZipCodeTextField } from '@/main/components'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { Skeleton } from '@/main/components/skeleton'
import { IdentityInfoContext } from '@/main/contexts'
import { useDebounce } from '@/main/hooks/debounce'
import React, { useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { AddressOrder } from '@/domain/models/address'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import {
  CityField,
  CologneField,
  ComplementField,
  NeighborhoodField,
  NumberField,
  ReferencesField,
  StateField,
  StreetField,
} from '../fields/index'
import { GridSkeletonContainer, GridSkeletonField } from './search-address-with-zip-code-fields.styles'
import { loadingSkeletonKeys } from '../utils/loading-skeleton'

type FieldValues = SearchAddressWithZipCodeForm

type Page = {
  configs: PersonalDataConfigs
  messages: PersonalDataMessages
  addressConfigs: AddressConfigs
}

type ZipCodeFieldStatus = {
  isLoading: boolean
}

type DialogMessage = string

type Dialog = boolean

type Api = {
  getAddressByZipCodeApi: (params: GetAddressByZipCodeParams) => Promise<GetAddressByZipCodeResponse>
}

interface SearchAddressWithZipCodeFieldsProps {
  page: Page
  api: Api
  disableFieldsPersonalData?: boolean
  setAddressFormat?: Function
}

export const SearchAddressWithZipCodeFields = ({
  page,
  api: { getAddressByZipCodeApi },
  disableFieldsPersonalData,
  setAddressFormat,
}: SearchAddressWithZipCodeFieldsProps) => {
  const { control, getValues, reset, watch, setValue, register } = useFormContext<FieldValues>()
  const { tenantId, businessModel, personId } = useContext(IdentityInfoContext)

  const [address, setAddress] = useState<GetAddressByZipCodeResponse>(null)
  const [zipCodeStatus, setZipCodeStatus] = useState<ZipCodeFieldStatus>({
    isLoading: false,
  })
  const [dialogMessage, setDialogMessage] = useState<DialogMessage>('')
  const [isDialogOpen, setIsDialogOpen] = useState<Dialog>(false)

  const messages = { ...page.messages.allocation.searchAddressWithZipCode }
  const configs = { ...page.configs.allocationStrategy.searchAddressWithZipCode }
  const fieldsOrder = page.addressConfigs.fieldsOrder || [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ]
  const handleGetAddressByZipCode = async () => {
    const zipCodeValue = getValues().zipCode
    const isNaturaMex = tenantId === TenantId.NaturaMEX
    const isValidZipCodeLength =
      zipCodeValue?.length >= configs.zipCode.rules.minLength &&
      zipCodeValue?.length <= configs.zipCode.rules.maxLength

    if (isValidZipCodeLength) {
      try {
        setZipCodeStatus({
          isLoading: true,
        })
        const paramsGetAddress = {
          businessModel,
          code: isNaturaMex ? zipCodeValue.toString() : Number(zipCodeValue),
          tenantId,
        }

        if (configs.shouldGetGeolocationCode) {
          const addressResponse = await getAddressByZipCodeApi({ ...paramsGetAddress, personId })
          setValue('district', addressResponse?.district)
          const formatAddress = fomatGetAddressNaturaMexico(addressResponse)
          setAddressFormat(addressResponse)
          setAddress(formatAddress)
        } else {
          const addressResponse = await getAddressByZipCodeApi(paramsGetAddress)
          setAddress(addressResponse)
        }

        setZipCodeStatus({
          isLoading: false,
        })
      } catch (error) {
        setZipCodeStatus({
          isLoading: false,
        })

        if (error?.statusCode === HttpStatusCode.notFound) {
          setDialogMessage(messages.dialog.error.notFound)
          setIsDialogOpen(true)

          return
        }

        setDialogMessage(messages.dialog.error.unexpected)
        setIsDialogOpen(true)
      }
    }
  }

  const fomatGetAddressNaturaMexico = (address: any) => {
    if (address) {
      const { city, state, district, cologne } = address
      const addressResponse = {
        city: city?.geoStructureDescription,
        state: state?.geoStructureDescription,
        district: district?.geoStructureDescription,
        cologne,
      }
      return addressResponse
    }
    return null
  }

  const resetsAddressFieldsExceptZipCode = () => {
    reset({
      ...getValues(),
      street: null,
      city: null,
      cologne: null,
      complement: null,
      neighborhood: null,
      number: null,
      references: null,
      state: null,
    })
  }

  const hideAllFieldsExceptZipCodeIfZipCodeIsInvalid = () => {
    const zipCodeValue = getValues().zipCode
    const isInvalidZipCodeLength = zipCodeValue?.length < configs.zipCode.rules.minLength

    if (isInvalidZipCodeLength && address) {
      setAddress(null)
      resetsAddressFieldsExceptZipCode()
    }
  }

  const clearZipCode = () => setValue('zipCode', '', { shouldValidate: true })

  const resetDialogMessage = () => {
    setIsDialogOpen(false)
    clearZipCode()
  }

  const { debouncedFunctionResult: getAddress } = useDebounce({
    customFunction: handleGetAddressByZipCode,
    delayInMilliSeconds: 500,
  })

  useEffect(() => {
    if (address) {
      reset({
        ...getValues(),
        street: address.street || null,
        neighborhood: address.neighborhood || null,
        city: address.city,
        state: address.state,
      })
    }
  }, [address, reset, getValues])

  useEffect(() => {
    register('district')
  }, [register])
  const zipCodeWatch = watch('zipCode')
  const isNaturaMX = TenantId.NaturaMEX === tenantId
  const disableFields = disableFieldsPersonalData && isNaturaMX
  useEffect(() => {
    getAddress()
    hideAllFieldsExceptZipCodeIfZipCodeIsInvalid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCodeWatch])
  const addressFieldComps = {
    STREET: !disableFields && (
      <StreetField
        messages={messages.street}
        configs={configs.street}
        control={control}
        address={address}
        key={1}
      />
    ),
    NUMBER: !disableFields && <NumberField messages={messages.number} configs={configs.number} key={2} />,
    COMPLEMENT: !disableFields && (
      <ComplementField
        messages={messages.complement}
        configs={configs.complement}
        control={control}
        key={3}
      />
    ),
    REFERENCES: !disableFields && (
      <ReferencesField messages={messages.reference} configs={configs.reference} control={control} key={4} />
    ),
    COLOGNE: (
      <CologneField
        messages={messages.cologne}
        configs={configs.cologne}
        control={control}
        address={address?.cologne}
        key={5}
      />
    ),
    CITY: <CityField messages={messages.city} configs={configs.city} control={control} key={6} />,
    NEIGHBORHOOD: (
      <NeighborhoodField
        messages={messages.neighborhood}
        configs={configs.neighborhood}
        control={control}
        address={address}
        key={7}
      />
    ),
    STATE: <StateField messages={messages.state} configs={configs.state} control={control} key={8} />,
  }

  return (
    <>
      <ZipCodeTextField
        label={messages.zipCode.label}
        placeholder={messages.zipCode.placeholder}
        rules={{
          pattern: {
            message: messages.zipCode.rules.pattern,
            value: configs.zipCode.rules.pattern,
          },
          maxLength: {
            message: messages.zipCode.rules.maxLength,
            value: configs.zipCode.rules.maxLength,
          },
          minLength: {
            message: messages.zipCode.rules.minLength,
            value: configs.zipCode.rules.minLength,
          },
          required: {
            message: messages.zipCode.rules.required,
            value: configs.zipCode.rules.required,
          },
        }}
        control={control}
        validChars={configs.zipCode.validChars}
        isLoading={zipCodeStatus.isLoading}
      />
      {address ? (
        <>
          {fieldsOrder &&
            fieldsOrder.map((Comp) => {
              return addressFieldComps[Comp]
            })}
        </>
      ) : (
        zipCodeStatus.isLoading && (
          <GridSkeletonContainer aria-live="polite" aria-busy="true">
            {loadingSkeletonKeys.map((loading) => (
              <GridSkeletonField key={loading.key}>
                <Skeleton height="small" width="largexxx" variant="rect" />
                <Skeleton height="mediumX" variant="rect" />
              </GridSkeletonField>
            ))}
          </GridSkeletonContainer>
        )
      )}
      <DialogAlert
        messages={{
          closeButtonText: messages.dialog.closeButton,
          infoText: dialogMessage,
        }}
        show={isDialogOpen}
        closeDialogCallback={resetDialogMessage}
      />
    </>
  )
}
