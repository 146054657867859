import { useEffect, useRef, useState } from 'react'
import { GetDigitalStoreInfoResponse } from '../../../../domain/use-cases/digital-store/get-digital-store-info'
import { DigitalStorePageApi } from '../api/make-digital-store-page-api'
import { DigitalStoreHookContext } from '../types'

export type DigitalStoreInfoResults = {
  name: string
  isLoading: boolean
  extraEntries: unknown
  hasExtraEntries: boolean
  alreadyHadSavedName: boolean
}

export type UseDigitalStoreInfoParams = {
  api: Pick<
    DigitalStorePageApi,
    'getInfo'
  >
  context: DigitalStoreHookContext
}

export const useDigitalStoreInfo = ({
  api,
  context,
}: UseDigitalStoreInfoParams): DigitalStoreInfoResults => {
  const {
    personId,
    companyId,
    countryId,
  } = context

  const fetch = async () => {
    const onDone = ({ digitalStoreName: name, extraEntries }: GetDigitalStoreInfoResponse) => {
      const hasExtraEntries = typeof extraEntries === 'object' && Object.keys(extraEntries).length > 0
      const alreadyHadSavedName = name !== ''

      setDigitalStoreInfo({
        ...digitalStoreInfo,
        name,
        isLoading: false,
        extraEntries,
        hasExtraEntries,
        alreadyHadSavedName,
      })
    }

    try {
      const {
        digitalStoreName,
        extraEntries,
      } = await api.getInfo({
        personId,
        companyId,
        countryId,
      })
      onDone({
        digitalStoreName: digitalStoreName || '',
        extraEntries,
      })
    } catch (error) {
      console.warn('Error fetchInfo')

      onDone({
        digitalStoreName: '',
        extraEntries: undefined,
      })
    }
  }

  const [
    digitalStoreInfo,
    setDigitalStoreInfo,
  ] = useState<DigitalStoreInfoResults>({
    name: '',
    isLoading: true,
    extraEntries: undefined,
    hasExtraEntries: false,
    alreadyHadSavedName: false,
  })

  const fetchRef = useRef(fetch)

  useEffect(() => {
    const hasMandatoryProps = !!personId && !!companyId && !!countryId

    if (hasMandatoryProps) {
      fetchRef.current()
    } else {
      setDigitalStoreInfo((currentDigitalStoreInfo) => ({
        ...currentDigitalStoreInfo,
        isLoading: false,
      }))
    }
  }, [companyId, countryId, personId])

  return digitalStoreInfo
}
