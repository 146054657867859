import React, { useContext } from 'react'
import { IdentityInfoContext } from '@/main/contexts'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Page } from '@/domain/models/page/page'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import { AddressPageType } from '@/domain/models/address'
import { GeographicalLevelsAddressPage } from './page-components/geographical-levels-address-page'
import { AddressPageWrapper, SearchedAddressPage, SimpleAddressPage } from './page-components'
import { AddressPageApi } from './api'

type AddressPageProps = {
  api: AddressPageApi
}

export const AddressPageSwitcher: React.FC<AddressPageProps> = ({ api }) => {
  const { tenantId } = useContext(IdentityInfoContext)
  const configs = useTenantConfigs(tenantId, Page.Address) as AddressConfigs

  switch (configs.pageType) {
    case AddressPageType.SearchedAddress:
      return <SearchedAddressPage api={api} />
    case AddressPageType.GeographicalLevels:
      return <GeographicalLevelsAddressPage api={api} />
    case AddressPageType.Legacy:
      return <AddressPageWrapper />
    case AddressPageType.SimplePage:
      return <SimpleAddressPage api={api} />
    default:
      return null
  }
}
