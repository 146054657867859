import { RemoteGetAddressByZipCode } from '@/data/use-cases/address/zip-code'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteGetAddressByZipCode = (): RemoteGetAddressByZipCode => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteGetAddressByZipCode(httpGetClient, xApiKey)
}
