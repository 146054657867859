import { Icon } from '@naturacosmeticos/natds-web'
import React, { useEffect, useState } from 'react'

import { MaintenanceRegisterMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { PHONE_TYPES } from '@/domain/models/phone/phone-type'
import { MaintenanceRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { Button } from '@/main/components'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { PhoneFields } from './phone-fields'

type PhonesProps = {
  tenantId: TenantId
  onRemoveSecondaryPhone?: (callback: Function) => void
  hasSecondaryPhone?: boolean
}

export const Phones = ({ tenantId, hasSecondaryPhone, onRemoveSecondaryPhone }: PhonesProps) => {
  const [showRemoveButton, setShowRemoveButton] = useState(hasSecondaryPhone)
  const messages = usePageMessages(Page.MaintenanceRegister).messages as MaintenanceRegisterMessages

  const { primaryPhoneOptions } = useTenantConfigs(
    tenantId,
    Page.MaintenanceRegister
  ) as MaintenanceRegisterConfigs

  const phoneMessages = {
    addPhoneNumber: messages.phoneNumber.addPhoneNumber,
    removePhoneNumber: messages.phoneNumber.removePhoneNumber,
    phoneTypes: messages.phoneTypes,
  }

  const primaryPhoneMessages = {
    label: messages.phoneNumber?.label,
    phoneType: messages.phoneTypes,
    placeholder: messages.phoneNumber?.placeholder,
    requiredErrorMessage: messages.requiredErrorMessage,
    firstDigitErrorMessage: messages.firstDigitErrorMessage,
    lengthErrorMessage: messages.lengthErrorMessage,
    invalidType: messages.invalidType,
  }

  const handlePhoneNumberButton = () => {
    if (showRemoveButton) {
      if (onRemoveSecondaryPhone) {
        onRemoveSecondaryPhone(() => {
          setShowRemoveButton((prev) => !prev)
        })
      } else {
        setShowRemoveButton(false)
      }

      return
    }

    setShowRemoveButton(true)
  }

  const mapPhoneTypes = PHONE_TYPES.map((type) => ({
    label: phoneMessages.phoneTypes.labels[type.label],
    value: String(type.id),
  })).sort((a, b) => Number(a.value) - Number(b.value))

  const filteredPrimaryPhoneOptions = primaryPhoneOptions
    ? mapPhoneTypes.filter((option) => primaryPhoneOptions.includes(Number(option.value)))
    : mapPhoneTypes

  useEffect(() => {
    setShowRemoveButton(hasSecondaryPhone)
  }, [hasSecondaryPhone])

  return (
    <div>
      <PhoneFields
        tenantId={tenantId}
        id="primary"
        hasAsteriskInTheLabel
        options={filteredPrimaryPhoneOptions}
        messages={primaryPhoneMessages}
      />

      {showRemoveButton && (
        <PhoneFields
          tenantId={tenantId}
          id="secondary"
          hasAsteriskInTheLabel={false}
          options={mapPhoneTypes}
          messages={{ ...primaryPhoneMessages, label: messages.phoneNumber.secondaryPhoneLabel }}
        />
      )}

      <Button
        startIcon={<Icon name={showRemoveButton ? 'filled-action-subtract' : 'filled-action-add'} />}
        onClick={handlePhoneNumberButton}
      >
        {showRemoveButton ? phoneMessages.removePhoneNumber : phoneMessages.addPhoneNumber}
      </Button>
    </div>
  )
}
