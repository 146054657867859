export const deviceSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobileXL: 460,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
}

export const minDeviceSizeForMediaQueries = {
  mobileS: `(min-width: ${deviceSize.mobileS}px)`,
  mobileM: `(min-width: ${deviceSize.mobileM}px)`,
  mobileL: `(min-width: ${deviceSize.mobileL}px)`,
  mobileXL: `(min-width: ${deviceSize.mobileXL}px)`,
  tablet: `(min-width: ${deviceSize.tablet}px)`,
  laptop: `(min-width: ${deviceSize.laptop}px)`,
  laptopL: `(min-width: ${deviceSize.laptopL}px)`,
  desktop: `(min-width: ${deviceSize.desktop}px)`,
  desktopL: `(min-width: ${deviceSize.desktop}px)`,
}
