import { ChangeEvent } from 'react'
import { handleValidChars } from '@/main/components/text-field/helper/handle-valid-chars'

export type CustomChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

type handleOnchangeProps = {
  event: CustomChangeEvent,
  onChange: (event: CustomChangeEvent) => void,
  validChars?: RegExp,
  isUpperCase?: boolean,
}

export const handleOnChange = ({
  event,
  onChange,
  validChars,
  isUpperCase,
}: handleOnchangeProps): void => {
  const toUpperCaseEventValue = (event: CustomChangeEvent): CustomChangeEvent => {
    const target = {
      ...event.target,
      value: event?.target?.value?.toUpperCase(),
    }

    const newEvent: CustomChangeEvent = {
      ...event,
      target,
    }

    return newEvent
  }

  const newEvent: CustomChangeEvent = isUpperCase ? toUpperCaseEventValue(event) : event

  const isValidRegExp = handleValidChars({
    event: newEvent,
    validChars,
  })

  if (isValidRegExp) {
    onChange(newEvent)
  }
}
