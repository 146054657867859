import { MaintenanceAddressMessages, MaintenanceAddressMessageOptions } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenanceAddress(translate: TFunction, options: MaintenanceAddressMessageOptions ): MaintenanceAddressMessages {
  return {
    labels: {
      previousButton: translate('maintenanceAddressPage.labels.previousButton'),
      saveButton: translate('maintenanceAddressPage.labels.saveButton'),
      addressDeliveryType: translate('maintenanceAddressPage.labels.addressDeliveryType'),
    },
    dialog: {
      successMessage: translate('maintenanceAddressPage.dialog.successMessage'),
      unexpectedErrorMessage: translate('maintenanceAddressPage.dialog.unexpectedErrorMessage'),
      buttonName: translate('maintenanceAddressPage.dialog.buttonName'),
    },
    errorLabel: {
      wrongZipcodeError: translate('maintenanceAddressPage.errorLabel.wrongZipcodeError'),
    },
    alert: {
      title: translate('maintenanceAddressPage.alert.title'),
      subtitleOne: translate('maintenanceAddressPage.alert.subtitleOne'),
      subtitleTwo: translate('maintenanceAddressPage.alert.subtitleTwo'),
    },
    leaderDialog: {
      title: translate('maintenanceAddressPage.leaderDialog.title'),
      alertRequestProcess: translate('maintenanceAddressPage.leaderDialog.alertRequestProcess'),
      leaderChange: translate('maintenanceAddressPage.leaderDialog.leaderChange'),
      confirmation: translate('maintenanceAddressPage.leaderDialog.confirmation'),
      confirmButton: translate('maintenanceAddressPage.leaderDialog.confirmButton'),
      cancelButton: translate('maintenanceAddressPage.leaderDialog.cancelButton'),
    },

    address: translate('maintenanceAddressPage.address', options ),
    requiredMessage: translate('maintenanceAddressPage.requiredMessage'),
    withoutNumber: translate('maintenanceAddressPage.withoutNumber'),
    streetExample: translate('maintenanceAddressPage.streetExample'),
  }
}
