import React from 'react'
import { ControlledTextField } from '@/main/components'
import { FieldsOrderProps } from '@/domain/models/tenant/tenant-configs/configs'

export const CityField = (fieldsOrderProps: FieldsOrderProps) => {
  const { messages, control, configs, id } = fieldsOrderProps
  return (
    <>
      <ControlledTextField
        id={id || 'city'}
        label={messages.label}
        placeholder={messages.placeholder}
        control={control}
        rules={{
          maxLength: {
            message: messages.rules.maxLength,
            value: configs.rules.maxLength,
          },
          required: {
            message: messages.rules.required,
            value: configs.rules.required,
          },
        }}
        validChars={configs.validChars}
        disabled
      />
    </>
  )
}
