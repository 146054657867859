import React from 'react'
import styled from 'styled-components'

import {
  TextParagraph,
  TextParagraphProps,
  CardTitle,
} from '@/main/components'
import { CardTitleProps } from '@/main/components/card-title/card-title'

export const Container = styled.div<{ isLoading?: boolean }>`
  display: flex;
  justify-content: center;

  margin-top: ${({ isLoading }) => (isLoading ? 64 : 0)}px;

  .MuiCard-root {
    text-align: center;
    padding: 40px 64px;
  }

  @media (max-width: 460px) {

    margin-top: ${({ isLoading }) => (isLoading ? 64 : 0)}px;

    .MuiCard-root {
      padding: 16px;
    }
  }
`

export const StatusBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

type StatusTextParagraphProps = {
  alignLeft?: boolean
  lowEmphasis?: boolean
  hasMarginBottom?: boolean
  isApproved?: boolean
}

type StatusTextParagraph = TextParagraphProps & StatusTextParagraphProps

export const StyledTextParagraph = styled<React.FC<StatusTextParagraph>>(TextParagraph)`
  font-size: 1.6rem;
  line-height: 24px;
  letter-spacing: 0.51;
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  margin-bottom: ${({ hasMarginBottom }) => (hasMarginBottom ? 16 : 0)}px;
  color: #${({ lowEmphasis }) => (lowEmphasis ? 777 : 0)};

  a {
    color: #227BBD;
  }
`

type StyledCardTitleProps = {
  isApproved?: boolean
} & CardTitleProps

export const StyledCardTitle = styled<React.FC<StyledCardTitleProps>>(CardTitle)`
  h2 {
    color: ${(props) => (props.isApproved ? '#51912f' : '#333')};
  }
  margin-bottom: 3.2rem;
`
