import { IndicationInfo, RemoteSearchIndicationParams } from '@/data/use-cases/indication/remote-search-indication'
import { makeRemoteSearchIndication } from '@/main/factories/remote/indication'

export type GetIndicationInterface = (params: RemoteSearchIndicationParams) =>
  Promise<IndicationInfo>

export type ConfirmationApi = {
  getIndication: GetIndicationInterface
}

export const makeConfirmationApi = (): ConfirmationApi => {
  const remoteGetIndication = makeRemoteSearchIndication()
  const getIndication = (params: RemoteSearchIndicationParams) => remoteGetIndication.search(params)

  return { getIndication }
}
