import { RemoteGetAddressData } from '@/data/use-cases/address/remote-get-address-data'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteGetAddressData = (): RemoteGetAddressData => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteGetAddressData(httpGetClient, xApiKey)
}
