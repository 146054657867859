export const MalaysiaEthnicitiesOptions = [
  {
    value: 1,
    label: 'Malay',
  },
  {
    value: 2,
    label: 'Bumiputera',
  },
  {
    value: 3,
    label: 'Chinese',
  },
  {
    value: 4,
    label: 'Indian',
  },
  {
    value: 5,
    label: 'Others',
  },
]
