import styled from 'styled-components'
import { Checkbox, Typography } from '@naturacosmeticos/natds-web'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const InfoLabel = styled.label`
  display: flex;
  flex-direction: column;
`

export const NameText = styled(Typography)`
  max-width: 170px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media ${minDeviceSizeForMediaQueries.mobileXL} {
    max-width: 230px;
  }
`

export const ModalBodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${minDeviceSizeForMediaQueries.mobileXL} {
    justify-content: space-evenly;
  }
`

export const SelectionCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root  {
      width: ${({ theme }) => `${theme.sizes.standard}px`};
      height: ${({ theme }) => `${theme.sizes.standard}px`};
    }
`
