import { RemoteGetDigitalStoreInfo } from '@/data/use-cases/digital-store'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { GetDigitalStoreInfo } from '@/domain/use-cases/digital-store'

export const makeGetDigitalStoreInfo = (): GetDigitalStoreInfo => {
  const apiUrl = '/digital-store/info/:personId'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteGetDigitalStoreInfo = new RemoteGetDigitalStoreInfo(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteGetDigitalStoreInfo
}
