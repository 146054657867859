import { GetStatesParams, State } from '@/domain/use-cases/address'
import { makeRemoteGetStates } from '@/main/factories/remote/address/make-remote-get-states'
import { makeRemoteAcceptAgreement } from '@/main/factories/remote/agreement'
import {
  AcceptAgreementParams,
  AcceptAgreementResponse,
} from '@/domain/use-cases/agreement'
import {
  CompleteDocumentationRequestParams, CompleteDocumentationRequestResponse,
} from '@/domain/use-cases/documentation/complete-documentation'
import {
  makeRemoteCompleteDocumentation,
} from '@/main/factories/remote/documentation/make-remote-complete-documentation'
import { GetDocumentationParams, GetDocumentationResponse } from '@/domain/use-cases/documentation/get-documentation'
import { makeRemoteGetDocumentation } from '@/main/factories/remote/documentation/make-remote-get-documentation'

export type DocumentationPageApi = {
  getStates: (params: GetStatesParams) => Promise<Array<State>>
  acceptAgreement: (params: AcceptAgreementParams) => Promise<AcceptAgreementResponse>
  completeDocumentation: (params: CompleteDocumentationRequestParams) =>
    Promise<CompleteDocumentationRequestResponse>
  getDocumentation: (params: GetDocumentationParams) => Promise<GetDocumentationResponse>
}

export const makeDocumentationPageApi = (): DocumentationPageApi => {
  const remoteGetStates = makeRemoteGetStates()
  const remoteAcceptAgreement = makeRemoteAcceptAgreement()
  const remoteCompleteDocumentation = makeRemoteCompleteDocumentation()
  const remoteGetDocumentation = makeRemoteGetDocumentation()
  const getStates = (params: GetStatesParams) => remoteGetStates.get(params)
  const acceptAgreement = (params: AcceptAgreementParams) => remoteAcceptAgreement.accept(params)
  const completeDocumentation = (
    params: CompleteDocumentationRequestParams,
  ) => remoteCompleteDocumentation.complete(params)
  const getDocumentation = (params: GetDocumentationParams) => remoteGetDocumentation.get(params)

  return {
    getStates, acceptAgreement, completeDocumentation, getDocumentation,
  }
}
