import {
  GetAccountBindLink,
  GetAccountBindLinkParams,
} from '@/domain/use-cases/payment/get-account-bind-link'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { NotFoundError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'

export type GetAccountBindLinkHttpRequestHeaders = {
  'x-api-key': string
}

export type GetAccountBindLinkHttpRequestResponseBody = {
  link: string
}

export class RemoteGetAccountBindLink implements GetAccountBindLink {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
      GetAccountBindLinkHttpRequestHeaders,
      GetAccountBindLinkHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  get = async (params: GetAccountBindLinkParams): Promise<string> => {
    const headers: GetAccountBindLinkHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
    }
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      headers,
      query: params,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body.link
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
