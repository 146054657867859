import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ControlledSelect } from '@/main/components'

type RacialSelfDeclarationProps = {
  messages: any,
  id: string,
  options: any[] 
}

export const RacialSelfDeclarationField = ({
  messages,
  id,
  options
}: RacialSelfDeclarationProps) => {
  const { control } = useFormContext()
  const {
    label,
    placeholder,
    requiredMessage
  } = messages

  return (
    <ControlledSelect
      id={id}
      label={label}
      control={control}
      placeholder={placeholder}
      options={options}
      rules={{
        required: requiredMessage,
      }}
    />
  )
}
