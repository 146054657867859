import {
  ConvertCountryIdFromAlpha3ToAlpha2,
  ConvertCountryIdFromAlpha3ToAlpha2Params,
  ConvertCountryIdFromAlpha3ToAlpha2Output,
} from '@/domain/use-cases/country/convert-country-id-from-alpha-3-to-alpha-2'
import {
 COUNTRIES_IDS_KEYS, CountryId, CountryIdAlpha2, countryIdsAlpha3Key,
} from '@/domain/models/country'

export class LocalConvertCountryIdFromAlpha3ToAlpha2 implements ConvertCountryIdFromAlpha3ToAlpha2 {
  private countryIdsAlpha3Keys: Map<CountryId, countryIdsAlpha3Key>

  constructor(countryIdsAlpha3Keys: Map<CountryId, countryIdsAlpha3Key>) {
    this.countryIdsAlpha3Keys = countryIdsAlpha3Keys || COUNTRIES_IDS_KEYS
  }

  public convert(params: ConvertCountryIdFromAlpha3ToAlpha2Params): ConvertCountryIdFromAlpha3ToAlpha2Output {
    const countryKey = this.countryIdsAlpha3Keys.get(params.countryId)

    return CountryIdAlpha2[countryKey]
  }
}
