import { BadRequestError } from '@/data/protocols/errors'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'
import { AddressFormValues } from '@/main/pages/address/models'

type GetAddressDataHeaders = {
  countryid: CountryId
  companyid: CompanyId
  'x-api-key': string,
}

export type GetAddressDataParams = {
  personId: string
  countryId: CountryId
  companyId: CompanyId
}

export class RemoteGetAddressData {
  constructor(
    private readonly httpGetClient: HttpGetClient<GetAddressDataHeaders, AddressFormValues>,
    private readonly xApiKey: string,
  ) {}

  get = async ({ personId, companyId, countryId }: GetAddressDataParams): Promise<AddressFormValues> => {
    const headers = {
      countryid: countryId,
      companyid: companyId,
      'x-api-key': this.xApiKey,
    }

    const httpResponse = await this.httpGetClient.get({
      url: `/address/get/${personId}`,
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
