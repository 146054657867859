import * as React from 'react'

import { TextParagraph } from '@/main/components'
import {
    StyledUrlSection,
    BoldTextParagraph,
  } from './digital-store-address-panel.styles'

export interface DigitalStoreAddressPanelProps {
    digitalStoreDomain: string,
    digitalStoreAddressText: string,
    disableLink?: boolean,
}

export const DigitalStoreAddressPanel: React.FunctionComponent<DigitalStoreAddressPanelProps> = ({ digitalStoreDomain, digitalStoreAddressText, disableLink = false }) => (
  <StyledUrlSection className="digital-store-address-panel">
    <TextParagraph content={digitalStoreAddressText} />
    <BoldTextParagraph
      content={disableLink ? digitalStoreDomain : `<a href=${`https://${digitalStoreDomain}`}>${digitalStoreDomain}</a>`}
    />
  </StyledUrlSection>
)
