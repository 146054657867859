import { Typography } from '@naturacosmeticos/natds-web'
import React from 'react'

export type EmailInfoRegistrationProps = {
    email: string
    shouldHide?: boolean
}

export const EmailInfoRegistration = ({ email, shouldHide }: EmailInfoRegistrationProps) => (
  <>
    {shouldHide && (
      <Typography
        color="textSecondary"
        variant="caption"
      >
        {email}
      </Typography>
    )}
  </>
)
