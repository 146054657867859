import React from 'react'
import {
  DigitalSignatureMessages,
} from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import {
  Image,
  Spacing,
  Typography,
} from '@/main/components'
import SignatureTutorialImage from '@/main/assets/pngs/signature-tutorial-second-page.png'
import { useSignatureTutorial } from '../../../hooks/useSignatureTutorial'
import { useSignatureNavigationPage } from '../../../hooks/useSignatureNavigationPage'
import { StyledContainer, MainContainer } from '../signature-tutorial-pages.styles'

export const SignatureTutorialSecondPage: React.FC = () => {
  const messages = usePageMessages(Page.DigitalSignaturePage).messages as DigitalSignatureMessages
  const { setShowDialog, setShowTutorial } = useSignatureNavigationPage()

  const {
    goToTutorialPreviousStep,
    resetTutorialStep,
  } = useSignatureTutorial()

  const onPreviousButtonClick = () => {
    goToTutorialPreviousStep()
  }

  const onNextButtonClick = () => {
    resetTutorialStep()
    setShowTutorial(false)
    setShowDialog(true)
  }

  return (
    <Spacing paddingX="small">
      <MainContainer
        previousButtonLabel={messages.tutorial.previousButton}
        onPreviousButtonClick={onPreviousButtonClick}
        nextButtonLabel={messages.tutorial.nextButton}
        onNextButtonClick={onNextButtonClick}
        nextButtonDataTestId="signature-tutorial-second-page-id"
      >
        <StyledContainer>
          <Spacing
            className="natds2"
            display="flex"
            marginY="standard"
          >
            <Typography variant="subtitle1" align="center">
              {messages.tutorial.secondPage.title}
            </Typography>
          </Spacing>

          <Spacing
            className="natds2"
            display="flex"
            marginY="standard"
          >
            <Image
              src={SignatureTutorialImage}
              alt="mouse image"
            />
          </Spacing>

          <Typography variant="subtitle1" align="center">
            {messages.tutorial.secondPage.description}
          </Typography>
        </StyledContainer>
      </MainContainer>
    </Spacing>
  )
}
