import { makeRemoteGetMultipleAddress } from '@/main/factories/remote/register-maintenance/make-remote-get-multiple-addresses'
import {
  GetMultipleAddressParams,
  MultipleAddress,
} from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { GetCnContactsParams,
  ResponseGetCNContacts } from '@/data/use-cases/register-maintenance-security-validation/remote-get-cn-contacts'
import { makeRemoteGetCNContacts } from '@/main/factories/remote/register-maintenance-security-validation/make-remote-get-cn-contacts'
import { GetAddressByZipCodeParams, GetAddressByZipCodeResponse } from '@/domain/use-cases/address/zip-code'
import { makeRemoteGetAddressByZipCode } from '@/main/factories/remote/address/zip-code'

export type RegisterMaintenanceAddressPageApi = {
  getCNContacts: (data: GetCnContactsParams) => Promise<ResponseGetCNContacts>
  getMultipleAddresses: (data: GetMultipleAddressParams) => Promise<MultipleAddress>
  getAddressByZipCode: (params: GetAddressByZipCodeParams) => Promise<GetAddressByZipCodeResponse>
}

export const makeRegisterMaintenanceAddressPageApi = (): RegisterMaintenanceAddressPageApi => {

  const remoteGetMultipleAddress = makeRemoteGetMultipleAddress()
  const getMultipleAddresses = (params: GetMultipleAddressParams) => remoteGetMultipleAddress.get(params)

  const remoteGetAddressByZipCode = makeRemoteGetAddressByZipCode()
  const getAddressByZipCode = (params: GetAddressByZipCodeParams) => remoteGetAddressByZipCode.get(params)

  const remoteGetCNContacts = makeRemoteGetCNContacts()
  const getCNContacts = (params: GetCnContactsParams) => remoteGetCNContacts.get(params)

  return {
    getCNContacts,
    getMultipleAddresses,
    getAddressByZipCode
  }
}
