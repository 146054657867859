import { CountryId } from '@/domain/models/country'

export const getCountryNames = (countryId: CountryId, key: string) => {
  if (!key) {
    return null
  }
  switch (countryId) {
    case CountryId.Malaysia:
      return nationalities[key].EN
    case CountryId.Brazil:
      return nationalities[key].BR
    default:
      return nationalities[key].ES
  }
}

const nationalities = {
  nationality_afghan: {
    BR: 'Afegão',
    EN: 'Afghan',
    ES: 'Afgano',
  },
  nationality_albanian: {
    BR: 'Albanês',
    EN: 'Albanian',
    ES: 'Albanés',
  },
  nationality_american: {
    BR: 'Americano',
    EN: 'American',
    ES: 'Norteamericano',
  },
  nationality_andorran: {
    BR: 'Andorrano',
    EN: 'Andorran',
    ES: 'Andorrano',
  },
  nationality_angolan: {
    BR: 'Angolano',
    EN: 'Angolan',
    ES: 'Angoleño',
  },
  nationality_anguillan: {
    BR: 'Anguila',
    EN: 'Anguillan',
    ES: 'Anguila',
  },
  nationality_antartic: {
    BR: 'Antártico',
    EN: 'Antartic',
    ES: 'Antártico',
  },
  nationality_antiguans: {
    BR: 'Antíguas',
    EN: 'Antiguans',
    ES: 'Antillano',
  },
  nationality_arab: {
    BR: 'Árabe',
    EN: 'Arab',
    ES: 'Árabe',
  },
  nationality_arubean: {
    BR: 'Aruba',
    EN: 'Arubean',
    ES: 'Arubiano',
  },
  nationality_algerian: {
    BR: 'Argelino',
    EN: 'Algerian',
    ES: 'Argelino',
  },
  nationality_argentinean: {
    BR: 'Argentina',
    EN: 'Argentinean',
    ES: 'Argentino',
  },
  nationality_armenian: {
    BR: 'Armênio',
    EN: 'Armenian',
    ES: 'Armenio',
  },
  nationality_australian: {
    BR: 'Australiano',
    EN: 'Australian',
    ES: 'Australiano',
  },
  nationality_austrian: {
    BR: 'Austríaco',
    EN: 'Austrian',
    ES: 'Austriaco',
  },
  nationality_azerbaijani: {
    BR: 'Azerbaijano',
    EN: 'Azerbaijani',
    ES: 'Azerbaiyano',
  },
  nationality_bahamian: {
    BR: 'Bahamense',
    EN: 'Bahamian',
    ES: 'Las Bahamas',
  },
  nationality_bahraini: {
    BR: 'Bahrein',
    EN: 'Bahraini',
    ES: 'Baréin',
  },
  nationality_bangladeshi: {
    BR: 'Bangladesi',
    EN: 'Bangladeshi',
    ES: 'Bangladesh',
  },
  nationality_barbadian: {
    BR: 'Bárbaro',
    EN: 'Barbadian',
    ES: 'Barbandense',
  },
  nationality_batswana: {
    BR: 'Batswana',
    EN: 'Batswana',
    ES: 'Betchuano',
  },
  nationality_belarusian: {
    BR: 'Bielorrusso',
    EN: 'Belarusian',
    ES: 'Bielo ruso',
  },
  nationality_belgian: {
    BR: 'Belga',
    EN: 'Belgian',
    ES: 'Belga',
  },
  nationality_belizean: {
    BR: 'Belizenho',
    EN: 'Belizean',
    ES: 'Beliceño',
  },
  nationality_beninese: {
    BR: 'Beninês',
    EN: 'Beninese',
    ES: 'Beninés',
  },
  nationality_bermudian: {
    BR: 'Bermudense',
    EN: 'Bermudian',
    ES: 'Bermudés',
  },
  nationality_bhutanese: {
    BR: 'Butanês',
    EN: 'Bhutanese',
    ES: 'Butanés',
  },
  nationality_bolivian: {
    BR: 'Boliviano',
    EN: 'Bolivian',
    ES: 'Boliviano',
  },
  nationality_bosnian: {
    BR: 'Bósnio',
    EN: 'Bosnian',
    ES: 'Bosnio',
  },
  nationality_bouvet_island: {
    BR: 'Ilha Bouvet',
    EN: 'Bouvet Island',
    ES: 'Ilhas Bouvet',
  },
  nationality_brazilian: {
    BR: 'Brasileiro',
    EN: 'Brazilian',
    ES: 'Brasileño',
  },
  nationality_british: {
    BR: 'Britânico',
    EN: 'British',
    ES: 'Británico',
  },
  nationality_bruneian: {
    BR: 'Bruneiano',
    EN: 'Bruneian',
    ES: 'Bruneano',
  },
  nationality_bulgarian: {
    BR: 'Búlgaro',
    EN: 'Bulgarian',
    ES: 'Búlgaro',
  },
  nationality_burkinabe: {
    BR: 'Burquinabe',
    EN: 'Burkinabe',
    ES: 'Burkina Faso',
  },
  nationality_burmese: {
    BR: 'Birmanês',
    EN: 'Burmese',
    ES: 'Myanmarense',
  },
  nationality_burundian: {
    BR: 'Burundi',
    EN: 'Burundian',
    ES: 'Burundiano',
  },
  nationality_cambodian: {
    BR: 'Cambojano',
    EN: 'Cambodian',
    ES: 'Camboyano',
  },
  nationality_cameroonian: {
    BR: 'Camarões',
    EN: 'Cameroonian',
    ES: 'Camerunés',
  },
  nationality_canadian: {
    BR: 'Canadense',
    EN: 'Canadian',
    ES: 'Canadiense',
  },
  nationality_cape_verdean: {
    BR: 'Cabo verdiana',
    EN: 'Cape Verdean',
    ES: 'Caboverdiano',
  },
  nationality_central_african: {
    BR: 'Central Africano',
    EN: 'Central African',
    ES: 'Centrafricano',
  },
  nationality_chadian: {
    BR: 'Chadiano',
    EN: 'Chadian',
    ES: 'Chadiano',
  },
  nationality_chilean: {
    BR: 'Chileno',
    EN: 'Chilean',
    ES: 'Chileno',
  },
  nationality_chinese: {
    BR: 'Chinês',
    EN: 'Chinese',
    ES: 'Chino',
  },
  nationality_colombian: {
    BR: 'Colombiano',
    EN: 'Colombian',
    ES: 'Colombiano',
  },
  nationality_comoran: {
    BR: 'Comoran',
    EN: 'Comoran',
    ES: 'Comorano',
  },
  nationality_congolese: {
    BR: 'Congolês',
    EN: 'Congolese',
    ES: 'Congolés',
  },
  nationality_cook_island: {
    BR: 'Ilhas Cook',
    EN: 'Cook Islands',
    ES: 'Cookiano',
  },
  nationality_costa_rican: {
    BR: 'Costa riquenho',
    EN: 'Costa Rican',
    ES: 'Coreano',
  },
  nationality_corean: {
    BR: 'Coreano',
    EN: 'Corean',
    ES: 'Costarricense',
  },
  nationality_croatian: {
    BR: 'Croata',
    EN: 'Croatian',
    ES: 'Croata',
  },
  nationality_cuban: {
    BR: 'Cubano',
    EN: 'Cuban',
    ES: 'Cubano',
  },
  nationality_cypriot: {
    BR: 'Cipriota',
    EN: 'Cypriot',
    ES: 'Cipriano',
  },
  nationality_czech: {
    BR: 'Checo',
    EN: 'Czech',
    ES: 'Checo',
  },
  nationality_danish: {
    BR: 'Dinamarquês',
    EN: 'Danish',
    ES: 'Danés',
  },
  nationality_djibouti: {
    BR: 'Djibuti',
    EN: 'Djibouti',
    ES: 'Djibutiano',
  },
  nationality_dominican: {
    BR: 'Dominicano',
    EN: 'Dominican',
    ES: 'Dominicano',
  },
  nationality_dutch: {
    BR: 'Holandês',
    EN: 'Dutch',
    ES: 'Holandés',
  },
  nationality_east_timorese: {
    BR: 'Timorenses',
    EN: 'East Timorese',
    ES: 'Timor Oriental',
  },
  nationality_ecuadorean: {
    BR: 'Equatoriano',
    EN: 'Ecuadorean',
    ES: 'Ecuatoriano',
  },
  nationality_egyptian: {
    BR: 'Egípcio',
    EN: 'Egyptian',
    ES: 'Egipcio',
  },
  nationality_equatorial_guinean: {
    BR: 'Guineense Equatorial',
    EN: 'Equatorial Guinean',
    ES: 'Guineano',
  },
  nationality_eritrean: {
    BR: 'Eritreia',
    EN: 'Eritrean',
    ES: 'Eritreu',
  },
  nationality_estonian: {
    BR: 'Estoniano',
    EN: 'Estonian',
    ES: 'Estonio',
  },
  nationality_ethiopian: {
    BR: 'Etíope',
    EN: 'Ethiopian',
    ES: 'Etíope',
  },
  nationality_fijian: {
    BR: 'Fiji',
    EN: 'Fijian',
    ES: 'Fiyiano',
  },
  nationality_filipino: {
    BR: 'Filipino',
    EN: 'Filipino',
    ES: 'Filipino',
  },
  nationality_finnish: {
    BR: 'Finlandês',
    EN: 'Finnish',
    ES: 'Finlandés',
  },
  nationality_french: {
    BR: 'Francês',
    EN: 'French',
    ES: 'Francés',
  },
  nationality_gabonese: {
    BR: 'Gabonês',
    EN: 'Gabonese',
    ES: 'Gabonés',
  },
  nationality_gambian: {
    BR: 'Gâmbia',
    EN: 'Gambian',
    ES: 'Gambiano',
  },
  nationality_georgian: {
    BR: 'Georgiano',
    EN: 'Georgian',
    ES: 'Georgiano',
  },
  nationality_german: {
    BR: 'Alemão',
    EN: 'German',
    ES: 'Alemán',
  },
  nationality_gibraltar: {
    BR: 'Gibraltar',
    EN: 'Gibraltar',
    ES: 'Ghanés',
  },
  nationality_ghanaian: {
    BR: 'Ganês',
    EN: 'Ghanaian',
    ES: 'Gibraltareño',
  },
  nationality_greek: {
    BR: 'Grego',
    EN: 'Greek',
    ES: 'Griego',
  },
  nationality_grenadian: {
    BR: 'Granada',
    EN: 'Grenadian',
    ES: 'Granadino',
  },
  nationality_guatemalan: {
    BR: 'Guatemalteco',
    EN: 'Guatemalan',
    ES: 'Guatemalteco',
  },
  nationality_guinea_bissauan: {
    BR: 'Guiné-Bissau',
    EN: 'Guinea-Bissauan',
    ES: 'Guiné-Bissau',
  },
  nationality_guinean: {
    BR: 'Guinense',
    EN: 'Guinean',
    ES: 'Guineano',
  },
  nationality_guyanese: {
    BR: 'Guianesa',
    EN: 'Guyanese',
    ES: 'Guayanés',
  },
  nationality_haitian: {
    BR: 'Haitiano',
    EN: 'Haitian',
    ES: 'Haitiano',
  },
  nationality_honduran: {
    BR: 'Hondurenho',
    EN: 'Honduran',
    ES: 'Hondureño',
  },
  nationality_hong_kong: {
    BR: 'Hong Kong',
    EN: 'Hong Kong',
    ES: 'Hong konguês',
  },
  nationality_hungarian: {
    BR: 'Húngaro',
    EN: 'Hungarian',
    ES: 'Húngaro',
  },
  nationality_i_kiribati: {
    BR: 'I-Kiribati',
    EN: 'I-Kiribati',
    ES: 'Kiribatiano',
  },
  nationality_icelander: {
    BR: 'Islandês',
    EN: 'Icelander',
    ES: 'Islandés',
  },
  nationality_indian: {
    BR: 'Indiano',
    EN: 'Indian',
    ES: 'Indú',
  },
  nationality_indonesian: {
    BR: 'Indonésio',
    EN: 'Indonesian',
    ES: 'Indonesio',
  },
  nationality_iranian: {
    BR: 'Iraniano',
    EN: 'Iranian',
    ES: 'Iraní',
  },
  nationality_iraqi: {
    BR: 'Iraquiano',
    EN: 'Iraqi',
    ES: 'Iraquí',
  },
  nationality_irish: {
    BR: 'Irlandês',
    EN: 'Irish',
    ES: 'Irlandés',
  },
  nationality_israeli: {
    BR: 'Israelense',
    EN: 'Israeli',
    ES: 'Israelita',
  },
  nationality_italian: {
    BR: 'Italiano',
    EN: 'Italian',
    ES: 'Italiano',
  },
  nationality_ivorian: {
    BR: 'Marfinense',
    EN: 'Ivorian',
    ES: 'Costa de Marfil',
  },
  nationality_jamaican: {
    BR: 'Jamaicano',
    EN: 'Jamaican',
    ES: 'Jamaicano',
  },
  nationality_japanese: {
    BR: 'Japonês',
    EN: 'Japanese',
    ES: 'Japonés',
  },
  nationality_jordanian: {
    BR: 'Jordânia',
    EN: 'Jordanian',
    ES: 'Jordano',
  },
  nationality_kazakhstani: {
    BR: 'Cazaquistão',
    EN: 'Kazakhstani',
    ES: 'Kazaja',
  },
  nationality_kenyan: {
    BR: 'Queniano',
    EN: 'Kenyan',
    ES: 'De Kenia',
  },
  nationality_kittian_and_nevisian: {
    BR: 'Kittian e Nevisian',
    EN: 'Kittian and Nevisian',
    ES: 'De São Cristovão e Nevis',
  },
  nationality_kuwaiti: {
    BR: 'Kuwait',
    EN: 'Kuwaiti',
    ES: 'Kuwaitiano',
  },
  nationality_kyrgyz: {
    BR: 'Quirguiz',
    EN: 'Kyrgyz',
    ES: 'Kirguises',
  },
  nationality_laotian: {
    BR: 'Laosiano',
    EN: 'Laotian',
    ES: 'Laosiano',
  },
  nationality_latvian: {
    BR: 'Letão',
    EN: 'Latvian',
    ES: 'Letón',
  },
  nationality_lebanese: {
    BR: 'Libanês',
    EN: 'Lebanese',
    ES: 'Libanés',
  },
  nationality_liberian: {
    BR: 'Liberiano',
    EN: 'Liberian',
    ES: 'Liberiano',
  },
  nationality_libyan: {
    BR: 'Líbio',
    EN: 'Libyan',
    ES: 'Libio',
  },
  nationality_liechtensteiner: {
    BR: 'Liechtenstein',
    EN: 'Liechtensteiner',
    ES: 'Liechtensteiniano',
  },
  nationality_lithuanian: {
    BR: 'Lituano',
    EN: 'Lithuanian',
    ES: 'Lituano',
  },
  nationality_luxembourger: {
    BR: 'Luxemburguês',
    EN: 'Luxembourger',
    ES: 'Luxemburgués',
  },
  nationality_macedonian: {
    BR: 'Macedônio',
    EN: 'Macedonian',
    ES: 'Macedonio',
  },
  nationality_malagasy: {
    BR: 'Malgaxe',
    EN: 'Malagasy',
    ES: 'Madagascar',
  },
  nationality_malawian: {
    BR: 'Malawi',
    EN: 'Malawian',
    ES: 'Malawiano',
  },
  nationality_malaysian: {
    BR: 'Malaio',
    EN: 'Malaysian',
    ES: 'Malayo',
  },
  nationality_maldivian: {
    BR: 'Maldivas',
    EN: 'Maldivian',
    ES: 'Maldivo',
  },
  nationality_malian: {
    BR: 'Mali',
    EN: 'Malian',
    ES: 'Malí',
  },
  nationality_maltese: {
    BR: 'Maltês',
    EN: 'Maltese',
    ES: 'Maltés',
  },
  nationality_marshallese: {
    BR: 'Marechal',
    EN: 'Marshallese',
    ES: 'Marshallino',
  },
  nationality_mauritanian: {
    BR: 'Mauritano',
    EN: 'Mauritanian',
    ES: 'Mauritano',
  },
  nationality_mauritian: {
    BR: 'Mauriciano',
    EN: 'Mauritian',
    ES: 'Mauriciano',
  },
  nationality_mexican: {
    BR: 'Mexicano',
    EN: 'Mexican',
    ES: 'Mexicano',
  },
  nationality_micronesian: {
    BR: 'Micronésio',
    EN: 'Micronesian',
    ES: 'Micronesiano',
  },
  nationality_moldovan: {
    BR: 'Moldávia',
    EN: 'Moldovan',
    ES: 'Moldavo',
  },
  nationality_monacan: {
    BR: 'Monaca',
    EN: 'Monacan',
    ES: 'Monegasco',
  },
  nationality_montserrat: {
    BR: 'Montserrate',
    EN: 'Montserrat',
    ES: 'Mongol',
  },
  nationality_mongolian: {
    BR: 'Mongol',
    EN: 'Mongolian',
    ES: 'Montserrat',
  },
  nationality_moroccan: {
    BR: 'Marroquino',
    EN: 'Moroccan',
    ES: 'Marroquí',
  },
  nationality_mosotho: {
    BR: 'Mosoto',
    EN: 'Mosotho',
    ES: 'Lesotense',
  },
  nationality_mozambican: {
    BR: 'Moçambicano',
    EN: 'Mozambican',
    ES: 'Mozambiqueño',
  },
  nationality_namibian: {
    BR: 'Namibiano',
    EN: 'Namibian',
    ES: 'Namibio',
  },
  nationality_nauruan: {
    BR: 'Nauruano',
    EN: 'Nauruan',
    ES: 'Nauruano',
  },
  nationality_nepalese: {
    BR: 'Nepalês',
    EN: 'Nepalese',
    ES: 'Nepalés',
  },
  nationality_new_zealander: {
    BR: 'Neozelandês',
    EN: 'New Zealander',
    ES: 'Neozelandés',
  },
  nationality_ni_vanuatu: {
    BR: 'Ni Vanuatu',
    EN: 'Ni-Vanuatu',
    ES: 'Vanuatuense',
  },
  nationality_nicaraguan: {
    BR: 'Nicaraguense',
    EN: 'Nicaraguan',
    ES: 'Nicaragüense',
  },
  nationality_nigerian: {
    BR: 'Nigeriano',
    EN: 'Nigerian',
    ES: 'Nigeriano',
  },
  nationality_nigerien: {
    BR: 'Nigéria',
    EN: 'Nigerien',
    ES: 'Níger',
  },
  nationality_niue_island: {
    BR: 'Ilha Niue',
    EN: 'Niue Island',
    ES: 'Niuean',
  },
  nationality_norwegian: {
    BR: 'Norueguês',
    EN: 'Norwegian',
    ES: 'Norfolkiano',
  },
  nationality_norfolk_island: {
    BR: 'Ilha Norfolk',
    EN: 'Norfolk Island',
    ES: 'Noruego',
  },
  nationality_omani: {
    BR: 'Omã',
    EN: 'Omani',
    ES: 'Omaní',
  },
  nationality_pakistani: {
    BR: 'Paquistanês',
    EN: 'Pakistani',
    ES: 'Pakistaní',
  },
  nationality_palauan: {
    BR: 'Palauano',
    EN: 'Palauan',
    ES: 'Palauano',
  },
  nationality_panamanian: {
    BR: 'Panamenho',
    EN: 'Panamanian',
    ES: 'Panameño',
  },
  nationality_papua_new_guinean: {
    BR: 'Papua Nova Guiné',
    EN: 'Papua New Guinean',
    ES: 'Papú',
  },
  nationality_paraguayan: {
    BR: 'Paraguaio',
    EN: 'Paraguayan',
    ES: 'Paraguayo',
  },
  nationality_peruvian: {
    BR: 'Peruano',
    EN: 'Peruvian',
    ES: 'Peruano',
  },
  nationality_polish: {
    BR: 'Polonês',
    EN: 'Polish',
    ES: 'Polaco',
  },
  nationality_portuguese: {
    BR: 'Português',
    EN: 'Portuguese',
    ES: 'Portugués',
  },
  nationality_qatari: {
    BR: 'Catarense',
    EN: 'Qatari',
    ES: 'Qatari',
  },
  nationality_romanian: {
    BR: 'Romena',
    EN: 'Romanian',
    ES: 'Rumano',
  },
  nationality_russian: {
    BR: 'Russo',
    EN: 'Russian',
    ES: 'Ruso',
  },
  nationality_rwandan: {
    BR: 'Ruanda',
    EN: 'Rwandan',
    ES: 'Ruandés',
  },
  nationality_saint_lucian: {
    BR: 'São Luciano',
    EN: 'Saint Lucian',
    ES: 'Santa Elena',
  },
  nationality_salvadoran: {
    BR: 'Salvador',
    EN: 'Salvadoran',
    ES: 'Santalucense',
  },
  nationality_samoan: {
    BR: 'Samoano',
    EN: 'Samoan',
    ES: 'Salvadoreño',
  },
  nationality_san_marinese: {
    BR: 'São Marinho',
    EN: 'San Marinese',
    ES: 'Samoano',
  },
  nationality_san_vicent: {
    BR: 'São Vicente e Granadinas',
    EN: 'Saint Vincent and the Grenadines',
    ES: 'Samarines',
  },
  nationality_saint_helenians: {
    BR: 'Santos Helenianos',
    EN: 'Saint Helenians',
    ES: 'Son vicentino',
  },
  nationality_sao_tomean: {
    BR: 'São Tomé',
    EN: 'Sao Tomean',
    ES: 'Son tomense',
  },
  nationality_saudi: {
    BR: 'Saudita',
    EN: 'Saudi',
    ES: 'Saudita',
  },
  nationality_senegalese: {
    BR: 'Senegalês',
    EN: 'Senegalese',
    ES: 'Senegalés',
  },
  nationality_seychellois: {
    BR: 'Seychelles',
    EN: 'Seychellois',
    ES: 'Seychellois',
  },
  nationality_sierra_leonean: {
    BR: 'Serra Leoa',
    EN: 'Sierra Leonean',
    ES: 'Leonés',
  },
  nationality_singaporean: {
    BR: 'Cingapura',
    EN: 'Singaporean',
    ES: 'Singapur',
  },
  nationality_slovakian: {
    BR: 'Eslovaco',
    EN: 'Slovakian',
    ES: 'Eslovaco',
  },
  nationality_slovenian: {
    BR: 'Esloveno',
    EN: 'Slovenian',
    ES: 'Esloveno',
  },
  nationality_solomon_islander: {
    BR: 'Ilhéu de Salomão',
    EN: 'Solomon Islander',
    ES: 'Salomónica',
  },
  nationality_somali: {
    BR: 'Somali',
    EN: 'Somali',
    ES: 'Somalí',
  },
  nationality_south_african: {
    BR: 'África do Sul',
    EN: 'South African',
    ES: 'Sudafricano',
  },
  nationality_spanish: {
    BR: 'Espanhol',
    EN: 'Spanish',
    ES: 'Español',
  },
  nationality_sri_lankan: {
    BR: 'Sri Lanka',
    EN: 'Sri Lankan',
    ES: 'Cingalés',
  },
  nationality_sudanese: {
    BR: 'Sudanês',
    EN: 'Sudanese',
    ES: 'Apátrida',
  },
  nationality_surinamer: {
    BR: 'Suriname',
    EN: 'Surinamer',
    ES: 'Sudanés',
  },
  nationality_stateless: {
    BR: 'Apátrida',
    EN: 'Stateless',
    ES: 'Surinamés',
  },
  nationality_swazi: {
    BR: 'Suazi',
    EN: 'Swazi',
    ES: 'Swazilandia',
  },
  nationality_swedish: {
    BR: 'Sueco',
    EN: 'Swedish',
    ES: 'Sueco',
  },
  nationality_swiss: {
    BR: 'Suíço',
    EN: 'Swiss',
    ES: 'Suizo',
  },
  nationality_syrian: {
    BR: 'Sírio',
    EN: 'Syrian',
    ES: 'Sírio',
  },
  nationality_taiwanese: {
    BR: 'Taiwanesa',
    EN: 'Taiwanese',
    ES: 'Taiwanés',
  },
  nationality_tajik: {
    BR: 'Tajique',
    EN: 'Tajik',
    ES: 'Tayiko',
  },
  nationality_tanzanian: {
    BR: 'Tanzânia',
    EN: 'Tanzanian',
    ES: 'Tanzano',
  },
  nationality_thai: {
    BR: 'Tailandês',
    EN: 'Thai',
    ES: 'Tailandés',
  },
  nationality_togolese: {
    BR: 'Togolês',
    EN: 'Togolese',
    ES: 'Togolés',
  },
  nationality_tokelau: {
    BR: 'Toquela',
    EN: 'Tokelau',
    ES: 'Toquelauano',
  },
  nationality_tongan: {
    BR: 'Tonganês',
    EN: 'Tongan',
    ES: 'Tongano',
  },
  nationality_trinidadian_or_tobagonian: {
    BR: 'Trinidadiano ou Tobagoniano',
    EN: 'Trinidadian or Tobagonian',
    ES: 'Trinitaria',
  },
  nationality_tunisian: {
    BR: 'Tunisiano',
    EN: 'Tunisian',
    ES: 'Tunecino',
  },
  nationality_turkish: {
    BR: 'Turco',
    EN: 'Turkish',
    ES: 'Turco',
  },
  nationality_turkmens: {
    BR: 'Turcomenos',
    EN: 'Turkmens',
    ES: 'Turcomano',
  },
  nationality_tuvaluan: {
    BR: 'Tuvaluano',
    EN: 'Tuvaluan',
    ES: 'Turco-caicense',
  },
  nationality_turks_caicos: {
    BR: 'Ilhas Turcas e Caicos',
    EN: 'Turks and Caicos Islands',
    ES: 'Tuvaluano',
  },
  nationality_ugandan: {
    BR: 'Uganda',
    EN: 'Ugandan',
    ES: 'Ugandés',
  },
  nationality_ukrainian: {
    BR: 'Ucraniano',
    EN: 'Ukrainian',
    ES: 'Ucraniano',
  },
  nationality_us_minor_islands: {
    BR: 'Ilhas Menores Distantes dos Estados Unidos',
    EN: 'United States Minor Outlying Islands',
    ES: 'Islas Ultramarinas Menores de los Estados Unidos',
  },
  nationality_uruguayan: {
    BR: 'Uruguaio',
    EN: 'Uruguayan',
    ES: 'Uruguayo',
  },
  nationality_uzbekistani: {
    BR: 'Uzbequistão',
    EN: 'Uzbekistani',
    ES: 'Uzbeko',
  },
  nationality_vaticano: {
    BR: 'Vaticano',
    EN: 'Vaticano',
    ES: 'Vaticano',
  },
  nationality_venezuelan: {
    BR: 'Venezuelano',
    EN: 'Venezuelan',
    ES: 'Venezolano',
  },
  nationality_vietnamese: {
    BR: 'Vietnamita',
    EN: 'Vietnamese',
    ES: 'Vietnamita',
  },
  nationality_wallis_futuna_island: {
    BR: 'Ilhas Wallis e Futuna',
    EN: 'Wallis and Futuna Islands',
    ES: 'Wallisiano',
  },
  nationality_western_sahara: {
    BR: 'Saara Ocidental',
    EN: 'Western Sahara',
    ES: 'Sara occidental',
  },
  nationality_yemenite: {
    BR: 'Iemenita',
    EN: 'Yemenite',
    ES: 'Yemenite',
  },
  nationality_yugoslavian: {
    BR: 'Iugoslava',
    EN: 'Yugoslavian',
    ES: 'Yugoslavo',
  },
  nationality_zairense: {
    BR: 'Zairense',
    EN: 'Zairense',
    ES: 'Zaire',
  },
  nationality_zambian: {
    BR: 'Zâmbia',
    EN: 'Zambian',
    ES: 'Zambiano',
  },
  nationality_zimbabwean: {
    BR: 'Zimbábue',
    EN: 'Zimbabwean',
    ES: 'Zimbabuense',
  },
}
