import { useContext } from 'react'
import { SignatureContext } from '../context/SignatureContext'

export const useSignatureNavigationPage = () => {
  const context = useContext(SignatureContext)

  if (context === undefined) {
    throw new Error('useSignatureNavigationPage must be used within a SignatureProvider')
  }

  return context
}
