import React from 'react'

type getLabelParams = {
  labelText: React.ReactNode,
  required: boolean,
  showAsterisk: boolean,
  optionalText: string
  hasBlankLabel?: boolean
}

export const getLabel = ({
 labelText, required, showAsterisk, optionalText, hasBlankLabel,
}: getLabelParams) => {
  if (!labelText) {
    return undefined
  }

  if (required && showAsterisk) {
    return (
      <>
        {labelText}
        {' *'}
      </>
    )
  }

  if (!required && optionalText) {
    return (
      <>
        {labelText}
        {` ${optionalText}`}
      </>
    )
  }

  if (hasBlankLabel) {
    return (<>&nbsp;</>)
  }

  return (
    <>
      {labelText}
    </>
  )
}
