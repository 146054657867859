import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { AcceptAuthorizationCode } from '@/domain/use-cases/authorization-code/accept-authorization-code'
import { RemoteAcceptAuthorizationCode } from '@/data/use-cases/authorization-code'

export const makeRemoteAcceptAuthorizationCode = (): AcceptAuthorizationCode => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const httpPostClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteAcceptAuthorizationCode(
    httpPostClient,
  )
}
