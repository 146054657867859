import { SelectOption } from '@/main/components'

type Keys = {
  value: string
  label: string
}

export const mapResponseSelectOption = (
  fields: Array<any>,
  { value, label }: Keys,
): Array<SelectOption> => fields && fields.map((field) => ({
    value: field[value],
    label: field[label],
  }))
