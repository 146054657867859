
import { RemoteGetCnCredit } from '@/data/use-cases/person/remote-get-cn-credit'
import { GetCnCreditData } from '@/domain/use-cases/person/minnegocio/get-cn-credit-data'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeGetCnCredit = (): GetCnCreditData => {
  const apiUrl = `${'/person/credit-data'}`
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)
  const remoteGetCnCredit = new RemoteGetCnCredit(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteGetCnCredit
}