export const getCountryByUrl = (): string | undefined => {
  const url = window?.location?.href?.split('/')
  try {
    const country = url?.[3]?.toUpperCase()
    return country || undefined
  } catch (error) {
    console.error('Error getting country from URL:', error)
    return undefined
  }
}
