import React from 'react'
import {
  CardContainer,
  StatusBar,
} from '@/main/components/status-card-container/status-card-container.styles'

type StatusCardContainerProps = {
  children: React.ReactNode,
  status?: colorFromStatus
}

export enum colorFromStatus {
  SUCCESS= 'success',
  ALERT = 'alert',
  PRIMARY = 'primary',
}

export const StatusCardContainer: React.FC<StatusCardContainerProps> = ({ children, status }) => (
  <CardContainer>
    {status && <StatusBar status={status} data-testid="status-bar" />}
    {children}
  </CardContainer>
)
