import { CompanyId } from '@/domain/models/company/company-id'
import { CountryIdAlpha2 } from '@/domain/models/country'
import { makeGetAccountBindLink } from '@/main/factories/remote'
import { useHttpRequest } from '@/main/hooks/useHttpRequest'

const getAccountBindLink = makeGetAccountBindLink()

export type useAccountBindLinkParamsType = {
  countryCode: CountryIdAlpha2,
  companyId: CompanyId,
  personId: string
}

export const useAccountBindLink = (
  params: useAccountBindLinkParamsType,
) => {
  const { data, error, isLoading } = useHttpRequest(
    {
      countryCode: params.countryCode,
      companyId: params.companyId,
      personId: params.personId,
    },
    {
      client: getAccountBindLink.get, shouldFetchOnStart: true,
    },
  )

  return {
    accountBindLinkData: data,
    accountBindLinkError: error,
    accountBindLinkLoading: isLoading,
  }
}
