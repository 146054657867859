import { HttpPatchClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { BadRequestError } from '@/data/protocols/errors'
import { CountryIdAlpha2 } from '@/domain/models/country/country-id-alpha-2'
import {
  UnlinkAgreement,
  UnlinkAgreementParams,
  UnlinkAgreementResponse,
} from '@/domain/use-cases/agreement'

export type UnlinkAgreementHttpRequestHeaders = {
  'x-api-key': string
}

type UnlinkAgreementHttpRequestBody = {
  products: string[]
}

export type UnlinkAgreementHttpRequestResponseBody = {
  id: string
  createdAt: string
  updatedAt: string
  products: string[]
  commercialStructure: string
  countryCode: CountryIdAlpha2
}

export class RemoteUnlinkAgreement
  implements UnlinkAgreement {
  constructor(
    private readonly url: string,
    private readonly httpPatchClient: HttpPatchClient<
      UnlinkAgreementHttpRequestHeaders,
      UnlinkAgreementHttpRequestBody,
      UnlinkAgreementHttpRequestResponseBody
      >,
    private readonly xApiKey: string,
  ) {}

  unlink = async (
    params: UnlinkAgreementParams,
  ): Promise<UnlinkAgreementResponse> => {
    const headers: UnlinkAgreementHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
    }

    const body: UnlinkAgreementHttpRequestBody = {
      products: params.products,
    }

    const httpResponse = await this.httpPatchClient.patch({
      url: this.url.replace(/:personId/i, params.personId),
      headers,
      body,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
