export enum DigitalStoreCurrentStatus {
  ACTIVE = 'ACTIVE',
  CEASED = 'CEASED',
  DENIED_BANK_ACCOUNT = 'DENIED_BANK_ACCOUNT',
  DENIED_PAYMENT_ACCOUNT = 'DENIED_PAYMENT_ACCOUNT',
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  PENDING_AGREEMENTS = 'PENDING_AGREEMENTS',
  PENDING_BANK_ACCOUNT = 'PENDING_BANK_ACCOUNT',
  PENDING_FRANCHISE_AGREEMENT = 'PENDING_FRANCHISE_AGREEMENT',
  PENDING_PAYMENT_ACCOUNT = 'PENDING_PAYMENT_ACCOUNT',
  SUSPENDED = 'SUSPENDED',
  UNABLE_TO_SELL = 'UNABLE_TO_SELL',
}
