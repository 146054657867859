import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteSendCodeValidation } from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'

export const makeRemoteSendCodeValidation = (): RemoteSendCodeValidation => {
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteSendCodeValidation(
    httpGetClient,
    xApiKey,
  )
}
